var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormatYearMonthDate } from '@src/app/helper';
import Datepicker from '@src/components/Atoms/Date/Date';
import DrawerComponent from '@src/components/Atoms/Drawer/Drawer';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useMemo } from 'react';
dayjs.extend(isSameOrAfter);
var ApprovalTransferDrawerForm = function (_a) {
    var open = _a.open, formik = _a.formik, minDate = _a.minDate, onClose = _a.onClose;
    var handleChangeDate = function (e) {
        formik.setFieldValue('paymentDue', FormatYearMonthDate(e));
    };
    var isInputOK = useMemo(function () {
        return dayjs(formik.values.paymentDue).isSameOrAfter(dayjs(minDate), 'date');
    }, [formik.values, minDate]);
    return (_jsx(DrawerComponent, __assign({ open: open, handleClose: onClose, title: 'Konfirmasi Approval', size: 'MD' }, { children: _jsxs("form", __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs("div", { children: [_jsx("label", __assign({ className: 'form-label fs-6 fw-bolder text-dark required', htmlFor: 'paymentDue' }, { children: "Due date" })), _jsx(Datepicker, { useForm: formik, date: formik.values.paymentDue, handleonchange: handleChangeDate }), _jsx("small", { children: "Due date tidak boleh kurang dari +13 hari sejak dibuat dari hari ini." })] }), _jsx("div", __assign({ className: 'my-5' }, { children: _jsxs("div", __assign({ className: 'd-flex gap-2 justify-content-end' }, { children: [_jsx("button", __assign({ type: 'button', className: 'btn btn-secondary btn-active-light-primary btn-sm', onClick: onClose }, { children: "Tutup" })), _jsx("button", __assign({ type: 'submit', className: 'btn btn-primary btn-sm ms-1', disabled: !isInputOK }, { children: "Simpan" }))] })) }))] })) })));
};
export { ApprovalTransferDrawerForm };
