var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MultiDimensionalFilter, userInformation } from '@src/app/helper';
import RJForm from '@src/components/molecules/Job/Form/RepeatJobForm';
import { KTCard } from '@src/_metronic/helpers';
import { PageTitle } from '@src/_metronic/layout/core';
import { useFormik } from 'formik';
import { Fragment, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import * as Yup from 'yup';
import { useTalents } from '../../TalentPage/TalentHooks/Talent.hooks';
import { useRepeatJob } from './RepeatJob.requests';
//breadcrumb
var usersBreadcrumbs = [
    {
        title: 'Kerja Sama',
        path: '/job/all-job',
        isSeparator: false,
        isActive: false,
    },
];
export var RepeatJobFormSchema = Yup.object({
    socialMediaPubId: Yup.string().required('Field harus diisi'),
    divisionPubId: Yup.string().required('Field harus diisi'),
    teamPubId: Yup.string().required('Field harus diisi'),
    talentPubId: Yup.string().required('Field harus diisi'),
});
var RepeatJob = {
    socialMediaPubId: '',
    divisionPubId: '',
    teamPubId: '',
    talentPubId: '',
};
var RepeatJobIndex = function () {
    var navigate = useNavigate();
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var socialMedias = JSON.parse(userInformation).socialMedias;
    var divisions = JSON.parse(userInformation).divisions;
    var teams = JSON.parse(userInformation).teams;
    var _b = useState([]), selectedTalent = _b[0], setSelectedTalent = _b[1];
    var _c = useState([]), selectedSocialMedia = _c[0], setSelectedSocialMedia = _c[1];
    var _d = useState([]), selectedDivision = _d[0], setSelectedDivision = _d[1];
    var _e = useState([]), selectedTeam = _e[0], setSelectedTeam = _e[1];
    var _f = useState(''), selectedTalentValue = _f[0], setSelectedTalentValue = _f[1];
    var _g = useState(''), selectedSocialMediaValue = _g[0], setSelectedSocialMediaValue = _g[1];
    var _h = useState(''), selectedDivisionValue = _h[0], setSelectedDivisionValue = _h[1];
    var _j = useState(''), selectedTeamValue = _j[0], setSelectedTeamValue = _j[1];
    var _k = useState({
        page: 1,
        pageSize: 100,
        keyword: '',
        filterBy: "[]",
    }), filter = _k[0], setFilter = _k[1];
    var debouncedKeyword = useDebounce(filter.keyword, 1000)[0];
    var talents = useTalents(__assign(__assign({}, filter), { keyword: debouncedKeyword }), { enabled: !!debouncedKeyword }).talents;
    var createRepeatJob = useRepeatJob().createRepeatJob;
    var optionSocialMedia = socialMedias &&
        socialMedias.length > 0 &&
        socialMedias.map(function (social) { return ({
            label: social.name,
            value: social.pubId,
        }); });
    var optionTeam = teams &&
        teams.length > 0 &&
        teams.map(function (team) { return ({
            label: team.name,
            value: team.pubId,
        }); });
    var optionDivisions = divisions &&
        divisions.length > 0 &&
        divisions.map(function (division, index) { return ({
            label: "".concat(division.name, " - ").concat(socialMedias && socialMedias[index].name),
            value: division.pubId,
        }); });
    var optionTalents = useMemo(function () {
        var _a;
        if (talents === null || talents === void 0 ? void 0 : talents.data) {
            return (_a = talents === null || talents === void 0 ? void 0 : talents.data) === null || _a === void 0 ? void 0 : _a.map(function (talent) {
                var profiles = talent.profiles.map(function (profile) { return profile.profileName; }).join(' / ');
                return {
                    label: "".concat(talent.name, " (").concat(profiles, ")"),
                    value: talent.pubId,
                };
            });
        }
        return [];
    }, [talents === null || talents === void 0 ? void 0 : talents.data]);
    //search & filters
    var handleMultiDimensionalFilterChange = function (event) {
        var filterValue = filter.filterBy !== undefined ? JSON.parse(filter.filterBy) : '';
        if (filter.filterBy === '') {
            var filterCriteria = [[event.target.name, event.target.value]];
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterCriteria) }));
        }
        else {
            var filterNew = MultiDimensionalFilter(event, filterValue);
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterNew) }));
        }
    };
    var repeatJobDetail = { data: RepeatJob }.data;
    var repeatJobValues = {
        socialMediaPubId: (repeatJobDetail === null || repeatJobDetail === void 0 ? void 0 : repeatJobDetail.socialMediaPubId) || '',
        divisionPubId: (repeatJobDetail === null || repeatJobDetail === void 0 ? void 0 : repeatJobDetail.divisionPubId) || '',
        teamPubId: (repeatJobDetail === null || repeatJobDetail === void 0 ? void 0 : repeatJobDetail.teamPubId) || '',
        talentPubId: (repeatJobDetail === null || repeatJobDetail === void 0 ? void 0 : repeatJobDetail.talentPubId) || '',
    };
    var RepeatJobFormik = useFormik({
        initialValues: repeatJobDetail ? repeatJobValues : RepeatJob,
        enableReinitialize: true,
        validationSchema: RepeatJobFormSchema,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var e_1, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoading(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, createRepeatJob(__assign({}, values))];
                    case 2:
                        _a.sent();
                        setLoading(false);
                        navigate("/job/all-job#DoneChat");
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        error = e_1.response.data;
                        setLoading(false);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); },
    });
    var selectedTalentDetail = useMemo(function () {
        return talents === null || talents === void 0 ? void 0 : talents.data.find(function (t) { return t.pubId === selectedTalentValue; });
    }, [selectedTalentValue, talents === null || talents === void 0 ? void 0 : talents.data]);
    function changeFormat(name, value) {
        var event = { target: { name: '', value: '' } };
        event.target.name = name;
        event.target.value = value;
        handleMultiDimensionalFilterChange(event);
    }
    function handleChangeSocialMedia(e) {
        setSelectedSocialMedia(e);
        setSelectedSocialMediaValue(e.value);
        changeFormat('talentTeams.division.socialMedia.pubId', e.value);
        RepeatJobFormik.setFieldValue('socialMediaPubId', e.value);
    }
    function handleChangeDivision(e) {
        setSelectedDivision(e);
        setSelectedDivisionValue(e.value);
        changeFormat('talentTeams.division.pubId', e.value);
        RepeatJobFormik.setFieldValue('divisionPubId', e.value);
    }
    function handleChangeTeam(e) {
        setSelectedTeam(e);
        setSelectedTeamValue(e.value);
        changeFormat('talentTeams.team.pubId', e.value);
        RepeatJobFormik.setFieldValue('teamPubId', e.value);
    }
    function handleChangeTalent(e) {
        setSelectedTalent(e);
        setSelectedTalentValue(e.value);
        RepeatJobFormik.setFieldValue('talentPubId', e.value);
    }
    function handleChangeSearchTalent(keyword) {
        setFilter(__assign(__assign({}, filter), { keyword: keyword }));
    }
    return (_jsxs(Fragment, { children: [_jsx(PageTitle, __assign({ breadcrumbs: usersBreadcrumbs }, { children: "Kerja Sama Ulang" })), _jsx(KTCard, { children: _jsx("div", __assign({ className: 'd-flex flex-column' }, { children: _jsx("div", __assign({ className: 'card-body py-4' }, { children: _jsxs("form", __assign({ id: 'kt_team_form', className: 'form', noValidate: true, onSubmit: RepeatJobFormik.handleSubmit }, { children: [_jsx(RJForm, { useForm: RepeatJobFormik, socialMediaList: optionSocialMedia, divisionList: optionDivisions, teamList: optionTeam, talentList: optionTalents || [], selectedSocialMedia: selectedSocialMedia, setSelectedSocialMedia: handleChangeSocialMedia, selectedTeam: selectedTeam, setSelectedDivision: handleChangeDivision, selectedDivision: selectedDivision, setSelectedTeam: handleChangeTeam, selectedTalent: selectedTalent, setSelectedTalent: handleChangeTalent, onSearchTalent: handleChangeSearchTalent }), (selectedTalentDetail === null || selectedTalentDetail === void 0 ? void 0 : selectedTalentDetail.isBlacklisted) && (_jsxs("div", __assign({ className: 'alert alert-danger' }, { children: [_jsx("h4", { children: "Talent Blacklist" }), _jsx("p", { children: selectedTalentDetail.blacklistReason }), _jsxs("p", { children: ["Di-blacklist oleh: ", selectedTalentDetail.blacklistedBy] })] }))), (selectedTalentDetail === null || selectedTalentDetail === void 0 ? void 0 : selectedTalentDetail.talentStatus) === 'OnGoing' && (_jsxs("div", __assign({ className: 'alert alert-warning' }, { children: [_jsx("h4", { children: "Talent Status" }), _jsx("p", { children: "Talent tersebut sedang dalam proses kerjasama." })] }))), _jsx("div", __assign({ className: 'button-container' }, { children: _jsxs("button", __assign({ type: 'submit', id: 'kt_team_submit', className: 'btn btn-primary ', disabled: RepeatJobFormik.isSubmitting ||
                                            !RepeatJobFormik.isValid ||
                                            (selectedTalentDetail === null || selectedTalentDetail === void 0 ? void 0 : selectedTalentDetail.isBlacklisted) ||
                                            (selectedTalentDetail === null || selectedTalentDetail === void 0 ? void 0 : selectedTalentDetail.talentStatus) === 'OnGoing' }, { children: [!loading && _jsx("span", __assign({ className: 'indicator-label' }, { children: "Submit" })), loading && (_jsxs("span", __assign({ className: 'indicator-progress', style: { display: 'block' } }, { children: ["Mohon tunggu...", _jsx("span", { className: 'spinner-border spinner-border-sm align-middle ms-2' })] })))] })) }))] })) })) })) })] }));
};
export default RepeatJobIndex;
