var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import SelectFormik from '@src/components/Atoms/Formik/Select/SelectFormik';
import { optionPajakList, optionTaxList } from '@src/app/helper';
import Date from '@src/components/Atoms/Date/Date';
import '@src/app/styles/style.css';
var TCForm = function (_a) {
    var useForm = _a.useForm, startDate = _a.startDate, endDate = _a.endDate, onChangeStartDate = _a.onChangeStartDate, onChangeEndDate = _a.onChangeEndDate, checked = _a.checked, onChangeCheck = _a.onChangeCheck;
    var id = useParams().id;
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: 'mx-5 mx-xl-15 my-7' }, { children: [_jsx("label", __assign({ className: 'form-label fs-6 fw-bolder text-dark required' }, { children: "Tanggal Mulai" })), _jsx(Date, { date: startDate, handleonchange: onChangeStartDate })] })), _jsxs("div", __assign({ className: 'mx-5 mx-xl-15 my-7' }, { children: [_jsxs("div", __assign({ className: 'form-check form-check-custom form-check-solid' }, { children: [_jsx("input", { className: 'form-check-input input-check-custom', type: 'checkbox', onChange: onChangeCheck }), _jsx("label", __assign({ className: 'form-label fs-6 fw-bolder text-dark' }, { children: "Sampai" }))] })), checked && _jsx(Date, { date: endDate, handleonchange: onChangeEndDate })] })), _jsxs("div", __assign({ className: 'mx-5 mx-xl-15 my-7' }, { children: [_jsx("label", __assign({ className: 'form-label fs-6 fw-bolder text-dark' }, { children: "Pajak" })), _jsx(SelectFormik, { label: '', name: 'pajakCategory', formik: useForm, optionList: optionTaxList }), _jsx(SelectFormik, { label: 'Berdasarkan', name: 'dateBy', formik: useForm, optionList: optionPajakList, isRequired: true })] }))] }));
};
export default TCForm;
