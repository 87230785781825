var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var Textarea = function (_a) {
    var label = _a.label, placeholder = _a.placeholder, name = _a.name, value = _a.value, onChange = _a.onChange;
    return (_jsxs("div", __assign({ className: 'fv-row mb-7' }, { children: [_jsx("label", __assign({ className: 'required fw-bold fs-6 mb-2' }, { children: label })), _jsx("textarea", { placeholder: placeholder, name: name, value: value, onChange: function (e) { return onChange(e); }, className: 'form-control mb-3 mb-lg-0' })] })));
};
export default Textarea;
