var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { paginationOptions, RoleList, talentDetailTabOptions, userInformation } from '@src/app/helper';
import DrawerComponent from '@src/components/Atoms/Drawer/Drawer';
import ProfileForm from '@src/components/Atoms/Drawer/TalentDetailFormDrawer/ProfileForm';
import ProfileHistoryForm from '@src/components/Atoms/Drawer/TalentDetailFormDrawer/ProfileHistoryForm';
import ProfileShareForm from '@src/components/Atoms/Drawer/TalentDetailFormDrawer/ProfileShareForm';
import Input from '@src/components/Atoms/Input/Input';
import Loading from '@src/components/Atoms/Loading/Loading';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import DrawerForm from '@src/components/molecules/Drawer/DrawerForm';
import RecordAdmin from '@src/components/molecules/Record/Admin';
import { KTCard } from '@src/_metronic/helpers';
import { useFormik } from 'formik';
import { Fragment, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import * as Yup from 'yup';
import { useProfile, useProfileById, useProfileHistory } from '../../TalentHooks/TalentDetail.hooks';
import { initialValue } from '../TalentDetail.types';
var ProfileFormSchema = Yup.object().shape({
    // socialMedia: Yup.string().required('Address is required'),
    profileName: Yup.string().required('Field belum diisi'),
    profileLink: Yup.string().required('Field belum diisi'),
});
var Profile = function (_a) {
    var datas = _a.datas, isLoading = _a.isLoading, filter = _a.filter, handlePageSizeChange = _a.handlePageSizeChange, handlePageChange = _a.handlePageChange, handleFilterChange = _a.handleFilterChange, dataTalent = _a.dataTalent;
    var role = JSON.parse(userInformation).roles[0];
    var id = useParams().id;
    var intl = useIntl();
    var _b = useState(false), isOpenAdd = _b[0], setIsOpenAdd = _b[1];
    var _c = useState(false), isOpenHistory = _c[0], setIsOpenHistory = _c[1];
    var _d = useState(false), isOpenShare = _d[0], setIsOpenShare = _d[1];
    var _e = useState(), shareData = _e[0], setShareData = _e[1];
    var _f = useState(''), tabName = _f[0], setTabName = _f[1];
    var _g = useState(''), idForEdit = _g[0], setIdForEdit = _g[1];
    var _h = useProfile(id, false), createProfile = _h.createProfile, updateProfile = _h.updateProfile, deleteProfile = _h.deleteProfile;
    var profileDetailQuery = useProfileById(id, idForEdit, {
        enabled: Boolean(idForEdit && isOpenAdd),
    });
    var profileValues = useMemo(function () {
        if (!idForEdit)
            return initialValue;
        var data = profileDetailQuery.data;
        return {
            socialMediaPubId: (data === null || data === void 0 ? void 0 : data.socialMedia) ? data === null || data === void 0 ? void 0 : data.socialMedia.pubId : '',
            profileName: (data === null || data === void 0 ? void 0 : data.profileName) || '',
            profileLink: (data === null || data === void 0 ? void 0 : data.profileLink) || '',
            isBlasted: true,
        };
    }, [idForEdit, profileDetailQuery.data]);
    var _j = useProfileHistory(idForEdit, !!idForEdit), profileHistory = _j.data, refetch = _j.refetch;
    var columns = [
        {
            name: 'Sosial Media',
            selector: function (row) { return row.socialMedia.name; },
            sortable: true,
        },
        {
            name: 'Nama',
            selector: function (row) { return row.profileName; },
            sortable: true,
        },
        {
            name: 'Oleh',
            cell: function (record) { return _jsx(RecordAdmin, { record: record }); },
            sortable: true,
        },
        {
            name: 'Action',
            cell: function (record) {
                return (_jsxs("div", { children: [_jsx("button", __assign({ type: 'button', onClick: function () { return historyItem(record); }, className: 'btn btn-primary btn-sm' }, { children: "History" })), _jsx("button", __assign({ type: 'button', onClick: function () { return editItem(record); }, className: 'btn btn-warning mx-2 btn-sm' }, { children: "Edit" })), _jsx("button", __assign({ type: 'button', onClick: function () { return deleteItem(record); }, className: 'btn btn-danger mx-2 btn-sm' }, { children: "Delete" })), role == RoleList.SpvCampaignExecutive && (_jsx("button", __assign({ type: 'button', onClick: function () { return shareItem(record); }, className: 'btn btn-success btn-sm' }, { children: "Share" })))] }));
            },
        },
    ];
    var handleAdd = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setIsOpenAdd(true);
            setTabName(talentDetailTabOptions.profile);
            setIdForEdit('');
            return [2 /*return*/];
        });
    }); };
    var historyItem = function (record) {
        setIdForEdit(record.pubId);
        setIsOpenHistory(true);
        setTabName(talentDetailTabOptions.profile);
    };
    var editItem = function (record) {
        setIsOpenAdd(true);
        setTabName(talentDetailTabOptions.profile);
        setIdForEdit(record.pubId);
    };
    var deleteItem = function (record) {
        swal({
            title: intl.formatMessage({ id: 'GENERAL.VALIDATION' }),
            text: '',
            icon: 'warning',
            buttons: [
                intl.formatMessage({ id: 'GENERAL.VALIDATION.NO' }),
                intl.formatMessage({ id: 'GENERAL.VALIDATION.YES' }),
            ],
            dangerMode: true,
        }).then(function (willDelete) { return __awaiter(void 0, void 0, void 0, function () {
            var e_1, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!willDelete) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, deleteProfile(__assign({ id: id }, record))];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        error = e_1.response.data;
                        toast.error(error.message);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    };
    var shareItem = function (record) {
        setShareData(record);
        setIsOpenShare(true);
        setTabName(talentDetailTabOptions.profile);
    };
    var ProfileFormik = useFormik({
        initialValues: profileValues,
        enableReinitialize: true,
        validationSchema: ProfileFormSchema,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var e_2, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!idForEdit) return [3 /*break*/, 2];
                        return [4 /*yield*/, updateProfile(__assign({ pubId: idForEdit, id: id }, values))];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, createProfile(__assign({ id: id }, values))];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        setIsOpenAdd(false);
                        ProfileFormik.resetForm();
                        return [3 /*break*/, 6];
                    case 5:
                        e_2 = _a.sent();
                        error = e_2.response.data;
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        }); },
    });
    return (_jsxs(Fragment, { children: [_jsx(KTCard, { children: _jsxs("div", __assign({ className: 'card' }, { children: [_jsxs("div", __assign({ className: 'card-header border-0 py-0 px-0 d-flex flex-row' }, { children: [_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Input, { placeholder: 'Search', type: 'text', name: 'keyword', value: filter.keyword, onChange: function (e) {
                                            handleFilterChange(e);
                                        } }) })), _jsx("div", __assign({ className: 'card-toolbar' }, { children: _jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-primary', onClick: function () { return handleAdd(); } }, { children: "Tambah" })) }))] })), _jsx("div", __assign({ className: 'card-body p-0' }, { children: !isLoading ? (datas && datas.length > 0 ? (_jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: datas ? datas : [], noHeader: true, progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: filter.filterBy === ''
                                    ? datas && datas.recordsTotal
                                    : datas && datas.recordsFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange }, datas && datas.length)) : (_jsx(NotFound, {}))) : (_jsx(Loading, {})) }))] })) }), _jsx(DrawerForm, { tabName: tabName, useForm: ProfileFormik, isOpen: isOpenAdd, handleClose: setIsOpenAdd, onSubmit: ProfileFormik.handleSubmit, component: ProfileForm, idForEdit: idForEdit }), _jsx(DrawerComponent, __assign({ open: isOpenHistory, handleClose: function () { return setIsOpenHistory(false); }, title: "History ".concat(tabName), size: 'MD' }, { children: _jsx(ProfileHistoryForm, { datas: profileHistory, handleClose: function () { return setIsOpenHistory(false); } }) })), _jsx(DrawerComponent, __assign({ open: isOpenShare, handleClose: function () { return setIsOpenShare(false); }, title: "Share ".concat(tabName), size: 'MD' }, { children: _jsx(ProfileShareForm, { datas: shareData, dataTalent: dataTalent, handleClose: function () { return setIsOpenShare(false); } }) }))] }));
};
export default Profile;
