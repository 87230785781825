var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { KTSVG } from '@src/_metronic/helpers';
import { Fragment } from 'react';
var BlastActionDropdown = function (_a) {
    var record = _a.record, blastItem = _a.blastItem;
    return (_jsxs(Fragment, { children: [_jsxs("button", __assign({ type: 'button', className: 'btn btn-light btn-active-light-primary btn-sm', "data-kt-menu-trigger": 'click', "data-kt-menu-placement": 'bottom-end' }, { children: ["Actions", _jsx(KTSVG, { path: '/media/icons/duotune/arrows/arr072.svg', className: 'svg-icon-5 m-0' })] })), _jsx("div", __assign({ className: 'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4', "data-kt-menu": 'true' }, { children: _jsx("div", __assign({ className: 'menu-item px-3' }, { children: _jsx("span", __assign({ className: 'menu-link px-3', onClick: function () {
                            blastItem(record);
                        } }, { children: "Blast" })) })) }))] }));
};
export default BlastActionDropdown;
