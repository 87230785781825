var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DefaultAllList, optionJobTypeAll, paginationOptions, RoleList, userInformation, } from '@src/app/helper';
import ApprovalActionKerjasama from '@src/app/modules/job/ApprovalActionKerjasama';
import { useDivisions } from '@src/app/pages/DivisionPage/DivisionHooks/Division.hooks';
import { RepeatJobFormSchema } from '@src/app/pages/JobPage/RepeatJob/RepeatJob';
import { useRepeatJob } from '@src/app/pages/JobPage/RepeatJob/RepeatJob.requests';
import { useSocialMedia } from '@src/app/pages/SocialMediaPage/SocialMedia/SocialMedia.hooks';
import { useTeams } from '@src/app/pages/TeamPage/TeamHooks/Team.hooks';
import DrawerComponent from '@src/components/Atoms/Drawer/Drawer';
import MultiSelectFormik from '@src/components/Atoms/Formik/MultiSelect/MultiSelectFormik';
import Input from '@src/components/Atoms/Input/Input';
import Loading from '@src/components/Atoms/Loading/Loading';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import Select from '@src/components/Atoms/Select/Select';
import RecordAdmin from '@src/components/molecules/Record/Admin';
import RecordCollaboration from '@src/components/molecules/Record/Collaboration';
import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
var Kerjasama = function (_a) {
    var datas = _a.datas, talentDetail = _a.talentDetail, refetchTalent = _a.refetchTalent, isLoading = _a.isLoading, filter = _a.filter, handlePageSizeChange = _a.handlePageSizeChange, handlePageChange = _a.handlePageChange, onchange = _a.onchange, handleMultiDimensionalFilterChange = _a.handleMultiDimensionalFilterChange;
    var navigate = useNavigate();
    var socialMedia = useSocialMedia().socialMedia;
    var role = JSON.parse(userInformation).roles[0];
    var teams = (JSON.parse(userInformation).teams || []);
    var talentTeams = (talentDetail === null || talentDetail === void 0 ? void 0 : talentDetail.talentTeams) || [];
    var hasATeamInAdmin = useMemo(function () {
        return talentTeams.some(function (talentTeam) {
            return teams.some(function (team) { return team.pubId === talentTeam.team.pubId; });
        });
    }, [teams, talentTeams]);
    var _b = useState(false), isOpenRepeatJob = _b[0], setOpenRepeatJob = _b[1];
    var handleClickRepeatJob = function () {
        if ((talentDetail === null || talentDetail === void 0 ? void 0 : talentDetail.talentStatus) === 'OnGoing') {
            swal({
                text: 'Talent ini sedang dalam proses kerjasama.',
                icon: 'warning',
            });
        }
        else {
            setOpenRepeatJob(true);
        }
    };
    var optionSocialMedia = socialMedia &&
        socialMedia.map(function (social) { return ({
            name: social.name,
            value: social.pubId,
        }); });
    optionSocialMedia && optionSocialMedia.unshift(DefaultAllList);
    var columns = [
        {
            name: 'Kerja sama',
            cell: function (record) { return (_jsx(RecordCollaboration, { id: record.pubId, record: record.job || '', excludes: ['bundling'] })); },
            sortable: true,
        },
        {
            name: 'Total Fee',
            cell: function (record) {
                return (_jsxs(_Fragment, { children: [_jsx("p", { children: record.job ? "Rp ".concat(record.job.totalFee.toLocaleString()) : '-' }), _jsxs("span", __assign({ className: 'badge badge-warning fw-bolder fs-7 mb-3' }, { children: ["Rate Bundling: Rp ", record.job.totalFeeBundling] }))] }));
            },
            sortable: true,
        },
        {
            name: 'Oleh',
            cell: function (record) { return _jsx(RecordAdmin, { record: record.job }); },
            sortable: true,
        },
        {
            name: 'Action',
            omit: ![RoleList.Audit].includes(role),
            cell: function (record) {
                return (_jsx(_Fragment, { children: _jsx(ApprovalActionKerjasama, { job: record.job, jobPubId: record.pubId }) }));
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(DrawerComponent, __assign({ open: isOpenRepeatJob, size: 'LG', handleClose: function () { return setOpenRepeatJob(false); }, title: 'Kerjasama Ulang' }, { children: _jsx(RepeatJobForm, { refetchTalent: refetchTalent, talentDetail: talentDetail, onClose: function () { return setOpenRepeatJob(false); } }) })), _jsxs("div", __assign({ className: 'd-flex flex-column' }, { children: [_jsx("div", __assign({ className: 'card-header border-0 py-0 px-0 d-flex flex-row' }, { children: _jsxs("div", __assign({ className: 'card-title w-100 flex' }, { children: [_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Input, { placeholder: 'Search', type: 'text', name: 'keyword', value: filter.keyword, onChange: function (e) {
                                            onchange(e);
                                        } }) })), _jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Select, { name: 'Type', onChange: function (e) {
                                            onchange(e, e.target.value);
                                            navigate("#".concat(e.target.value));
                                        }, optionList: optionJobTypeAll, isPlaceholder: false }) })), _jsx("div", __assign({ className: 'card-title' }, { children: optionSocialMedia && optionSocialMedia.length > 0 && (_jsx(Select, { name: 'division.socialMedia.pubId', onChange: function (e) {
                                            handlePageSizeChange(e);
                                            handleMultiDimensionalFilterChange(e);
                                        }, isPlaceholder: false, optionList: optionSocialMedia, defaultValue: optionSocialMedia[0].value })) })), hasATeamInAdmin && (_jsx("div", __assign({ className: 'card-title ms-auto' }, { children: _jsx("button", __assign({ className: 'btn btn-sm btn-primary', onClick: handleClickRepeatJob }, { children: "Buat Kerjasama Ulang" })) })))] })) })), _jsx("div", __assign({ className: 'card-body py-0 px-0' }, { children: !isLoading ? (datas && datas.data && datas.data.length > 0 ? (_jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: datas ? datas.data : [], noHeader: true, progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: filter.filterBy === ''
                                ? datas && datas.recordsTotal
                                : datas && datas.recordsFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange }, datas && datas.data.length)) : (_jsx(NotFound, {}))) : (_jsx(Loading, {})) }))] }))] }));
};
var RepeatJobForm = function (_a) {
    var _b, _c, _d, _e, _f;
    var talentDetail = _a.talentDetail, refetchTalent = _a.refetchTalent, onClose = _a.onClose;
    var _g = useState(false), isLoading = _g[0], setLoading = _g[1];
    var createRepeatJob = useRepeatJob().createRepeatJob;
    var form = useFormik({
        initialValues: {
            socialMediaPubId: '',
            divisionPubId: '',
            teamPubId: '',
            talentPubId: (talentDetail === null || talentDetail === void 0 ? void 0 : talentDetail.pubId) || '',
        },
        enableReinitialize: true,
        validationSchema: RepeatJobFormSchema,
        onSubmit: function (values, _a) {
            var resetForm = _a.resetForm;
            return __awaiter(void 0, void 0, void 0, function () {
                var e_1, error;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            setLoading(true);
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 3, 4, 5]);
                            return [4 /*yield*/, createRepeatJob(__assign(__assign({}, values), { talentPubId: (talentDetail === null || talentDetail === void 0 ? void 0 : talentDetail.pubId) || '' }))];
                        case 2:
                            _b.sent();
                            setLoading(false);
                            refetchTalent();
                            resetForm();
                            return [3 /*break*/, 5];
                        case 3:
                            e_1 = _b.sent();
                            error = e_1.response.data;
                            toast.error(error.message);
                            return [3 /*break*/, 5];
                        case 4:
                            onClose();
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        },
    });
    var talentTeams = useMemo(function () {
        return (talentDetail === null || talentDetail === void 0 ? void 0 : talentDetail.talentTeams) || [];
    }, [talentDetail]);
    var _h = useState([]), selectedSocial = _h[0], setSelectedSocial = _h[1];
    var _j = useState([]), selectedDivision = _j[0], setSelectedDivision = _j[1];
    var _k = useState([]), selectedTeam = _k[0], setSelectedTeam = _k[1];
    var talentSocialMediaOptions = useMemo(function () {
        var _a;
        if ((_a = talentDetail === null || talentDetail === void 0 ? void 0 : talentDetail.profiles) === null || _a === void 0 ? void 0 : _a.length) {
            var stringifiedSet = new Set(talentDetail.profiles.map(function (profile) { return JSON.stringify(profile.socialMedia); }));
            return Array.from(stringifiedSet)
                .map(function (social) { return JSON.parse(social); })
                .map(function (social) { return ({ label: social.name, value: social.pubId }); });
        }
    }, [talentDetail === null || talentDetail === void 0 ? void 0 : talentDetail.profiles]);
    var divisions = useDivisions({
        filterBy: "[[\"socialMedia.pubId\", \"".concat((_b = selectedSocial[0]) === null || _b === void 0 ? void 0 : _b.value, "\"]]"),
    }, { enabled: selectedSocial.length > 0 }).divisions;
    var divisionOptions = useMemo(function () {
        if (!(divisions === null || divisions === void 0 ? void 0 : divisions.length) || !(talentSocialMediaOptions === null || talentSocialMediaOptions === void 0 ? void 0 : talentSocialMediaOptions.length))
            return [];
        return divisions.map(function (div) {
            var _a;
            var social = ((_a = talentSocialMediaOptions === null || talentSocialMediaOptions === void 0 ? void 0 : talentSocialMediaOptions.find(function (soc) { return soc.value === div.socialMedia.pubId; })) === null || _a === void 0 ? void 0 : _a.label) || '';
            return { label: "".concat(div.name, " - ").concat(social), value: div.pubId };
        });
    }, [divisions, talentSocialMediaOptions]);
    var team = useTeams({
        filterBy: "[[\"divisions.pubId\", \"".concat((_c = selectedDivision[0]) === null || _c === void 0 ? void 0 : _c.value, "\"], [\"divisions.socialMedia.pubId\", \"").concat((_d = selectedSocial[0]) === null || _d === void 0 ? void 0 : _d.value, "\"]]"),
        page: 1,
        pageSize: 100,
    }, { enabled: selectedDivision.length > 0 && selectedSocial.length > 0 }).team;
    var teamOptions = useMemo(function () {
        var _a;
        if (!((_a = team === null || team === void 0 ? void 0 : team.data) === null || _a === void 0 ? void 0 : _a.length) || !talentTeams.length)
            return [];
        return team.data
            .filter(function (t) {
            return !!talentTeams.find(function (talentTeam) { return talentTeam.team.pubId === t.pubId; });
        })
            .map(function (t) { return ({ label: t.name, value: t.pubId }); });
    }, [team === null || team === void 0 ? void 0 : team.data, talentTeams]);
    // TODO: Load the default value
    // useEffect(() => {
    //   if (isLoad) return
    //   if (talentSocialMediaOptions?.length && divisionOptions?.length) {
    //     const defaultSocial = talentSocialMediaOptions[0] as Options
    //     setSelectedSocial([defaultSocial])
    //     form.setFieldValue('socialMediaPubId', defaultSocial.value)
    //     const defaultDivision = divisionOptions[0] as Options
    //     setSelectedDivision([defaultDivision])
    //     form.setFieldValue('divisionPubId', defaultDivision.value)
    //     setIsLoad(true)
    //   }
    // }, [talentSocialMediaOptions, divisionOptions])
    return (_jsxs("form", __assign({ id: 'kt_team_form', className: 'form', noValidate: true, onSubmit: form.handleSubmit }, { children: [_jsx(MultiSelectFormik, { name: 'socialMediaPubId', label: 'Sosial Media', formik: form, options: talentSocialMediaOptions, isMulti: false, value: selectedSocial, onchange: function (e) {
                    setSelectedSocial([e]);
                    form.setFieldValue('socialMediaPubId', e.value);
                    setSelectedDivision([]);
                    form.setFieldValue('divisionPubId', '');
                    setSelectedTeam([]);
                    form.setFieldValue('teamPubId', '');
                } }), _jsx(MultiSelectFormik, { name: 'divisionPubId', label: 'Division', formik: form, options: divisionOptions, isMulti: false, value: selectedDivision, onchange: function (e) {
                    setSelectedDivision([e]);
                    form.setFieldValue('divisionPubId', e.value);
                    setSelectedTeam([]);
                    form.setFieldValue('teamPubId', '');
                }, isDisabled: !((_e = selectedSocial[0]) === null || _e === void 0 ? void 0 : _e.value) }), _jsx(MultiSelectFormik, { name: 'teamPubId', label: 'Team', formik: form, options: teamOptions, isMulti: false, value: selectedTeam, onchange: function (e) {
                    setSelectedTeam([e]);
                    form.setFieldValue('teamPubId', e.value);
                }, isDisabled: !((_f = selectedDivision[0]) === null || _f === void 0 ? void 0 : _f.value) }), _jsxs("div", __assign({ className: 'my-5 d-flex justify-content-end gap-2' }, { children: [_jsx("button", __assign({ type: 'button', className: 'btn btn-secondary btn-active-light-primary ms-1', onClick: onClose, disabled: form.isSubmitting }, { children: "Batal" })), _jsxs("button", __assign({ type: 'submit', id: 'kt_jobtype_submit', className: 'btn btn-primary ', disabled: form.isSubmitting || !form.isValid }, { children: [!isLoading && _jsx("span", __assign({ className: 'indicator-label' }, { children: "Simpan" })), isLoading && (_jsxs("span", __assign({ className: 'indicator-progress', style: { display: 'block' } }, { children: ["Mohon tunggu...", _jsx("span", { className: 'spinner-border spinner-border-sm align-middle ms-2' })] })))] }))] }))] })));
};
export default Kerjasama;
