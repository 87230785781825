var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
var DetailCard = function (_a) {
    var data = _a.data;
    var id = useParams().id;
    var intl = useIntl();
    return (_jsxs("div", __assign({ className: 'content-detail' }, { children: [_jsxs("div", __assign({ className: '' }, { children: [_jsx("label", __assign({ className: 'fw-bold' }, { children: "Nama" })), _jsx("p", { children: (data === null || data === void 0 ? void 0 : data.talent.name) ? data === null || data === void 0 ? void 0 : data.talent.name : '-' })] })), _jsxs("div", __assign({ className: '' }, { children: [_jsx("label", __assign({ className: 'fw-bold' }, { children: "Contact Person" })), _jsx("p", { children: (data === null || data === void 0 ? void 0 : data.talent.cpName) ? data === null || data === void 0 ? void 0 : data.talent.cpName : '-' })] })), _jsxs("div", __assign({ className: '' }, { children: [_jsx("label", __assign({ className: 'fw-bold' }, { children: "No Contact Person" })), _jsx("p", { children: (data === null || data === void 0 ? void 0 : data.talent.cpPhoneNumber) ? data === null || data === void 0 ? void 0 : data.talent.cpPhoneNumber : '-' })] })), _jsxs("div", __assign({ className: '' }, { children: [_jsx("label", __assign({ className: 'fw-bold' }, { children: "Email Contact Person" })), _jsx("p", { children: (data === null || data === void 0 ? void 0 : data.talent.cpEmail) ? data === null || data === void 0 ? void 0 : data.talent.cpEmail : '-' })] })), _jsxs("div", __assign({ className: '' }, { children: [_jsx("label", __assign({ className: 'fw-bold' }, { children: "Lainnya" })), _jsx("p", { children: (data === null || data === void 0 ? void 0 : data.talent.cpOther) ? data === null || data === void 0 ? void 0 : data.talent.cpOther : '-' })] })), _jsxs("div", __assign({ className: '' }, { children: [_jsx("label", __assign({ className: 'fw-bold' }, { children: "Kategori" })), _jsx("p", { children: (data === null || data === void 0 ? void 0 : data.talent.categories)
                            ? data === null || data === void 0 ? void 0 : data.talent.categories.map(function (obj, key) {
                                return (_jsx("span", __assign({ className: 'badge badge-info me-2' }, { children: obj.name }), key));
                            })
                            : '-' })] }))] })));
};
export default DetailCard;
