var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { KTCard } from '@src/_metronic/helpers';
import { paginationOptions, talentDetailTabOptions, userInformation, } from '@src/app/helper';
import AttachmentForm from '@src/components/Atoms/Drawer/TalentDetailFormDrawer/AttachmentForm';
import Loading from '@src/components/Atoms/Loading/Loading';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import DrawerForm from '@src/components/molecules/Drawer/DrawerForm';
import { useFormik } from 'formik';
import { Fragment, useState } from 'react';
import DataTable from 'react-data-table-component';
import * as Yup from 'yup';
import { AttachmentValues } from '../TalentDetail.types';
import { uploadFile } from '@src/app/pages/JobPage/Job/Job.requests';
import { useAttachments } from '../../TalentHooks/TalentDetail.hooks';
import Input from '@src/components/Atoms/Input/Input';
import RecordAdmin from '@src/components/molecules/Record/Admin';
var AttachmentFormSchema = Yup.object().shape({});
var Attachment = function (_a) {
    var talentId = _a.talentId, isLoading = _a.isLoading, datas = _a.datas, filter = _a.filter, handlePageSizeChange = _a.handlePageSizeChange, handlePageChange = _a.handlePageChange, handleFilterChange = _a.handleFilterChange;
    var tenantPubId = JSON.parse(userInformation).tenant.pubId;
    var _b = useState(false), isOpenAdd = _b[0], setIsOpenAdd = _b[1];
    var _c = useState(''), tabName = _c[0], setTabName = _c[1];
    var _d = useState(''), idForEdit = _d[0], setIdForEdit = _d[1];
    var createAttachment = useAttachments(filter, false).createAttachment;
    var columns = [
        {
            name: 'Nama',
            cell: function (row) {
                return _jsx("p", { children: row.name });
            },
        },
        {
            name: 'Oleh',
            cell: function (record) { return _jsx(RecordAdmin, { record: record }); },
            sortable: true,
        },
        {
            name: 'Action',
            cell: function (record) {
                return (record.url && (_jsx("div", { children: _jsx("a", __assign({ href: record.url, className: 'btn btn-sm btn-secondary', target: '_blank', rel: 'noopener noreferrer' }, { children: "View" })) })));
            },
        },
    ];
    var handleAdd = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setIsOpenAdd(true);
            setTabName(talentDetailTabOptions.attachment);
            setIdForEdit('');
            return [2 /*return*/];
        });
    }); };
    var AttachmentFormik = useFormik({
        initialValues: AttachmentValues,
        enableReinitialize: true,
        validationSchema: AttachmentFormSchema,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var file, e_1, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, uploadFile({
                                File: values.files[0],
                                TenantPubId: tenantPubId,
                                TalentPubId: talentId,
                                Type: 'Attachments',
                            })];
                    case 1:
                        file = _a.sent();
                        if (!(file.status === 200)) return [3 /*break*/, 3];
                        values.talentPubId = talentId;
                        values.filePubId = file.data.data.pubId;
                        return [4 /*yield*/, createAttachment(values)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        setIsOpenAdd(false);
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        error = e_1.response.data;
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); },
    });
    return (_jsxs(Fragment, { children: [_jsx(KTCard, { children: _jsxs("div", __assign({ className: 'card' }, { children: [_jsxs("div", __assign({ className: 'card-header border-0 py-0 px-0 d-flex flex-row' }, { children: [_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Input, { placeholder: 'Search', type: 'text', name: 'keyword', value: filter.keyword, onChange: function (e) {
                                            handleFilterChange(e);
                                        } }) })), _jsx("div", __assign({ className: 'card-toolbar' }, { children: _jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-primary', onClick: function () { return handleAdd(); } }, { children: "Tambah" })) }))] })), _jsx("div", __assign({ className: 'card-body p-0' }, { children: !isLoading ? datas && datas.data && datas.data.length > 0 ? (_jsx(DataTable, { title: '', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: (datas === null || datas === void 0 ? void 0 : datas.data) || [], noHeader: true, progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: datas === null || datas === void 0 ? void 0 : datas.recordFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangePage: handlePageSizeChange, onChangeRowsPerPage: handlePageChange })) : (_jsx(NotFound, {})) : (_jsx(Loading, {})) }))] })) }), _jsx(DrawerForm, { tabName: tabName, useForm: AttachmentFormik, isOpen: isOpenAdd, handleClose: setIsOpenAdd, onSubmit: AttachmentFormik.handleSubmit, component: AttachmentForm, idForEdit: idForEdit })] }));
};
export default Attachment;
