var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from 'react';
var PageDataContext = createContext({
    setPageTitle: function (_title) { },
    setPageBreadcrumbs: function (_breadcrumbs) { },
    setPageDescription: function (_description) { },
});
var PageDataProvider = function (_a) {
    var children = _a.children;
    var _b = useState(''), pageTitle = _b[0], setPageTitle = _b[1];
    var _c = useState(''), pageDescription = _c[0], setPageDescription = _c[1];
    var _d = useState([]), pageBreadcrumbs = _d[0], setPageBreadcrumbs = _d[1];
    var value = {
        pageTitle: pageTitle,
        setPageTitle: setPageTitle,
        pageDescription: pageDescription,
        setPageDescription: setPageDescription,
        pageBreadcrumbs: pageBreadcrumbs,
        setPageBreadcrumbs: setPageBreadcrumbs,
    };
    return _jsx(PageDataContext.Provider, __assign({ value: value }, { children: children }));
};
function usePageData() {
    return useContext(PageDataContext);
}
var PageTitle = function (_a) {
    var children = _a.children, description = _a.description, breadcrumbs = _a.breadcrumbs;
    var _b = usePageData(), setPageTitle = _b.setPageTitle, setPageDescription = _b.setPageDescription, setPageBreadcrumbs = _b.setPageBreadcrumbs;
    useEffect(function () {
        if (children) {
            setPageTitle(children.toString());
        }
        return function () {
            setPageTitle('');
        };
    }, [children]);
    useEffect(function () {
        if (description) {
            setPageDescription(description);
        }
        return function () {
            setPageDescription('');
        };
    }, [description]);
    useEffect(function () {
        if (breadcrumbs) {
            setPageBreadcrumbs(breadcrumbs);
        }
        return function () {
            setPageBreadcrumbs([]);
        };
    }, [breadcrumbs]);
    return _jsx(_Fragment, {});
};
var PageDescription = function (_a) {
    var children = _a.children;
    var setPageDescription = usePageData().setPageDescription;
    useEffect(function () {
        if (children) {
            setPageDescription(children.toString());
        }
        return function () {
            setPageDescription('');
        };
    }, [children]);
    return _jsx(_Fragment, {});
};
export { PageDescription, PageTitle, PageDataProvider, usePageData };
