// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hH4mIY910IAyLXNobxqS{display:flex;align-items:center;column-gap:20px;justify-content:space-between;width:100%}.laoqc7fvzvSDru33cgua{display:flex;align-items:center;column-gap:20px;justify-content:flex-start}.EOMCvYJv_qgtNuKldy3W{display:flex;align-items:center;column-gap:20px;justify-content:space-between;width:100%}", "",{"version":3,"sources":["webpack://./src/app/pages/GroupTransferPage/GroupTransfer/GroupTransfer.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,eAAA,CACA,6BAAA,CACA,UAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,eAAA,CACA,0BAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,eAAA,CACA,6BAAA,CACA,UAAA","sourcesContent":[".card-search {\n  display: flex;\n  align-items: center;\n  column-gap: 20px;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.card-search__filter {\n  display: flex;\n  align-items: center;\n  column-gap: 20px;\n  justify-content: flex-start;\n}\n\n.card-filter {\n  display: flex;\n  align-items: center;\n  column-gap: 20px;\n  justify-content: space-between;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card-search": "hH4mIY910IAyLXNobxqS",
	"card-search__filter": "laoqc7fvzvSDru33cgua",
	"card-filter": "EOMCvYJv_qgtNuKldy3W"
};
export default ___CSS_LOADER_EXPORT___;
