// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HJTrzHvv33VSmfuSvuk5{background-color:#f9f9f9;padding:20px 30px 10px;margin-bottom:20px;border-radius:5px;display:flex;align-items:center;column-gap:30px}._EoD2_KJ9l7xEWr0Vb_b{display:flex;justify-content:space-between;align-items:center;margin-bottom:10px}.Utot7ijxg0ez9yFdzsFK{flex-grow:1}.Aco4XJ1wO4yjQ4MR8DNl{margin-top:-16px}", "",{"version":3,"sources":["webpack://./src/components/molecules/SendProduct/Form/ProductForm.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CAGF,sBACE,WAAA,CAGF,sBACE,gBAAA","sourcesContent":[".profile-container {\n  background-color: #f9f9f9;\n  padding: 20px 30px 10px;\n  margin-bottom: 20px;\n  border-radius: 5px;\n  display: flex;\n  align-items: center;\n  column-gap: 30px;\n}\n\n.social-media-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 10px;\n}\n\n.profile-layout {\n  flex-grow: 1;\n}\n\n.profile-status-message {\n  margin-top: -16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile-container": "HJTrzHvv33VSmfuSvuk5",
	"social-media-container": "_EoD2_KJ9l7xEWr0Vb_b",
	"profile-layout": "Utot7ijxg0ez9yFdzsFK",
	"profile-status-message": "Aco4XJ1wO4yjQ4MR8DNl"
};
export default ___CSS_LOADER_EXPORT___;
