var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { useProducts } from '@src/app/pages/ProductPage/Product/Product.hooks';
import InputFormik from '@src/components/Atoms/Formik/Input/InputFormik';
import MultiSelectFormik from '@src/components/Atoms/Formik/MultiSelect/MultiSelectFormik';
import styles from './ProductForm.module.scss';
export default function SendProductForm(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var formik = _a.formik, index = _a.index, categoryOptions = _a.categoryOptions, onRemove = _a.onRemove;
    var _l = useState({
        page: 1,
        pageSize: 1000,
        keyword: '',
        filterBy: '',
        initialFilterBy: "[[\"recordStatus\", \"Active\"]]",
    }), filter = _l[0], setFilter = _l[1];
    var _m = useProducts(filter), isLoading = _m.isLoading, products = _m.products;
    var disabledProductOptions = !((_c = (_b = formik.values.sendProduct) === null || _b === void 0 ? void 0 : _b.products[index]) === null || _c === void 0 ? void 0 : _c.productCategoryPubId);
    var productOptions = useMemo(function () {
        var _a;
        if (disabledProductOptions || !((_a = products === null || products === void 0 ? void 0 : products.data) === null || _a === void 0 ? void 0 : _a.length) || isLoading)
            return [];
        return products.data.map(function (_a) {
            var name = _a.name, pubId = _a.pubId;
            return ({ label: name, value: pubId });
        });
    }, [isLoading, products, disabledProductOptions]);
    var selectedCategory = (_f = (_e = (_d = formik.values.sendProduct) === null || _d === void 0 ? void 0 : _d.products[index]) === null || _e === void 0 ? void 0 : _e.productCategoryPubId) !== null && _f !== void 0 ? _f : '';
    var selectedProduct = (_j = (_h = (_g = formik.values.sendProduct) === null || _g === void 0 ? void 0 : _g.products[index]) === null || _h === void 0 ? void 0 : _h.productPubId) !== null && _j !== void 0 ? _j : '';
    var handleChange = function (value, field) {
        formik.setFieldValue('sendProduct', __assign(__assign({}, formik.values.sendProduct), { products: __spreadArray([], formik.values.sendProduct.products.map(function (product, idx) {
                var _a;
                if (idx === index) {
                    return __assign(__assign({}, product), (_a = {}, _a[field] = value, _a));
                }
                return product;
            }), true) }));
    };
    useEffect(function () {
        if (!selectedCategory)
            return;
        setFilter(function (prev) { return (__assign(__assign({}, prev), { filterBy: JSON.stringify([['productCategory.pubId', selectedCategory]]) })); });
        handleChange('', 'productPubId');
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCategory]);
    return (_jsxs("div", __assign({ className: styles['profile-container'] }, { children: [_jsxs("div", __assign({ className: 'row' }, { children: [_jsx("div", __assign({ className: 'col-md-4' }, { children: _jsx(MultiSelectFormik, { name: 'productCategoryPubId', label: 'Kategori', formik: formik, isMulti: false, options: categoryOptions, value: selectedCategory
                                ? [
                                    categoryOptions.find(function (category) { return category.value === selectedCategory; }),
                                ]
                                : [], onchange: function (selected) { return handleChange(selected.value, 'productCategoryPubId'); }, isRequired: true }) })), _jsx("div", __assign({ className: 'col-md-4' }, { children: _jsx(MultiSelectFormik, { name: 'productPubId', label: 'Barang', formik: formik, isMulti: false, options: productOptions, value: selectedProduct
                                ? [
                                    productOptions.find(function (product) { return product.value === selectedProduct; }),
                                ]
                                : [], onchange: function (selected) { return handleChange(selected.value, 'productPubId'); }, isRequired: true }) })), _jsx("div", __assign({ className: 'col-md-4' }, { children: _jsx(InputFormik, { label: 'Qty', type: 'text', placeholder: 'Qty', name: "qty.".concat(index), formik: formik, onblur: function (e) { return handleChange(e.target.value, 'qty'); }, isChildField: true }) }))] })), ((_k = formik.values.sendProduct) === null || _k === void 0 ? void 0 : _k.products.length) > 1 && (_jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-danger', onClick: function () { return onRemove(index); } }, { children: _jsx("i", { className: 'fa fa-trash p-0' }) })))] })));
}
