var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TalentStatusOptions, BlacklistOptions, followerCategoriesOptions } from '@src/app/helper';
import Select from '@src/components/Atoms/Select/Select';
import { useTalentCategories } from '@src/app/pages/TalentCategoryPage/TalentCategoryHooks/TalentCategory.hooks';
import MultiSelectFormik from '@src/components/Atoms/Formik/MultiSelect/MultiSelectFormik';
import { useCities } from '@src/app/pages/LookupPage/LookupHooks/Lookup.hooks';
import { useState } from 'react';
var TalentFilter = function (props) {
    //get categories
    var _a = useState({
        page: 1,
        pageSize: 100,
        keyword: '',
        filterBy: '',
        initialFilterBy: '',
    }), filterPageSize = _a[0], setFilterPageSize = _a[1];
    var _b = useTalentCategories(filterPageSize), talentCategories = _b.talentCategories, isLoadingTalentCategories = _b.isLoading, errorTalentCategories = _b.error;
    var optionTalentCategories = talentCategories &&
        talentCategories.data.length > 0 &&
        talentCategories.data.map(function (talentcategory) { return ({
            name: talentcategory.name,
            value: talentcategory.pubId, //sent to filter (change to pubId or name)
        }); });
    //getcities
    var cities = useCities().cities;
    var _c = useState([]), selectedCity = _c[0], setSelectedCity = _c[1];
    var cityOptions = (cities === null || cities === void 0 ? void 0 : cities.data) &&
        cities.data.length > 0 &&
        cities.data.map(function (city) { return ({
            label: city.name,
            value: city.pubId,
        }); });
    var onChangeCity = function (e) {
        var event = { target: { name: '', value: null } };
        event.target.name = 'addresses.city.pubId';
        event.target.value = e == null ? '' : e.value;
        setSelectedCity(e);
        props.handlechange(event);
    };
    //category
    var _d = useState([]), selectedCategory = _d[0], setSelectedCategory = _d[1];
    var onChangeCategory = function (e) {
        var event = { target: { name: '', value: null } };
        event.target.name = 'profiles.followerCategory';
        event.target.value = e == null ? '' : e.value;
        setSelectedCategory(e);
        props.handlechange(event);
    };
    return (_jsxs("div", __assign({ className: 'd-flex flex-row', "data-kt-team-table-filter": 'form' }, { children: [_jsx("div", __assign({ className: 'mb-5 px-1 d-flex flex-column flex-row-auto' }, { children: !isLoadingTalentCategories && optionTalentCategories && (_jsx(Select, { name: 'categories.pubId', optionList: optionTalentCategories, onChange: function (e) { return props.handlechange(e); }, placeholder: 'Filter by Kategori' })) })), _jsx("div", __assign({ className: 'mb-5 px-1 d-flex flex-column flex-row-auto' }, { children: _jsx(Select, { name: 'talentStatus', optionList: TalentStatusOptions, onChange: function (e) { return props.handlechange(e); }, placeholder: 'Filter by Status' }) })), _jsx("div", __assign({ className: 'mb-5 px-1 d-flex flex-column flex-row-auto' }, { children: _jsx(Select, { name: 'isBlacklisted', optionList: BlacklistOptions, onChange: function (e) { return props.handlechange(e); }, placeholder: 'Filter by Blacklist' }) })), (props.filterdata.socialMedia == 'Instagram' || props.filterdata.socialMedia == 'TikTok' || props.filterdata.division == 'Instagram' || props.filterdata.division == 'TikTok') && (_jsx("div", __assign({ className: 'd-flex flex-column flex-row-auto w-250px mx-2', style: { marginTop: '-26px' } }, { children: _jsx(MultiSelectFormik, { name: 'profiles.followerCategory', label: '', placeholder: 'Filter by Kategori Follower', formik: [], isMulti: false, options: followerCategoriesOptions, value: selectedCategory, onchange: onChangeCategory, isChildField: true }) }))), _jsx("div", __assign({ className: 'd-flex flex-column flex-row-auto w-250px mx-2', style: { marginTop: '-26px' } }, { children: cityOptions && (_jsx(MultiSelectFormik, { name: 'addresses.city.pubId', label: '', placeholder: 'Filter by City', formik: [], isMulti: false, options: cityOptions, value: selectedCity, onchange: onChangeCity, isChildField: true })) }))] })));
};
export default TalentFilter;
