var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './Record.module.scss';
import { FormatDateTime } from '@src/app/helper';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
var RecordAdmin = function (_a) {
    var record = _a.record, _b = _a.isShow, isShow = _b === void 0 ? true : _b;
    return (_jsx(OverlayTrigger, __assign({ placement: 'top', delay: { show: 250, hide: 400 }, overlay: function (props) {
            var _a, _b, _c, _d;
            return isShow ? (_jsx(Tooltip, __assign({ id: 'button-tooltip' }, props, { children: record
                    ? record.job
                        ? (_a = record.job.team) === null || _a === void 0 ? void 0 : _a.name
                        : record.team
                            ? (_b = record.team) === null || _b === void 0 ? void 0 : _b.name
                            : record.talentTeams
                                ? record.talentTeams[0].team.name
                                : record.talent
                                    ? record.talent.talentTeams
                                        ? (_d = (_c = record.talent.talentTeams[0]) === null || _c === void 0 ? void 0 : _c.team) === null || _d === void 0 ? void 0 : _d.name
                                        : ''
                                    : ''
                    : '' }))) : (_jsx("span", {}));
        } }, { children: _jsxs("div", { children: [(record === null || record === void 0 ? void 0 : record.createdBy) ? (_jsxs("div", __assign({ className: 'mb-5' }, { children: [_jsx("p", __assign({ className: "".concat(styles['title'], " mb-1") }, { children: "Dibuat Oleh:" })), _jsx("p", __assign({ className: 'mb-1' }, { children: record.createdBy })), _jsx("p", __assign({ className: 'mb-1' }, { children: FormatDateTime(record.createdOn) }))] }))) : ('-'), (record === null || record === void 0 ? void 0 : record.updatedBy) && (_jsxs("div", __assign({ className: 'by mb-3' }, { children: [_jsx("p", __assign({ className: 'mb-1' }, { children: "Diubah Oleh:" })), _jsx("p", __assign({ className: 'mb-1' }, { children: record.updatedBy })), _jsx("p", __assign({ className: 'mb-1' }, { children: FormatDateTime(record.updatedOn) }))] })))] }) })));
};
export default RecordAdmin;
