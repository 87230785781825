var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
var baseApiUrl = process.env.NEXUS_API_URL;
function TransferPerTerm(repeatjob) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = repeatjob.id, values = __rest(repeatjob, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(baseApiUrl, "/transfers/job/").concat(id, "/transfer-per-term/"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
export function useTransferProcess() {
    var queryClient = useQueryClient();
    var TransferPerTermMutation = useMutation(TransferPerTerm, {
        onSuccess: function (newRepeatJob) {
            queryClient.setQueryData('repeatjob', function (oldRepeatJobs) {
                if (oldRepeatJobs === void 0) { oldRepeatJobs = []; }
                return __spreadArray(__spreadArray([], oldRepeatJobs, true), [
                    newRepeatJob,
                ], false);
            });
            queryClient.refetchQueries();
        },
        onError: function (error) {
        },
    });
    return {
        TransferPerTerm: TransferPerTermMutation.mutateAsync,
    };
}
export var groupTransfer = function (values) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, axios.post("".concat(baseApiUrl, "/transfers/transfer-as-group"), values)];
    });
}); };
export var inputTransfers = function (id) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, axios.post("".concat(baseApiUrl, "/transfers/").concat(id, "/input/"), {
                pubId: id,
            })];
    });
}); };
export var failedInputTransfers = function (id, reason) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, axios.post("".concat(baseApiUrl, "/transfers/").concat(id, "/input-failed/"), {
                reason: reason,
            })];
    });
}); };
export var fixTransfers = function (id, values) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, axios.post("".concat(baseApiUrl, "/transfers/").concat(id, "/fix-transfer/"), values)];
    });
}); };
export var authTransfers = function (id) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, axios.post("".concat(baseApiUrl, "/transfers/").concat(id, "/auth/"))];
    });
}); };
export var bulkAuthTransfers = function (ids) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, axios.post("".concat(baseApiUrl, "/transfers/auth-bulk"), {
                pubIds: ids,
            })];
    });
}); };
export var failedTransfers = function (id, reason) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, axios.post("".concat(baseApiUrl, "/transfers/").concat(id, "/transfer-failed/"), {
                reason: reason,
            })];
    });
}); };
export var refundTransfers = function (id, reason, proof, totalAmount) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, axios.post("".concat(baseApiUrl, "/transfers/").concat(id, "/refund/"), {
                reason: reason,
                proofRefundFilePubId: proof,
                totalAmount: totalAmount,
            })];
    });
}); };
export var completedTransfers = function (id, spk) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, axios.post("".concat(baseApiUrl, "/transfers/").concat(id, "/transfer-completed/"), {
                spkFilePubId: spk,
            })];
    });
}); };
export var cancelTransfers = function (id) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, axios.post("".concat(baseApiUrl, "/transfers/").concat(id, "/cancel/"))];
    });
}); };
export var incorrectTransfers = function (id) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, axios.post("".concat(baseApiUrl, "/transfers/").concat(id, "/transfer-incorrect/"))];
    });
}); };
export var refundCompletedTransfers = function (id) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, axios.post("".concat(baseApiUrl, "/transfers/").concat(id, "/refund-completed/"))];
    });
}); };
export var doneTransfers = function (id, transferFile, bankId
// date: string
) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, axios.post("".concat(baseApiUrl, "/transfers/").concat(id, "/done/"), {
                proofTransferFilePubId: transferFile,
                bankTransferByPubId: bankId,
                // transferDate: date,
            })];
    });
}); };
export var uploadProofTransfer = function (id, transferFile, bankId
// date: string
) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, axios.post("".concat(baseApiUrl, "/transfers/").concat(id, "/transfer-proof/"), {
                proofTransferFilePubId: transferFile,
                bankTransferByPubId: bankId,
                // transferDate: date,
            })];
    });
}); };
