var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { useAuth } from '@src/app/pages/auth';
import { userInformation } from '@src/app/helper';
import MenuAccountSwitcher from '@src/components/MenuAccountSwitcher';
// search by all tenant belum bisa, please specify selected tenant
// status filter by default is active
var HeaderUserMenu = function () {
    var _a = useAuth(), currentUser = _a.currentUser, logout = _a.logout;
    var name = JSON.parse(userInformation).name;
    return (_jsxs("div", __assign({ className: 'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-400px', "data-kt-menu": 'true' }, { children: [_jsx("div", __assign({ className: 'menu-item px-3' }, { children: _jsxs("div", __assign({ className: 'menu-content d-flex align-items-center px-3' }, { children: [_jsx("div", __assign({ className: 'symbol symbol-50px me-5' }, { children: _jsx("div", __assign({ className: 'symbol-label fs-3 bg-light-info text-info' }, { children: (currentUser === null || currentUser === void 0 ? void 0 : currentUser.userId) && currentUser.userName.charAt(0) })) })), _jsxs("div", __assign({ className: 'd-flex flex-column' }, { children: [_jsx("div", __assign({ className: 'fw-bolder d-flex align-items-center fs-5' }, { children: name })), _jsx("a", __assign({ href: '#', className: 'fw-bold text-muted text-hover-primary fs-7' }, { children: currentUser === null || currentUser === void 0 ? void 0 : currentUser.userId }))] }))] })) })), _jsx("div", { className: 'separator my-2' }), _jsx("div", __assign({ className: 'menu-item px-5 my-1' }, { children: _jsx(Link, __assign({ to: '/account/settings', className: 'menu-link px-5' }, { children: "Account Settings" })) })), _jsx("div", __assign({ className: 'menu-item px-5' }, { children: _jsx("a", __assign({ onClick: logout, className: 'menu-link px-5' }, { children: "Sign Out" })) })), _jsx("hr", {}), _jsx(MenuAccountSwitcher, {})] })));
};
export { HeaderUserMenu };
