export function FormatParam(value) {
    return Object.entries(value)
        .reduce(function (acc, curr) {
        if (curr[1] !== '') {
            var newvar = "".concat(encodeURIComponent(curr[0]), "=").concat(encodeURIComponent(curr[1]));
            acc.push(newvar);
        }
        return acc;
    }, [])
        .join('&');
}
