var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
var BelumTransfer = function (_a) {
    var record = _a.record, failedTransferItem = _a.failedTransferItem, cancelTransferItem = _a.cancelTransferItem, invoiceItem = _a.invoiceItem;
    return (_jsx(Fragment, { children: _jsxs("div", __assign({ className: 'text-center' }, { children: [_jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-danger mx-2 my-2', onClick: function () { return failedTransferItem(record); } }, { children: "Gagal Transfer" })), _jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-warning mx-2 my-2', onClick: function () { return cancelTransferItem(record); } }, { children: "Batal Transfer" })), invoiceItem && (_jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-success mx-2', onClick: function () { return invoiceItem(record); } }, { children: "Invoice" })))] })) }));
};
export default BelumTransfer;
