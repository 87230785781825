var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx';
import { KTSVG } from '../../../helpers';
import { useLayout } from '../../core';
import { Header } from './Header';
import { DefaultTitle } from './page-title/DefaultTitle';
import { Topbar } from './Topbar';
export function HeaderWrapper() {
    var _a = useLayout(), config = _a.config, classes = _a.classes, attributes = _a.attributes;
    var header = config.header, pageTitle = config.pageTitle;
    return (_jsx("div", __assign({ id: 'kt_header', className: clsx('header', classes.header.join(' '), ' align-items-stretch') }, attributes.headerMenu, { children: _jsxs("div", __assign({ className: clsx(classes.headerContainer.join(' '), 'd-flex align-items-stretch justify-content-between') }, { children: [_jsx("div", __assign({ className: 'd-flex align-items-center d-lg-none ms-n3 me-1', title: 'Show aside menu' }, { children: _jsx("div", __assign({ className: 'btn btn-icon btn-active-color-primary w-40px h-40px', id: 'kt_aside_toggle' }, { children: _jsx(KTSVG, { path: '/media/icons/duotune/abstract/abs015.svg', className: 'svg-icon-1' }) })) })), (pageTitle === null || pageTitle === void 0 ? void 0 : pageTitle.display) && (_jsx("div", __assign({ className: 'd-flex align-items-center', id: 'kt_header_wrapper' }, { children: _jsx(DefaultTitle, {}) }))), _jsxs("div", __assign({ className: clsx('d-flex align-items-stretch', "justify-content-".concat(header.menu ? 'between' : 'end'), 'flex-lg-grow-1', 'flex-md-grow-1') }, { children: [header.menu && (_jsx("div", __assign({ className: 'd-flex align-items-stretch', id: 'kt_header_nav' }, { children: _jsx(Header, {}) }))), _jsx(Topbar, {})] }))] })) })));
}
