var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
var FileFormik = function (_a) {
    var label = _a.label, placeholder = _a.placeholder, name = _a.name, formik = _a.formik, isRequired = _a.isRequired, onBlur = _a.onBlur, onChange = _a.onChange;
    return (_jsxs("div", __assign({ className: 'fv-row mb-7' }, { children: [_jsx("label", __assign({ className: "form-label fs-6 fw-bolder text-dark ".concat(isRequired ? 'required' : '') }, { children: label })), _jsx("input", { placeholder: placeholder, 
                // {...formik.getFieldProps(name)}
                className: clsx('form-control form-control-lg mb-3 mb-lg-0 lh-base', { 'is-invalid': formik.touched[name] && formik.errors[name] }, {
                    'is-valid': formik.touched[name] && !formik.errors[name],
                }), type: 'file', name: name, onBlur: onBlur, onChange: onChange }), formik.touched[name] && formik.errors[name] && (_jsx("div", __assign({ className: 'fv-plugins-message-container' }, { children: _jsx("div", __assign({ className: 'fv-help-block' }, { children: _jsx("span", __assign({ role: 'alert' }, { children: formik.errors[name] })) })) })))] })));
};
export default FileFormik;
