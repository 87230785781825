var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Routes, Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { MenuComponent } from '@src/_metronic/assets/ts/components';
import TalentIndex from './Talent/Talent';
import TForm from './TalentForm/TalentForm';
import TalentDetail from './TalentDetail/TalentDetail';
import TalentMigration from './TalentMigration/TalentMigration';
import TalentBlacklist from './TalentBlacklist/TalentBlacklist';
import TalentAffiliate from './TalentAffiliate/TalentAffiliate';
var TalentPage = function () {
    //getdata
    var _a = useState([]), TalentData = _a[0], setTalentData = _a[1];
    useEffect(function () {
        MenuComponent.reinitialization();
    }, [TalentData]);
    return (_jsx(Routes, { children: _jsxs(Route, __assign({ element: _jsx(Outlet, {}) }, { children: [_jsx(Route, { path: '/all-talents/', element: _jsx(TalentIndex, {}) }), _jsx(Route, { path: '/migration', element: _jsx(TalentMigration, {}) }), _jsx(Route, { path: '/blacklist', element: _jsx(TalentBlacklist, {}) }), _jsx(Route, { path: '/affiliate', element: _jsx(TalentAffiliate, {}) }), _jsx(Route, { path: '/add', element: _jsx(TForm, {}) }), _jsx(Route, { path: '/edit/:id', element: _jsx(TForm, {}) }), _jsx(Route, { path: '/detail/:id', element: _jsx(TalentDetail, {}) })] })) }));
};
export default TalentPage;
