var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useLayout } from '../core';
var Footer = function () {
    var classes = useLayout().classes;
    var year = new Date().getFullYear();
    return (_jsx("div", __assign({ className: 'footer py-4 d-flex flex-lg-column', id: 'kt_footer' }, { children: _jsx("div", __assign({ className: "".concat(classes.footerContainer, " d-flex flex-column flex-md-row flex-stack") }, { children: _jsx("div", __assign({ className: 'text-dark order-2 order-md-1' }, { children: _jsxs("span", __assign({ className: 'text-gray-400 fw-bold me-1' }, { children: ["\u00A9 Devopto ", year] })) })) })) })));
};
export { Footer };
