var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import dayjs from 'dayjs';
import Axios from 'axios';
import { getUserInformation } from './_requests';
var AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';
var AUTH_CURRENT_INDEX_LOCAL_STORAGE_KEY = 'currentUserIndex';
var getAuth = function () {
    var _a;
    if (!localStorage) {
        return;
    }
    var lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    var currentUserIndex = (_a = localStorage.getItem(AUTH_CURRENT_INDEX_LOCAL_STORAGE_KEY)) !== null && _a !== void 0 ? _a : 0;
    if (!lsValue || !currentUserIndex) {
        return;
    }
    try {
        var auth = JSON.parse(lsValue);
        if (auth) {
            return auth[+currentUserIndex];
        }
    }
    catch (error) {
        console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
    }
};
var getAllAuth = function () {
    try {
        var value = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
        if (!value)
            throw new Error('Auth not found');
        return JSON.parse(value);
    }
    catch (error) {
        return [];
    }
};
var getCurrentUserIndex = function () {
    try {
        var value = localStorage.getItem(AUTH_CURRENT_INDEX_LOCAL_STORAGE_KEY);
        if (!value)
            throw new Error('Account index not found');
        return JSON.parse(value);
    }
    catch (error) {
        throw error;
    }
};
var setAuth = function (auth) {
    if (!localStorage) {
        return;
    }
    var expirationTime = new Date(auth.data.expiration).getTime();
    try {
        var newAuth = __assign(__assign({}, auth), { expiration: expirationTime });
        var allAuth = __spreadArray(__spreadArray([], getAllAuth(), true), [newAuth], false);
        var currentUserIndex = allAuth.length ? allAuth.length - 1 : 0;
        localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(allAuth));
        localStorage.setItem(AUTH_CURRENT_INDEX_LOCAL_STORAGE_KEY, "".concat(currentUserIndex));
    }
    catch (error) {
        console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
    }
};
var updateAuth = function (updatedAuth) {
    if (!localStorage) {
        return;
    }
    try {
        var updatedAuths = getAllAuth().map(function (auth) {
            if (updatedAuth.data.userName === auth.data.userName) {
                var expirationTime = new Date(updatedAuth.data.expiration).getTime();
                return __assign(__assign({}, updatedAuth), { expiration: expirationTime });
            }
            return auth;
        });
        localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(updatedAuths));
    }
    catch (error) {
        console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
    }
};
var removeCurrentAuth = function (allAuth) {
    try {
        var currentUserIndex = getCurrentUserIndex();
        allAuth.splice(currentUserIndex, 1);
        localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(allAuth));
    }
    catch (error) {
        console.error('AUTH LOCAL STORAGE REMOVE ERROR', error);
        throw error;
    }
};
var refreshAxios = Axios.create({});
var isTokenRefreshing = false;
var refreshTokenPromise = null;
var refreshToken = function (token, refreshToken) { return __awaiter(void 0, void 0, void 0, function () {
    var res, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, refreshAxios.post("".concat(process.env.NEXUS_API_URL, "/auth/refresh-token"), {
                        token: token,
                        refreshToken: refreshToken,
                    })];
            case 1:
                res = _a.sent();
                updateAuth(res.data);
                return [2 /*return*/, res.data.data.token];
            case 2:
                error_1 = _a.sent();
                throw error_1;
            case 3: return [2 /*return*/];
        }
    });
}); };
var isTokenExpired = function (expiration) {
    return dayjs(new Date()).isAfter(dayjs(expiration));
};
var removeAuth = function () {
    try {
        localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
        localStorage.removeItem('userInformation');
        localStorage.removeItem(AUTH_CURRENT_INDEX_LOCAL_STORAGE_KEY);
    }
    catch (error) {
        throw error;
    }
};
var getAndSetUserRights = function () { return __awaiter(void 0, void 0, void 0, function () {
    var res, datas;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getUserInformation()];
            case 1:
                res = (_a.sent()).data;
                datas = Object(res.data);
                localStorage.setItem('userInformation', JSON.stringify(datas));
                return [2 /*return*/];
        }
    });
}); };
var switchAuth = function (auth) { return __awaiter(void 0, void 0, void 0, function () {
    var allAuth, index;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                allAuth = getAllAuth();
                index = allAuth.findIndex(function (e) { return e.data.userName === auth.data.userName; });
                localStorage.setItem(AUTH_CURRENT_INDEX_LOCAL_STORAGE_KEY, "".concat(index));
                return [4 /*yield*/, getAndSetUserRights()];
            case 1:
                _a.sent();
                window.location.replace('/dashboard');
                return [2 /*return*/];
        }
    });
}); };
var logoutAll = function () {
    removeAuth();
    window.location.replace('/auth');
};
var logout = function () {
    var allAuth = getAllAuth();
    if (allAuth.length > 1) {
        removeCurrentAuth(allAuth);
        window.location.replace('/account/switch');
    }
    else {
        logoutAll();
    }
};
export function setupAxios(axios) {
    var _this = this;
    axios.defaults.headers.Accept = 'application/json';
    axios.interceptors.request.use(function (config) { return __awaiter(_this, void 0, void 0, function () {
        var auth, token;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    auth = getAuth();
                    if (!(auth && (auth === null || auth === void 0 ? void 0 : auth.data) && (auth === null || auth === void 0 ? void 0 : auth.data.token))) return [3 /*break*/, 5];
                    token = auth.data.token;
                    if (!isTokenExpired(auth.expiration)) return [3 /*break*/, 4];
                    if (!!isTokenRefreshing) return [3 /*break*/, 2];
                    isTokenRefreshing = true;
                    return [4 /*yield*/, refreshToken(token, auth.data.refreshToken)
                            .then(function (newToken) {
                            isTokenRefreshing = false;
                            refreshTokenPromise = null;
                            return newToken;
                        })
                            .catch(function (err) {
                            isTokenRefreshing = false;
                            refreshTokenPromise = null;
                            logout();
                        })];
                case 1:
                    refreshTokenPromise = _a.sent();
                    _a.label = 2;
                case 2: return [4 /*yield*/, refreshTokenPromise(token, auth.data.refreshToken)];
                case 3:
                    token = _a.sent();
                    _a.label = 4;
                case 4:
                    config.headers.Authorization = "Bearer ".concat(token);
                    _a.label = 5;
                case 5: return [2 /*return*/, config];
            }
        });
    }); }, function (err) { return Promise.reject(err); });
}
export { getAndSetUserRights, getAuth, setAuth, removeAuth, removeCurrentAuth, switchAuth, logout, logoutAll, getAllAuth, getCurrentUserIndex, AUTH_LOCAL_STORAGE_KEY, AUTH_CURRENT_INDEX_LOCAL_STORAGE_KEY, };
