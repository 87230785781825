var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState, useEffect } from 'react';
import { DefaultLayoutConfig } from './DefaultLayoutConfig';
import { getEmptyCssClasses, getEmptyCSSVariables, getEmptyHTMLAttributes, LayoutSetup, } from './LayoutSetup';
var LayoutContext = createContext({
    config: DefaultLayoutConfig,
    classes: getEmptyCssClasses(),
    attributes: getEmptyHTMLAttributes(),
    cssVariables: getEmptyCSSVariables(),
    setLayout: function (config) { },
});
var enableSplashScreen = function () {
    var splashScreen = document.getElementById('splash-screen');
    if (splashScreen) {
        splashScreen.style.setProperty('display', 'flex');
    }
};
var disableSplashScreen = function () {
    var splashScreen = document.getElementById('splash-screen');
    if (splashScreen) {
        splashScreen.style.setProperty('display', 'none');
    }
};
var LayoutProvider = function (_a) {
    var children = _a.children;
    var _b = useState(LayoutSetup.config), config = _b[0], setConfig = _b[1];
    var _c = useState(LayoutSetup.classes), classes = _c[0], setClasses = _c[1];
    var _d = useState(LayoutSetup.attributes), attributes = _d[0], setAttributes = _d[1];
    var _e = useState(LayoutSetup.cssVariables), cssVariables = _e[0], setCSSVariables = _e[1];
    var setLayout = function (_themeConfig) {
        enableSplashScreen();
        var bodyClasses = Array.from(document.body.classList);
        bodyClasses.forEach(function (cl) { return document.body.classList.remove(cl); });
        LayoutSetup.updatePartialConfig(_themeConfig);
        setConfig(Object.assign({}, LayoutSetup.config));
        setClasses(LayoutSetup.classes);
        setAttributes(LayoutSetup.attributes);
        setCSSVariables(LayoutSetup.cssVariables);
        setTimeout(function () {
            disableSplashScreen();
        }, 500);
    };
    var value = {
        config: config,
        classes: classes,
        attributes: attributes,
        cssVariables: cssVariables,
        setLayout: setLayout,
    };
    useEffect(function () {
        disableSplashScreen();
    }, []);
    return _jsx(LayoutContext.Provider, __assign({ value: value }, { children: children }));
};
export { LayoutContext, LayoutProvider };
export function useLayout() {
    return useContext(LayoutContext);
}
