var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './Record.module.scss';
import { useNavigate } from 'react-router-dom';
import { FormatDateEpoch, RoleList, userInformation } from '@src/app/helper';
var RecordJoblist = function (_a) {
    var jobNumber = _a.jobNumber, scheduleJobNumber = _a.scheduleJobNumber, schedules = _a.schedules, _b = _a.isFromTalent, isFromTalent = _b === void 0 ? false : _b;
    var role = JSON.parse(userInformation).roles[0];
    var navigate = useNavigate();
    return (_jsxs("div", { children: [scheduleJobNumber && (_jsx("p", { children: role === RoleList.CampaignExecutive ? (_jsx("a", __assign({ href: "/job/detail/".concat(scheduleJobNumber), className: styles['record-job__id'], onClick: function () { return navigate("/job/detail/".concat(scheduleJobNumber)); } }, { children: scheduleJobNumber }))) : (scheduleJobNumber) })), schedules &&
                schedules.length > 0 &&
                schedules.map(function (schedule, i) { return (_jsxs("div", __assign({ className: 'mb-3' }, { children: [jobNumber && (_jsx("p", { children: _jsx("a", __assign({ href: "/job/detail/".concat(jobNumber), className: styles['record-job__id'], onClick: function () { return navigate("/job/detail/".concat(jobNumber)); } }, { children: jobNumber })) })), isFromTalent && _jsx("p", { children: "Upload On: ".concat(FormatDateEpoch(schedule.schedule.upOn)) }), _jsxs("p", { children: [schedule.jobDetail.jobType.name, " - ", schedule.jobDetail.totalPosts, "x (", schedule.schedule.sequence, "/", schedule.jobDetail.totalPosts, ")"] }), schedule.schedule.insightCPE && _jsxs("p", { children: ["CPE: ", schedule.schedule.insightCPE] }), schedule.schedule.insightCPV && _jsxs("p", { children: ["CPV: ", schedule.schedule.insightCPV] }), schedule.schedule.insightER && _jsxs("p", { children: ["ER: ", schedule.schedule.insightER] }), schedule.schedule.insightROI && _jsxs("p", { children: ["ROI: ", schedule.schedule.insightROI] })] }), i)); })] }));
};
export default RecordJoblist;
