var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { KTCard, isFullURL } from '@src/_metronic/helpers';
import { DefaultAllList, FormatYearMonthDate, MultiDimensionalFilter, RoleList, optionAllJadwalList, paginationOptions, transferOptions, userInformation, } from '@src/app/helper';
import { useDivisions } from '@src/app/pages/DivisionPage/DivisionHooks/Division.hooks';
import { useJobTypes } from '@src/app/pages/JobTypePage/JobType/JobType.hooks';
import { useSocialMedia } from '@src/app/pages/SocialMediaPage/SocialMedia/SocialMedia.hooks';
import { useTeams } from '@src/app/pages/TeamPage/TeamHooks/Team.hooks';
import '@src/app/styles/datatable.css';
import ScheduleActionDropdown from '@src/components/Atoms/ActionDropdown/ScheduleActionDropdown';
import WithScheduleActions from '@src/components/Atoms/ActionDropdown/WithScheduleActions';
import ScheduleActionButton from '@src/components/Atoms/Buttons/ScheduleActionButton';
import Date from '@src/components/Atoms/Date/Date';
import EditScheduleForm from '@src/components/Atoms/Drawer/ScheduleDrawer/EditScheduleForm';
import WithScheduleForm from '@src/components/Atoms/Drawer/ScheduleDrawer/WithScheduleForm';
import Loading from '@src/components/Atoms/Loading/Loading';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import Select from '@src/components/Atoms/Select/Select';
import DrawerDataTable from '@src/components/molecules/Drawer/DrawerDataTable';
import RecordConfirmation from '@src/components/molecules/Record/Confirmation';
import RecordContactPerson from '@src/components/molecules/Record/ContactPerson';
import RecordJoblist from '@src/components/molecules/Record/JobList';
import RecordName from '@src/components/molecules/Record/Name';
import RecordUploadSchedule from '@src/components/molecules/Record/UploadSchedule';
import { useProcessSchedule } from '@src/components/molecules/Schedule/NoSchedule/NoSchedule.request';
import Process from '@src/components/molecules/Schedule/Process';
import { Fragment, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
var JobDetailSchedule = function (_a) {
    var datas = _a.datas, isLoading = _a.isLoading, filter = _a.filter, setFilter = _a.setFilter, refetchSchedule = _a.refetchSchedule, date = _a.date, setDate = _a.setDate;
    var intl = useIntl();
    var role = JSON.parse(userInformation).roles[0];
    var _b = useState(), scheduleRecord = _b[0], setScheduleRecord = _b[1];
    var _c = useState(false), isOpenEditSettings = _c[0], setIsOpenEditSettings = _c[1];
    var _d = useState(false), isOpenProcessSettings = _d[0], setIsOpenProcessSettings = _d[1];
    var _e = useState(false), isOpenScheduling = _e[0], setIsOpenScheduling = _e[1];
    var socialMedias = JSON.parse(userInformation).socialMedias;
    var socmedIds = socialMedias === null || socialMedias === void 0 ? void 0 : socialMedias.map(function (socmed) { return socmed.pubId; });
    var socialMedia = useSocialMedia().socialMedia;
    var team = useTeams().team;
    var divisions = useDivisions().divisions;
    if (role === RoleList.CampaignExecutive) {
        socialMedia = JSON.parse(userInformation).socialMedias;
        divisions = JSON.parse(userInformation).divisions;
        team = JSON.parse(userInformation).teams;
    }
    var getOptionTeam = function (team) {
        if (team && team.data) {
            if (team.data.length > 0) {
                return team.data.map(function (team) { return ({
                    name: team.name,
                    value: team.pubId,
                }); });
            }
            else {
                return [];
            }
        }
        else {
            if (team) {
                return team.map(function (team) { return ({
                    name: team.name,
                    value: team.pubId,
                }); });
            }
            else {
                return [];
            }
        }
    };
    var optionSocialMedia = socialMedia && socialMedia.length > 0
        ? socialMedia.map(function (social) { return ({
            name: social.name,
            value: social.pubId,
        }); })
        : undefined;
    role !== RoleList.CampaignExecutive &&
        optionSocialMedia &&
        optionSocialMedia.unshift(DefaultAllList);
    var optionDivision = divisions && divisions.length > 0
        ? role === RoleList.CampaignExecutive
            ? divisions &&
                divisions.map(function (division, index) { return ({
                    name: "".concat(division.name, " - ").concat(socialMedia && socialMedia[index].name),
                    value: division.pubId,
                }); })
            : divisions &&
                divisions.map(function (division) { return ({
                    name: "".concat(division.name, " - ").concat(division.socialMedia.name),
                    value: division.pubId,
                }); })
        : undefined;
    var optionTeam = getOptionTeam(team);
    role !== RoleList.CampaignExecutive && optionTeam && optionTeam.unshift(DefaultAllList);
    var _f = useState({
        page: 1,
        pageSize: 100,
        keyword: '',
        filterBy: '[]',
        initialFilterBy: "[[\"recordStatus\", \"Active\"]]",
    }), filterJobType = _f[0], setFilterJobType = _f[1];
    var _g = useJobTypes(filterJobType), jobTypes = _g.jobTypes, isLoadingJobType = _g.isLoading, refetchJobType = _g.refetch;
    var optionJobType = jobTypes && jobTypes.data && jobTypes.data.length > 0
        ? jobTypes.data
            .filter(function (obj) { return socmedIds.includes(obj.socialMedia.pubId); })
            .map(function (job) { return ({
            name: job.name,
            value: job.pubId,
        }); })
        : undefined;
    var onChangeDate = function (e) {
        var event = { target: { name: '', value: null } };
        event.target.name = 'date';
        event.target.value = e == null ? '' : FormatYearMonthDate(e);
        setDate(event.target.value);
        handleMultiDimensionalFilterChange(event);
    };
    var handleMultiDimensionalFilterChange = function (event) {
        var filterValue = filter.filterBy !== '' ? JSON.parse(filter.filterBy) : '';
        if (filter.filterBy === '') {
            var filterCriteria = [[event.target.name, event.target.value]];
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterCriteria) }));
        }
        else {
            var filterNew = MultiDimensionalFilter(event, filterValue);
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterNew) }));
        }
    };
    var handleFilterChange = function (event) {
        var _a;
        setFilter(__assign(__assign({}, filter), (_a = {}, _a[event.target.name] = event.target.value, _a)));
    };
    var handlePageChange = function (page) {
        setFilter(__assign(__assign({}, filter), { page: page }));
    };
    var handlePageSizeChange = function (size) {
        setFilter(__assign(__assign({}, filter), { page: 1, pageSize: size }));
    };
    var navigate = useNavigate();
    //gotodetail
    var gotoDetail = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("/talent/detail/".concat(record));
            return [2 /*return*/];
        });
    }); };
    var _h = useState(), talentRecord = _h[0], setTalentRecord = _h[1];
    var _j = useState(), selectedToEdit = _j[0], setSelectedToEdit = _j[1];
    var syncTalentRecord = useMemo(function () {
        if (!(datas === null || datas === void 0 ? void 0 : datas.data.length) || !talentRecord)
            return;
        return ((datas === null || datas === void 0 ? void 0 : datas.data.find(function (schedule) { return schedule.talent.pubId === talentRecord.talent.pubId; })) || talentRecord);
    }, [talentRecord, datas === null || datas === void 0 ? void 0 : datas.data]);
    var handleProcessTalent = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setIsOpenProcessSettings(true);
            setTalentRecord(record);
            return [2 /*return*/];
        });
    }); };
    var handleLinkItem = function (url) {
        if (isFullURL(url)) {
            window.open(url, '_blank');
        }
        else {
            window.open("https://".concat(url), '_blank');
        }
    };
    var handleEditItem = function (record, talentId, tenantId) {
        var recordData = __assign(__assign({}, record), { talentId: talentId, tenantId: tenantId });
        setIsOpenEditSettings(true);
        setSelectedToEdit(recordData);
    };
    var undoSchedule = useProcessSchedule().undoSchedule;
    var handleUndoItem = function (id) {
        swal({
            title: intl.formatMessage({ id: 'GENERAL.VALIDATION' }),
            text: '',
            icon: 'warning',
            buttons: [
                intl.formatMessage({ id: 'GENERAL.VALIDATION.NO' }),
                intl.formatMessage({ id: 'GENERAL.VALIDATION.YES' }),
            ],
            dangerMode: true,
        }).then(function (willDelete) { return __awaiter(void 0, void 0, void 0, function () {
            var e_1, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!willDelete) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, undoSchedule(id)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        error = e_1.response.data;
                        toast.error(error.message);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    };
    // TODO: show cp email and cp other and country name on datatable row, as its missing from api return
    var columns = __spreadArray(__spreadArray(__spreadArray([
        {
            name: 'Contact Person',
            cell: function (record) { return (_jsx(RecordContactPerson, { cpName: record.talent.cpName, cpPhoneNumber: record.talent.cpPhoneNumber })); },
            sortable: true,
        },
        {
            name: 'Nama',
            cell: function (record) { return (_jsx(RecordName, { pubId: record.talent.pubId, talentName: record.talent.name, profiles: record.talent.profiles })); },
            sortable: true,
        },
        {
            name: 'Kerja Sama',
            cell: function (record) { return _jsx(RecordJoblist, { schedules: record.schedules }); },
            sortable: true,
        }
    ], (['WithSchedule', 'LateUpload'].includes(filter.Type)
        ? [
            {
                name: 'Oleh',
                cell: function (record) { return _jsx(RecordConfirmation, { record: record.schedules }); },
                sortable: true,
            },
        ]
        : []), true), (['DoneUpload', 'DoneUploadNoLink'].includes(filter.Type)
        ? [
            {
                name: 'Jadwal Upload',
                cell: function (record) { return _jsx(RecordUploadSchedule, { schedules: record.schedules }); },
                sortable: true,
            },
            {
                name: 'Tanggal Upload',
                cell: function (record) { return (_jsx(RecordUploadSchedule, { schedules: record.schedules, showConfirmBy: true })); },
                sortable: true,
            },
        ]
        : []), true), [
        {
            name: 'Action',
            cell: function (record) {
                if (['WithSchedule', 'LateUpload'].includes(filter.Type)) {
                    return (_jsx(WithScheduleActions, { handleProcess: handleProcessTalent, handleNote: function () { }, handleUp: function () { }, record: record, transferType: transferOptions.pertermin }));
                }
                if (filter.Type === 'NoSchedule')
                    return _jsx(ScheduleActionDropdown, { handleProcess: handleScheduleTalent, record: record });
                if (['DoneUpload', 'DoneUploadNoLink'].includes(filter.Type)) {
                    return (_jsx(ScheduleActionButton, { schedules: record.schedules, talent: record.talent, linkItem: handleLinkItem, editItem: handleEditItem, undoItem: handleUndoItem }));
                }
            },
        },
    ], false);
    var handleScheduleTalent = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setScheduleRecord(record);
            setIsOpenScheduling(true);
            return [2 /*return*/];
        });
    }); };
    var _k = useState(0), jobTypeValue = _k[0], setJobTypeValue = _k[1];
    var onChangeSocialMedia = function (e) {
        filterJobType['filterBy'] = "[[\"socialMedia.pubId\", \"".concat(e.target.value, "\"]]");
        setJobTypeValue(jobTypeValue + 1);
    };
    useEffect(function () {
        refetchJobType();
    }, [jobTypeValue]);
    useEffect(function () {
        var filterValue = JSON.parse(filter.filterBy);
        var filterJobTypeValue = JSON.parse(filterJobType.filterBy);
        if (socialMedia && socialMedia.length > 0) {
            var filterCriteria = ['division.socialMedia.pubId', socialMedia && socialMedia[0].pubId];
            filterValue.push(filterCriteria);
            var filterJobTypeCriteria = ['socialMedia.pubId', socialMedia && socialMedia[0].pubId];
            filterJobTypeValue.push(filterJobTypeCriteria);
        }
        if (optionTeam) {
            var filterCriteria = ['team.pubId', optionTeam && optionTeam[0].value];
            filterValue.push(filterCriteria);
        }
        if (date) {
            var filterCriteria = ['date', date];
            filterValue.push(filterCriteria);
        }
        setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterValue) }));
        setFilterJobType(__assign(__assign({}, filterJobType), { filterBy: JSON.stringify(filterJobTypeValue) }));
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(DrawerDataTable, { isOpen: isOpenProcessSettings, handleClose: setIsOpenProcessSettings, record: syncTalentRecord, component: WithScheduleForm, size: 'LG', title: 'Detail', refetch: refetchSchedule }), _jsx(DrawerDataTable, { isOpen: isOpenEditSettings, handleClose: setIsOpenEditSettings, record: selectedToEdit, component: EditScheduleForm, size: 'LG', title: 'Edit Jadwal', refetch: refetchSchedule }), scheduleRecord && (_jsx(_Fragment, { children: _jsx(Process, { isOpen: isOpenScheduling, handleClose: function () {
                        setScheduleRecord('');
                        setIsOpenScheduling(false);
                    }, record: scheduleRecord, refetch: refetchSchedule }) })), _jsxs(KTCard, { children: [_jsx("div", __assign({ className: 'd-flex flex-column' }, { children: _jsx("div", __assign({ className: 'border-0 d-flex flex-row' }, { children: _jsxs("div", __assign({ className: 'd-flex flex-row gap-2 align-items-center' }, { children: [_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Date, { date: date, handleonchange: onChangeDate }) })), optionAllJadwalList && (_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Select, { name: 'Type' //filter ke be
                                            , onChange: function (e) {
                                                handleFilterChange(e);
                                                // setScheduleType(e.target.value)
                                                navigate("#".concat(e.target.value));
                                            }, optionList: optionAllJadwalList }) }))), optionSocialMedia && optionSocialMedia.length > 0 && (_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Select, { name: 'division.socialMedia.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                                onChangeSocialMedia(e);
                                            }, optionList: optionSocialMedia, defaultValue: optionSocialMedia[0].value, isPlaceholder: false }) }))), optionDivision && optionDivision.length > 0 && (_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Select, { name: 'division.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, optionList: optionDivision, defaultValue: optionDivision[0].value }) }))), optionTeam && optionTeam.length > 0 && (_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Select, { name: 'team.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, optionList: optionTeam, defaultValue: optionTeam[0].value }) }))), !isLoadingJobType && optionJobType && (_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Select, { name: 'jobDetail.jobType.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, optionList: optionJobType }) })))] })) })) })), _jsx("div", __assign({ className: 'card-body p-0' }, { children: !isLoading ? datas && datas.data && datas.data.length > 0 ? (_jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: datas ? datas === null || datas === void 0 ? void 0 : datas.data : [], noHeader: true, progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: filter.filterBy === ''
                                ? datas && (datas === null || datas === void 0 ? void 0 : datas.recordsTotal)
                                : datas && (datas === null || datas === void 0 ? void 0 : datas.recordsFiltered), paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange }, datas && (datas === null || datas === void 0 ? void 0 : datas.data.length))) : (_jsx(NotFound, {})) : (_jsx(Loading, {})) }))] })] }));
};
export default JobDetailSchedule;
