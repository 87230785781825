var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useMemo, Fragment } from 'react';
import { PageTitle } from '@src/_metronic/layout/core';
import { KTCard } from '@src/_metronic/helpers';
import DataTable from 'react-data-table-component';
import swal from 'sweetalert';
import { MenuComponent } from '@src/_metronic/assets/ts/components';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { RecordStatusOptions, paginationOptions, MultiDimensionalFilter, userInformation, } from '@src/app/helper';
import Input from '@src/components/Atoms/Input/Input';
import { toast } from 'react-toastify';
import { useTalents } from '../TalentHooks/Talent.hooks';
import '@src/app/styles/datatable.css';
import { TalentStatusList } from '../Talent.enum';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import Loading from '@src/components/Atoms/Loading/Loading';
//breadcrumb
var usersBreadcrumbs = [
    {
        title: 'Talent',
        path: '',
        isSeparator: true,
        isActive: false,
    },
];
var TalentMigration = function () {
    var navigate = useNavigate();
    var intl = useIntl();
    var id = useParams().id;
    //initialize filter
    var _a = useState({
        page: 1,
        pageSize: 10,
        keyword: '',
        filterBy: '',
    }), filter = _a[0], setFilter = _a[1];
    //getdata
    var _b = useTalents(filter), talents = _b.talents, isLoading = _b.isLoading, deleteTalent = _b.deleteTalent, deleteTalents = _b.deleteTalents;
    var socialMedias = JSON.parse(userInformation).socialMedias;
    var divisions = JSON.parse(userInformation).divisions;
    var teams = JSON.parse(userInformation).teams;
    var optionSocialMedia = socialMedias &&
        socialMedias.map(function (social) { return ({
            name: social.name,
            value: social.pubId,
        }); });
    var optionDivision = divisions &&
        divisions.map(function (division) { return ({
            name: "".concat(division.name, " - ").concat(division.socialMedia.name),
            value: division.pubId,
        }); });
    var optionTeam = teams &&
        teams.map(function (team) { return ({
            name: team.name,
            value: team.pubId,
        }); });
    //filter
    var _c = useState({
        recordStatus: '',
    }), selectedFilter = _c[0], setSelectedFilter = _c[1];
    //pagination
    var handlePageChange = function (page) {
        setFilter(__assign(__assign({}, filter), { page: page }));
    };
    var handlePageSizeChange = function (size) {
        setFilter(__assign(__assign({}, filter), { page: 1, pageSize: size }));
    };
    //getsearch
    var _d = useState(RecordStatusOptions), Status = _d[0], setStatus = _d[1];
    //search & filters
    var handleMultiDimensionalFilterChange = function (event) {
        var filterValue = filter.filterBy !== '' ? JSON.parse(filter.filterBy) : '';
        if (filter.filterBy === '') {
            var filterCriteria = [[event.target.name, event.target.value]];
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterCriteria) }));
        }
        else {
            var filterNew = MultiDimensionalFilter(event, filterValue);
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterNew) }));
        }
    };
    var handleFilterChange = function (event) {
        var _a;
        setFilter(__assign(__assign({}, filter), (_a = {}, _a[event.target.name] = event.target.value, _a)));
    };
    //reset filter
    function resetFilter() {
        setFilter(__assign(__assign({}, filter), { filterBy: '' }));
    }
    //select
    var _e = useState([]), selectedRows = _e[0], setSelectedRows = _e[1];
    var _f = useState(false), toggleCleared = _f[0], setToggleCleared = _f[1];
    var handleRowSelected = function (state) {
        setSelectedRows(state.selectedRows);
    };
    var contextActions = useMemo(function () {
        var handleDelete = function () {
            swal({
                title: intl.formatMessage({ id: 'GENERAL.VALIDATION' }),
                text: '',
                icon: 'warning',
                buttons: [
                    intl.formatMessage({ id: 'GENERAL.VALIDATION.NO' }),
                    intl.formatMessage({ id: 'GENERAL.VALIDATION.YES' }),
                ],
                dangerMode: true,
            }).then(function (willDelete) { return __awaiter(void 0, void 0, void 0, function () {
                var rows, e_1, error;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!willDelete) return [3 /*break*/, 4];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            setToggleCleared(!toggleCleared);
                            rows = selectedRows.map(function (obj, index) {
                                return obj['pubId'];
                            });
                            return [4 /*yield*/, deleteTalents(rows)];
                        case 2:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 3:
                            e_1 = _a.sent();
                            error = e_1.response.data;
                            toast.error(error.message);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            }); });
        };
        return (_jsx("button", __assign({ onClick: handleDelete, className: 'btn btn-sm btn-danger' }, { children: "Delete" }), 'delete'));
    }, [selectedRows, toggleCleared]);
    //edit
    var editItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("edit/".concat(record.pubId));
            return [2 /*return*/];
        });
    }); };
    //delete
    var deleteItem = function (record) {
        swal({
            title: intl.formatMessage({ id: 'GENERAL.VALIDATION' }),
            text: '',
            icon: 'warning',
            buttons: [
                intl.formatMessage({ id: 'GENERAL.VALIDATION.NO' }),
                intl.formatMessage({ id: 'GENERAL.VALIDATION.YES' }),
            ],
            dangerMode: true,
        }).then(function (willDelete) { return __awaiter(void 0, void 0, void 0, function () {
            var e_2, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!willDelete) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, deleteTalent(record.pubId)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        error = e_2.response.data;
                        toast.error(error.message);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    };
    //gotodetail
    var gotoDetail = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("/talent/detail/".concat(record));
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        MenuComponent.reinitialization();
    }, []);
    var columns = [
        {
            name: 'Contact Person',
            cell: function (record) {
                return (_jsxs("div", { children: [_jsx("p", __assign({ className: 'mb-2' }, { children: record.cpName })), _jsx("p", __assign({ className: 'mb-2' }, { children: record.cpPhoneNumber })), _jsx("p", __assign({ className: 'mb-2' }, { children: record.cpEmail }))] }));
            },
            sortable: true,
        },
        {
            name: 'Nama',
            cell: function (record) { return (_jsx(_Fragment, { children: _jsx("div", { children: _jsx("p", { children: record.name }) }) })); },
            sortable: true,
        },
        {
            name: 'Profile',
            cell: function (record) { return (_jsxs("div", { children: [_jsx("p", __assign({ className: 'mb-1' }, { children: "Profile" })), _jsx("div", { children: record.profiles.map(function (obj, key) {
                            return (_jsx("div", __assign({ className: 'mt-2' }, { children: _jsx("a", __assign({ className: 'goto', href: obj.profileLink, target: '_blank', rel: 'noopener noreferrer' }, { children: obj.socialMedia.name })) }), key));
                        }) })] })); },
            sortable: true,
        },
        {
            name: 'Note',
            cell: function (record) {
                var _a;
                return (
                // <div className={`badge badge-${TalentBadgeStatus(record.talentStatus)} fw-bolder`}>
                //   {TalentStatus(record.talentStatus)}
                // </div>
                _jsx("div", { children: (_a = record.note) !== null && _a !== void 0 ? _a : '-' }));
            },
            sortable: true,
        },
        {
            name: 'Action',
            cell: function (row) {
                return (_jsx("div", { children: _jsx("button", __assign({ className: 'btn btn-sm btn-success', onClick: function () { return gotoDetail(row.pubId); } }, { children: "Detail" })) }));
            },
            sortable: true,
        },
    ];
    //show/hide filter
    var _g = useState(true), hideFilter = _g[0], setHideFilter = _g[1];
    var toggleHideFilter = function () {
        setHideFilter(!hideFilter);
        resetFilter();
    };
    var TalentStatus = function (status) {
        switch (status) {
            case TalentStatusList.NOTYET:
                return 'Not Yet';
            case TalentStatusList.DONE:
                return 'Done';
            case TalentStatusList.CANCELLED:
                return 'Cancelled';
            case TalentStatusList.ONGOING:
                return 'On Going';
            case TalentStatusList.REJECTED:
                return 'Rejected';
        }
    };
    var TalentBadgeStatus = function (status) {
        switch (status) {
            case TalentStatusList.NOTYET:
                return 'light';
            case TalentStatusList.DONE:
                return 'success';
            case TalentStatusList.CANCELLED:
                return 'danger';
            case TalentStatusList.ONGOING:
                return 'primary';
            case TalentStatusList.REJECTED:
                return 'warning';
        }
    };
    return (_jsxs(Fragment, { children: [_jsx(PageTitle, __assign({ breadcrumbs: usersBreadcrumbs }, { children: "Talent Migration" })), _jsxs(KTCard, { children: [_jsx("div", __assign({ className: 'd-flex flex-column' }, { children: _jsx("div", __assign({ className: 'card-header border-0 pt-6 d-flex flex-row' }, { children: _jsx("div", __assign({ className: 'card-title flex' }, { children: _jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Input, { placeholder: 'Search', type: 'text', name: 'keyword', value: filter.keyword, onChange: function (e) {
                                            handleFilterChange(e);
                                        } }) })) })) })) })), _jsx("div", __assign({ className: 'card-body py-4' }, { children: _jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: talents ? talents.data : [], noHeader: true, progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: talents && talents.recordsTotal, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange }) }))] })] }));
};
export default TalentMigration;
