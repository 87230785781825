var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import MultiSelectFormik from '@src/components/Atoms/Formik/MultiSelect/MultiSelectFormik';
var TCForm = function (_a) {
    var useForm = _a.useForm, socialMediaList = _a.socialMediaList, divisionList = _a.divisionList, teamList = _a.teamList, talentList = _a.talentList, selectedSocialMedia = _a.selectedSocialMedia, setSelectedSocialMedia = _a.setSelectedSocialMedia, selectedDivision = _a.selectedDivision, setSelectedDivision = _a.setSelectedDivision, selectedTeam = _a.selectedTeam, setSelectedTeam = _a.setSelectedTeam, selectedTalent = _a.selectedTalent, setSelectedTalent = _a.setSelectedTalent, onSearchTalent = _a.onSearchTalent;
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'mx-5 mx-xl-15 my-7' }, { children: [_jsx(MultiSelectFormik, { name: 'socialMediaPubId', label: 'Sosial Media', formik: useForm, options: socialMediaList, isMulti: false, value: selectedSocialMedia, onchange: setSelectedSocialMedia }), _jsx(MultiSelectFormik, { name: 'divisionPubId', label: 'Division', formik: useForm, options: divisionList, isMulti: false, value: selectedDivision, onchange: setSelectedDivision }), _jsx(MultiSelectFormik, { name: 'teamPubId', label: 'Team', formik: useForm, options: teamList, isMulti: false, value: selectedTeam, onchange: setSelectedTeam }), _jsx(MultiSelectFormik, { name: 'talentPubId', label: 'Talent', formik: useForm, options: talentList, isMulti: false, value: selectedTalent, onchange: setSelectedTalent, onInputChange: function (value) {
                        onSearchTalent(value);
                    } })] })) }));
};
export default TCForm;
