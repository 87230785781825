var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import SVG from 'react-inlinesvg';
var KTSVG = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, path = _a.path, _c = _a.svgClassName, svgClassName = _c === void 0 ? 'mh-50px' : _c;
    return (_jsx("span", __assign({ className: "svg-icon ".concat(className) }, { children: _jsx(SVG, { src: path, className: svgClassName }) })));
};
export { KTSVG };
