var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { RoleList, userInformation } from '@src/app/helper';
import { Fragment } from 'react';
var WithScheduleActions = function (_a) {
    var record = _a.record, _b = _a.handleProcess, handleProcess = _b === void 0 ? function () { return false; } : _b, _c = _a.handleNote, handleNote = _c === void 0 ? function () { return false; } : _c, _d = _a.handleUp, handleUp = _d === void 0 ? function () { return false; } : _d, transferType = _a.transferType;
    var role = JSON.parse(userInformation).roles[0];
    return (_jsx(Fragment, { children: _jsxs("div", __assign({ className: 'd-flex gap-2' }, { children: [role === RoleList.CampaignExecutive ? (_jsx("button", __assign({ type: 'button', onClick: function () { return handleProcess(record); }, className: 'btn btn-primary btn-active-light-primary mr-2 btn-sm' }, { children: "Detail" }))) : undefined, role === RoleList.CampaignAssistant ? (_jsxs(_Fragment, { children: [_jsx("button", __assign({ type: 'button', onClick: function () { return handleUp(record); }, className: 'btn btn-primary btn-active-light-primary mr-2 btn-sm' }, { children: "Up" })), _jsx("button", __assign({ type: 'button', onClick: function () { return handleNote(record); }, className: 'btn btn-secondary btn-active-light-primary mr-2 btn-sm' }, { children: "Note" }))] })) : undefined] })) }));
};
export default WithScheduleActions;
