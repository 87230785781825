var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useAuth } from '../core/Auth';
export function Accounts(_a) {
    var auths = _a.auths;
    var _b = useAuth(), switchUser = _b.switchUser, logoutAll = _b.logoutAll;
    return (_jsxs("div", __assign({ className: 'd-flex flex-column' }, { children: [auths.map(function (auth, index) { return (_jsxs("div", { children: [_jsxs("button", __assign({ className: 'btn-account-switcher', onClick: function () {
                            switchUser(auth);
                        } }, { children: [_jsx("span", __assign({ className: 'fw-bold fs-4' }, { children: auth.data.userName })), " ", _jsx("br", {}), _jsx("div", __assign({ className: 'text-muted fs-7 d-flex gap-2' }, { children: auth.data.roles.map(function (role) { return (_jsx("span", { children: role }, role)); }) }))] })), _jsx("hr", {})] }, index)); }), _jsxs("button", __assign({ className: 'btn-account-switcher d-flex align-items-center', onClick: function () {
                    logoutAll();
                } }, { children: [_jsx("i", { className: 'fas fa-arrow-right-from-bracket me-2 fs-4' }), _jsx("span", __assign({ className: 'text-muted fw-bold fs-5' }, { children: "Sign out all accounts" })), " ", _jsx("br", {})] }))] })));
}
