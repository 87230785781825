var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormatDateEpoch } from '@src/app/helper';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
var RecordUploadSchedule = function (_a) {
    var schedules = _a.schedules, _b = _a.showConfirmBy, showConfirmBy = _b === void 0 ? false : _b, record = _a.record;
    return (_jsx(OverlayTrigger, __assign({ placement: "right", delay: { show: 250, hide: 400 }, overlay: function (props) { return (_jsx(Tooltip, __assign({ id: "button-tooltip" }, props, { children: record === null || record === void 0 ? void 0 : record.team.name }))); } }, { children: _jsx("div", { children: schedules &&
                schedules.length > 0 &&
                schedules.map(function (data, i) { return (_jsx("div", __assign({ className: 'mb-3' }, { children: !showConfirmBy ? (_jsx("p", { children: FormatDateEpoch(Number(data.schedule.scheduleDate)) })) : (_jsxs("div", { children: [_jsx("p", { children: FormatDateEpoch(Number(data.schedule.upOn)) }), _jsx("p", { children: data.schedule.upBy || '-' })] })) }), i)); }) }) })));
};
export default RecordUploadSchedule;
