import { ScheduleFilterType } from '@src/components/molecules/Schedule/NoSchedule/NoSchedule.constant';
export var JadwalList = {
    tanpajadwal: 'TanpaJadwal',
    denganjadwal: 'DenganJadwal',
    telat: 'Telat',
    doneupload: 'DoneUpload',
    tanpabukti: 'DoneUploadTanpaBukti',
};
export var optionAllJadwalList = [
    {
        value: ScheduleFilterType.NOSCHEDULE,
        name: 'Tanpa Jadwal',
    },
    {
        value: ScheduleFilterType.WITHSCHEDULE,
        name: 'Dengan Jadwal',
    },
    {
        value: ScheduleFilterType.LATEUPLOAD,
        name: 'Telat Upload',
    },
    {
        value: ScheduleFilterType.DONEUPLOAD,
        name: 'Done Upload',
    },
    {
        value: ScheduleFilterType.DONEUPLOADNOLINK,
        name: 'Done Upload Tanpa Bukti',
    },
];
export var optionJadwalList = [
    {
        value: ScheduleFilterType.WITHSCHEDULE,
        name: 'Dengan Jadwal',
    },
    {
        value: ScheduleFilterType.LATEUPLOAD,
        name: 'Telat Upload',
    },
];
export var optionJadwalUploadList = [
    {
        value: ScheduleFilterType.DONEUPLOAD,
        name: 'Done Upload',
    },
    {
        value: ScheduleFilterType.DONEUPLOADNOLINK,
        name: 'Done Upload Tanpa Bukti',
    },
];
