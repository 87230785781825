import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate } from 'react-router-dom';
import { userInformation, permissions } from '@src/app/helper';
var usePermissions = function () {
    var role = JSON.parse(userInformation).roles[0];
    var hasPermission = function (permissionName) {
        return permissions[permissionName].includes(role);
    };
    return { hasPermission: hasPermission };
};
export var ProtectedRoute = function (_a) {
    var permission = _a.permission, children = _a.children;
    var hasPermission = usePermissions().hasPermission;
    if (!hasPermission(permission)) {
        return _jsx(Navigate, { to: '/error/403' });
    }
    return children;
};
export function ProtectedUrl(permission) {
    var hasRole = true;
    var hasPermission = usePermissions().hasPermission;
    if (hasPermission(permission)) {
        return hasRole;
    }
}
