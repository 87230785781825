export var jobInitialValues = {
    talentAddressPubId: '',
    talentBankAccountPubId: '',
    address: {
        recipientName: '',
        recipientPhoneNumber: '',
        address: '',
        cityPubId: '',
    },
    bank: {
        bankPubId: '',
        accountNumber: '',
        accountName: '',
        branchName: '',
        cityPubId: '',
    },
    totalFeeBundling: 0,
    request: '',
    details: [
        {
            profilePubId: '',
            jobTypePubId: '',
            exclusiveStatus: '',
            fee: '',
            feePerUnit: '',
            totalPosts: '',
            totalFollowers: '',
            timeFrame: '',
            notes: '',
            owningType: '',
            boostingType: '',
        },
    ],
};
export var defaultOptionAddress = [
    {
        value: 'add-new',
        label: '-- Add new address --',
    },
];
export var defaultOptionBank = [
    {
        value: 'add-new',
        label: '-- Add new bank --',
    },
];
export var ExclusiveOptions = [
    {
        value: 'NonExclusive',
        name: 'Non Exclusive',
    },
    {
        value: 'Exclusive',
        name: 'Exclusive',
    },
];
export var defaultOptionSendItem = [
    {
        value: 'Send Item',
        name: 'Send',
    },
    {
        value: 'Not Send Item',
        name: 'No',
    },
];
export var OwningOptions = [
    {
        value: 'NoOwning',
        name: 'No Owning',
    },
    {
        value: 'Owning',
        name: 'Owning',
    },
];
export var BoostingOptions = [
    {
        value: 'NoBoosting',
        name: 'No Boosting',
    },
    {
        value: 'Boosting',
        name: 'Boosting',
    },
];
export var defaultOptionCourier = [
    {
        value: 'jne-yes',
        label: 'JNE YES',
    },
    {
        value: 'choir-express',
        label: 'Choir Express',
    },
    {
        value: 'choir-express',
        label: 'Choir Express',
    },
    {
        value: 'gosend',
        label: 'Gosend',
    },
    {
        value: 'sicepat',
        label: 'Sicepat',
    },
    {
        value: 'ems',
        label: 'EMS',
    },
    {
        value: 'lion-parcel',
        label: 'Lion Parcel',
    },
];
export var defaultOptionItemType = [
    {
        value: 'face',
        name: 'Face',
    },
    {
        value: 'body',
        name: 'Body',
    },
    {
        value: 'giveaway',
        name: 'Giveaway',
    },
];
export var defaultOptionItemName = [
    {
        value: 'acne-day-cream',
        name: 'Acne Day Cream',
    },
    {
        value: 'acne-night-cream',
        name: 'Acne Night Cream',
    },
];
