var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var RadioFormik = function (_a) {
    var label = _a.label, value = _a.value, name = _a.name, formik = _a.formik;
    return (_jsxs("div", __assign({ className: 'fv-row' }, { children: [_jsxs("div", __assign({ className: 'd-flex align-items-center gap-2' }, { children: [_jsx("input", __assign({ type: 'radio' }, formik.getFieldProps(name), { 
                        //   className={clsx(
                        //     'form-select form-select-lg mb-3 mb-lg-0',
                        //     {'is-invalid': formik.touched[name] && formik.errors[name]},
                        //     {
                        //       'is-valid': formik.touched[name] && !formik.errors[name],
                        //     }
                        //   )}
                        name: name, value: value, id: "".concat(name, "-").concat(value) })), _jsx("label", __assign({ htmlFor: "".concat(name, "-").concat(value), className: 'form-label fs-6 fw-bolder text-dark mb-0' }, { children: label }))] })), formik.touched[name] && formik.errors[name] && (_jsx("div", __assign({ className: 'fv-plugins-message-container' }, { children: _jsx("div", __assign({ className: 'fv-help-block' }, { children: _jsx("span", __assign({ role: 'alert' }, { children: formik.errors[name] })) })) })))] })));
};
export default RadioFormik;
