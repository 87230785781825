var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { Logout, AuthPage, useAuth } from '@src/app/pages/auth';
import { App } from '../App';
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
var PUBLIC_URL = process.env.PUBLIC_URL;
var AppRoutes = function () {
    var _a = useAuth(), currentUser = _a.currentUser, getAllAuth = _a.getAllAuth;
    var allAuth = getAllAuth();
    return (_jsx(BrowserRouter, { children: _jsx(Routes, { children: _jsxs(Route, __assign({ element: _jsx(App, {}) }, { children: [_jsx(Route, { path: 'error/*', element: _jsx(ErrorsPage, {}) }), _jsx(Route, { path: 'logout', element: _jsx(Logout, {}) }), currentUser || allAuth.length > 0 ? (_jsxs(_Fragment, { children: [_jsx(Route, { path: '/*', element: _jsx(PrivateRoutes, {}) }), _jsx(Route, { index: true, element: _jsx(Navigate, { to: '/dashboard' }) })] })) : (_jsxs(_Fragment, { children: [_jsx(Route, { path: 'auth/*', element: _jsx(AuthPage, {}) }), _jsx(Route, { path: '*', element: _jsx(Navigate, { to: '/auth' }) })] }))] })) }) }));
};
export { AppRoutes };
