var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLayout } from '../../../core/LayoutProvider';
import { usePageData } from '../../../core/PageData';
var DefaultTitle = function () {
    var _a = usePageData(), pageTitle = _a.pageTitle, pageDescription = _a.pageDescription, pageBreadcrumbs = _a.pageBreadcrumbs;
    var config = useLayout().config;
    return (_jsxs("div", __assign({ "data-kt-swapper": 'true', "data-kt-swapper-mode": 'prepend', "data-kt-swapper-parent": "{default: '#kt_content_container', lg: '#kt_header_wrapper'}", className: 'd-flex flex-column align-items-start justify-content-center flex-wrap me-lg-20 pb-lg-0' }, { children: [pageTitle && (_jsxs("h1", __assign({ className: 'text-dark fw-bolder my-1 fs-3 lh-1' }, { children: [pageTitle, pageDescription && config.pageTitle && config.pageTitle.description && (_jsx("small", __assign({ className: 'text-muted fs-6 fw-normal ms-1' }, { children: pageDescription })))] }))), pageBreadcrumbs &&
                pageBreadcrumbs.length > 0 &&
                config.pageTitle &&
                config.pageTitle.breadCrumbs && (_jsxs("ul", __assign({ className: 'breadcrumb fw-bold fs-8 my-1' }, { children: [Array.from(pageBreadcrumbs)
                        .filter(function (l) { return !l.isSeparator; })
                        .map(function (item, index) { return (_jsx("li", __assign({ className: clsx('breadcrumb-item', {
                            'text-muted': !item.isSeparator && !item.isActive,
                        }) }, { children: _jsx(Link, __assign({ className: 'text-muted', to: item.path }, { children: item.title })) }), "".concat(item.path).concat(index))); }), _jsx("li", __assign({ className: 'breadcrumb-item text-muted' }, { children: pageTitle }))] })))] })));
};
export { DefaultTitle };
