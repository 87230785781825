import { followerCategoriesEnum } from './JobHelpers';
export function handletotalFollowerStyle(total) {
    switch (total) {
        case followerCategoriesEnum.NANO:
            return 'danger';
        case followerCategoriesEnum.MIKRO:
            return 'warning';
        case followerCategoriesEnum.MAKRO:
            return 'success';
        case followerCategoriesEnum.MEGA:
            return 'primary';
        default:
            return 'dark';
    }
}
