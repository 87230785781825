export var BankList = {
    semua: 'Semua',
    bca: 'BCA',
    nonbca: 'Non BCA',
};
export var optionBankList = [
    {
        value: 'All',
        name: BankList.semua,
    },
    {
        value: 'BCA',
        name: BankList.bca,
    },
    {
        value: 'NonBCA',
        name: BankList.nonbca,
    },
];
export var optionCetakList = [
    {
        value: 'BCA',
        name: BankList.bca,
    },
    {
        value: 'NonBCA',
        name: BankList.nonbca,
    },
];
