var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
var Address = function (_a) {
    var data = _a.data;
    var id = useParams().id;
    var intl = useIntl();
    return (_jsxs("div", __assign({ className: 'content-detail' }, { children: [_jsxs("div", __assign({ className: '' }, { children: [_jsx("label", __assign({ className: 'fw-bold' }, { children: "Nama" })), _jsx("p", { children: (data === null || data === void 0 ? void 0 : data.recipientName) ? data === null || data === void 0 ? void 0 : data.recipientName : '-' })] })), _jsxs("div", __assign({ className: '' }, { children: [_jsx("label", __assign({ className: 'fw-bold' }, { children: "No HP" })), _jsx("p", { children: (data === null || data === void 0 ? void 0 : data.recipientPhoneNumber) ? data === null || data === void 0 ? void 0 : data.recipientPhoneNumber : '-' })] })), _jsxs("div", __assign({ className: '' }, { children: [_jsx("label", __assign({ className: 'fw-bold' }, { children: "Alamat" })), _jsx("p", { children: (data === null || data === void 0 ? void 0 : data.address) ? data === null || data === void 0 ? void 0 : data.address : '-' })] })), _jsxs("div", __assign({ className: '' }, { children: [_jsx("label", __assign({ className: 'fw-bold' }, { children: "Kota" })), _jsx("p", { children: (data === null || data === void 0 ? void 0 : data.city.name) ? data === null || data === void 0 ? void 0 : data.city.name : '-' })] }))] })));
};
export default Address;
