var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { KTCard } from '@src/_metronic/helpers';
import { PageTitle } from '@src/_metronic/layout/core';
import { Fragment } from 'react';
import '@src/app/styles/datatable.css';
import InputFormik from '@src/components/Atoms/Formik/Input/InputFormik';
import { useFormik } from 'formik';
import { changePasswordSchema } from './ChangePassword.requests';
import { useChangePassword } from './ChangePassword.hooks';
import { toast } from 'react-toastify';
//breadcrumb
var usersBreadcrumbs = [
    {
        title: 'Change Password',
        path: '',
        isSeparator: true,
        isActive: false,
    },
];
var initialFormValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
};
var ChangePassword = function () {
    var changePasswordMutation = useChangePassword();
    var ChangePasswordFormik = useFormik({
        enableReinitialize: true,
        initialValues: initialFormValues,
        validationSchema: changePasswordSchema,
        onSubmit: function (values, _a) {
            var resetForm = _a.resetForm;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    changePasswordMutation.mutate(values, {
                        onError: function (err) {
                            var _a, _b;
                            toast.error((_b = (_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message);
                        },
                        onSuccess: function () {
                            toast.success('Ubah Password Berhasil!');
                            resetForm();
                        },
                    });
                    return [2 /*return*/];
                });
            });
        },
    });
    return (_jsxs(Fragment, { children: [_jsx(PageTitle, __assign({ breadcrumbs: usersBreadcrumbs }, { children: "Change Password" })), _jsx(KTCard, { children: _jsxs("form", __assign({ noValidate: true, className: 'card-body py-4', onSubmit: ChangePasswordFormik.handleSubmit }, { children: [_jsx(InputFormik, { label: 'Password Lama', name: 'oldPassword', formik: ChangePasswordFormik, type: 'text', placeholder: 'Input old password here' }), _jsx(InputFormik, { label: 'Password Baru', name: 'newPassword', formik: ChangePasswordFormik, type: 'text', placeholder: 'Input new password here' }), _jsx(InputFormik, { label: 'Konfirmasi Password', name: 'confirmPassword', formik: ChangePasswordFormik, type: 'text', placeholder: 'Input new password confirmation here' }), _jsx("div", __assign({ className: 'my-5' }, { children: _jsxs("div", __assign({ style: { textAlign: 'right' } }, { children: [_jsx("button", __assign({ type: 'button', className: 'btn btn-secondary btn-active-light-primary btn-sm', onClick: function () { return false; }, disabled: changePasswordMutation.isLoading, style: { marginRight: '1rem' } }, { children: "Cancel" })), _jsx("button", __assign({ type: 'submit', className: 'btn btn-primary btn-active-light-primary mr-2 btn-sm', onClick: function () { return false; }, disabled: changePasswordMutation.isLoading }, { children: "Save" }))] })) }))] })) })] }));
};
export default ChangePassword;
