var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
var ScheduledDetail = function (_a) {
    var record = _a.record, upItem = _a.upItem, noteItem = _a.noteItem, editItem = _a.editItem, deleteItem = _a.deleteItem;
    return (_jsx(Fragment, { children: _jsxs("div", { children: [_jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-primary', onClick: function () { return upItem(record); } }, { children: "Up" })), _jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-success mx-2', onClick: function () { return noteItem(record); } }, { children: "Note" })), _jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-warning', onClick: function () { return editItem(record); } }, { children: "Edit" })), _jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-danger mx-2', onClick: function () { return deleteItem(record); } }, { children: "x" }))] }) }));
};
export default ScheduledDetail;
