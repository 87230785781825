export var initialSendProductValues = {
    talentPubId: '',
    talentAddressPubId: '',
    jobPubId: '',
    teamPubId: '',
    courierPubId: '',
    details: [],
    products: [],
    note: '',
    sendProduct: {
        products: [
            {
                productPubId: '',
                qty: 1,
                productCategoryPubId: '',
            },
        ],
    },
    address: null,
};
export var SendProductValues = {
    talent: {
        pubId: '',
    },
    job: {
        pubId: '',
    },
    address: {
        pubId: '',
    },
    courier: {
        pubId: '',
    },
    details: [
        {
            product: {
                pubId: '',
                name: '',
                productCategory: {
                    pubId: '',
                    name: '',
                },
            },
            qty: 1,
        },
    ],
    note: '',
    products: [
        {
            productPubId: '',
            productCategoryPubId: '',
            qty: 1,
        },
    ],
};
