var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
var Datepicker = function (_a) {
    var date = _a.date, handleonchange = _a.handleonchange, _b = _a.name, name = _b === void 0 ? '' : _b, useForm = _a.useForm;
    return (_jsxs(_Fragment, { children: [_jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, { value: date, inputFormat: 'DD/MM/YYYY', dayOfWeekFormatter: function (day) { return day; }, onChange: function (e) {
                        handleonchange(e);
                    }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { className: 'product-datepicker form-control form-control-solid' }))); } }) })), useForm && useForm.touched[name] && useForm.errors[name] && (_jsx("div", __assign({ className: 'fv-plugins-message-container' }, { children: _jsx("div", __assign({ className: 'fv-help-block' }, { children: _jsx("span", __assign({ role: 'alert' }, { children: useForm.errors[name] })) })) })))] }));
};
export default Datepicker;
