var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var Select = function (_a) {
    var label = _a.label, name = _a.name, optionList = _a.optionList, onChange = _a.onChange, _b = _a.placeholder, placeholder = _b === void 0 ? 'Select One' : _b, _c = _a.isPlaceholder, isPlaceholder = _c === void 0 ? true : _c, defaultValue = _a.defaultValue;
    return (_jsxs("div", __assign({ className: "fv-row ".concat(label && 'mb-7') }, { children: [label && _jsx("label", __assign({ className: 'fw-bold fs-6 mb-2' }, { children: label })), _jsxs("select", __assign({ name: name, onChange: function (e) { return onChange(e); }, className: 'form-control form-select form-select mb-3 mb-lg-0', defaultValue: defaultValue }, { children: [isPlaceholder && _jsx("option", __assign({ value: '' }, { children: placeholder })), optionList.map(function (option) { return (_jsx("option", __assign({ value: option.value }, { children: option.name }), option.value)); })] }))] })));
};
export default Select;
