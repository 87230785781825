var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import MultiSelectFormik from '../../Formik/MultiSelect/MultiSelectFormik';
import SelectFormik from '../../Formik/Select/SelectFormik';
import { useBanks } from '@src/app/pages/BankPage/BankHooks/Bank.hooks';
import { RecordStatusList } from '@src/app/helper';
import { useCities } from '@src/app/pages/LookupPage/LookupHooks/Lookup.hooks';
import InputFormik from '../../Formik/Input/InputFormik';
var GagalTransferForm = function (_a) {
    var useForm = _a.useForm;
    var _b = useState({
        page: 1,
        pageSize: 200,
        keyword: '',
        filterBy: '',
        initialFilterBy: "[[\"recordStatus\", \"Active\"]]",
    }), filterPageSize = _b[0], setFilterPageSize = _b[1];
    var banks = useBanks(filterPageSize).banks;
    var cities = useCities().cities;
    var _c = useState([]), selectedCity = _c[0], setSelectedCity = _c[1];
    var optionBanks = banks &&
        banks.data &&
        banks.data.length > 0 &&
        banks.data
            .filter(function (bank) { return bank.recordStatus.toString() == RecordStatusList.Active; })
            .map(function (bank) { return ({
            name: bank.name,
            value: bank.pubId,
        }); });
    var optionCities = cities &&
        cities.data &&
        cities.data.length > 0 &&
        cities.data.map(function (city) { return ({
            label: city.name,
            value: city.pubId,
        }); });
    var onChangeCity = function (e) {
        setSelectedCity(e);
        useForm.values.cityPubId = e.value;
    };
    var cityOnEdit = cities &&
        cities.data &&
        cities.data.length > 0 &&
        cities.data
            .filter(function (city) { return city.pubId === useForm.values.cityPubId; })
            .map(function (obj) { return ({
            label: obj.name,
            value: obj.pubId,
        }); });
    useEffect(function () {
        cityOnEdit && setSelectedCity(cityOnEdit); //set city on edit
    }, [cities]);
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: 'mb-3' }, { children: _jsx("div", { children: optionBanks && (_jsx(SelectFormik, { name: 'transferFrom', label: 'Transfer Dari', formik: useForm, optionList: optionBanks })) }) })), _jsx("div", __assign({ className: 'mb-3' }, { children: _jsx("div", { children: _jsx(InputFormik, { label: 'No Rekening', type: 'text', placeholder: 'No Rekening', name: 'accountNumber', formik: useForm }) }) })), _jsx("div", __assign({ className: 'mb-3' }, { children: _jsx("div", { children: _jsx(InputFormik, { label: 'Nama Penerima', type: 'text', placeholder: 'Nama Penerima', name: 'accountName', formik: useForm }) }) })), _jsx("div", __assign({ className: 'mb-5' }, { children: _jsx("div", { children: _jsx(InputFormik, { label: 'Kantor Cabang', type: 'text', placeholder: 'Kantor Cabang', name: 'branchName', formik: useForm }) }) })), _jsx("div", __assign({ className: 'mb-3' }, { children: _jsx("div", { children: optionCities && (_jsx(MultiSelectFormik, { name: 'cityPubId', label: 'Kota/Kabupaten', formik: useForm, isMulti: false, options: optionCities, value: selectedCity, onchange: onChangeCity })) }) }))] }));
};
export default GagalTransferForm;
