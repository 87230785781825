var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Routes, Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { MenuComponent } from '@src/_metronic/assets/ts/components';
import SaldoTeamIndex from './SaldoTeam/SaldoTeam';
import SForm from './SaldoTeamForm/SaldoTeamForm';
import { ProtectedRoute } from '@src/app/helper';
var SaldoTeamPage = function () {
    //getdata
    var _a = useState([]), saldoTeamData = _a[0], setSaldoTeamData = _a[1];
    useEffect(function () {
        MenuComponent.reinitialization();
    }, [saldoTeamData]);
    return (_jsx(Routes, { children: _jsxs(Route, __assign({ element: _jsx(Outlet, {}) }, { children: [_jsx(Route, { path: '', element: _jsx(ProtectedRoute, { permission: 'saldoteam.view', children: _jsx(SaldoTeamIndex, {}) }) }), _jsx(Route, { path: '/add', element: _jsx(ProtectedRoute, { permission: 'saldoteam.add', children: _jsx(SForm, {}) }) })] })) }));
};
export default SaldoTeamPage;
