var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { RoleList, userInformation } from '@src/app/helper';
import { checkProfiles } from '@src/app/pages/BlastPage/BlastForm/BlastForm.requests';
import InputFormik from '@src/components/Atoms/Formik/Input/InputFormik';
import RadioFormik from '@src/components/Atoms/Formik/Radio/RadioFormik';
import SelectFormik from '@src/components/Atoms/Formik/Select/SelectFormik';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import styles from './TalentForm.module.scss';
var SOCIAL_MEDIA_URL = {
    instagram: 'https://www.instagram.com/',
    'ig komunitas': 'https://www.instagram.com/',
    youtube: 'https://www.youtube.com/@',
    twitter: 'https://twitter.com/',
    tiktok: 'https://www.tiktok.com/@',
};
export var BlastedOptions = [
    {
        value: 'false',
        name: 'No',
    },
    {
        value: 'true',
        name: 'Yes',
    },
];
var TalentSocialMedia = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var useForm = _a.useForm, isLoading = _a.isLoading, index = _a.index, optionList = _a.optionList, blacklistOption = _a.blacklistOption, removeSocialMedia = _a.removeSocialMedia, value = _a.value;
    var role = JSON.parse(userInformation).roles[0];
    var _h = useState(''), profileResult = _h[0], setProfileResult = _h[1];
    var _j = useState(), isProfileErr = _j[0], setIsProfileErr = _j[1];
    var debouncedValue = useDebounce(value.profileName, 1000)[0];
    var getProfileStatus = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_1, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, checkProfiles(value.profileName, value.socialMediaPubId)];
                case 1:
                    data = (_a.sent()).data;
                    if (data.message === 'Success') {
                        setProfileResult('Profile isnt available');
                        setIsProfileErr(true);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    response = error_1.response;
                    if (response) {
                        setProfileResult('Profile is available');
                        setIsProfileErr(false);
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var _k = useState(''), username = _k[0], setUsername = _k[1];
    useEffect(function () {
        useForm.setFieldValue("profiles[".concat(index, "].profileName"), username);
    }, [username]);
    var jsonValue = JSON.stringify({
        socialMediaPubId: value.socialMediaPubId,
        profileLink: value.profileLink,
    });
    var jsonOptions = JSON.stringify(optionList || []);
    // stringify json to prevent re-run in useEffect
    useEffect(function () {
        var _a, _b;
        var parsedValue = JSON.parse(jsonValue);
        var parsedOptions = JSON.parse(jsonOptions);
        if (!parsedValue.socialMediaPubId)
            return;
        var socialMediaName = (_a = parsedOptions === null || parsedOptions === void 0 ? void 0 : parsedOptions.find(function (obj) { return obj.value === parsedValue.socialMediaPubId; })) === null || _a === void 0 ? void 0 : _a.name;
        if (!Object.keys(SOCIAL_MEDIA_URL).includes(socialMediaName.toLowerCase())) {
            setUsername(parsedValue.profileLink);
        }
        else {
            if ((_b = parsedValue.profileLink) === null || _b === void 0 ? void 0 : _b.endsWith('/')) {
                setUsername(parsedValue.profileLink.slice(0, -1).split('/').slice(-1)[0]);
            }
            else {
                setUsername(parsedValue.profileLink.split('/').slice(-1)[0]);
            }
        }
    }, [jsonValue]);
    useEffect(function () {
        getProfileStatus();
    }, [debouncedValue]);
    return (_jsxs("div", __assign({ className: styles['profile-container'] }, { children: [_jsxs("div", __assign({ className: styles['profile-layout'] }, { children: [!isLoading && optionList && (_jsx(SelectFormik, { name: "profiles[".concat(index, "].socialMediaPubId"), label: 'Sosial Media', formik: useForm, optionList: optionList, isChildField: true, isRequired: true })), ' ', ((_b = useForm.errors) === null || _b === void 0 ? void 0 : _b.profiles) &&
                        ((_c = useForm.errors) === null || _c === void 0 ? void 0 : _c.profiles.filter(function (_, i) { return i === index; }).map(function (data, i) { return (_jsx("div", __assign({ className: 'fv-plugins-message-container mb-7', style: { marginTop: '-15px' } }, { children: _jsx("div", __assign({ className: 'fv-help-block' }, { children: _jsx("span", __assign({ role: 'alert' }, { children: data === null || data === void 0 ? void 0 : data.socialMediaPubId })) })) }), i)); })), _jsx(InputFormik, { label: 'Link Profile', type: 'text', placeholder: 'Link Profile', name: "profiles[".concat(index, "].profileLink"), formik: useForm, isRequired: true }), ((_d = useForm.errors) === null || _d === void 0 ? void 0 : _d.profiles) &&
                        ((_e = useForm.errors) === null || _e === void 0 ? void 0 : _e.profiles.filter(function (_, i) { return i === index; }).map(function (data, i) { return (_jsx("div", __assign({ className: 'fv-plugins-message-container mb-7', style: { marginTop: '-15px' } }, { children: _jsx("div", __assign({ className: 'fv-help-block' }, { children: _jsx("span", __assign({ role: 'alert' }, { children: data === null || data === void 0 ? void 0 : data.profileLink })) })) }), i)); })), _jsxs("div", __assign({ className: 'fv-row mb-7' }, { children: [_jsx("label", __assign({ className: 'form-label fs-6 fw-bolder text-dark required' }, { children: "Username" })), _jsx("input", { placeholder: 'Username', className: clsx("form-control form-control-lg mb-3 mb-lg-0 lh-base"), type: 'text', value: username, onChange: function (event) {
                                    setUsername(event.target.value);
                                } })] })), ((_f = useForm.errors) === null || _f === void 0 ? void 0 : _f.profiles) &&
                        ((_g = useForm.errors) === null || _g === void 0 ? void 0 : _g.profiles.filter(function (_, i) { return i === index; }).map(function (data, i) { return (_jsx("div", __assign({ className: 'fv-plugins-message-container mb-7', style: { marginTop: '-15px' } }, { children: _jsx("div", __assign({ className: 'fv-help-block' }, { children: _jsx("span", __assign({ role: 'alert' }, { children: data === null || data === void 0 ? void 0 : data.profileName })) })) }), i)); })), value.profileName && profileResult && profileResult !== '' && (_jsx("p", __assign({ className: "".concat(isProfileErr ? 'text-danger' : 'text-success', " ").concat(styles['profile-status-message']) }, { children: profileResult }))), role === RoleList.Community && (_jsxs(_Fragment, { children: [_jsx("label", __assign({ className: 'form-label fs-6 fw-bolder text-dark mb-2 required' }, { children: "Blast" })), _jsxs("div", __assign({ className: 'd-flex gap-3' }, { children: [_jsx(RadioFormik, { name: "profiles[".concat(index, "].isBlasted"), value: true, label: 'Yes', formik: useForm }), _jsx(RadioFormik, { name: "profiles[".concat(index, "].isBlasted"), value: false, label: 'No', formik: useForm })] }))] }))] })), _jsx("button", __assign({ className: 'btn btn-sm btn-danger', onClick: function () { return removeSocialMedia(index); } }, { children: _jsx("i", { className: 'fa fa-trash p-0' }) }))] })));
};
export default TalentSocialMedia;
