import axios from 'axios';
var API_URL = process.env.NEXUS_API_URL;
export var GET_USER_BY_ACCESSTOKEN_URL = "".concat(API_URL, "/verify_token");
export var GET_USER_INFORMATION_URL = "".concat(API_URL, "/users/access-rights");
export var LOGIN_URL = "".concat(API_URL, "/auth/login");
export var REGISTER_URL = "".concat(API_URL, "/register");
export var REQUEST_PASSWORD_URL = "".concat(API_URL, "/forgot_password");
// Server should return AuthModel
export function login(username, password) {
    return axios.post(LOGIN_URL, {
        username: username,
        password: password,
    });
}
// Server should return AuthModel
export function register(email, firstname, lastname, password, password_confirmation) {
    return axios.post(REGISTER_URL, {
        email: email,
        first_name: firstname,
        last_name: lastname,
        password: password,
        password_confirmation: password_confirmation,
    });
}
// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email) {
    return axios.post(REQUEST_PASSWORD_URL, {
        email: email,
    });
}
export function getUserByToken(token) {
    return axios.post(GET_USER_BY_ACCESSTOKEN_URL, {
        api_token: token,
    });
}
export function getUserInformation() {
    return axios.get(GET_USER_INFORMATION_URL);
}
