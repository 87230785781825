var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import Select from 'react-select';
var MultiSelectFormik = function (_a) {
    var label = _a.label, options = _a.options, name = _a.name, isMulti = _a.isMulti, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, value = _a.value, formik = _a.formik, onchange = _a.onchange, onkeydown = _a.onkeydown, onInputChange = _a.onInputChange, _c = _a.isChildField, isChildField = _c === void 0 ? false : _c, isRequired = _a.isRequired, placeholder = _a.placeholder, errorMessage = _a.errorMessage, _d = _a.clearable, clearable = _d === void 0 ? false : _d;
    var fieldName = isChildField ? name.split('.') : name;
    // Recursive function to access nested value
    var getNestedValue = function (obj, keys) {
        if (obj && keys.length > 0) {
            var currentKey = keys[0], remainingKeys_1 = keys.slice(1);
            if (Array.isArray(obj[currentKey])) {
                return obj[currentKey].map(function (item) { return getNestedValue(item, remainingKeys_1); });
            }
            if (typeof obj === 'object' && currentKey in obj) {
                return getNestedValue(obj[currentKey], remainingKeys_1);
            }
        }
        return obj;
    };
    // this function is to convert field name to proper array field key list
    var getFieldKey = function () {
        var arrayData = name.split('.');
        var index = arrayData.findIndex(function (value) { return value.includes('['); });
        if (index !== -1) {
            var splitValue = arrayData[index].split('[');
            var indexNum = splitValue[1].replace(']', '');
            arrayData.splice(index, 1);
            arrayData.splice(index, 0, splitValue[0], indexNum);
        }
        return arrayData;
    };
    // this function to make validation to if the field name is deep nested
    var fieldKey = isChildField && fieldName.length > 2 ? getFieldKey() : fieldName;
    var touched = isChildField && fieldName.length > 2
        ? getNestedValue(formik.touched, fieldKey)
        : getNestedValue(formik.touched, fieldName);
    var error = isChildField && fieldName.length > 2
        ? getNestedValue(formik.errors, fieldKey)
        : getNestedValue(formik.errors, fieldName);
    // this function is to return the status of the field error and touch status
    var touchedValue = touched && touched.length > 0 && touched[fieldKey[2]];
    var touchedData = touchedValue && fieldKey.length > 3 ? touchedValue[fieldKey[fieldKey.length - 1]] : touched;
    var errorValue = error && error.length > 0 && error[fieldKey[2]];
    var errorData = errorValue && fieldKey.length > 3 ? errorValue[fieldKey[fieldKey.length - 1]] : error;
    // this function to implement classname, depends on the field if its error or success
    var isValidClassname = function () {
        if (!isChildField) {
            if (formik.touched[name] && formik.errors[name]) {
                return 'is-invalid';
            }
            else if (formik.touched[name] && !formik.errors[name]) {
                return 'is-valid';
            }
        }
        else {
            if (errorData &&
                typeof errorData !== 'object' &&
                typeof touchedData !== 'object' &&
                touchedData) {
                return 'is-invalid';
            }
            else if (!errorData &&
                typeof errorData !== 'object' &&
                typeof touchedData !== 'object' &&
                touchedData) {
                return 'is-valid';
            }
        }
    };
    return (_jsxs("div", __assign({ className: 'fv-row mb-7' }, { children: [_jsx("label", __assign({ className: "".concat(isRequired ? 'required' : '', " ").concat(label ? 'form-label fs-6 fw-bolder text-dark' : '') }, { children: label })), _jsx(Select, { className: clsx("form-select p-0 lh-xxl ".concat(isValidClassname())), name: name, placeholder: placeholder ? placeholder : "Cari ".concat(label), options: options, isMulti: isMulti, onChange: onchange, value: value, onInputChange: onInputChange, onKeyDown: onkeydown, isDisabled: isDisabled, isClearable: clearable }), !isChildField && formik.touched[name] && formik.errors[name] && (_jsx("div", __assign({ className: 'fv-plugins-message-container' }, { children: _jsx("div", __assign({ className: 'fv-help-block' }, { children: _jsx("span", __assign({ role: 'alert' }, { children: formik.errors[name] })) })) }))), (errorMessage ||
                (isChildField &&
                    errorData &&
                    typeof errorData !== 'object' &&
                    typeof touchedData !== 'object' &&
                    touchedData)) && (_jsx("div", __assign({ className: 'fv-plugins-message-container' }, { children: _jsx("div", __assign({ className: 'fv-help-block' }, { children: _jsx("span", __assign({ role: 'alert' }, { children: errorMessage || errorData })) })) })))] })));
};
export default MultiSelectFormik;
