export var transferOptions = {
    pertermin: 'TransferPerTerms',
    input: 'InputTransfer',
    belum: 'NotYetTransfer',
    oto: 'AuthTransfer',
    gagal: 'FailedTransfer',
    done: 'DoneTransfer',
    refund: 'RefundTransfer',
    upcoming: 'UpcomingTransfer',
};
export var optionTransferAllTypes = [
    {
        value: 'TransferPerTerms',
        name: 'Transfer Per Termin',
    },
    {
        value: 'InputTransfer',
        name: 'Input Transfer',
    },
    {
        value: 'NotYetTransfer',
        name: 'Belum Transfer',
    },
    {
        value: 'AuthTransfer',
        name: 'Oto Transfer',
    },
    {
        value: 'DoneTransfer',
        name: 'Done Transfer',
    },
    {
        value: 'FailedTransfer',
        name: 'Gagal Transfer',
    },
    {
        value: 'RefundTransfer',
        name: 'Refund Transfer',
    },
];
export var optionTransferType = [
    {
        value: 'InputTransfer',
        name: 'Input Transfer',
    },
    {
        value: 'NotYetTransfer',
        name: 'Belum Transfer',
    },
    {
        value: 'AuthTransfer',
        name: 'Oto Transfer',
    },
    {
        value: 'DoneTransfer',
        name: 'Done Transfer',
    },
    {
        value: 'FailedTransfer',
        name: 'Gagal Transfer',
    },
    {
        value: 'RefundTransfer',
        name: 'Refund Transfer',
    },
];
export var optionTransferCEType = [
    {
        value: 'NotYetTransfer',
        name: 'Belum Transfer',
    },
    {
        value: 'FailedTransfer',
        name: 'Gagal Transfer',
    },
    {
        value: 'DoneTransfer',
        name: 'Done Transfer',
    },
    {
        value: 'RefundTransfer',
        name: 'Refund Transfer',
    },
];
export var optionTransferFType = [
    {
        value: 'InputTransfer',
        name: 'Input Transfer',
    },
    {
        value: 'NotYetTransfer',
        name: 'Belum Transfer',
    },
    {
        value: 'AuthTransfer',
        name: 'Oto Transfer',
    },
    {
        value: 'DoneTransfer',
        name: 'Done Transfer',
    },
    {
        value: 'FailedTransfer',
        name: 'Gagal Transfer',
    },
    {
        value: 'RefundTransfer',
        name: 'Refund Transfer',
    },
    {
        value: 'UpcomingTransfer',
        name: 'Upcoming Transfer',
    },
];
export var termStatusOptions = [
    {
        value: 'AlmostDone',
        name: 'Kurang Satu Transaksi',
    },
];
