var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import DataTable from 'react-data-table-component';
var Teams = function (_a) {
    var data = _a.data;
    // FIXME: any
    var columns = [
        {
            name: 'Sosial Media',
            selector: function (row) { return row.division.socialMedia.name; },
            sortable: true,
        },
        {
            name: 'Tim',
            selector: function (row) { return row.team.name; },
            sortable: true,
        },
    ];
    return (_jsx("div", __assign({ className: 'mt-4' }, { children: _jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: data, noHeader: true }) })));
};
export default Teams;
