var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeUrl } from '@src/utils/commonUtils';
import styles from './Record.module.scss';
var RecordName = function (_a) {
    var pubId = _a.pubId, talentName = _a.talentName, profiles = _a.profiles;
    return (_jsxs("div", { children: [_jsx("a", __assign({ href: "/talent/detail/".concat(pubId), className: styles['record-job__id'], target: '_blank', rel: 'noreferrer' }, { children: talentName })), profiles && profiles.length > 0 && (_jsx("div", __assign({ className: styles['record-socmed__section'] }, { children: _jsx("div", { children: profiles.map(function (obj, key) {
                        return (_jsxs("div", __assign({ className: 'socialmedianame mt-2' }, { children: ["".concat(obj.socialMedia && obj.socialMedia.name, ": "), _jsx("a", __assign({ className: 'goto', href: makeUrl(obj.profileLink), target: '_blank', rel: 'noopener noreferrer' }, { children: obj.profileName }))] }), key));
                    }) }) })))] }));
};
export default RecordName;
