var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { Divider } from '@mui/material';
import { RoleList, handletotalFollowerStyle, userInformation } from '@src/app/helper';
import styles from './Record.module.scss';
var RecordCollaboration = function (_a) {
    var record = _a.record, id = _a.id, excludes = _a.excludes;
    var role = JSON.parse(userInformation).roles[0];
    var typeChecking = function (value) {
        switch (value) {
            case 'NoOwning':
                return 'No Owning';
            case 'Owning':
                return 'Owning';
            case 'NoBoosting':
                return 'No Boosting';
            case 'Boosting':
                return 'Boosting';
            default:
                return '-';
        }
    };
    var handleBargainStyle = function (type) {
        switch (type) {
            case 'Open':
                return 'warning';
            case 'Nego':
                return 'info';
            case 'NegoSpv':
                return 'success';
            case 'Cancel':
                return 'danger';
            case 'Done':
                return 'primary';
            default:
                return 'primary';
        }
    };
    return (_jsx("div", __assign({ className: 'mt-3' }, { children: record ? (_jsxs(Fragment, { children: [role === RoleList.CampaignExecutive ||
                    role === RoleList.SpvCampaignExecutive ||
                    role === RoleList.Audit ||
                    role === RoleList.Finance ? (_jsx("a", __assign({ href: "/job/detail/".concat(record.jobNumber), className: styles['record-job__id'], target: '_blank', rel: 'noreferrer' }, { children: record.jobNumber }))) : (_jsx("p", { children: record.jobNumber })), record.jobDetails &&
                    record.jobDetails.length > 0 &&
                    record.jobDetails.map(function (job, i) {
                        var _a, _b, _c;
                        return (_jsxs("div", __assign({ className: styles['record-cp__section'] }, { children: [_jsxs("p", __assign({ className: styles['title'] }, { children: [(_a = job.jobType.name) !== null && _a !== void 0 ? _a : (_c = (_b = job.profile) === null || _b === void 0 ? void 0 : _b.socialMedia) === null || _c === void 0 ? void 0 : _c.name, " "] })), _jsxs("p", { children: [job.exclusiveStatus, " - ", job.totalPosts.toLocaleString() || 0, "x"] }), _jsxs("p", { children: ["Fee: Rp ", job.fee.toLocaleString() || 0] }), _jsxs("p", { children: ["Followers: ", job.totalFollowers.toLocaleString() || 0, ' ', _jsx("span", __assign({ className: "badge badge-".concat(handletotalFollowerStyle(job.profile.followerCategory)) }, { children: job.profile.followerCategory }))] }), ' ', _jsxs("p", { children: ["Rate Satuan: Rp ", job.feePerUnit.toLocaleString() || 0, " / review"] }), !(excludes === null || excludes === void 0 ? void 0 : excludes.includes('bundling')) && (_jsxs("span", __assign({ className: 'badge badge-warning fw-bolder fs-7 mb-3' }, { children: ["Rate Bundling: Rp ", record.totalFeeBundling.toLocaleString() || 0] }))), _jsxs("p", { children: ["Jangka Waktu: ", job.timeFrame || '-'] }), _jsxs("p", { children: ["Owning: ", typeChecking(job.owningType) || '-'] }), _jsxs("p", { children: ["Boosting: ", typeChecking(job.boostingType) || '-'] }), _jsxs("p", { children: ["Catatan: ", job.notes || '-'] }), _jsx(Divider, {})] }), i));
                    }), record.invoiceUrl && (_jsx("div", __assign({ className: 'mb-2' }, { children: _jsx("a", __assign({ href: record.invoiceUrl, target: '_blank', rel: 'noopener noreferrer' }, { children: "View Invoice" })) }))), record.spkUrl && (_jsx("div", { children: _jsx("a", __assign({ href: record.spkUrl, target: '_blank', rel: 'noopener noreferrer' }, { children: "View SPK" })) })), record.totalFee ? (_jsx("div", __assign({ className: styles['record-job__total-fee'] }, { children: _jsxs("p", { children: ["Total Fee: Rp ", record.totalFee.toLocaleString() || 0] }) }))) : undefined, _jsxs("div", __assign({ className: styles['record-job__total-fee'] }, { children: [_jsx("span", __assign({ className: "badge badge-".concat(handleBargainStyle(record.bargainType)) }, { children: record.bargainType })), record.bargainType === 'Cancel' && record.canceledReason && (_jsxs("p", __assign({ className: 'mt-1' }, { children: ["Alasan Cancel: ", record.canceledReason] })))] }))] })) : (_jsx("span", { children: "-" })) })));
};
export default RecordCollaboration;
