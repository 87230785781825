var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import DrawerComponent from '@src/components/Atoms/Drawer/Drawer';
import { Fragment } from 'react';
import SetSchedule from './SetSchedule';
var Process = function (_a) {
    var isOpen = _a.isOpen, handleClose = _a.handleClose, record = _a.record, _b = _a.refetch, refetch = _b === void 0 ? function () { } : _b;
    return (_jsx(DrawerComponent, __assign({ open: isOpen, handleClose: function () { return handleClose(false); }, title: 'Jadwal', size: 'MD' }, { children: record && (_jsxs("div", { children: [_jsxs("div", __assign({ className: 'mb-3' }, { children: [_jsx("div", { children: _jsx(Typography, __assign({ variant: 'body1' }, { children: "Nama Talent" })) }), _jsx("div", { children: _jsx(Typography, __assign({ variant: 'h6' }, { children: record.talent.name })) })] })), _jsxs("div", __assign({ className: 'mb-3' }, { children: [_jsx("div", { children: _jsx(Typography, __assign({ variant: 'body1' }, { children: "Contact Person" })) }), _jsxs("div", { children: [_jsxs(Typography, __assign({ variant: 'h6' }, { children: [record.talent.cpName, " - ", record.talent.cpPhoneNumber] })), _jsx(Typography, __assign({ variant: 'h6' }, { children: "Team Name" }))] })] })), record &&
                    record.schedules &&
                    record.schedules.length > 0 &&
                    record.schedules.map(function (data, i) { return (_jsx(Fragment, { children: _jsx(SetSchedule, { data: data, handleClose: handleClose, refetch: refetch }) }, i)); }), _jsx("div", __assign({ className: 'my-5' }, { children: _jsx("div", __assign({ style: { textAlign: 'right' } }, { children: _jsx("button", __assign({ type: 'button', className: 'btn btn-secondary btn-active-light-primary btn-sm', onClick: function () { return handleClose(false); }, style: { marginRight: '1rem' } }, { children: "Tutup" })) })) }))] })) })));
};
export default Process;
