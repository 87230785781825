var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
var DistributionActionDropdown = function (_a) {
    var record = _a.record, handleKeep = _a.handleKeep, handleReject = _a.handleReject, handleProcess = _a.handleProcess, isTransit = _a.isTransit;
    return (_jsx(Fragment, { children: _jsxs("div", __assign({ className: 'd-flex gap-2' }, { children: [!isTransit && (_jsx("button", __assign({ type: 'button', onClick: function () { return handleKeep(record); }, className: 'btn btn-secondary mr-2 btn-sm' }, { children: "Keep" }))), _jsx("button", __assign({ type: 'button', onClick: function () { return handleProcess(record); }, className: 'btn btn-primary btn-active-light-primary mr-2 btn-sm' }, { children: "Process" })), !isTransit && (_jsx("button", __assign({ type: 'button', onClick: function () { return handleReject(record); }, className: 'btn btn-danger btn-active-light-primary btn-sm' }, { children: "Reject" })))] })) }));
};
export default DistributionActionDropdown;
