export var PajakCategory = {
    PPH21: 'PPh21',
    PPH23: 'PPh23',
    PPH23PLUSPPN: 'PPh23PlusPPN',
    PPH26: 'PPh26',
};
export var optionTaxList = [
    {
        name: 'Semua',
        value: '',
    },
    {
        name: 'PPH 21',
        value: PajakCategory.PPH21,
    },
    {
        name: 'PPH 23',
        value: PajakCategory.PPH23,
    },
    {
        name: 'PPH 23 Plus PPN',
        value: PajakCategory.PPH23PLUSPPN,
    },
    {
        name: 'PPH 26',
        value: PajakCategory.PPH26,
    },
];
