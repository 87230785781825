var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import { MasterInit } from '../_metronic/layout/MasterInit';
import { AuthInit } from '@src/app/pages//auth';
var App = function () {
    return (_jsx(Suspense, __assign({ fallback: _jsx(LayoutSplashScreen, {}) }, { children: _jsx(I18nProvider, { children: _jsx(LayoutProvider, { children: _jsxs(AuthInit, { children: [_jsx(Outlet, {}), _jsx(MasterInit, {})] }) }) }) })));
};
export { App };
