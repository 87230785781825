var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ProtectedRoute } from '@src/app/helper';
import { Route, Routes, Outlet } from 'react-router-dom';
import JobIndex from './Job/Job';
import JobDetail from './JobDetail/JobDetail';
import RepeatJobIndex from './RepeatJob/RepeatJob';
var JobPage = function () {
    return (_jsx(Routes, { children: _jsxs(Route, __assign({ element: _jsx(Outlet, {}) }, { children: [_jsx(Route, { path: '/all-job/', element: _jsx(ProtectedRoute, { permission: 'job.view', children: _jsx(JobIndex, {}) }) }), _jsx(Route, { path: '/detail/:id', element: _jsx(ProtectedRoute, { permission: 'jobdetail.view', children: _jsx(JobDetail, {}) }) }), _jsx(Route, { path: '/repeat-job/', element: _jsx(ProtectedRoute, { permission: 'job.repeat.view', children: _jsx(RepeatJobIndex, {}) }) })] })) }));
};
export default JobPage;
