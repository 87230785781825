var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from 'react';
import { PageTitle } from '@src/_metronic/layout/core';
import { KTCard } from '@src/_metronic/helpers';
import DataTable from 'react-data-table-component';
import { MenuComponent } from '@src/_metronic/assets/ts/components';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { FormatDateTime, RecordStatusOptions, paginationOptions, MultiDimensionalFilter, userInformation, } from '@src/app/helper';
import Input from '@src/components/Atoms/Input/Input';
import { useTalents } from '../TalentHooks/Talent.hooks';
import '@src/app/styles/datatable.css';
import styles from '@src/components/molecules/Record/Record.module.scss';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import Loading from '@src/components/Atoms/Loading/Loading';
//breadcrumb
var usersBreadcrumbs = [
    {
        title: 'Talent',
        path: '',
        isSeparator: true,
        isActive: false,
    },
];
var TalentBlacklist = function () {
    var navigate = useNavigate();
    var intl = useIntl();
    var id = useParams().id;
    var role = JSON.parse(userInformation).roles[0];
    //initialize filter
    var _a = useState({
        page: 1,
        pageSize: 10,
        keyword: '',
        filterBy: '[["isBlacklisted", true]]',
    }), filter = _a[0], setFilter = _a[1];
    //getdata
    var _b = useTalents(filter), talents = _b.talents, isLoading = _b.isLoading, deleteTalent = _b.deleteTalent, deleteTalents = _b.deleteTalents;
    //filter
    var _c = useState({
        recordStatus: '',
    }), selectedFilter = _c[0], setSelectedFilter = _c[1];
    //pagination
    var handlePageChange = function (page) {
        setFilter(__assign(__assign({}, filter), { page: page }));
    };
    var handlePageSizeChange = function (size) {
        setFilter(__assign(__assign({}, filter), { page: 1, pageSize: size }));
    };
    //getsearch
    var _d = useState(RecordStatusOptions), Status = _d[0], setStatus = _d[1];
    //search & filters
    var handleMultiDimensionalFilterChange = function (event) {
        var filterValue = filter.filterBy !== '' ? JSON.parse(filter.filterBy) : '';
        if (filter.filterBy === '') {
            var filterCriteria = [[event.target.name, event.target.value]];
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterCriteria) }));
        }
        else {
            var filterNew = MultiDimensionalFilter(event, filterValue);
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterNew) }));
        }
    };
    var handleFilterChange = function (event) {
        var _a;
        setFilter(__assign(__assign({}, filter), (_a = {}, _a[event.target.name] = event.target.value, _a)));
    };
    //gotodetail
    var gotoDetail = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("/talent/detail/".concat(record));
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        MenuComponent.reinitialization();
    }, []);
    var columns = [
        {
            name: 'Contact Person',
            cell: function (record) {
                return (_jsxs("div", { children: [_jsx("p", __assign({ className: 'mb-2' }, { children: record.cpName })), _jsx("p", __assign({ className: 'mb-2' }, { children: record.cpPhoneNumber })), _jsx("p", __assign({ className: 'mb-2' }, { children: record.cpEmail }))] }));
            },
            sortable: true,
        },
        {
            name: 'Nama',
            cell: function (record) { return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: 'mb-2' }, { children: _jsx("span", { children: record.name }) })), record.isBlacklisted && _jsx("div", __assign({ className: "badge badge-danger fw-bolder" }, { children: "Blacklist" }))] })); },
            sortable: true,
        },
        {
            name: 'Profile',
            cell: function (record) { return (_jsxs("div", { children: [_jsx("p", __assign({ className: 'mb-1' }, { children: "Profile" })), _jsx("div", { children: record.profiles.map(function (obj, key) {
                            return (_jsx("div", __assign({ className: 'mt-2' }, { children: _jsx("a", __assign({ className: 'goto', href: obj.profileLink, target: '_blank', rel: 'noopener noreferrer' }, { children: obj.socialMedia.name })) }), key));
                        }) })] })); },
            sortable: true,
        },
        {
            name: 'Alasan',
            cell: function (record) { var _a; return _jsx("div", { children: (_a = record.blacklistReason) !== null && _a !== void 0 ? _a : '-' }); },
            sortable: true,
        },
        {
            name: 'Oleh',
            cell: function (record) { return (_jsxs("div", __assign({ className: 'mb-5' }, { children: [_jsx("p", __assign({ className: "".concat(styles['title'], " mb-1") }, { children: "Diblacklist Oleh:" })), _jsx("p", __assign({ className: 'mb-1' }, { children: record.blacklistedBy })), _jsx("p", __assign({ className: 'mb-1' }, { children: FormatDateTime(record.blacklistedOn) }))] }))); },
            sortable: true,
        },
        {
            name: 'Action',
            cell: function (row) {
                return (_jsx("div", { children: _jsx("button", __assign({ className: 'btn btn-sm btn-success', onClick: function () { return gotoDetail(row.pubId); } }, { children: "Detail" })) }));
            },
            sortable: true,
        },
    ];
    return (_jsxs(Fragment, { children: [_jsx(PageTitle, __assign({ breadcrumbs: usersBreadcrumbs }, { children: "Talent Blacklist" })), _jsxs(KTCard, { children: [_jsx("div", __assign({ className: 'd-flex flex-column' }, { children: _jsx("div", __assign({ className: 'card-header border-0 pt-6 d-flex flex-row' }, { children: _jsx("div", __assign({ className: 'card-title flex' }, { children: _jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Input, { placeholder: 'Search', type: 'text', name: 'keyword', value: filter.keyword, onChange: function (e) {
                                            handleFilterChange(e);
                                        } }) })) })) })) })), _jsx("div", __assign({ className: 'card-body py-4' }, { children: _jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: talents ? talents.data : [], noHeader: true, progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: talents && talents.recordsFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange }) }))] })] }));
};
export default TalentBlacklist;
