var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, Fragment, useEffect } from 'react';
import { PageTitle } from '@src/_metronic/layout/core';
import { KTCard } from '@src/_metronic/helpers';
import DataTable from 'react-data-table-component';
import '@src/app/styles/datatable.css';
import { useJob } from '../JobHooks/Job.hooks';
import Select from '@src/components/Atoms/Select/Select';
import Input from '@src/components/Atoms/Input/Input';
import { toast } from 'react-toastify';
import JobActionDropdown from '@src/components/Atoms/ActionDropdown/JobActionDropdown';
import RecordTalent from '@src/components/molecules/Record/Talent';
import RecordCollaboration from '@src/components/molecules/Record/Collaboration';
import { MultiDimensionalFilter, paginationOptions, JobTypeEnum, optionJobNegoStatus, optionJobType, userInformation, RoleList, FormatYearMonthDate, optionOnHold, DefaultAllList, FormatDateTime, } from '@src/app/helper';
import { useLocation, useNavigate } from 'react-router-dom';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import swal from 'sweetalert';
import { useIntl } from 'react-intl';
import { checkJob, processDoneChatTalent } from './Job.requests';
import Process from '@src/components/molecules/Job/ProcessJob';
import Reject from '@src/components/molecules/Job/RejectJob';
import Date from '@src/components/Atoms/Date/Date';
import NegoJob from '@src/components/Atoms/Buttons/NegoJobButton';
import UpdateJob from '@src/components/molecules/JobDetail/Kerjasama/Update';
import RejectJob from '@src/components/molecules/JobDetail/Kerjasama/Reject';
import ProcessJob from '@src/components/molecules/JobDetail/Kerjasama/Process';
import dayjs from 'dayjs';
import Loading from '@src/components/Atoms/Loading/Loading';
import RecordAdmin from '@src/components/molecules/Record/Admin';
import TemplateInvoice from '@src/components/molecules/JobDetail/Kerjasama/TemplateInvoice';
import { useTeams } from '../../TeamPage/TeamHooks/Team.hooks';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
//breadcrumb
var usersBreadcrumbs = [
    {
        title: 'Kerja Sama',
        path: '',
        isSeparator: true,
        isActive: false,
    },
];
var handleJobType = function (type) {
    switch (type) {
        case 'DoneChat':
            return 1;
        case 'Nego':
            return 2;
        case 'NegoOnly':
            return 2;
        case 'OnHold':
            return 3;
        case 'Rejected':
            return 4;
        default:
            return 0;
    }
};
var JobIndex = function () {
    var intl = useIntl();
    var location = useLocation();
    var navigate = useNavigate();
    var role = JSON.parse(userInformation).roles[0];
    var _a = useState(), type = _a[0], setType = _a[1];
    var _b = useState(role == RoleList.SpvCampaignExecutive
        ? handleJobType(JobTypeEnum[2])
        : handleJobType(location.hash.split('#')[1])), jobType = _b[0], setJobType = _b[1];
    var _c = useState(false), isOpenProcessSettings = _c[0], setIsOpenProcessSettings = _c[1];
    var _d = useState(false), isOpenRejectSettings = _d[0], setIsOpenRejectSettings = _d[1];
    var _e = useState({
        page: 1,
        pageSize: 10,
        keyword: '',
        filterBy: '[]',
        Type: jobType,
    }), filter = _e[0], setFilter = _e[1];
    var _f = useState({
        page: 1,
        pageSize: 100,
        keyword: '',
        filterBy: '[]',
    }), filterSearch = _f[0], setFilterSearch = _f[1];
    var _g = useTeams(filterSearch), team = _g.team, refetchTeam = _g.refetch;
    var _h = useState(), talentRecord = _h[0], setTalentRecord = _h[1];
    var _j = useJob(filter), jobs = _j.jobs, isLoading = _j.isLoading, refetch = _j.refetch;
    var socialMedias = JSON.parse(userInformation).socialMedias;
    var divisions = JSON.parse(userInformation).divisions;
    var teams = JSON.parse(userInformation).teams;
    var optionSocialMedia = socialMedias &&
        socialMedias.map(function (social) { return ({
            name: social.name,
            value: social.pubId,
        }); });
    role !== RoleList.CampaignExecutive &&
        optionSocialMedia &&
        optionSocialMedia.unshift(DefaultAllList);
    var optionDivision = divisions &&
        divisions.map(function (division, index) { return ({
            name: "".concat(division.name, " - ").concat(socialMedias && socialMedias[index].name),
            value: division.pubId,
        }); });
    var optionTeam = role == RoleList.CampaignExecutive ? teams &&
        teams.map(function (team) { return ({
            name: team.name,
            value: team.pubId,
        }); }) :
        team && team.data && team.data.map(function (team) { return ({
            name: team.name,
            value: team.pubId,
        }); });
    role !== RoleList.CampaignExecutive && optionTeam && optionTeam.unshift(DefaultAllList);
    var _k = useState(dayjs().format('YYYY-MM-DD').toString()), date = _k[0], setDate = _k[1];
    var onChangeDate = function (e) {
        var event = { target: { name: '', value: null } };
        event.target.name = 'date';
        event.target.value = e == null ? '' : FormatYearMonthDate(e);
        setDate(event.target.value);
        handleMultiDimensionalFilterChange(event);
    };
    var handleMultiDimensionalFilterChange = function (event) {
        var filterValue = filter.filterBy !== '' ? JSON.parse(filter.filterBy) : '';
        if (filter.filterBy === '') {
            var filterCriteria = [[event.target.name, event.target.value]];
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterCriteria) }));
        }
        else {
            var filterNew = MultiDimensionalFilter(event, filterValue);
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterNew) }));
        }
    };
    var handleFilterChange = function (event, key) {
        var _a, _b;
        if (key) {
            setJobType(JobTypeEnum[key]);
            setFilter(__assign(__assign({}, filter), (_a = {}, _a[event.target.name] = JobTypeEnum[key], _a)));
            navigate("#".concat(key));
        }
        else {
            setJobType('');
            setFilter(__assign(__assign({}, filter), (_b = {}, _b[event.target.name] = event.target.value, _b)));
        }
    };
    var handlePageChange = function (page) {
        setFilter(__assign(__assign({}, filter), { page: page }));
    };
    var handlePageSizeChange = function (size) {
        setFilter(__assign(__assign({}, filter), { page: 1, pageSize: size }));
    };
    var handleDoneTalent = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            processDoneChat(record);
            setTalentRecord(record);
            return [2 /*return*/];
        });
    }); };
    var handleProcessTalent = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setTalentRecord(record);
            setIsOpenProcessSettings(true);
            return [2 /*return*/];
        });
    }); };
    var handleRejectTalent = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setTalentRecord(record);
            setIsOpenRejectSettings(true);
            return [2 /*return*/];
        });
    }); };
    var gotoDetail = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("/talent/detail/".concat(record));
            return [2 /*return*/];
        });
    }); };
    var _l = useState(false), showUpdate = _l[0], setShowUpdate = _l[1];
    var _m = useState(false), showReject = _m[0], setShowReject = _m[1];
    var _o = useState(false), showApprove = _o[0], setShowApprove = _o[1];
    var _p = useState(false), showProcess = _p[0], setShowProcess = _p[1];
    var _q = useState([]), detailJob = _q[0], setDetailJob = _q[1];
    var _r = useState([]), profile = _r[0], setProfile = _r[1];
    var _s = useState([]), talent = _s[0], setTalent = _s[1];
    var _t = useState([]), job = _t[0], setJob = _t[1];
    var _u = useState(0), totalFeeBundling = _u[0], setTotalFeeBundling = _u[1];
    var _v = useState(''), detailJobPubId = _v[0], setDetailJobPubId = _v[1];
    var _w = useState(false), showInvoice = _w[0], setShowInvoice = _w[1];
    var editItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setDetailJob(record.job.jobDetails);
            setProfile(record.talent.profiles); //choosed index [0]
            setTotalFeeBundling(record.job.totalFeeBundling);
            setDetailJobPubId(record.pubId);
            setShowUpdate(true);
            return [2 /*return*/];
        });
    }); };
    var handleApproveItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (role === RoleList.CampaignExecutive) {
                // await finalRecapJob(record)
                setDetailJob(record.job.jobDetails);
                setTalent(record.talent);
                setJob(record.job);
                setDetailJobPubId(record.pubId);
                setShowProcess(true);
            }
            else {
                setTalent(record.talent);
                setJob(record.job);
                setDetailJobPubId(record.pubId);
                // setShowApprove(true) //use this for non-popup
                approveItem(record);
            }
            return [2 /*return*/];
        });
    }); };
    var cancelItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setTalent(record.talent);
            setDetailJobPubId(record.pubId);
            setShowReject(true);
            return [2 /*return*/];
        });
    }); };
    // TODO: show cp email and cp other and country name on datatable row, as its missing from api return
    var columns = [
        {
            cell: function (record) {
                return (record.job ?
                    record.job.isChecked ? _jsx(OverlayTrigger, __assign({ placement: "top", delay: { show: 250, hide: 400 }, overlay: function (props) { return (_jsx(Tooltip, __assign({ id: "button-tooltip" }, props, { children: _jsxs("div", __assign({ className: 'by' }, { children: [_jsx("p", __assign({ className: 'createdby mb-0' }, { children: record.job.checkedBy })), _jsx("p", __assign({ className: 'createdon mb-2' }, { children: FormatDateTime(record.job.checkedOn) }))] })) }))); } }, { children: _jsx("div", __assign({ className: 'form-check form-check-custom form-check-solid' }, { children: _jsx("input", { className: 'form-check-input input-check-custom', type: 'checkbox', checked: record.job.isChecked, onChange: function () { return onChangeCheck(record.pubId, !record.job.isChecked); } }) })) })) : _jsx("div", __assign({ className: 'form-check form-check-custom form-check-solid' }, { children: _jsx("input", { className: 'form-check-input input-check-custom', type: 'checkbox', checked: record.job.isChecked, onChange: function () { return onChangeCheck(record.pubId, !record.job.isChecked); } }) })) : '');
            },
            width: '64px',
            omit: (role === RoleList.CampaignExecutive && filter.Type == JobTypeEnum.Nego) ? false : true,
        },
        {
            name: 'Talent',
            cell: function (record) { return (_jsx(RecordTalent, { name: record.talent.name, pubId: record.talent.pubId, cpName: record.talent.cpName, cpPhoneNumber: record.talent.cpPhoneNumber, profiles: record.talent.profiles, onClick: gotoDetail })); },
            sortable: true,
        },
        {
            name: 'Kerja sama',
            cell: function (record) { return _jsx(RecordCollaboration, { id: record.pubId, record: record.job || '' }); },
            sortable: true,
        },
        {
            name: 'Alasan Hold',
            cell: function (record) {
                return _jsx("p", { children: record.job && record.job.holdReason });
            },
            omit: filter.Type !== JobTypeEnum.OnHold,
        },
        {
            name: 'Alasan Dibatalkan',
            cell: function (record) {
                return _jsx("p", { children: record.job && record.job.canceledReason });
            },
            omit: filter.Type !== JobTypeEnum.Rejected,
        },
        {
            name: 'Oleh',
            cell: function (record) { return _jsx(RecordAdmin, { record: record.job ? record.job : record }); },
            sortable: true,
        },
        {
            name: 'Action',
            cell: function (record) {
                return (_jsxs(_Fragment, { children: [_jsx(NegoJob, { record: record, editItem: editItem, approveItem: handleApproveItem, cancelItem: cancelItem, jobType: jobType }), _jsx(JobActionDropdown, { handleReject: handleRejectTalent, handleProcess: handleProcessTalent, handleDone: handleDoneTalent, record: record, jobType: jobType })] }));
            },
        },
    ];
    var onChangeCheck = function (id, status) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, checkJob(id, status)];
                case 1:
                    data = (_a.sent()).data;
                    refetch();
                    return [2 /*return*/];
            }
        });
    }); };
    //approve
    var approveJob = useJob().approveJob;
    var approveItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        var formValues;
        return __generator(this, function (_a) {
            formValues = {
                id: record.pubId,
                reason: null,
            };
            swal({
                title: intl.formatMessage({ id: 'GENERAL.VALIDATION' }),
                text: '',
                icon: 'warning',
                buttons: [
                    intl.formatMessage({ id: 'GENERAL.VALIDATION.NO' }),
                    intl.formatMessage({ id: 'GENERAL.VALIDATION.YES' }),
                ],
                dangerMode: true,
            }).then(function (willApprove) { return __awaiter(void 0, void 0, void 0, function () {
                var e_1, error;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!willApprove) return [3 /*break*/, 4];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, approveJob(formValues)];
                        case 2:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 3:
                            e_1 = _a.sent();
                            error = e_1.response.data;
                            toast.error(error.message);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            }); });
            return [2 /*return*/];
        });
    }); };
    var processDoneChat = function (record) {
        swal({
            title: intl.formatMessage({ id: 'GENERAL.VALIDATION' }),
            text: '',
            icon: 'warning',
            buttons: [
                intl.formatMessage({ id: 'GENERAL.VALIDATION.NO' }),
                intl.formatMessage({ id: 'GENERAL.VALIDATION.YES' }),
            ],
            dangerMode: true,
        }).then(function (willUpdate) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, data, status_1, e_2, error;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!willUpdate) return [3 /*break*/, 4];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, processDoneChatTalent(record.pubId)];
                    case 2:
                        _a = _b.sent(), data = _a.data, status_1 = _a.status;
                        refetch();
                        if (status_1 === 200) {
                            toast.success('Status berhasil diupdate');
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _b.sent();
                        error = e_2.response.data;
                        toast.error(error.message);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    };
    useEffect(function () {
        refetch();
        if (!location.hash) {
            if (role == RoleList.SpvCampaignExecutive) {
                navigate("#".concat(JobTypeEnum[2]));
            }
            else {
                navigate("#".concat(JobTypeEnum[0]));
            }
        }
        else {
            var key = Object.keys(JobTypeEnum).indexOf(location.hash.split('#')[1]);
            var status_2 = Object.values(JobTypeEnum)[key];
            setType(status_2);
            var event_1 = { target: { name: 'Type', value: null } };
            handleFilterChange(event_1, location.hash.split('#')[1]);
        }
    }, [isOpenProcessSettings, isOpenRejectSettings]);
    useEffect(function () {
        var filterValue = JSON.parse(filter.filterBy);
        if (socialMedias && socialMedias.length > 0 && optionSocialMedia[0].value !== '') {
            var filterCriteria = ['division.socialMedia.pubId', socialMedias[0].pubId];
            filterValue.push(filterCriteria);
        }
        if (teams && teams.length > 0) {
            var filterCriteria = ['team.pubId', optionTeam && optionTeam[0].value];
            filterValue.push(filterCriteria);
        }
        if (date) {
            var filterCriteria = ['date', date];
            filterValue.push(filterCriteria);
        }
        setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterValue) }));
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(PageTitle, __assign({ breadcrumbs: usersBreadcrumbs }, { children: "Kerja Sama" })), _jsx(KTCard, { children: _jsxs("div", __assign({ className: 'd-flex flex-column' }, { children: [_jsx("div", __assign({ className: 'card-header border-0 pt-6 d-flex flex-row' }, { children: _jsxs("div", __assign({ className: 'card-title flex' }, { children: [_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Input, { placeholder: 'Search', type: 'text', name: 'keyword', value: filter.keyword, onChange: function (e) {
                                                handleFilterChange(e);
                                            } }) })), _jsxs("div", __assign({ className: 'card-title' }, { children: [role == RoleList.CampaignExecutive && (_jsx(Select, { name: 'Type', onChange: function (e) {
                                                    handleFilterChange(e, e.target.value);
                                                }, optionList: optionJobType, defaultValue: location.hash ? location.hash.split('#')[1] : optionJobType[0].value })), role == RoleList.SpvCampaignExecutive && (_jsx(Select, { name: 'Type', onChange: function (e) {
                                                    handleFilterChange(e, e.target.value);
                                                }, optionList: optionOnHold, defaultValue: location.hash ? location.hash.split('#')[1] : optionOnHold[0].value }))] })), _jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Date, { date: date, handleonchange: onChangeDate }) })), _jsx("div", __assign({ className: 'card-title' }, { children: optionSocialMedia && optionSocialMedia.length > 0 && (_jsx(Select, { name: 'division.socialMedia.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, optionList: optionSocialMedia, defaultValue: optionSocialMedia[0].value, isPlaceholder: optionSocialMedia[0].label !== 'Semua' ? false : true })) })), _jsx("div", __assign({ className: 'card-title' }, { children: (jobType == JobTypeEnum.Nego ||
                                            jobType == JobTypeEnum.NegoOnly ||
                                            jobType == JobTypeEnum.OnHold ||
                                            jobType == JobTypeEnum.Rejected) &&
                                            optionDivision &&
                                            optionDivision.length > 0 && (_jsx(Select, { name: 'division.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, optionList: optionDivision })) })), _jsx("div", __assign({ className: 'card-title' }, { children: optionTeam && optionTeam.length > 0 && (_jsx(Select, { name: 'team.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, isPlaceholder: false, optionList: optionTeam, defaultValue: optionTeam[0].value })) })), _jsx("div", __assign({ className: 'card-title' }, { children: (jobType == JobTypeEnum.Nego || jobType == JobTypeEnum.NegoOnly) && (_jsx(Select, { name: 'bargainType', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, optionList: optionJobNegoStatus, isPlaceholder: false })) }))] })) })), _jsx("div", __assign({ className: 'card-body py-4' }, { children: _jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: jobs ? jobs.data : [], noHeader: true, progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: filter.filterBy === '' ? jobs === null || jobs === void 0 ? void 0 : jobs.recordsTotal : jobs === null || jobs === void 0 ? void 0 : jobs.recordsFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange }, jobs === null || jobs === void 0 ? void 0 : jobs.data.length) }))] })) }), talentRecord && (_jsx(Process, { isOpen: isOpenProcessSettings, handleClose: function () {
                    setTalentRecord('');
                    setIsOpenProcessSettings(false);
                }, record: talentRecord })), talentRecord && (_jsx(Reject, { isOpen: isOpenRejectSettings, handleClose: function () {
                    setTalentRecord('');
                    setIsOpenRejectSettings(false);
                }, record: talentRecord })), jobs && (_jsx(UpdateJob, { isOpen: showUpdate, handleClose: function () {
                    setShowUpdate(false);
                }, record: detailJob, profiles: profile, totalFeeBundling: totalFeeBundling, id: detailJobPubId })), jobs && (_jsx(RejectJob, { isOpen: showReject, handleClose: function () {
                    setShowReject(false);
                }, record: {
                    talent: talent,
                }, id: detailJobPubId })), jobs && showProcess && (_jsx(ProcessJob, { isOpen: showProcess, handleClose: function () {
                    setShowProcess(false);
                }, record: {
                    job: job,
                    talent: talent,
                }, details: detailJob, id: detailJobPubId })), _jsx(TemplateInvoice, { isOpen: showInvoice, handleClose: function () {
                    setShowInvoice(false);
                }, id: detailJobPubId, talentId: talent.pubId })] }));
};
export default JobIndex;
