var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ScrollTopComponent, DrawerComponent, ToggleComponent, StickyComponent, } from '../../assets/ts/components';
import { KTSVG } from '../../helpers';
export function ScrollTop() {
    var pathname = useLocation().pathname;
    var isFirstRun = useRef(true);
    var pluginsReinitialization = function () {
        setTimeout(function () {
            StickyComponent.reInitialization();
            setTimeout(function () {
                ToggleComponent.reinitialization();
                DrawerComponent.reinitialization();
            }, 70);
        }, 140);
    };
    var scrollTop = function () {
        ScrollTopComponent.goTop();
    };
    var updateHeaderSticky = function () {
        var stickyHeader = document.body.querySelectorAll("[data-kt-sticky-name=\"header\"]");
        if (stickyHeader && stickyHeader.length > 0) {
            var sticky = StickyComponent.getInstance(stickyHeader[0]);
            if (sticky) {
                sticky.update();
            }
        }
    };
    useEffect(function () {
        if (isFirstRun.current) {
            isFirstRun.current = false;
        }
        else {
            pluginsReinitialization();
        }
        updateHeaderSticky();
        setTimeout(function () {
            scrollTop();
        }, 0);
    }, [pathname]);
    return (_jsx("div", __assign({ id: 'kt_scrolltop', className: 'scrolltop', "data-kt-scrolltop": 'true' }, { children: _jsx(KTSVG, { path: '/media/icons/duotune/arrows/arr066.svg' }) })));
}
