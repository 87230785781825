var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { JobTypeEnum } from '@src/app/helper/JobHelpers';
import { Fragment } from 'react';
var JobActionDropdown = function (_a) {
    var record = _a.record, handleReject = _a.handleReject, handleProcess = _a.handleProcess, handleDone = _a.handleDone, jobType = _a.jobType;
    return (_jsx(Fragment, { children: _jsxs("div", __assign({ className: 'd-flex gap-2 mt-3' }, { children: [((jobType == JobTypeEnum.NotYetChat &&
                    !record.job &&
                    record.isDeal &&
                    !record.isDoneChat &&
                    !record.isRejected) ||
                    (!record.job && record.isDeal && !record.isDoneChat && !record.isRejected)) && (_jsx("button", __assign({ type: 'button', onClick: function () { return handleDone(record); }, className: 'btn btn-primary btn-sm mr-2' }, { children: "Done Chat" }))), ((jobType == JobTypeEnum.DoneChat &&
                    !record.job &&
                    record.isDeal &&
                    record.isDoneChat &&
                    !record.isRejected) ||
                    (!record.job && record.isDeal && record.isDoneChat && !record.isRejected)) && (_jsx("button", __assign({ type: 'button', onClick: function () { return handleProcess(record); }, className: 'btn btn-primary btn-sm mr-2' }, { children: "Process" }))), (jobType == JobTypeEnum.NotYetChat || jobType == JobTypeEnum.DoneChat) && !record.job ? (_jsx("button", __assign({ type: 'button', onClick: function () { return handleReject(record); }, className: 'btn btn-danger btn-sm' }, { children: "Reject" }))) : (''), jobType == JobTypeEnum.OnHold || (!record.isHold && !record.job && ''), jobType == JobTypeEnum.Rejected || (!record.isCanceled && !record.job && '')] })) }));
};
export default JobActionDropdown;
