var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCountries } from '@src/app/pages/LookupPage/LookupHooks/Lookup.hooks';
import { useSocialMedia } from '@src/app/pages/SocialMediaPage/SocialMedia/SocialMedia.hooks';
import { useTenants } from '@src/app/pages/TenantPage/TenantHooks/Tenant.hooks';
import InputFormik from '@src/components/Atoms/Formik/Input/InputFormik';
import MultiSelectFormik from '@src/components/Atoms/Formik/MultiSelect/MultiSelectFormik';
import TextAreaFormik from '@src/components/Atoms/Formik/TextArea/TextAreaFormik';
import { Fragment } from 'react';
import styles from './TalentForm.module.scss';
import TalentSocialMedia from './TalentSocialMedia';
export var BlacklistOptions = [
    {
        value: 0,
        name: 'No',
    },
    {
        value: 1,
        name: 'Yes',
    },
];
var TForm = function (_a) {
    var useForm = _a.useForm, formValues = _a.formValues, addSocialMedia = _a.addSocialMedia, removeSocialMedia = _a.removeSocialMedia, selectedTalentCategory = _a.selectedTalentCategory, isLoadingTalentCategory = _a.isLoadingTalentCategory, optionTalentCategory = _a.optionTalentCategory, onChangeTalentCategory = _a.onChangeTalentCategory, selectedCountry = _a.selectedCountry, onChangeCountry = _a.onChangeCountry;
    var _b = useTenants(), tenants = _b.tenants, isLoadingTenants = _b.isLoading, errorTenants = _b.error;
    var _c = useSocialMedia(), socialMedia = _c.socialMedia, isLoadingSocialMedia = _c.isLoading, errorSocialMedia = _c.error;
    var countries = useCountries().countries;
    var optionTenant = tenants &&
        tenants.length > 0 &&
        tenants.map(function (tenant) { return ({
            name: tenant.name,
            value: tenant.pubId,
        }); });
    var optionSocialMedia = socialMedia &&
        socialMedia.length > 0 &&
        socialMedia.map(function (social) { return ({
            name: social.name,
            value: social.pubId,
        }); });
    var optionCountries = countries &&
        countries.data &&
        countries.data.length > 0 &&
        countries.data.map(function (country) { return ({
            label: country.name,
            value: country.pubId,
        }); });
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'mx-7 mt-7' }, { children: [_jsx(InputFormik, { label: 'Nama', type: 'text', placeholder: 'Nama', name: 'name', formik: useForm, isRequired: true }), _jsx(InputFormik, { label: 'Nama Contact Person', type: 'text', placeholder: 'Nama Contact Person', name: 'cpName', formik: useForm, isRequired: true }), _jsx(InputFormik, { label: 'No Contact Person', type: 'text', placeholder: 'No Contact Person', name: 'cpPhoneNumber', isRequired: true, formik: useForm }), _jsx(InputFormik, { label: 'Email Contact Person', type: 'text', placeholder: 'Email Contact Person', name: 'cpEmail', formik: useForm }), _jsx(TextAreaFormik, { label: 'Note', placeholder: 'Note', name: 'note', formik: useForm }), optionCountries && (_jsx(MultiSelectFormik, { name: 'countryPubId', label: 'Negara', formik: useForm, isMulti: false, options: optionCountries, value: selectedCountry, onchange: onChangeCountry })), !isLoadingTalentCategory && optionTalentCategory && (
                // <SelectFormik
                //   name='talentCategoryPubId'
                //   label='Category'
                //   formik={useForm}
                //   optionList={optionTalentCategory}
                // />
                _jsx(MultiSelectFormik, { name: 'talentCategoryPubId', label: 'Kategori Talent', formik: useForm, isMulti: true, options: optionTalentCategory, value: selectedTalentCategory, onchange: onChangeTalentCategory })), _jsxs("div", __assign({ className: styles['social-media-container'] }, { children: [_jsx("p", __assign({ className: 'form-label fs-6 fw-bolder required text-dark' }, { children: "Sosial Media Talent" })), _jsx("button", __assign({ type: 'button', className: ' btn btn-sm btn-secondary', onClick: addSocialMedia }, { children: "Tambah Sosial Media" }))] })), formValues &&
                    formValues.profiles &&
                    formValues.profiles.length > 0 &&
                    formValues.profiles.map(function (profile, index) { return (_jsx(Fragment, { children: _jsx(TalentSocialMedia, { useForm: useForm, isLoading: isLoadingSocialMedia, index: index, value: profile, optionList: optionSocialMedia, blacklistOption: BlacklistOptions, removeSocialMedia: removeSocialMedia }) }, index)); })] })) }));
};
export default TForm;
