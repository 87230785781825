var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { paginationOptions, optionTransferAllTypes, FormatDateTime, RoleList, userInformation, } from '@src/app/helper';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import RecordAdmin from '@src/components/molecules/Record/Admin';
import Select from '@src/components/Atoms/Select/Select';
import RecordTransfer from '@src/components/molecules/Record/Collaboration';
import Loading from '@src/components/Atoms/Loading/Loading';
import { PajakCategory } from '@src/components/molecules/Transfer/Transfer.enum';
import RecordDescription from '@src/components/molecules/Record/Description';
import Input from '@src/components/Atoms/Input/Input';
import UndoApprovalButton from '@src/app/modules/transfer/UndoApprovalButton';
import UndoTransferButton from '@src/app/modules/transfer/UndoTransferButton';
var pajakCategory = function (status) {
    switch (status) {
        case PajakCategory.PPH23PLUSPPN:
            return 'PPH 23 Plus PPN';
        case PajakCategory.PPH21:
            return 'PPH 21 ';
        case PajakCategory.PPH23:
            return 'PPH 23';
        case PajakCategory.PPH26:
            return 'PPH 26';
        default:
            break;
    }
};
var Transfer = function (_a) {
    var datas = _a.datas, isLoading = _a.isLoading, filter = _a.filter, handlePageSizeChange = _a.handlePageSizeChange, handlePageChange = _a.handlePageChange, onchange = _a.onchange;
    var navigate = useNavigate();
    //gotodetail
    var gotoDetail = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("/talent/detail/".concat(record));
            return [2 /*return*/];
        });
    }); };
    var role = JSON.parse(userInformation).roles[0];
    var columns = [
        {
            name: 'No.Transfer',
            cell: function (record) { return record.transferNumber; },
        },
        {
            name: 'Kerja Sama',
            cell: function (record) {
                return record.jobs.map(function (data, key) { return (_jsx(RecordTransfer, { record: data || '', id: data.pubId }, key)); });
            },
            sortable: true,
        },
        {
            name: 'Keterangan',
            cell: function (record, rowIndex) {
                return (_jsx(RecordDescription, { total: record.totalAmount, bankName: record.talentBankAccount.bank.name, cityName: record.talentBankAccount.city && record.talentBankAccount.city.name, accountNumber: record.talentBankAccount.accountNumber, accountName: record.talentBankAccount.accountName, pajakType: record.pajakType, pajakMethod: record.pajakMethod, pajakCategory: record.pajakCategory, paymentMethod: record.paymentMethod, pajakFee: record.pajakFee, pajakBruto: record.pajakBruto, pajakPPN: record.pajakPPN, bankTransfer: record.bankTransferBy ? record.bankTransferBy.name : '-', invoice: record.invoiceUrl, spk: record.spkUrl, transfer: record.transferUrl, proofTransfer: record.proofTransferUrl, isCanceled: record.isCanceled }));
            },
            sortable: true,
        },
        {
            name: 'Note',
            cell: function (record) {
                var _a;
                return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: 'd-flex gap-2 flex-column' }, { children: (_a = record.note) !== null && _a !== void 0 ? _a : '-' })), record.canceledReason && (_jsxs("div", __assign({ className: 'd-flex gap-2 flex-column mt-5' }, { children: ["Alasan Cancel: ", record.canceledReason] })))] }));
            },
            sortable: true,
        },
        {
            name: 'Approved',
            cell: function (record) {
                return (_jsx("div", __assign({ className: 'd-flex gap-2 flex-column' }, { children: record.isApproved && (_jsxs("div", __assign({ className: 'by' }, { children: [_jsx("i", { className: record.isApproved ? 'bi bi-check-circle-fill px-2 text-success' : '' }), _jsx("p", __assign({ className: 'createdby mb-0' }, { children: record.approvedBy })), _jsx("p", __assign({ className: 'createdon mb-2' }, { children: FormatDateTime(record.approvedOn) }))] }))) })));
            },
            sortable: true,
        },
        {
            name: 'Sudah Ditransfer',
            cell: function (record) {
                return (_jsx("div", { children: record.isTransferred && (_jsxs("div", __assign({ className: 'by' }, { children: [_jsx("i", { className: record.isTransferred ? 'bi bi-check-circle-fill px-2 text-success' : '' }), _jsx("p", __assign({ className: 'createdby mb-0' }, { children: record.transferredBy })), _jsx("p", __assign({ className: 'createdon mb-2' }, { children: FormatDateTime(record.transferredOn) }))] }))) }));
            },
            sortable: true,
        },
        {
            name: 'Ditolak',
            cell: function (record) {
                return (_jsx("div", { children: record.isCanceled && (_jsxs("div", __assign({ className: 'by' }, { children: [_jsx("i", { className: record.isCanceled ? 'bi bi-check-circle-fill px-2 text-success' : '' }), _jsx("p", __assign({ className: 'createdby mb-0' }, { children: record.canceledBy })), _jsx("p", __assign({ className: 'createdon mb-2' }, { children: FormatDateTime(record.canceledOn) })), _jsx("p", __assign({ className: 'createdon mb-2' }, { children: record.canceledReason }))] }))) }));
            },
            sortable: true,
        },
        {
            name: 'Oleh',
            cell: function (record) { return _jsx(RecordAdmin, { record: record }); },
            sortable: true,
        },
        {
            name: 'Action',
            omit: ![RoleList.Audit, RoleList.Finance].includes(role),
            cell: function (record) {
                return (_jsxs(_Fragment, { children: [_jsx(UndoApprovalButton, { transferPubId: record.pubId, transfer: record }), _jsx(UndoTransferButton, { transferPubId: record.pubId, transfer: record })] }));
            },
        },
    ];
    return (_jsxs("div", __assign({ className: 'd-flex flex-column' }, { children: [_jsx("div", __assign({ className: 'card-header border-0 py-0 px-0 d-flex flex-row' }, { children: _jsxs("div", __assign({ className: 'card-title flex' }, { children: [_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Input, { placeholder: 'Search', type: 'text', name: 'keyword', value: filter.keyword, onChange: function (e) {
                                    onchange(e);
                                } }) })), _jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Select, { name: 'Type', onChange: function (e) {
                                    onchange(e);
                                    navigate("#".concat(e.target.value));
                                }, optionList: optionTransferAllTypes, defaultValue: optionTransferAllTypes[0].value }) }))] })) })), _jsx("div", __assign({ className: 'card-body py-0 px-0' }, { children: !isLoading ? (datas && datas.data && datas.data.length > 0 ? (_jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: datas ? datas.data : [], noHeader: true, progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: filter.filterBy === ''
                        ? datas && datas.recordsTotal
                        : datas && datas.recordsFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange }, datas && datas.data.length)) : (_jsx(NotFound, {}))) : (_jsx(Loading, {})) }))] })));
};
export default Transfer;
