var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import FileFormik from '../../Formik/Input/FileFormik';
import InputFormik from '../../Formik/Input/InputFormik';
var AttachmentForm = function (_a) {
    var useForm = _a.useForm, idForEdit = _a.idForEdit;
    var onChangeFiles = function (e) {
        useForm.values.files = e.target.files;
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: 'mb-3' }, { children: _jsx("div", { children: _jsx(InputFormik, { label: 'Name', type: 'text', placeholder: 'Name', name: 'name', formik: useForm }) }) })), _jsx("div", __assign({ className: 'mb-3' }, { children: _jsx("div", { children: _jsx(FileFormik, { label: 'Upload', placeholder: 'Upload', name: 'file', 
                        // name={`${useForm.values.name}`}
                        isRequired: true, formik: useForm, onChange: onChangeFiles }) }) }))] }));
};
export default AttachmentForm;
