export function MultiDimensionalFilter(event, filterValue) {
    var filterCriteria = [event.target.name, event.target.value];
    var index = filterValue.findIndex(function (arr) { return arr.includes(event.target.name); });
    if (index !== -1) {
        filterValue.splice(index, 1);
        filterValue.push(filterCriteria);
    }
    else {
        filterValue.push(filterCriteria);
    }
    return filterValue;
}
