var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { KTCard } from '@src/_metronic/helpers';
import { PageTitle } from '@src/_metronic/layout/core';
import { Fragment, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import SForm from '@src/components/molecules/SaldoTeam/Form/SaldoTeamForm';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import '@src/app/styles/style.css';
import dayjs from 'dayjs';
import { FormatYearMonthDate, userInformation } from '@src/app/helper';
import { useSaldoTeams } from '../SaldoTeamHooks/SaldoTeam.hooks';
var SaldoTeamFormSchema = Yup.object().shape({
    amount: Yup.string().required('Field belum diisi'),
});
var SaldoTeamForm = function () {
    var intl = useIntl();
    var navigate = useNavigate();
    var id = useParams().id;
    var teams = JSON.parse(userInformation).teams;
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var createSaldoTeam = useSaldoTeams().createSaldoTeam;
    var saldoTeamValues = {
        amount: '',
        teamPubId: teams[0].pubId,
        date: FormatYearMonthDate(Number(dayjs())).split('/').join('-'),
    };
    var SaldoTeamFormik = useFormik({
        initialValues: saldoTeamValues,
        enableReinitialize: true,
        validationSchema: SaldoTeamFormSchema,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var e_1, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoading(true);
                        values.amount = values.amount.replace(/\./g, '');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, createSaldoTeam(values)];
                    case 2:
                        _a.sent();
                        navigate("/saldo-team");
                        setLoading(false);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        error = e_1.response.data;
                        setLoading(false);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); },
    });
    return (_jsxs(Fragment, { children: [_jsx(PageTitle, { children: intl.formatMessage({ id: 'SALDOTEAM.ADD' }) }), _jsx(KTCard, { children: _jsxs("form", __assign({ id: 'kt_saldoTeam_form', className: 'form', noValidate: true, onSubmit: SaldoTeamFormik.handleSubmit }, { children: [_jsx(SForm, { useForm: SaldoTeamFormik }), _jsxs("div", __assign({ className: 'button-container' }, { children: [_jsx("div", { children: SaldoTeamFormik.status }), _jsx(Link, __assign({ to: '/saldo-team/' }, { children: _jsx("button", __assign({ className: 'btn btn-light' }, { children: "Kembali" })) })), _jsxs("button", __assign({ type: 'submit', id: 'kt_saldoTeam_submit', className: 'btn btn-primary ', disabled: SaldoTeamFormik.isSubmitting || !SaldoTeamFormik.isValid }, { children: [!loading && _jsx("span", __assign({ className: 'indicator-label' }, { children: "Simpan" })), loading && (_jsxs("span", __assign({ className: 'indicator-progress', style: { display: 'block' } }, { children: ["Mohon tunggu...", _jsx("span", { className: 'spinner-border spinner-border-sm align-middle ms-2' })] })))] }))] }))] })) })] }));
};
export default SaldoTeamForm;
