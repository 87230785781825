export var RecordStatusList;
(function (RecordStatusList) {
    RecordStatusList["Active"] = "Active";
    RecordStatusList["Inactive"] = "Inactive";
    RecordStatusList["Deleted"] = "Deleted";
})(RecordStatusList || (RecordStatusList = {}));
export var recordStatuses = Object.values(RecordStatusList);
//initial values for recordStatusList
export var RecordStatusOptions = [
    {
        value: RecordStatusList.Active,
        name: RecordStatusList.Active,
    },
    {
        value: RecordStatusList.Inactive,
        name: RecordStatusList.Inactive,
    },
];
