var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import { useLayout } from '../core';
import { DrawerComponent } from '../../assets/ts/components';
var Content = function (_a) {
    var children = _a.children;
    var classes = useLayout().classes;
    var location = useLocation();
    useEffect(function () {
        DrawerComponent.hideAll();
    }, [location]);
    return (_jsx("div", __assign({ id: 'kt_content_container', className: clsx(classes.contentContainer.join(' ')) }, { children: children })));
};
export { Content };
