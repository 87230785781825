var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useIntl } from 'react-intl';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { FormatDateTime, userInformation, MultiDimensionalFilter } from '@src/app/helper';
import Input from '@src/components/Atoms/Input/Input';
import Select from '@src/components/Atoms/Select/Select';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import Loading from '@src/components/Atoms/Loading/Loading';
var Refund = function (_a) {
    var data = _a.data;
    var id = useParams().id;
    var intl = useIntl();
    //get socialmedia
    var socialMedias = JSON.parse(userInformation).socialMedias;
    var optionSocialMedia = socialMedias &&
        socialMedias.map(function (social) { return ({
            name: social.name,
            value: social.pubId,
        }); });
    //initialize filter
    var _b = useState({
        page: 1,
        pageSize: 10,
        keyword: '',
        filterBy: '',
    }), filter = _b[0], setFilter = _b[1];
    var columns = [
        {
            name: 'No',
            selector: function (row) { return row.socialMedia.name; },
            sortable: true,
        },
        {
            name: 'Kerja Sama',
            selector: function (row) { return row.profileName; },
            sortable: true,
        },
        {
            name: 'Refund Dikonfirmasi',
            selector: function (row) { return row.profileName; },
            sortable: true,
        },
        {
            name: 'Oleh',
            cell: function (row) {
                return (_jsxs("div", { children: [_jsx("p", __assign({ className: 'createdby mb-0' }, { children: row.createdBy })), _jsx("p", __assign({ className: 'createdon mb-2' }, { children: FormatDateTime(row.createdOn) }))] }));
            },
            sortable: true,
        },
    ];
    //search & filters
    var handleMultiDimensionalFilterChange = function (event) {
        var filterValue = filter.filterBy !== '' ? JSON.parse(filter.filterBy) : '';
        if (filter.filterBy === '') {
            var filterCriteria = [[event.target.name, event.target.value]];
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterCriteria) }));
        }
        else {
            var filterNew = MultiDimensionalFilter(event, filterValue);
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterNew) }));
        }
    };
    var handleFilterChange = function (event) {
        var _a;
        setFilter(__assign(__assign({}, filter), (_a = {}, _a[event.target.name] = event.target.value, _a)));
    };
    return (_jsxs("div", __assign({ className: 'd-flex flex-column' }, { children: [_jsx("div", __assign({ className: 'card-header border-0 py-0 px-0 d-flex flex-row' }, { children: _jsxs("div", __assign({ className: 'card-title flex' }, { children: [_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Input, { placeholder: 'Search', type: 'text', name: 'keyword', value: filter.keyword, onChange: function (e) {
                                    handleFilterChange(e);
                                } }) })), _jsx("div", __assign({ className: 'card-title' }, { children: optionSocialMedia && (_jsx(Select, { name: 'profiles.socialMedia.pubId', onChange: function (e) {
                                    handleMultiDimensionalFilterChange(e);
                                }, placeholder: 'Filter by Social Media', optionList: optionSocialMedia })) }))] })) })), _jsx("div", __assign({ className: 'card-body py-0 px-0' }, { children: data.length > 0 ? (_jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: data, noHeader: true, progressComponent: _jsx(Loading, {}) })) : (_jsx(NotFound, {})) }))] })));
};
export default Refund;
