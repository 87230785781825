var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { handletotalFollowerStyle, userInformation } from '@src/app/helper';
import ApprovalActionKerjasama from '@src/app/modules/job/ApprovalActionKerjasama';
import KerjasamaActionDropdown from '@src/components/Atoms/ActionDropdown/KerjasamaActionDropdown';
import Loading from '@src/components/Atoms/Loading/Loading';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import HistoryJob from '@src/components/molecules/JobDetail/Kerjasama/History';
import ProcessJob from '@src/components/molecules/JobDetail/Kerjasama/Process';
import RejectJob from '@src/components/molecules/JobDetail/Kerjasama/Reject';
import TemplateInvoice from '@src/components/molecules/JobDetail/Kerjasama/TemplateInvoice';
import TemplateSK from '@src/components/molecules/JobDetail/Kerjasama/TemplateSK';
import TemplateSP from '@src/components/molecules/JobDetail/Kerjasama/TemplateSP';
import TemplateSPK from '@src/components/molecules/JobDetail/Kerjasama/TemplateSPK';
import UpdateJob from '@src/components/molecules/JobDetail/Kerjasama/Update';
import { Fragment, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { useJob } from '../../JobHooks/Job.hooks';
import { useComplete } from '../../JobHooks/JobDetail.hooks';
var RecordKerjasama = function (_a) {
    var name = _a.name, status = _a.status, totalPosts = _a.totalPosts;
    return (_jsxs(Fragment, { children: [_jsx("p", { children: status }), _jsxs("p", { children: [name, " - ", totalPosts.toLocaleString(), "x"] })] }));
};
var RecordKeterangan = function (_a) {
    var followers = _a.followers, followerCategory = _a.followerCategory, timeFrame = _a.timeFrame, feePerUnit = _a.feePerUnit, totalFeeBundling = _a.totalFeeBundling, holdReason = _a.holdReason;
    return (_jsxs(Fragment, { children: [_jsxs("p", { children: ["Followers: ", followers.toLocaleString() || 0, _jsx("span", __assign({ className: "badge badge-".concat(handletotalFollowerStyle(followerCategory), " mx-2") }, { children: followerCategory }))] }), _jsxs("p", { children: ["Rate Satuan: Rp ", feePerUnit.toLocaleString(), " / review"] }), _jsxs("span", __assign({ className: 'badge badge-warning fw-bolder fs-7 mb-3' }, { children: ["Rate Bundling: Rp ", totalFeeBundling.toLocaleString()] })), _jsxs("p", { children: ["Jangka Waktu: ", timeFrame || '-'] }), holdReason && (_jsxs("p", __assign({ style: { fontWeight: '800' } }, { children: ["Alasan Hold: ", _jsx("span", __assign({ style: { fontWeight: '600' } }, { children: holdReason }))] })))] }));
};
var Kerjasama = function (_a) {
    var details = _a.details, DetailData = _a.job, id = _a.id, talent = _a.talent;
    var role = JSON.parse(userInformation).roles[0];
    var intl = useIntl();
    var _b = useState(false), showUpdate = _b[0], setShowUpdate = _b[1];
    var _c = useState(false), showProcess = _c[0], setShowProcess = _c[1];
    var _d = useState(false), showReject = _d[0], setShowReject = _d[1];
    var _e = useState(false), showHistory = _e[0], setShowHistory = _e[1];
    var _f = useState(false), showInvoice = _f[0], setShowInvoice = _f[1];
    var _g = useState(false), showSPK = _g[0], setShowSPK = _g[1];
    var _h = useState(false), showSP = _h[0], setShowSP = _h[1];
    var _j = useState(false), showSK = _j[0], setShowSK = _j[1];
    var _k = useComplete(id), complete = _k.complete, uncomplete = _k.uncomplete;
    //generate template
    var handleTemplateInvoice = function (record) {
        setShowInvoice(true);
    };
    var handleTemplateSPK = function (record) {
        setShowSPK(true);
    };
    var handleTemplateSP = function (record) {
        setShowSP(true);
    };
    var handleTemplateSK = function (record) {
        setShowSK(true);
    };
    var handleComplete = function (id, status) {
        if (status) {
            uncomplete(id);
        }
        else {
            complete(id);
        }
    };
    var columns = [
        {
            name: 'No Kerja Sama',
            cell: function (row) {
                return DetailData.jobNumber;
            },
        },
        {
            name: 'Kerja Sama',
            cell: function (row) { return (_jsx(RecordKerjasama, { name: row.jobType.name, status: row.exclusiveStatus, totalPosts: row.totalPosts })); },
            sortable: true,
        },
        {
            name: 'Total Fee',
            selector: function (row) { return "Rp ".concat(row.fee.toLocaleString()); },
            sortable: true,
        },
        {
            name: 'Note',
            cell: function (row) { return row.notes; },
            sortable: true,
        },
        {
            name: 'Alasan Cancel',
            omit: !DetailData.canceledReason && DetailData.bargainType !== 'Cancel',
            cell: function () { return DetailData.canceledReason; },
        },
        {
            name: 'Keterangan',
            cell: function (row) {
                return (_jsx(RecordKeterangan, { timeFrame: row.timeFrame, followers: row.totalFollowers, followerCategory: row.profile.followerCategory, feePerUnit: row.feePerUnit, totalFeeBundling: DetailData.totalFeeBundling, holdReason: DetailData.holdReason }));
            },
            sortable: true,
        },
    ];
    var handleBargainStyle = function (type) {
        switch (type) {
            case 'Open':
                return 'warning';
            case 'Nego':
                return 'info';
            case 'NegoSpv':
                return 'success';
            case 'Cancel':
                return 'danger';
            case 'Done':
                return 'primary';
            default:
                return 'primary';
        }
    };
    var approveJob = useJob(undefined, false).approveJob;
    var approveItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        var formValues;
        return __generator(this, function (_a) {
            formValues = {
                id: id,
                pubId: talent.pubId,
                reason: null,
            };
            swal({
                title: intl.formatMessage({ id: 'GENERAL.VALIDATION' }),
                text: '',
                icon: 'warning',
                buttons: [
                    intl.formatMessage({ id: 'GENERAL.VALIDATION.NO' }),
                    intl.formatMessage({ id: 'GENERAL.VALIDATION.YES' }),
                ],
                dangerMode: true,
            }).then(function (willApprove) { return __awaiter(void 0, void 0, void 0, function () {
                var e_1, error;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!willApprove) return [3 /*break*/, 4];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, approveJob(formValues)];
                        case 2:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 3:
                            e_1 = _a.sent();
                            error = e_1.response.data;
                            toast.error(error.message);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            }); });
            return [2 /*return*/];
        });
    }); };
    return (_jsxs("div", __assign({ className: 'd-flex flex-column' }, { children: [_jsxs("div", __assign({ className: 'd-flex justify-content-between align-items-center mb-2' }, { children: [_jsxs("div", { children: [_jsx("span", __assign({ className: "badge badge-".concat(handleBargainStyle(DetailData.bargainType)) }, { children: DetailData.bargainType })), DetailData.isHold && _jsx("span", __assign({ className: 'badge badge-warning mx-3' }, { children: "Hold" }))] }), _jsxs("div", __assign({ className: 'd-flex justify-content-between align-items-center mb-2' }, { children: [_jsx("div", __assign({ className: 'mx-2', style: { textAlign: 'right' } }, { children: _jsx(ApprovalActionKerjasama, { jobPubId: id, job: DetailData }) })), !(DetailData === null || DetailData === void 0 ? void 0 : DetailData.isCanceled) &&
                                !(DetailData === null || DetailData === void 0 ? void 0 : DetailData.isHold) &&
                                !(DetailData === null || DetailData === void 0 ? void 0 : DetailData.isCompleted) && (_jsx("div", __assign({ style: { textAlign: 'right' } }, { children: _jsx(KerjasamaActionDropdown, { updateItem: function () { return setShowUpdate(true); }, rejectItem: function () { return setShowReject(true); }, handleTemplateInvoice: handleTemplateInvoice, handleTemplateSPK: handleTemplateSPK, handleTemplateSP: handleTemplateSP, handleTemplateSK: handleTemplateSK, processItem: function () { return setShowProcess(true); }, approveItem: function (record) { return approveItem(record); }, showHistory: function () { return setShowHistory(true); }, record: details, isApprovedLeader: DetailData.isApprovedLeader, bargainType: DetailData.bargainType }) }))), (DetailData === null || DetailData === void 0 ? void 0 : DetailData.isTransferPerTerm) && ( //isTransferAllTermsCompleted
                            _jsx("div", __assign({ style: { textAlign: 'right' } }, { children: _jsx(KerjasamaActionDropdown, { updateItem: function () { return setShowUpdate(true); }, showHistory: function () { return setShowHistory(true); }, rejectItem: function () { return setShowReject(true); }, handleTemplateInvoice: handleTemplateInvoice, handleTemplateSPK: handleTemplateSPK, handleTemplateSP: handleTemplateSP, handleTemplateSK: handleTemplateSK, approveItem: function (record) { return approveItem(record); }, record: details, isCompleted: DetailData.isCompleted, jobPubId: id, handleComplete: handleComplete, bargainType: DetailData.bargainType, isUpdate: false, isProcess: false, isApprove: false, isReject: false, isTemplateSPK: false, isApprovedLeader: DetailData.isApprovedLeader }) })))] }))] })), _jsx("div", __assign({ className: 'card-body py-0 px-0' }, { children: _jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: details, noHeader: true, progressComponent: _jsx(Loading, {}) }) })), details && DetailData && (_jsx(UpdateJob, { isOpen: showUpdate, handleClose: function () {
                    setShowUpdate(false);
                }, record: details, profiles: talent.profiles, totalFeeBundling: DetailData.totalFeeBundling, id: id })), showProcess && details && DetailData && (_jsx(ProcessJob, { isOpen: showProcess, handleClose: function () {
                    setShowProcess(false);
                }, record: {
                    job: DetailData,
                    talent: talent,
                }, details: details, id: id })), details && DetailData && (_jsx(RejectJob, { isOpen: showReject, handleClose: function () {
                    setShowReject(false);
                }, record: {
                    talent: talent,
                }, id: id })), details && DetailData && (_jsx(HistoryJob, { isOpen: showHistory, handleClose: function () {
                    setShowHistory(false);
                }, record: {
                    talent: talent,
                }, id: id })), _jsx(TemplateInvoice, { isOpen: showInvoice, handleClose: function () {
                    setShowInvoice(false);
                }, id: id, talentId: talent.pubId }), showSPK && (_jsx(TemplateSPK, { isOpen: showSPK, handleClose: function () {
                    setShowSPK(false);
                }, record: DetailData, id: id, talentId: talent.pubId })), _jsx(TemplateSP, { isOpen: showSP, handleClose: function () {
                    setShowSP(false);
                }, id: id }), _jsx(TemplateSK, { isOpen: showSK, handleClose: function () {
                    setShowSK(false);
                }, id: id, record: DetailData, talentId: talent.pubId })] })));
};
export default Kerjasama;
