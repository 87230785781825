var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment } from 'react';
import { PageTitle } from '@src/_metronic/layout/core';
import { KTCard } from '@src/_metronic/helpers';
import DataTable from 'react-data-table-component';
import swal from 'sweetalert';
import '@src/app/styles/datatable.css';
import { useBlasts } from './Blast.hooks';
import Select from '@src/components/Atoms/Select/Select';
import Input from '@src/components/Atoms/Input/Input';
import { useSocialMedia } from '../../SocialMediaPage/SocialMedia/SocialMedia.hooks';
import BlastActionDropdown from '@src/components/Atoms/ActionDropdown/BlastActionDropdown';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import Loading from '@src/components/Atoms/Loading/Loading';
import { paginationOptions } from '@src/app/helper';
//breadcrumb
var usersBreadcrumbs = [
    {
        title: 'Blast',
        path: '',
        isSeparator: true,
        isActive: false,
    },
];
var BlastIndex = function (_a) {
    var _b = _a.disableBreadcrumbs, disableBreadcrumbs = _b === void 0 ? false : _b;
    var _c = useState({
        page: 1,
        pageSize: 10,
        keyword: '',
        filterBy: '[["isBlasted", false]]',
    }), filter = _c[0], setFilter = _c[1];
    var _d = useBlasts(filter), blasts = _d.blasts, isLoading = _d.isLoading, updateBlastStatus = _d.updateBlastStatus;
    var _e = useSocialMedia(), socialMedia = _e.socialMedia, isLoadingSocialMedia = _e.isLoading, errorSocialMedia = _e.error;
    var optionSocialMedia = socialMedia &&
        socialMedia.length > 0 &&
        socialMedia.map(function (social) { return ({
            name: social.name,
            value: social.pubId,
        }); });
    var handleMultiDimensionalFilterChange = function (event) {
        var filterValue = filter.filterBy !== '' ? JSON.parse(filter.filterBy) : '';
        if (filter.filterBy === '') {
            var filterCriteria = [[event.target.name, event.target.value]];
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterCriteria) }));
        }
        else {
            var filterCriteria = [event.target.name, event.target.value];
            var index = filterValue.findIndex(function (arr) { return arr.includes(event.target.name); });
            if (index !== -1) {
                filterValue.splice(index, 1);
                filterValue.push(filterCriteria);
            }
            else {
                filterValue.push(filterCriteria);
            }
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterValue) }));
        }
    };
    var handleFilterChange = function (event) {
        var _a;
        setFilter(__assign(__assign({}, filter), (_a = {}, _a[event.target.name] = event.target.value, _a)));
    };
    var handlePageChange = function (page) {
        setFilter(__assign(__assign({}, filter), { page: page }));
    };
    var handlePageSizeChange = function (size) {
        setFilter(__assign(__assign({}, filter), { page: 1, pageSize: size }));
    };
    var blastItem = function (record) {
        swal({
            title: 'Blast this Profile?',
            text: '',
            icon: 'warning',
            buttons: ['No', 'Yes'],
            dangerMode: true,
        }).then(function (willDelete) { return __awaiter(void 0, void 0, void 0, function () {
            var e_1, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!willDelete) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, updateBlastStatus(record)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        error = e_1.response.data;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    };
    // TODO: show cp email and cp other and country name on datatable row, as its missing from api return
    var columns = [
        {
            name: 'Nama Contact Person',
            selector: function (row) { return row.talent.cpName; },
            sortable: true,
        },
        {
            name: 'No Contact Person',
            selector: function (row) { return row.talent.cpPhoneNumber; },
            sortable: true,
        },
        {
            name: 'Nama Talent',
            selector: function (row) { return row.talent.name; },
            sortable: true,
        },
        {
            name: 'Sosial Media Talent',
            selector: function (row) { return "".concat(row.profile.socialMedia.name, " - ").concat(row.profile.profileName); },
            sortable: true,
            width: '15%',
        },
        {
            name: 'Oleh',
            selector: function (row) {
                return "".concat(row.createdBy, " - ").concat(row.createdOn && new Date(row.createdOn * 1000).toISOString().split('T')[0]);
            },
            sortable: true,
        },
        {
            name: 'Action',
            cell: function (record) { return _jsx(BlastActionDropdown, { blastItem: blastItem, record: record }); },
        },
    ];
    return (_jsxs(Fragment, { children: [!disableBreadcrumbs ? (_jsx(PageTitle, __assign({ breadcrumbs: usersBreadcrumbs }, { children: "Blast" }))) : undefined, _jsxs(KTCard, { children: [_jsx("div", __assign({ className: 'd-flex flex-column' }, { children: _jsx("div", __assign({ className: 'card-header border-0 pt-6 d-flex flex-row' }, { children: _jsxs("div", __assign({ className: 'card-title flex' }, { children: [_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Input, { placeholder: 'Search', type: 'text', name: 'keyword', value: filter.keyword, onChange: function (e) {
                                                handleFilterChange(e);
                                            } }) })), _jsx("div", __assign({ className: 'card-title' }, { children: !isLoadingSocialMedia && optionSocialMedia && (_jsx(Select, { name: 'profile.socialMedia.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, placeholder: 'Talent Belum di Blast', optionList: optionSocialMedia })) }))] })) })) })), _jsx("div", __assign({ className: 'card-body py-4' }, { children: _jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: blasts ? blasts.data : [], noHeader: true, progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: filter.filterBy === ''
                                ? blasts && blasts.recordsTotal
                                : blasts && blasts.recordsFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange }) }))] })] }));
};
export default BlastIndex;
