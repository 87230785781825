var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { FormatDateTime } from '@src/app/helper';
import DrawerComponent from '@src/components/Atoms/Drawer/Drawer';
var SendProductDetailTalent = function (_a) {
    var isOpen = _a.isOpen, handleClose = _a.handleClose, record = _a.record, _b = _a.refetch, refetch = _b === void 0 ? function () { } : _b;
    return (_jsx(DrawerComponent, __assign({ open: isOpen, handleClose: function () { return handleClose(false); }, title: 'Tracking Pengiriman Barang', size: 'MD' }, { children: record && (_jsxs("div", { children: [_jsxs("div", __assign({ className: 'mb-3' }, { children: [_jsx("div", { children: _jsx(Typography, __assign({ variant: 'body1' }, { children: "No Resi" })) }), _jsx("div", { children: _jsx(Typography, __assign({ variant: 'h6' }, { children: record.noResi ? record.noResi : '-' })) })] })), record.tracking && record.tracking.length > 0 ? (_jsxs("div", __assign({ className: 'mb-3' }, { children: [_jsx("div", { children: _jsx(Typography, __assign({ variant: 'body1' }, { children: "Tracking" })) }), record.tracking.map(function (item, i) { return (_jsx("div", __assign({ className: 'card card-bordered card-rounded-0 border-gray-400 mt-3' }, { children: _jsxs("div", __assign({ className: 'card-body px-3 py-3' }, { children: [_jsx(Typography, __assign({ variant: 'h6' }, { children: item.state })), _jsxs("span", { children: [item.by, " ", FormatDateTime(item.at)] })] })) }), i)); })] }))) : undefined, _jsx("div", __assign({ className: 'my-5' }, { children: _jsx("div", __assign({ style: { textAlign: 'right' } }, { children: _jsx("button", __assign({ type: 'button', className: 'btn btn-secondary btn-active-light-primary btn-sm', onClick: function () { return handleClose(false); }, style: { marginRight: '1rem' } }, { children: "Tutup" })) })) }))] })) })));
};
export default SendProductDetailTalent;
