var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment } from 'react';
import { PageTitle } from '@src/_metronic/layout/core';
import { KTCard } from '@src/_metronic/helpers';
import { FormatYearMonthDateHypen } from '@src/app/helper';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TaxForm from '@src/components/molecules/Tax/Form/TaxForm';
import { useTax } from './Tax.requests';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
//breadcrumb
var usersBreadcrumbs = [
    {
        title: 'Download Pajak',
        path: '/',
        isSeparator: true,
        isActive: false,
    },
];
var RepeatJobFormSchema = Yup.object().shape({
    dateBy: Yup.string().required('Field belum diisi'),
});
var Tax = {
    startDate: '',
    endDate: '',
    pajakCategory: '',
    dateBy: '',
};
var RepeatJobIndex = function () {
    var navigate = useNavigate();
    var downloadTax = useTax().downloadTax;
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var TaxFormik = useFormik({
        initialValues: Tax,
        enableReinitialize: true,
        validationSchema: RepeatJobFormSchema,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var file, FILE_API_BASE_URL, url, e_1, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoading(true);
                        values.startDate = startDate.toString();
                        values.endDate = endDate.toString();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, downloadTax(__assign({}, values))];
                    case 2:
                        file = _a.sent();
                        FILE_API_BASE_URL = process.env.NEXUS_FILE_API_URL;
                        url = "".concat(FILE_API_BASE_URL, "/").concat(file.pubId, "/download");
                        window.open(url, '_blank', 'noreferrer');
                        setLoading(false);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        error = e_1.response.data;
                        setLoading(false);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); },
    });
    var _b = useState(dayjs().format('YYYY-MM-DD').toString()), startDate = _b[0], setStartDate = _b[1];
    var onChangeStartDate = function (e) {
        var event = { target: { name: '', value: null } };
        event.target.name = 'startDate';
        event.target.value = e == null ? '' : FormatYearMonthDateHypen(e);
        setStartDate(event.target.value);
        TaxFormik.setFieldValue(event.target.name, event.target.value);
    };
    var _c = useState(dayjs().format('YYYY-MM-DD').toString()), endDate = _c[0], setEndDate = _c[1];
    var onChangeEndDate = function (e) {
        var event = { target: { name: '', value: null } };
        event.target.name = 'endDate';
        event.target.value = e == null ? '' : FormatYearMonthDateHypen(e);
        setEndDate(event.target.value);
        TaxFormik.setFieldValue(event.target.name, event.target.value);
    };
    var _d = useState(false), checked = _d[0], setChecked = _d[1];
    var onChangeCheck = function (e) {
        setChecked(e.target.checked);
    };
    return (_jsxs(Fragment, { children: [_jsx(PageTitle, __assign({ breadcrumbs: usersBreadcrumbs }, { children: "Download Pajak" })), _jsx(KTCard, { children: _jsx("div", __assign({ className: 'row g-5' }, { children: _jsx("div", __assign({ className: 'col-lg-6' }, { children: _jsx("div", __assign({ className: 'card card-flush shadow-sm' }, { children: _jsx("div", __assign({ className: 'card-body p-0' }, { children: _jsxs("form", __assign({ id: 'kt_team_form', className: 'form', noValidate: true, onSubmit: TaxFormik.handleSubmit }, { children: [_jsx(TaxForm, { useForm: TaxFormik, startDate: startDate, endDate: endDate, onChangeStartDate: onChangeStartDate, onChangeEndDate: onChangeEndDate, checked: checked, onChangeCheck: onChangeCheck }), _jsx("div", __assign({ className: 'button-container' }, { children: _jsxs("button", __assign({ type: 'submit', id: 'kt_team_submit', className: 'btn btn-primary ', disabled: TaxFormik.isSubmitting || !TaxFormik.isValid }, { children: [!loading && _jsx("span", __assign({ className: 'indicator-label' }, { children: "Download" })), loading && (_jsxs("span", __assign({ className: 'indicator-progress', style: { display: 'block' } }, { children: ["Mohon tunggu...", _jsx("span", { className: 'spinner-border spinner-border-sm align-middle ms-2' })] })))] })) }))] })) })) })) })) })) })] }));
};
export default RepeatJobIndex;
