var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ProtectedRoute } from '@src/app/helper';
import { Route, Routes, Outlet } from 'react-router-dom';
import ApprovalJobIndex from './Approval/ApprovalJob/ApprovalJob';
import JobDetail from '@src/app/pages/JobPage/JobDetail/JobDetail';
import ApprovalAddressIndex from './Approval/ApprovalAddress/ApprovalAddress';
import ApprovalBankAccountIndex from './Approval/ApprovalBankAccount/ApprovalBankAccount';
import ApprovalTransferIndex from './Approval/ApprovalTransfer/ApprovalTransfer';
import ApprovalSendProductIndex from './Approval/ApprovalSendProduct/ApprovalSendProduct';
var ApprovalPage = function () {
    return (_jsx(Routes, { children: _jsxs(Route, __assign({ element: _jsx(Outlet, {}) }, { children: [_jsx(Route, { path: '/job/', element: _jsx(ProtectedRoute, { permission: 'approval.job.view', children: _jsx(ApprovalJobIndex, {}) }) }), _jsx(Route, { path: '/talent/detail/:id', element: _jsx(ProtectedRoute, { permission: 'approval.talent.view', children: _jsx(JobDetail, {}) }) }), _jsx(Route, { path: '/address/', element: _jsx(ProtectedRoute, { permission: 'approval.address.view', children: _jsx(ApprovalAddressIndex, {}) }) }), _jsx(Route, { path: '/bankaccount/', element: _jsx(ProtectedRoute, { permission: 'approval.bankaccount.view', children: _jsx(ApprovalBankAccountIndex, {}) }) }), _jsx(Route, { path: '/transfer/', element: _jsx(ProtectedRoute, { permission: 'approval.transfer.view', children: _jsx(ApprovalTransferIndex, {}) }) }), _jsx(Route, { path: '/sendproduct/', element: _jsx(ProtectedRoute, { permission: 'approval.sendproduct.view', children: _jsx(ApprovalSendProductIndex, {}) }) })] })) }));
};
export default ApprovalPage;
