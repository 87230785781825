// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HXGrfaXsEG3xjTOQYpEq{background-color:#f9f9f9;padding:30px;margin-bottom:30px;border-radius:5px;display:flex;align-items:flex-start;column-gap:30px;flex-direction:column}.HXGrfaXsEG3xjTOQYpEq .EMoT7cThjvV7PwdBFowE{width:100%}.JppHvKhVKgGR62lV2Pwg{display:flex;justify-content:space-between;align-items:center;margin-bottom:10px}.EMoT7cThjvV7PwdBFowE{flex-grow:1}.eFL88B1jt04g1TC2wGa0{margin-top:-16px}.pfDaMTq0xC8CZMZw7mmD{background-color:#ecf9ff;padding:15px 30px 0;margin-bottom:15px;border-radius:5px}.pfDaMTq0xC8CZMZw7mmD .rsq67lrYS2hguG1UrIRq{display:flex;align-items:center;column-gap:30px;width:100%}", "",{"version":3,"sources":["webpack://./src/components/molecules/Job/JobDetailForm.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,YAAA,CACA,sBAAA,CACA,eAAA,CACA,qBAAA,CAEA,4CACE,UAAA,CAIJ,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CAGF,sBACE,WAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,wBAAA,CACA,mBAAA,CACA,kBAAA,CACA,iBAAA,CAEA,4CACE,YAAA,CACA,kBAAA,CACA,eAAA,CACA,UAAA","sourcesContent":[".profile-container {\n  background-color: #f9f9f9;\n  padding: 30px;\n  margin-bottom: 30px;\n  border-radius: 5px;\n  display: flex;\n  align-items: flex-start;\n  column-gap: 30px;\n  flex-direction: column;\n\n  .profile-layout {\n    width: 100%;\n  }\n}\n\n.social-media-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 10px;\n}\n\n.profile-layout {\n  flex-grow: 1;\n}\n\n.profile-status-message {\n  margin-top: -16px;\n}\n\n.item-container {\n  background-color: #ecf9ff;\n  padding: 15px 30px 0;\n  margin-bottom: 15px;\n  border-radius: 5px;\n\n  .item-layout {\n    display: flex;\n    align-items: center;\n    column-gap: 30px;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile-container": "HXGrfaXsEG3xjTOQYpEq",
	"profile-layout": "EMoT7cThjvV7PwdBFowE",
	"social-media-container": "JppHvKhVKgGR62lV2Pwg",
	"profile-status-message": "eFL88B1jt04g1TC2wGa0",
	"item-container": "pfDaMTq0xC8CZMZw7mmD",
	"item-layout": "rsq67lrYS2hguG1UrIRq"
};
export default ___CSS_LOADER_EXPORT___;
