var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import '@src/app/styles/datatable.css';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormatDateEpoch } from '@src/app/helper';
import RejectForm from '../JobDetailFormDrawer/RejectForm';
import { useProcessSchedule } from '@src/components/molecules/Schedule/NoSchedule/NoSchedule.request';
import { useParams } from 'react-router-dom';
var NoteScheduleForm = function (_a) {
    var record = _a.record, refetch = _a.refetch, handleClose = _a.handleClose;
    var id = useParams().id;
    var editSchedule = useProcessSchedule(id).editSchedule;
    var _b = useState(false), isOpenNote = _b[0], setIsOpenNote = _b[1];
    var _c = useState(), noteId = _c[0], setNoteId = _c[1];
    var _d = useState(''), scheduleId = _d[0], setScheduleId = _d[1];
    var _e = useState(false), isSubmitting = _e[0], setIsSubmitting = _e[1];
    //note
    var noteItem = function (record, index, note) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            NoteFormik.setFieldValue('note', note);
            setScheduleId(record);
            setNoteId(index);
            setIsOpenNote(!isOpenNote);
            return [2 /*return*/];
        });
    }); };
    var NoteFormSchema = Yup.object().shape({
        note: Yup.string().required('Field belum diisi'),
    });
    var NoteFormik = useFormik({
        initialValues: {
            note: '',
        },
        enableReinitialize: true,
        validationSchema: NoteFormSchema,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var formData, e_1, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formData = {
                            note: values.note,
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        setIsSubmitting(true);
                        return [4 /*yield*/, editSchedule(__assign({ id: scheduleId }, formData))];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _a.sent();
                        error = e_1.response.data;
                        // setStatus(error.message)
                        toast.error(error.message);
                        return [3 /*break*/, 5];
                    case 4:
                        setIsSubmitting(false);
                        handleClose(false);
                        refetch();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); },
    });
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: 'd-flex flex-row border border-dashed border-gray-200 px-2 py-2' }, { children: [_jsx("div", __assign({ className: 'd-flex flex-column w-250px fw-bold' }, { children: "Sosial Media" })), _jsx("div", __assign({ className: 'd-flex flex-column w-250px fw-bold' }, { children: "Kerja Sama" })), _jsx("div", __assign({ className: 'd-flex flex-column w-300px fw-bold' }, { children: "Action" }))] })), record &&
                record.schedules &&
                record.schedules.length > 0 &&
                record.schedules.map(function (data, key) {
                    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: 'd-flex flex-row border border-dashed border-gray-200 px-2 py-2' }, { children: [_jsxs("div", __assign({ className: 'd-flex flex-column w-250px' }, { children: [_jsx("p", { children: data.jobDetail.profile.socialMedia.name }), _jsx("a", __assign({ href: data.jobDetail.profile.profileLink }, { children: data.jobDetail.profile.profileName }))] })), _jsxs("div", __assign({ className: 'd-flex flex-column w-250px' }, { children: [_jsxs("p", { children: [data.jobDetail.jobType.name, " - ", data.jobDetail.totalPosts, "x (", data.schedule.sequence, "/", data.jobDetail.totalPosts, ")"] }), _jsxs("p", { children: ["Note: ", data.schedule.note || '-'] }), _jsxs("p", { children: ["Jadwal: ", FormatDateEpoch(data.schedule.scheduleDate) || '-'] })] })), _jsx("div", __assign({ className: 'd-flex flex-column w-300px' }, { children: _jsx("div", { children: _jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-success mx-2', onClick: function () { return noteItem(data.schedule.pubId, key, data.schedule.note); } }, { children: "Note this Schedule" })) }) }))] })), isOpenNote && noteId === key && (_jsx("div", __assign({ className: 'mb-5 bg-light' }, { children: _jsx("div", __assign({ className: 'px-3 py-2' }, { children: _jsxs("form", __assign({ id: 'kt_note_form', className: 'form', noValidate: true, onSubmit: NoteFormik.handleSubmit }, { children: [_jsx(RejectForm, { useForm: NoteFormik, name: 'Note' }), _jsx("p", __assign({ className: 'text-sm mb-5 text-italic', style: { marginTop: '-1rem', fontStyle: 'italic' } }, { children: "*editing this note will replace current note" })), _jsx("div", __assign({ className: 'mb-5' }, { children: _jsx("button", __assign({ type: 'submit', className: 'btn btn-primary btn-active-light-primary btn-sm mr-2', disabled: isSubmitting }, { children: "Save" })) }))] })) })) })))] }, key));
                })] }));
};
export default NoteScheduleForm;
