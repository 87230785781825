var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { FormatDateTime, paginationOptions, RoleList, userInformation } from '@src/app/helper';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import { Fragment, useState } from 'react';
import Loading from '@src/components/Atoms/Loading/Loading';
import { useSendProductTracking } from '@src/app/pages/SendProductPage/SendProductHooks/SendProduct.hooks';
import SendProductDetailTracking from '@src/app/pages/SendProductPage/SendProductDetail/SendProductDetailTracking';
import RecordAdmin from '@src/components/molecules/Record/Admin';
import { KTCard } from '@src/_metronic/helpers';
import Input from '@src/components/Atoms/Input/Input';
import ApprovalActionKirimBarang from '@src/app/modules/send-product/ApprovalActionKirimBarang';
var BarangDikirim = function (_a) {
    var datas = _a.datas, isLoading = _a.isLoading, filter = _a.filter, handlePageSizeChange = _a.handlePageSizeChange, handlePageChange = _a.handlePageChange, handleFilterChange = _a.handleFilterChange;
    var id = useParams().id;
    var intl = useIntl();
    var _b = useState(false), isOpenDetail = _b[0], setIsOpenDetail = _b[1];
    var _c = useState(''), trackingNumber = _c[0], setTrackingNumber = _c[1];
    var _d = useState(false), enabledSendProduct = _d[0], setEnabledSendProduct = _d[1];
    var sendProductTracking = useSendProductTracking(trackingNumber, enabledSendProduct).data;
    var handleDetail = function (record) {
        setTrackingNumber(record.orderNumber);
        setEnabledSendProduct(true);
        setIsOpenDetail(true);
    };
    var role = JSON.parse(userInformation).roles[0];
    var columns = [
        {
            name: 'No Pesanan',
            cell: function (record) {
                return (_jsx("div", __assign({ className: 'mt-2' }, { children: _jsx("a", __assign({ className: 'goto', onClick: function () { return handleDetail(record); } }, { children: record.orderNumber })) })));
            },
            sortable: true,
            width: '170px',
        },
        {
            name: 'Barang',
            cell: function (record) {
                return record.details.map(function (obj, key) {
                    return (_jsx("div", { children: _jsxs("p", __assign({ className: 'createdby mb-2' }, { children: ["[", obj.product.productCategory.name, "] ", obj.product.name, ", ", obj.qty, " pc(s)"] })) }, key));
                });
            },
            sortable: true,
        },
        {
            name: 'Kurir',
            cell: function (record) {
                return (_jsx("div", __assign({ className: 'd-flex gap-2 flex-column' }, { children: record.courier.name })));
            },
            sortable: true,
        },
        {
            name: 'Note',
            cell: function (record) {
                return (_jsx("div", __assign({ className: 'd-flex gap-2 flex-column' }, { children: record.note })));
            },
            sortable: true,
        },
        {
            name: 'Approved',
            cell: function (record) {
                return (_jsx("div", __assign({ className: 'd-flex gap-2 flex-column' }, { children: record.isApproved ? (_jsxs("div", __assign({ className: 'by' }, { children: [_jsx("p", __assign({ className: 'createdby mb-0' }, { children: record.approvedBy })), _jsx("p", __assign({ className: 'createdon mb-2' }, { children: FormatDateTime(record.approvedOn) }))] }))) : ('-') })));
            },
            sortable: true,
        },
        {
            name: 'Sent',
            cell: function (record) {
                return (_jsx("div", { children: record.isSent ? (_jsxs("div", __assign({ className: 'by' }, { children: [_jsx("p", __assign({ className: 'createdby mb-0' }, { children: record.sentBy })), _jsx("p", __assign({ className: 'createdon mb-2' }, { children: FormatDateTime(record.sentOn) }))] }))) : ('-') }));
            },
            sortable: true,
        },
        {
            name: 'Rejected',
            cell: function (record) {
                return (_jsx("div", { children: record.isRejected ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: 'by' }, { children: [_jsx("p", __assign({ className: 'createdby mb-0' }, { children: record.rejectedBy })), _jsx("p", __assign({ className: 'createdon mb-2' }, { children: FormatDateTime(record.rejectedOn) }))] })), _jsx("div", __assign({ className: 'by' }, { children: (record === null || record === void 0 ? void 0 : record.rejectedReason) && (_jsxs(_Fragment, { children: [_jsx("p", __assign({ className: 'createdby mb-0' }, { children: "Note:" })), _jsx("p", __assign({ className: 'createdby mb-0' }, { children: record.rejectedReason }))] })) }))] })) : ('-') }));
            },
            sortable: true,
        },
        {
            name: 'Oleh',
            cell: function (record) { return _jsx(RecordAdmin, { record: record }); },
            sortable: true,
        },
        {
            name: 'Action',
            omit: ![RoleList.Audit].includes(role),
            cell: function (record) {
                return (_jsx(_Fragment, { children: _jsx(ApprovalActionKirimBarang, { sendProductPubId: record.pubId, sendProduct: record }) }));
            },
        },
    ];
    return (_jsx(Fragment, { children: _jsx(KTCard, { children: _jsxs("div", __assign({ className: 'card' }, { children: [_jsx("div", __assign({ className: 'card-header border-0 py-0 px-0 d-flex flex-row' }, { children: _jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Input, { placeholder: 'Search', type: 'text', name: 'keyword', value: filter.keyword, onChange: function (e) {
                                    handleFilterChange(e);
                                } }) })) })), _jsx("div", __assign({ className: 'card-body p-0' }, { children: !isLoading ? (datas && datas.data && datas.data.length > 0 ? (_jsxs(_Fragment, { children: [_jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: datas ? datas.data : [], noHeader: true, progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: filter.filterBy === ''
                                        ? datas && datas.recordsTotal
                                        : datas && datas.recordsFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange }, datas && datas.data.length), _jsx(SendProductDetailTracking, { isOpen: isOpenDetail, handleClose: setIsOpenDetail, record: sendProductTracking })] })) : (_jsx(NotFound, {}))) : (_jsx(Loading, {})) }))] })) }) }));
};
export default BarangDikirim;
