export var DefaultLayoutConfig = {
    main: {
        type: 'default',
        darkSkinEnabled: false,
        primaryColor: '#7239EA',
        pageBgWhite: false,
    },
    loader: {
        display: false,
        type: 'default', // Set default|spinner-message|spinner-logo to hide or show page loader
    },
    scrolltop: {
        display: true,
    },
    header: {
        display: true,
        width: 'fluid',
        fixed: {
            desktop: true,
            tabletAndMobile: true, // Set true|false to set fixed Header for tablet and mobile modes
        },
        menuIcon: 'svg',
        menu: true,
    },
    megaMenu: {
        display: true, // Set true|false to show or hide Mega Menu
    },
    aside: {
        fixed: true,
        menuIcon: 'font',
    },
    content: {
        width: 'fluid',
        layout: 'default',
    },
    toolbar: {
        display: false, // Display toolbar
    },
    footer: {
        width: 'fluid', // Set fixed|fluid to change width type
    },
    pageTitle: {
        display: true,
        breadCrumbs: true,
        description: false,
    },
};
