var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { KTCard } from '@src/_metronic/helpers';
import { FormatDateTime, paginationOptions } from '@src/app/helper';
import { useRejectTalentsQuery } from '@src/app/pages/JobPage/JobHooks/Job.hooks';
import Loading from '@src/components/Atoms/Loading/Loading';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import { useState } from 'react';
import DataTable from 'react-data-table-component';
var AlasanReject = function (_a) {
    var talentId = _a.talentId;
    var _b = useState({
        page: 1,
        pageSize: 10,
        filterBy: "[[\"talent.pubId\", \"".concat(talentId, "\"]]"),
    }), filter = _b[0], setFilter = _b[1];
    var _c = useRejectTalentsQuery(filter, {
        enabled: !!talentId,
    }), datas = _c.data, isLoading = _c.isLoading;
    var columns = [
        {
            name: 'Alasan',
            cell: function (row) {
                return _jsx("p", { children: row.reason });
            },
        },
        {
            name: 'Oleh',
            cell: function (row) {
                return (_jsxs("div", { children: [_jsx("span", { children: "Direject oleh" }), " ", _jsx("br", {}), _jsx("span", __assign({ className: 'font-medium' }, { children: row.rejectedBy })), " ", _jsx("br", {}), _jsx("span", { children: FormatDateTime(row.rejectedOn) })] }));
            },
        },
    ];
    return (_jsx(KTCard, { children: _jsx("div", __assign({ className: 'card-body p-0' }, { children: !isLoading ? datas && datas.data && datas.data.length > 0 ? (_jsx(DataTable, { title: '', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: (datas === null || datas === void 0 ? void 0 : datas.data) || [], noHeader: true, progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: datas === null || datas === void 0 ? void 0 : datas.recordFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangePage: function (page) { return setFilter(function (prev) { return (__assign(__assign({}, prev), { page: page })); }); }, onChangeRowsPerPage: function (pageSize) { return setFilter(function (prev) { return (__assign(__assign({}, prev), { pageSize: pageSize })); }); } })) : (_jsx(NotFound, {})) : (_jsx(Loading, {})) })) }));
};
export default AlasanReject;
