var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
var TransferProofActionButton = function (_a) {
    var schedules = _a.schedules, talent = _a.talent, _b = _a.linkItem, linkItem = _b === void 0 ? function () { } : _b, _c = _a.insightItem, insightItem = _c === void 0 ? function () { } : _c, _d = _a.imgItem, imgItem = _d === void 0 ? function () { } : _d, editItem = _a.editItem;
    return (_jsx(Fragment, { children: schedules &&
            schedules.length > 0 &&
            schedules.map(function (data, i) { return (_jsxs("div", { children: [data.schedule.upLink && (_jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-primary mx-2 mb-2', onClick: function () { return linkItem(data.schedule.upLink); } }, { children: "Link" }))), data.schedule.proofUploadFileUrl && (_jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-primary mx-2 mb-2', onClick: function () { return imgItem(data.schedule.proofUploadFileUrl); } }, { children: "Lihat File" }))), _jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-warning mx-2 mb-2', onClick: function () { return insightItem(data.schedule.pubId); } }, { children: "Insight" })), editItem && (_jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-success mx-2 mb-2', onClick: function () { return editItem(data, talent.pubId, talent.tenant.pubId); } }, { children: "Edit" })))] }, i)); }) }));
};
export default TransferProofActionButton;
