var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import DrawerComponent from '@src/components/Atoms/Drawer/Drawer';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import SelectFormik from '@src/components/Atoms/Formik/Select/SelectFormik';
import MultiSelectFormik from '@src/components/Atoms/Formik/MultiSelect/MultiSelectFormik';
import { useSocialMedia } from '@src/app/pages/SocialMediaPage/SocialMedia/SocialMedia.hooks';
import { useState } from 'react';
import { useBankTransfer } from '@src/app/pages/LookupPage/LookupHooks/Lookup.hooks';
import { dummyTaxTypeOptions } from './Transfer.constant';
import { FormatYearMonthDate, optionCetakList, transferOptions } from '@src/app/helper';
import { useTransfer } from '@src/app/pages/TransferPage/Transfer/Transfer.hooks';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
var TemplateCetakSchema = Yup.object().shape({
    transferBankType: Yup.string().required('Field belum diisi'),
    bankTransferByPubId: Yup.string().required('Field belum diisi'),
});
var TemplateCetak = function (_a) {
    var isOpen = _a.isOpen, handleClose = _a.handleClose, type = _a.type;
    var socialMedia = useSocialMedia().socialMedia;
    var _b = useState([]), selectedSocialMedia = _b[0], setSelectedSocialMedia = _b[1];
    var _c = useBankTransfer(), bankTransfer = _c.bankTransfer, isLoadingBankTransfer = _c.isLoading;
    var templateCetakTransfer = useTransfer().templateCetakTransfer;
    var optionSocialMedias = socialMedia &&
        socialMedia.length > 0 &&
        socialMedia.map(function (social) { return ({
            label: social.name,
            value: social.pubId,
        }); });
    var optionBankTransfer = bankTransfer &&
        bankTransfer.data.length > 0 &&
        bankTransfer.data.map(function (bank) { return ({
            name: bank.name,
            value: bank.pubId,
        }); });
    var onChangeSocialMedia = function (e) {
        TemplateCetakFormik.setFieldValue('socialMediaPubId', e.value);
        setSelectedSocialMedia(e);
    };
    var _d = useState(dayjs().format('YYYY-MM-DD').toString()), startDate = _d[0], setStartDate = _d[1];
    var _e = useState(dayjs().format('YYYY-MM-DD').toString()), endDate = _e[0], setEndDate = _e[1];
    var onChangePaymentDue = function (e, name) {
        var event = { target: { name: '', value: null } };
        event.target.value = e == null ? '' : FormatYearMonthDate(e);
        TemplateCetakFormik.setFieldValue(name, event.target.value);
        switch (name) {
            case 'startDate':
                return setStartDate(event.target.value);
            case 'endDate':
                return setEndDate(event.target.value);
            default:
        }
    };
    var TemplateCetakValues = {
        socialMediaPubId: '',
        transferBankType: '',
        pajakCategory: '',
        bankTransferByPubId: '',
        TransferFilterType: '',
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
    };
    var TemplateCetakFormik = useFormik({
        initialValues: TemplateCetakValues,
        enableReinitialize: true,
        validationSchema: TemplateCetakSchema,
        onSubmit: function (values, _a) {
            var resetForm = _a.resetForm;
            return __awaiter(void 0, void 0, void 0, function () {
                var bankTransferByPubId, pajakCategory, socialMediaPubId, transferBankType, startDate, endDate, file, FILE_API_BASE_URL, url, e_1, error;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            bankTransferByPubId = values.bankTransferByPubId, pajakCategory = values.pajakCategory, socialMediaPubId = values.socialMediaPubId, transferBankType = values.transferBankType, startDate = values.startDate, endDate = values.endDate;
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 3, 4, 5]);
                            return [4 /*yield*/, templateCetakTransfer({
                                    socialMediaPubId: socialMediaPubId === 'All' ? '' : socialMediaPubId,
                                    bankTransferByPubId: bankTransferByPubId === 'All' ? '' : bankTransferByPubId,
                                    pajakCategory: pajakCategory === 'All' ? '' : pajakCategory,
                                    transferBankType: transferBankType,
                                    TransferFilterType: type,
                                    startDate: type == transferOptions.upcoming ? startDate : '',
                                    endDate: type == transferOptions.upcoming ? endDate : '',
                                })];
                        case 2:
                            file = _b.sent();
                            handleClose();
                            FILE_API_BASE_URL = process.env.NEXUS_FILE_API_URL;
                            url = "".concat(FILE_API_BASE_URL, "/").concat(file.pubId, "/download");
                            window.open(url, '_blank', 'noreferrer');
                            return [3 /*break*/, 5];
                        case 3:
                            e_1 = _b.sent();
                            error = e_1.response.message;
                            toast.error(error);
                            return [3 /*break*/, 5];
                        case 4:
                            resetForm();
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        },
    });
    return (_jsx(DrawerComponent, __assign({ open: isOpen, size: 'LG', handleClose: handleClose, title: 'Cetak' }, { children: _jsxs("form", __assign({ className: 'form w-100', onSubmit: TemplateCetakFormik.handleSubmit, noValidate: true, id: 'template-invoice-form' }, { children: [_jsx("div", __assign({ className: 'my-5' }, { children: optionSocialMedias && (_jsx(MultiSelectFormik, { name: 'socialMediaPubId', label: 'Sosial Media', isRequired: true, formik: TemplateCetakFormik, isMulti: false, options: __spreadArray([
                            {
                                label: 'Semua',
                                value: 'All',
                            }
                        ], optionSocialMedias, true), value: selectedSocialMedia, onchange: onChangeSocialMedia })) })), _jsx("div", __assign({ className: 'my-5' }, { children: _jsx(SelectFormik, { name: 'transferBankType', label: 'Tipe', isRequired: true, formik: TemplateCetakFormik, optionList: __spreadArray([
                            {
                                name: 'Semua',
                                value: 'All',
                            }
                        ], optionCetakList, true) }) })), _jsx("div", __assign({ className: 'my-5' }, { children: _jsx(SelectFormik, { name: 'pajakCategory', label: 'Pajak', isRequired: true, formik: TemplateCetakFormik, optionList: __spreadArray([
                            {
                                name: 'Semua',
                                value: 'All',
                            }
                        ], dummyTaxTypeOptions, true) }) })), _jsx("div", __assign({ className: 'my-5' }, { children: !isLoadingBankTransfer && optionBankTransfer && (_jsx(SelectFormik, { label: 'Transfer dari', name: 'bankTransferByPubId', isRequired: true, formik: TemplateCetakFormik, optionList: __spreadArray([
                            {
                                name: 'Semua',
                                value: 'All',
                            }
                        ], optionBankTransfer, true) })) })), type == transferOptions.upcoming && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: 'my-5' }, { children: _jsxs(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: [_jsxs("label", __assign({ className: 'form-label fs-6 fw-bolder required text-dark' }, { children: ["Payment Due Start", ' '] })), _jsx(DatePicker, { value: startDate, inputFormat: 'DD/MM/YYYY', dayOfWeekFormatter: function (day) { return day; }, onChange: function (e) {
                                            onChangePaymentDue(e, 'startDate');
                                        }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { className: 'product-datepicker form-control' }))); } })] })) })), _jsx("div", __assign({ className: 'my-5' }, { children: _jsxs(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: [_jsxs("label", __assign({ className: 'form-label fs-6 fw-bolder required text-dark' }, { children: ["Payment Due End", ' '] })), _jsx(DatePicker, { value: endDate, inputFormat: 'DD/MM/YYYY', dayOfWeekFormatter: function (day) { return day; }, onChange: function (e) {
                                            onChangePaymentDue(e, 'endDate');
                                        }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { className: 'product-datepicker form-control' }))); } })] })) }))] })), _jsx("div", __assign({ className: 'my-5' }, { children: _jsxs("div", __assign({ style: { textAlign: 'right' } }, { children: [_jsx("button", __assign({ type: 'button', className: 'btn btn-secondary btn-active-light-primary btn-sm', onClick: handleClose, disabled: TemplateCetakFormik.isSubmitting, style: { marginRight: '1rem' } }, { children: _jsx("span", __assign({ className: 'indicator-label' }, { children: "Batal" })) })), _jsxs("button", __assign({ type: 'submit', className: 'btn btn-primary btn-sm', disabled: TemplateCetakFormik.isSubmitting }, { children: [!TemplateCetakFormik.isSubmitting && _jsx("span", __assign({ className: 'indicator-label' }, { children: "Simpan" })), TemplateCetakFormik.isSubmitting && (_jsxs("span", __assign({ className: 'indicator-progress', style: { display: 'block' } }, { children: ["Mohon tunggu...", _jsx("span", { className: 'spinner-border spinner-border-sm align-middle ms-2' })] })))] }))] })) }))] })) })));
};
export default TemplateCetak;
