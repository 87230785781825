var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
var Error404 = function () {
    return (_jsx("div", __assign({ className: 'd-flex flex-column flex-root' }, { children: _jsxs("div", __assign({ className: 'd-flex flex-column flex-center flex-column-fluid p-10' }, { children: [_jsx("img", { src: '/media/illustrations/sigma-1/18-dark.png', alt: '', className: 'mw-100 mb-10 h-lg-450px' }), _jsx(Link, __assign({ to: '/', className: 'btn btn-primary' }, { children: "Return Home" }))] })) })));
};
export { Error404 };
