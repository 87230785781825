var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useIntl } from 'react-intl';
import { useState } from 'react';
import { FormatDateTime } from '@src/app/helper';
import '@src/app/styles/style.css';
import { useJobsActivity } from '../../JobHooks/Job.hooks';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
var commentValues = {
    comment: '',
};
var Activity = function (_a) {
    var datas = _a.datas, id = _a.id;
    var intl = useIntl();
    var _b = useState(''), idForEdit = _b[0], setIdForEdit = _b[1];
    var _c = useJobsActivity(id), createActivity = _c.createActivity, editActivity = _c.editActivity, deleteActivity = _c.deleteActivity;
    var _d = useState(false), isCommentActive = _d[0], setIsCommentActive = _d[1];
    var _e = useState(''), comment = _e[0], setComment = _e[1];
    var _f = useState(''), activityPubId = _f[0], setActivityPubId = _f[1];
    var handleMessageChange = function (event) {
        setComment(event.target.value);
    };
    function resetComment() {
        setComment('');
        setIsCommentActive(false);
    }
    function submitComment() {
        commentFormik.handleSubmit();
    }
    var editItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setIdForEdit(record.jobPubId);
            setComment(record.comment);
            setActivityPubId(record.pubId);
            setIsCommentActive(true);
            return [2 /*return*/];
        });
    }); };
    var deleteItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            swal({
                title: intl.formatMessage({ id: 'GENERAL.VALIDATION' }),
                text: '',
                icon: 'warning',
                buttons: [
                    intl.formatMessage({ id: 'GENERAL.VALIDATION.NO' }),
                    intl.formatMessage({ id: 'GENERAL.VALIDATION.YES' }),
                ],
                dangerMode: true,
            }).then(function (willDelete) { return __awaiter(void 0, void 0, void 0, function () {
                var e_1, error;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!willDelete) return [3 /*break*/, 5];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, 4, 5]);
                            return [4 /*yield*/, deleteActivity(__assign({ id: id }, record))];
                        case 2:
                            _a.sent();
                            return [3 /*break*/, 5];
                        case 3:
                            e_1 = _a.sent();
                            error = e_1.response.data;
                            toast.error(error.message);
                            return [3 /*break*/, 5];
                        case 4: return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            }); });
            return [2 /*return*/];
        });
    }); };
    var commentFormik = useFormik({
        initialValues: comment ? { comment: comment } : commentValues,
        enableReinitialize: true,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var e_2, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!idForEdit) return [3 /*break*/, 2];
                        return [4 /*yield*/, editActivity(__assign({ id: id, pubId: activityPubId }, values))];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, createActivity(__assign({ id: id }, values))];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        setIsCommentActive(false);
                        setIdForEdit('');
                        setComment('');
                        return [3 /*break*/, 6];
                    case 5:
                        e_2 = _a.sent();
                        error = e_2.response.data;
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        }); },
    });
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: 'form-group row' }, { children: [_jsx("div", __assign({ className: isCommentActive ? 'col-lg-9' : 'col-lg-12' }, { children: _jsx("textarea", { className: 'form-control', placeholder: 'Tambahkan komentar', value: comment, onChange: handleMessageChange, onFocus: function () { return setIsCommentActive(true); } }) })), _jsxs("div", __assign({ className: isCommentActive ? 'col-lg-3' : 'hide' }, { children: [_jsx("button", __assign({ type: 'button', className: 'btn btn-primary btn-sm', onClick: submitComment }, { children: "Simpan" })), _jsx("button", __assign({ type: 'reset', className: 'btn btn-secondary btn-sm mx-2', onClick: resetComment }, { children: "Batal" }))] }))] })), datas &&
                datas.map(function (obj, key) {
                    return (_jsx("div", __assign({ className: 'card card-bordered card-rounded-0 border-gray-400 mt-10' }, { children: _jsxs("div", __assign({ className: 'card-body px-3 py-5' }, { children: [_jsxs("div", __assign({ className: 'comment-header d-flex justify-content-between' }, { children: [_jsxs("div", { children: [_jsx("span", __assign({ className: 'fw-bold px-2' }, { children: obj.createdBy })), _jsx("span", { children: FormatDateTime(obj.createdOn) }), obj.updatedBy && obj.updatedOn && (_jsx("span", __assign({ className: 'px-2' }, { children: _jsx("small", { children: _jsxs("em", { children: ["(Updated by ", _jsx("b", { children: obj.updatedBy }), " on ", FormatDateTime(obj.updatedOn), ")"] }) }) })))] }), _jsxs("div", { children: [_jsx("a", __assign({ href: '#', className: 'btn-flush me-3', onClick: function () { return editItem(obj); } }, { children: _jsx("i", { className: 'bi bi-pencil text-warning' }) })), _jsx("a", __assign({ href: '#', className: 'btn-flush', onClick: function () { return deleteItem(obj); } }, { children: _jsx("i", { className: 'bi bi-x-lg text-danger' }) }))] })] })), _jsx("div", __assign({ className: 'comment-content px-2' }, { children: _jsx("p", { children: obj.comment }) }))] })) }), key));
                })] }));
};
export default Activity;
