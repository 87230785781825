var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Login } from './components/Login';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
export var AuthLayout = function () {
    useEffect(function () {
        document.body.style.backgroundImage = 'none';
        return function () { };
    }, []);
    return (_jsx("div", __assign({ className: 'd-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed', style: {
            backgroundImage: "url(".concat(toAbsoluteUrl('/media/illustrations/sigma-1/14.png'), ")"),
        } }, { children: _jsx("div", __assign({ className: 'd-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20' }, { children: _jsx("div", __assign({ className: 'w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto' }, { children: _jsx(Outlet, {}) })) })) })));
};
var AuthPage = function () { return (_jsx(Routes, { children: _jsxs(Route, __assign({ element: _jsx(AuthLayout, {}) }, { children: [_jsx(Route, { path: 'login', element: _jsx(Login, {}) }), _jsx(Route, { index: true, element: _jsx(Login, {}) })] })) })); };
export { AuthPage };
