var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { checkIsActive, KTSVG } from '../../../helpers';
import { useLayout } from '../../core';
import { ConditionalWrapper } from '../../../../utils';
var AsideMenuItem = function (_a) {
    var children = _a.children, to = _a.to, title = _a.title, icon = _a.icon, fontIcon = _a.fontIcon, className = _a.className, bsTitle = _a.bsTitle, _b = _a.outside, outside = _b === void 0 ? false : _b, _c = _a.hasBullet, hasBullet = _c === void 0 ? false : _c;
    var pathname = useLocation().pathname;
    var isActive = checkIsActive(pathname, to);
    var config = useLayout().config;
    var aside = config.aside;
    return (_jsx(ConditionalWrapper, __assign({ condition: bsTitle != null, wrapper: function (children) { return (_jsx(OverlayTrigger, __assign({ placement: 'right', delay: { show: 50, hide: 200 }, overlay: function (props) { return (_jsx(Tooltip, __assign({ id: 'button-tooltip' }, props, { children: bsTitle }))); } }, { children: children }))); } }, { children: _jsx("div", __assign({ className: clsx('menu-item', isActive && 'here show', className) }, { children: outside ? (_jsx("a", __assign({ href: to, target: '_blank', className: clsx('menu-link menu-center', { active: isActive }) }, { children: fontIcon && aside.menuIcon === 'font' && (_jsx("span", __assign({ className: 'menu-icon me-0' }, { children: _jsx("i", { className: clsx('bi', fontIcon, 'fs-2') }) }))) }))) : (_jsxs(_Fragment, { children: [_jsxs(Link, __assign({ className: clsx('menu-link menu-center', { active: isActive }), to: to, "data-bs-toggle": 'tooltip', "data-bs-trigger": 'hover', "data-bs-dismiss": 'click', "data-bs-placement": 'right', "data-bs-original-title": bsTitle }, { children: [hasBullet && (_jsx("span", __assign({ className: 'menu-bullet' }, { children: _jsx("span", { className: 'bullet bullet-dot' }) }))), icon && aside.menuIcon === 'svg' && (_jsx("span", __assign({ className: 'menu-icon' }, { children: _jsx(KTSVG, { path: icon, className: 'svg-icon-2' }) }))), fontIcon && aside.menuIcon === 'font' ? (_jsx("span", __assign({ className: 'menu-icon me-0' }, { children: _jsx("i", { className: clsx('bi', fontIcon, 'fs-2') }) }))) : (_jsx("span", __assign({ className: 'menu-title' }, { children: title })))] })), children] })) })) })));
};
export { AsideMenuItem };
