var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useLayout } from '../../core';
import { AsideMenu } from './AsideMenu';
var AsideDefault = function () {
    var classes = useLayout().classes;
    return (_jsxs("div", __assign({ id: 'kt_aside', className: clsx('aside bg-primary', classes.aside.join(' ')), "data-kt-drawer": 'true', "data-kt-drawer-name": 'aside', "data-kt-drawer-activate": '{default: true, lg: false}', "data-kt-drawer-overlay": 'true', "data-kt-drawer-width": 'auto', "data-kt-drawer-direction": 'start', "data-kt-drawer-toggle": '#kt_aside_toggle' }, { children: [_jsx("div", __assign({ className: 'aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-8', id: 'kt_aside_logo' }, { children: _jsx(Link, __assign({ to: '/dashboard' }, { children: _jsx("img", { src: '/media/logos/demo4.svg', alt: 'logo', className: 'h-55px' }) })) })), _jsx("div", __assign({ className: 'asaside-nav d-flex flex-column align-lg-center flex-column-fluid w-100 pt-5 pt-lg-0', id: 'kt_aside_nav' }, { children: _jsx(AsideMenu, { asideMenuCSSClasses: classes.asideMenu }) }))] })));
};
export { AsideDefault };
