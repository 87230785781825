var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormatDateTime, paginationOptions, RoleList, userInformation } from '@src/app/helper';
import ApprovalActionKirimBarang from '@src/app/modules/send-product/ApprovalActionKirimBarang';
import { SendProductContextProvider } from '@src/app/pages/SendProductPage/SendProduct/SendProduct';
import { initialSendProductValues, } from '@src/app/pages/SendProductPage/SendProduct/SendProduct.types';
import SendProductDetailTracking from '@src/app/pages/SendProductPage/SendProductDetail/SendProductDetailTracking';
import { useSendProductById, useSendProductTracking, } from '@src/app/pages/SendProductPage/SendProductHooks/SendProduct.hooks';
import '@src/app/styles/datatable.css';
import SendProductForm from '@src/components/Atoms/Drawer/SendProductFormDrawer/SendProductForm';
import Loading from '@src/components/Atoms/Loading/Loading';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import DrawerForm from '@src/components/molecules/Drawer/DrawerForm';
import { MenuComponent } from '@src/_metronic/assets/ts/components';
import { KTSVG } from '@src/_metronic/helpers';
import { useFormik } from 'formik';
import { Fragment, memo, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
var SendProductFormSchema = Yup.object().shape({});
var BarangDikirim = function (_a) {
    var _b, _c, _d, _e;
    var datas = _a.datas, createSendProduct = _a.createSendProduct, updateSendProduct = _a.updateSendProduct, isLoading = _a.isLoading, jobPubId = _a.jobPubId, talent = _a.talent, address = _a.address;
    var role = JSON.parse(userInformation).roles[0];
    //initialize filter
    var _f = useState({
        page: 1,
        pageSize: 10,
        keyword: '',
        filterBy: '',
    }), filter = _f[0], setFilter = _f[1];
    //getdata
    var _g = useState('Barang'), tabName = _g[0], setTabName = _g[1];
    var _h = useState(), selectedRecord = _h[0], setSelectedRecord = _h[1];
    var _j = useState(false), isOpenAdd = _j[0], setIsOpenAdd = _j[1];
    var _k = useState(false), isOpenDetail = _k[0], setIsOpenDetail = _k[1];
    var sendProductTracking = useSendProductTracking(selectedRecord === null || selectedRecord === void 0 ? void 0 : selectedRecord.orderNumber, !!selectedRecord).data;
    var sendProduct = useSendProductById(selectedRecord === null || selectedRecord === void 0 ? void 0 : selectedRecord.pubId, !!selectedRecord).data;
    var sendProductValues = useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return {
            talentName: ((_a = sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.talent) === null || _a === void 0 ? void 0 : _a.name) || (talent === null || talent === void 0 ? void 0 : talent.name) || '',
            talentPubId: ((_b = sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.talent) === null || _b === void 0 ? void 0 : _b.pubId) || (talent === null || talent === void 0 ? void 0 : talent.pubId) || '',
            talentAddressPubId: ((_c = sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.address) === null || _c === void 0 ? void 0 : _c.pubId) || (address === null || address === void 0 ? void 0 : address.pubId) || '',
            jobPubId: (sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.pubId) || jobPubId || '',
            courierPubId: (_e = (_d = sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.courier) === null || _d === void 0 ? void 0 : _d.pubId) !== null && _e !== void 0 ? _e : '',
            details: [],
            sendProduct: (_g = {
                products: (_f = sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.details) === null || _f === void 0 ? void 0 : _f.map(function (obj, key) { return ({
                    productPubId: obj.product.pubId,
                    productCategoryPubId: obj.product.productCategory.pubId,
                    qty: obj.qty,
                }); }),
            }) !== null && _g !== void 0 ? _g : [],
            products: (_h = sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.details) === null || _h === void 0 ? void 0 : _h.map(function (obj, key) { return ({
                productPubId: obj.product.pubId,
                productCategoryPubId: obj.product.productCategory.pubId,
                qty: obj.qty,
            }); }),
            note: (_j = sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.note) !== null && _j !== void 0 ? _j : '',
        };
    }, [sendProduct, talent, address, jobPubId]);
    var _l = useState([
        {
            productPubId: '',
            productCategoryPubId: '',
            qty: 1,
        },
    ]), newVar = _l[0], setNewVar = _l[1];
    var handlePageChange = function (page) {
        setFilter(__assign(__assign({}, filter), { page: page }));
    };
    var handlePageSizeChange = function (size) {
        setFilter(__assign(__assign({}, filter), { page: 1, pageSize: size }));
    };
    var addItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setIsOpenAdd(true);
            setSelectedRecord(undefined);
            return [2 /*return*/];
        });
    }); };
    var editItem = function (record) {
        setSelectedRecord(record);
        setIsOpenAdd(true);
    };
    var handleDetail = function (record) {
        setSelectedRecord(record);
        setIsOpenDetail(true);
    };
    var SendProductFormik = useFormik({
        initialValues: sendProduct
            ? sendProductValues
            : __assign(__assign({}, initialSendProductValues), { talentPubId: talent === null || talent === void 0 ? void 0 : talent.pubId, talentAddressPubId: address === null || address === void 0 ? void 0 : address.pubId }),
        enableReinitialize: true,
        validationSchema: SendProductFormSchema,
        onSubmit: function (values, _a) {
            var resetForm = _a.resetForm;
            return __awaiter(void 0, void 0, void 0, function () {
                var courierPubId, jobPubId, note, sendProduct, talentAddressPubId, talentPubId, products, formData, e_1, error;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            courierPubId = values.courierPubId, jobPubId = values.jobPubId, note = values.note, sendProduct = values.sendProduct, talentAddressPubId = values.talentAddressPubId, talentPubId = values.talentPubId;
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 6, 7, 8]);
                            products = sendProduct.products;
                            formData = {
                                courierPubId: courierPubId,
                                jobPubId: jobPubId,
                                note: note,
                                products: products,
                                talentAddressPubId: talentAddressPubId,
                                talentPubId: talentPubId,
                            };
                            if (!selectedRecord) return [3 /*break*/, 3];
                            return [4 /*yield*/, updateSendProduct(__assign({ id: selectedRecord.pubId }, formData))];
                        case 2:
                            _b.sent();
                            return [3 /*break*/, 5];
                        case 3: return [4 /*yield*/, createSendProduct(formData)];
                        case 4:
                            _b.sent();
                            _b.label = 5;
                        case 5: return [3 /*break*/, 8];
                        case 6:
                            e_1 = _b.sent();
                            error = e_1.response.data;
                            toast.error(error);
                            return [3 /*break*/, 8];
                        case 7:
                            setIsOpenAdd(false);
                            resetForm();
                            return [7 /*endfinally*/];
                        case 8: return [2 /*return*/];
                    }
                });
            });
        },
    });
    useEffect(function () {
        MenuComponent.reinitialization();
    }, []);
    var columns = [
        {
            name: 'No Pesanan',
            cell: function (record) {
                return (_jsx("div", __assign({ className: 'mt-2' }, { children: _jsx("a", __assign({ className: 'goto', onClick: function () { return handleDetail(record); } }, { children: record.orderNumber })) })));
            },
            sortable: true,
            width: '170px',
        },
        {
            name: 'Barang',
            cell: function (record) {
                return record.details.map(function (obj, key) {
                    return (_jsx("div", { children: _jsxs("p", __assign({ className: 'createdby mb-2' }, { children: ["[", obj.product.productCategory.name, "] ", obj.product.name, ", ", obj.qty, " pc(s)"] })) }, key));
                });
            },
            sortable: true,
        },
        {
            name: 'Kurir',
            cell: function (record) {
                return (_jsx("div", __assign({ className: 'd-flex gap-2 flex-column' }, { children: record.courier.name })));
            },
            sortable: true,
        },
        {
            name: 'Note',
            cell: function (record) {
                return (_jsx("div", __assign({ className: 'd-flex gap-2 flex-column' }, { children: record.note })));
            },
            sortable: true,
        },
        {
            name: 'Approved',
            cell: function (record) {
                return (_jsx("div", __assign({ className: 'd-flex gap-2 flex-column' }, { children: record.isApproved ? (_jsxs("div", __assign({ className: 'by' }, { children: [_jsx("p", __assign({ className: 'createdby mb-0' }, { children: record.approvedBy })), _jsx("p", __assign({ className: 'createdon mb-2' }, { children: FormatDateTime(record.approvedOn) }))] }))) : ('-') })));
            },
            sortable: true,
        },
        {
            name: 'Sent',
            cell: function (record) {
                return (_jsx("div", { children: record.isSent ? (_jsxs("div", __assign({ className: 'by' }, { children: [_jsx("p", __assign({ className: 'createdby mb-0' }, { children: record.sentBy })), _jsx("p", __assign({ className: 'createdon mb-2' }, { children: FormatDateTime(record.sentOn) }))] }))) : ('-') }));
            },
            sortable: true,
        },
        {
            name: 'Rejected',
            cell: function (record) {
                return (_jsx("div", { children: record.isRejected ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: 'by' }, { children: [_jsx("p", __assign({ className: 'createdby mb-0' }, { children: record.rejectedBy })), _jsx("p", __assign({ className: 'createdon mb-2' }, { children: FormatDateTime(record.rejectedOn) }))] })), _jsx("div", __assign({ className: 'by' }, { children: (record === null || record === void 0 ? void 0 : record.rejectedReason) && (_jsx("p", __assign({ className: 'createdby mb-0' }, { children: record.rejectedReason }))) }))] })) : ('-') }));
            },
            sortable: true,
        },
        {
            name: 'Oleh',
            cell: function (record) {
                return (_jsxs("div", __assign({ className: 'mb-3' }, { children: [_jsxs("div", { children: [_jsx("p", { children: "Dibuat Oleh:" }), _jsx("p", { children: record.createdBy || '-' }), _jsx("p", { children: FormatDateTime(record.createdOn) })] }), _jsx("hr", {}), _jsx("div", { children: record.updatedOn ? (_jsxs(_Fragment, { children: [_jsx("p", __assign({ className: 'title' }, { children: "Diubah Oleh:" })), _jsx("p", { children: record.updatedBy }), _jsx("p", __assign({ className: 'mb-1' }, { children: FormatDateTime(record.updatedOn) }))] })) : ('-') })] })));
            },
            sortable: true,
        },
        {
            name: 'Action',
            cell: function (record) {
                return (_jsxs(_Fragment, { children: [!(record === null || record === void 0 ? void 0 : record.job.isApproved) &&
                            !(record === null || record === void 0 ? void 0 : record.job.isApprovedLeader) &&
                            !(record === null || record === void 0 ? void 0 : record.job.isCompleted) &&
                            (record === null || record === void 0 ? void 0 : record.job.isCanceled) && (_jsx("div", { children: _jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-secondary', onClick: function () { return editItem(record); } }, { children: "Edit" })) })), _jsx(ApprovalActionKirimBarang, { sendProductPubId: record.pubId, sendProduct: record })] }));
            },
            width: '150px',
            omit: role === RoleList.CampaignExecutive || role === RoleList.Audit ? false : true,
        },
    ];
    return (_jsxs(Fragment, { children: [_jsxs("div", __assign({ className: 'card card-px-0' }, { children: [_jsx("div", __assign({ className: 'card-toolbar justify-content-end mb-5' }, { children: _jsx("div", __assign({ className: 'd-flex justify-content-end', "data-kt-talentcategory-table-toolbar": 'base' }, { children: ((role === RoleList.CampaignExecutive &&
                                datas &&
                                !((_b = datas.data[0]) === null || _b === void 0 ? void 0 : _b.job.isApproved) &&
                                !((_c = datas.data[0]) === null || _c === void 0 ? void 0 : _c.job.isApproved) &&
                                !((_d = datas.data[0]) === null || _d === void 0 ? void 0 : _d.job.isApproved) &&
                                ((_e = datas.data[0]) === null || _e === void 0 ? void 0 : _e.job.isCanceled)) ||
                                role === RoleList.Audit) && (_jsx("div", __assign({ className: 'me-3' }, { children: _jsxs("button", __assign({ type: 'button', className: 'btn btn-sm btn-primary', onClick: addItem }, { children: [_jsx(KTSVG, { path: '/media/icons/duotune/arrows/arr075.svg', className: 'svg-icon-2' }), "Tambah"] })) }))) })) })), _jsx("div", __assign({ className: 'card-body py-0' }, { children: !isLoading ? (datas && datas.data && datas.data.length > 0 ? (_jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: datas ? datas.data : [], noHeader: true, progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: datas && datas.recordsTotal, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange })) : (_jsx(NotFound, {}))) : (_jsx(Loading, {})) }))] })), _jsx(SendProductContextProvider, __assign({ currentData: selectedRecord }, { children: _jsx(DrawerForm, { tabName: tabName, useForm: SendProductFormik, isOpen: isOpenAdd, handleClose: setIsOpenAdd, onSubmit: SendProductFormik.handleSubmit, component: SendProductForm, idForEdit: (selectedRecord === null || selectedRecord === void 0 ? void 0 : selectedRecord.pubId) || '', size: 'LG', editableValue: newVar, setEditableValue: setNewVar }) })), _jsx(SendProductDetailTracking, { isOpen: isOpenDetail, handleClose: setIsOpenDetail, record: sendProductTracking })] }));
};
export default memo(BarangDikirim);
