var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { KTSVG } from '../../../helpers';
import { HeaderUserMenu } from '../../../partials';
import { useLayout } from '../../core';
import { useAuth } from '@src/app/pages/auth';
var itemClass = 'ms-1 ms-lg-3', userAvatarClass = 'symbol-30px symbol-md-40px';
var Topbar = function () {
    var config = useLayout().config;
    var currentUser = useAuth().currentUser;
    return (_jsxs("div", __assign({ className: 'd-flex align-items-stretch justify-self-end flex-shrink-0' }, { children: [_jsxs("div", __assign({ className: clsx('d-flex align-items-center', itemClass), id: 'kt_header_user_menu_toggle' }, { children: [_jsx("div", __assign({ className: clsx('cursor-pointer symbol', userAvatarClass), "data-kt-menu-trigger": 'click', "data-kt-menu-attach": 'parent', "data-kt-menu-placement": 'bottom-end' }, { children: _jsx("div", __assign({ className: 'symbol-label fs-3 bg-light-info text-info' }, { children: (currentUser === null || currentUser === void 0 ? void 0 : currentUser.userId) && currentUser.userName.charAt(0) })) })), _jsx(HeaderUserMenu, {})] })), config.header.left === 'menu' && (_jsx("div", __assign({ className: 'd-flex align-items-center d-lg-none ms-2 me-n3', title: 'Show header menu' }, { children: _jsx("div", __assign({ className: 'btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px', id: 'kt_header_menu_mobile_toggle' }, { children: _jsx(KTSVG, { path: '/media/icons/duotune/text/txt001.svg', className: 'svg-icon-1' }) })) })))] })));
};
export { Topbar };
