var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider } from '@mui/material';
import DrawerComponent from '@src/components/Atoms/Drawer/Drawer';
import { memo } from 'react';
var DrawerForm = function (_a) {
    var useForm = _a.useForm, tabName = _a.tabName, isOpen = _a.isOpen, handleClose = _a.handleClose, onSubmit = _a.onSubmit, component = _a.component, idForEdit = _a.idForEdit, size = _a.size, name = _a.name, editableValue = _a.editableValue, setEditableValue = _a.setEditableValue, _b = _a.initialValues, initialValues = _b === void 0 ? [] : _b;
    var Component = component;
    return (_jsx(DrawerComponent, __assign({ size: size, open: isOpen, handleClose: function () { return handleClose(false); }, title: !idForEdit ? "Tambah ".concat(tabName) : "Edit ".concat(tabName) }, { children: _jsx("div", { children: _jsxs("form", __assign({ id: 'transfer-form', className: 'form', noValidate: true, onSubmit: onSubmit }, { children: [_jsx(Component, { useForm: useForm, idForEdit: idForEdit, name: name, editableValue: editableValue, setEditableValue: setEditableValue, initialValues: initialValues }), _jsx(Divider, {}), _jsx("div", __assign({ className: 'my-5' }, { children: _jsxs("div", __assign({ style: { textAlign: 'right' } }, { children: [_jsx("button", __assign({ type: 'button', className: 'btn btn-secondary btn-active-light-primary btn-sm', onClick: function () { return handleClose(false); }, style: { marginRight: '1rem' }, disabled: useForm.isSubmitting }, { children: "Batal" })), _jsxs("button", __assign({ type: 'submit', className: 'btn btn-primary btn-active-light-primary mr-2 btn-sm', disabled: useForm.isSubmitting }, { children: [!useForm.isSubmitting && _jsx("span", __assign({ className: 'indicator-label' }, { children: "Simpan" })), useForm.isSubmitting && (_jsxs("span", __assign({ className: 'indicator-progress', style: { display: 'block' } }, { children: ["Mohon tunggu...", _jsx("span", { className: 'spinner-border spinner-border-sm align-middle ms-2' })] })))] }))] })) }))] })) }) })));
};
export default memo(DrawerForm);
