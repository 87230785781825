var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Divider, Typography } from '@mui/material';
import DrawerComponent from '@src/components/Atoms/Drawer/Drawer';
var DrawerDataTable = function (_a) {
    var _b, _c, _d;
    var isOpen = _a.isOpen, handleClose = _a.handleClose, record = _a.record, component = _a.component, size = _a.size, title = _a.title, refetch = _a.refetch;
    var Component = component;
    return (_jsx(DrawerComponent, __assign({ size: size, title: title, open: isOpen, handleClose: function () { return handleClose(false); } }, { children: _jsxs(_Fragment, { children: [title == 'Detail' ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: 'mb-3' }, { children: [_jsx("div", { children: _jsx(Typography, __assign({ variant: 'body1' }, { children: "Nama Talent" })) }), _jsx("div", { children: _jsx(Typography, __assign({ variant: 'h6' }, { children: (_b = record === null || record === void 0 ? void 0 : record.talent) === null || _b === void 0 ? void 0 : _b.name })) })] })), _jsxs("div", __assign({ className: 'mb-3' }, { children: [_jsx("div", { children: _jsx(Typography, __assign({ variant: 'body1' }, { children: "Contact Person" })) }), _jsxs("div", { children: [_jsx(Typography, __assign({ variant: 'h6' }, { children: (_c = record === null || record === void 0 ? void 0 : record.talent) === null || _c === void 0 ? void 0 : _c.cpName })), _jsx(Typography, __assign({ variant: 'h6' }, { children: (_d = record === null || record === void 0 ? void 0 : record.talent) === null || _d === void 0 ? void 0 : _d.cpPhoneNumber }))] })] })), _jsx(Divider, {})] })) : undefined, _jsx(Component, { record: record, handleClose: handleClose, refetch: refetch }), _jsx("div", __assign({ className: 'my-5' }, { children: _jsx("div", __assign({ style: { textAlign: 'right' } }, { children: _jsx("button", __assign({ type: 'button', className: 'btn btn-secondary btn-active-light-primary btn-sm', onClick: function () { return handleClose(false); }, style: { marginRight: '1rem' } }, { children: "Tutup" })) })) }))] }) })));
};
export default DrawerDataTable;
