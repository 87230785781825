var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth } from '@src/app/pages/auth';
import { Accounts } from '@src/app/pages/auth/components/Accounts';
import { Link } from 'react-router-dom';
export default function MenuAccountSwitcher() {
    var _a = useAuth(), getAllAuth = _a.getAllAuth, currentUser = _a.currentUser;
    var auths = getAllAuth();
    var filteredAuths = auths.filter(function (auth) { return auth.data.userName !== (currentUser === null || currentUser === void 0 ? void 0 : currentUser.userName); });
    return (_jsxs("div", __assign({ className: 'px-5' }, { children: [_jsxs("div", __assign({ className: 'd-flex justify-content-between align-items-center mb-3' }, { children: [filteredAuths.length > 0 && _jsx("div", { children: "Ganti Akun" }), _jsx(Link, __assign({ to: '/account/add' }, { children: "Tambah Akun" }))] })), filteredAuths.length > 0 && (_jsx("div", __assign({ className: 'd-flex flex-column p-4', style: {
                    background: '#f7f8fa',
                    borderRadius: '8px',
                    maxHeight: '300px',
                    overflowY: 'scroll',
                } }, { children: _jsx(Accounts, { auths: filteredAuths }) })))] })));
}
