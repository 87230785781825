var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import SelectFormik from '../../Formik/Select/SelectFormik';
import FileFormik from '../../Formik/Input/FileFormik';
import Date from '../../Date/Date';
import { FormatDate } from '@src/app/helper';
import { useBankTransfer } from '@src/app/pages/LookupPage/LookupHooks/Lookup.hooks';
var SudahTransferForm = function (_a) {
    var useForm = _a.useForm;
    var _b = useBankTransfer(), bankTransfer = _b.bankTransfer, isLoadingBankTransfer = _b.isLoading;
    var _c = useState(), date = _c[0], setDate = _c[1];
    var _d = useState({
        proofTransferFilePubId: '',
    }), files = _d[0], setFiles = _d[1];
    var onChangeFiles = function (e) {
        var _a;
        setFiles(__assign(__assign({}, files), (_a = {}, _a[e.target.name] = e.target.files, _a)));
        useForm.setFieldValue(e.target.name, e.target.files);
    };
    var onChangeDate = function (e) {
        var val = FormatDate(e).split('/').reverse().join('-');
        setDate(e);
        useForm.setFieldValue('transferDate', val);
    };
    var optionBankTransfer = bankTransfer &&
        bankTransfer.data.length > 0 &&
        bankTransfer.data.map(function (bank) { return ({
            name: bank.name,
            value: bank.pubId,
        }); });
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'mb-3' }, { children: [_jsx("div", { children: _jsx(FileFormik, { label: 'Bukti Transfer', placeholder: 'Bukti Transfer', name: 'proofTransferFilePubId', formik: useForm, onChange: onChangeFiles }) }), _jsxs("div", { children: [_jsx("label", __assign({ className: "form-label fs-6 fw-bolder text-dark required" }, { children: "Tanggal" })), _jsx(Date, { date: date, handleonchange: onChangeDate, useForm: useForm, name: 'transferDate' })] }), _jsx("div", { children: !isLoadingBankTransfer && optionBankTransfer && (_jsx("div", __assign({ className: 'my-5' }, { children: _jsx(SelectFormik, { label: 'Transfer dari', name: 'bankTransferByPubId', isRequired: true, formik: useForm, optionList: optionBankTransfer }) }))) })] })) }));
};
export default SudahTransferForm;
