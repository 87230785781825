var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
var I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig';
var initialState = {
    selectedLang: 'en',
};
function getConfig() {
    var ls = localStorage.getItem(I18N_CONFIG_KEY);
    if (ls) {
        try {
            return JSON.parse(ls);
        }
        catch (er) {
            console.error(er);
        }
    }
    return initialState;
}
// Side effect
export function setLanguage(lang) {
    localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
    window.location.reload();
}
var I18nContext = createContext(initialState);
var useLang = function () {
    return useContext(I18nContext).selectedLang;
};
var MetronicI18nProvider = function (_a) {
    var children = _a.children;
    var lang = getConfig();
    return _jsx(I18nContext.Provider, __assign({ value: lang }, { children: children }));
};
export { MetronicI18nProvider, useLang };
