var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeUrl } from '@src/utils/commonUtils';
import styles from './Record.module.scss';
var RecordTalent = function (_a) {
    var pubId = _a.pubId, name = _a.name, cpName = _a.cpName, cpPhoneNumber = _a.cpPhoneNumber, cpEmail = _a.cpEmail, profiles = _a.profiles, onClick = _a.onClick;
    return (_jsxs("div", __assign({ className: 'mt-3' }, { children: [_jsxs("div", { children: [_jsx("p", { children: cpName !== null && cpName !== void 0 ? cpName : '' }), _jsx("p", { children: cpPhoneNumber !== null && cpPhoneNumber !== void 0 ? cpPhoneNumber : '' }), _jsx("p", { children: cpEmail !== null && cpEmail !== void 0 ? cpEmail : '' })] }), _jsxs("div", { children: [_jsx("p", { children: "Talent:" }), _jsx("a", __assign({ href: "/talent/detail/".concat(pubId), className: styles['record-talent__name'], onClick: function () { return onClick(pubId); } }, { children: name }))] }), profiles && profiles.length > 0 && (_jsxs("div", __assign({ className: styles['record-socmed__section'] }, { children: [_jsx("p", __assign({ className: 'mb-1 mt-3' }, { children: "Profile:" })), _jsx("div", { children: profiles.map(function (obj, key) {
                            return (_jsxs("div", __assign({ className: 'socialmedianame mt-2' }, { children: ["".concat(obj.socialMedia.name, ": "), _jsx("a", __assign({ className: 'goto', href: makeUrl(obj.profileLink), target: '_blank', rel: 'noopener noreferrer' }, { children: obj.profileName }))] }), key));
                        }) })] })))] })));
};
export default RecordTalent;
