var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import InputFormik from '@src/components/Atoms/Formik/Input/InputFormik';
import MultiSelectFormik from '../../Formik/MultiSelect/MultiSelectFormik';
import { useState, useEffect } from 'react';
import { useCities } from '@src/app/pages/LookupPage/LookupHooks/Lookup.hooks';
var AddressForm = function (_a) {
    var useForm = _a.useForm, idForEdit = _a.idForEdit;
    var cities = useCities().cities;
    var _b = useState([]), selectedCity = _b[0], setSelectedCity = _b[1];
    var optionCities = cities &&
        cities.data &&
        cities.data.length > 0 &&
        cities.data.map(function (city) { return ({
            label: city.name,
            value: city.pubId,
        }); });
    var onChangeCity = function (e) {
        setSelectedCity(e);
        useForm.values.cityPubId = e.value;
    };
    var cityOnEdit = cities &&
        cities.data &&
        cities.data.length > 0 &&
        cities.data
            .filter(function (city) { return city.pubId === useForm.values.cityPubId; })
            .map(function (obj) { return ({
            label: obj.name,
            value: obj.pubId,
        }); });
    useEffect(function () {
        cityOnEdit && setSelectedCity(cityOnEdit); //set city on edit
    }, [cities]);
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: 'mb-3' }, { children: _jsx("div", { children: _jsx(InputFormik, { label: 'Nama Penerima', type: 'text', placeholder: 'Nama Penerima', name: 'recipientName', formik: useForm, isRequired: true }) }) })), _jsx("div", __assign({ className: 'mb-3' }, { children: _jsx("div", { children: _jsx(InputFormik, { label: 'Nomor Penerima', type: 'text', placeholder: 'Nomor Penerima', name: 'recipientPhoneNumber', formik: useForm, isRequired: true }) }) })), _jsx("div", __assign({ className: 'mb-5' }, { children: _jsx("div", { children: _jsx(InputFormik, { label: 'Alamat', type: 'text', placeholder: 'Alamat', name: 'address', formik: useForm, isRequired: true }) }) })), _jsx("div", __assign({ className: 'mb-3' }, { children: _jsx("div", { children: optionCities && (_jsx(MultiSelectFormik, { name: 'cityPubId', label: 'Kota/Kabupaten', formik: useForm, isMulti: false, options: optionCities, value: selectedCity, onchange: onChangeCity })) }) }))] }));
};
export default AddressForm;
