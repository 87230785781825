var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useProducts } from '@src/app/pages/ProductPage/Product/Product.hooks';
import InputFormik from '@src/components/Atoms/Formik/Input/InputFormik';
import MultiSelectFormik from '@src/components/Atoms/Formik/MultiSelect/MultiSelectFormik';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import styles from './ProductForm.module.scss';
var ProductForm = function (_a) {
    var _b;
    var useForm = _a.useForm, index = _a.index, productCategoryList = _a.productCategoryList, removeProduct = _a.removeProduct, onchangecategory = _a.onchangecategory, onchangeproduct = _a.onchangeproduct, selectedcategory = _a.selectedcategory, selectedproduct = _a.selectedproduct, handleBlur = _a.handleBlur, selectCategory = _a.selectCategory, selectProduct = _a.selectProduct, _c = _a.name, name = _c === void 0 ? 'details' : _c, isEdit = _a.isEdit;
    var _d = useState({
        page: 1,
        pageSize: 1000,
        keyword: '',
        filterBy: '',
        initialFilterBy: '[["recordStatus", "Active"]]',
    }), filterProducts = _d[0], setFilterProducts = _d[1];
    var debouncedSearchProduct = useDebounce(filterProducts.keyword, 500)[0];
    var products = useProducts(__assign(__assign({}, filterProducts), { filterBy: "[[\"productCategory.pubId\", \"".concat(selectedcategory === null || selectedcategory === void 0 ? void 0 : selectedcategory.value, "\"]]"), keyword: debouncedSearchProduct }), !!selectedcategory).products;
    var optionProducts = (products === null || products === void 0 ? void 0 : products.data) &&
        products.data.length > 0 &&
        products.data.map(function (product) { return ({
            label: product.name,
            value: product.pubId,
        }); });
    var categoryOnEdit = isEdit !== undefined &&
        useForm.values.details &&
        useForm.values.details.map(function (obj) { return ({
            label: obj.product.productCategory.name,
            value: obj.product.productCategory.pubId,
        }); });
    var productOnEdit = isEdit !== undefined &&
        useForm.values.details &&
        useForm.values.details.map(function (obj) { return ({
            label: obj.product.name,
            value: obj.product.pubId,
        }); });
    var qtydata = {};
    isEdit !== undefined &&
        useForm.values.details.forEach(function (v, k) {
            var key = 'qty' + k;
            qtydata[key] = v.qty;
        });
    useForm.values = __assign(__assign({}, useForm.values), __assign({}, qtydata));
    useEffect(function () {
        isEdit !== undefined && selectCategory(categoryOnEdit); //set category on edit
        isEdit !== undefined && selectProduct(productOnEdit); //set product on edit
    }, []);
    return (_jsxs("div", __assign({ className: styles['profile-container'] }, { children: [_jsxs("div", __assign({ className: 'row' }, { children: [_jsx("div", __assign({ className: 'col-md-4 w-200px' }, { children: _jsx(MultiSelectFormik, { name: "".concat(name, "[").concat(index, "].productCategoryPubId"), label: 'Kategori', formik: useForm, isMulti: false, options: productCategoryList, value: selectedcategory, onchange: function (e) {
                                onchangecategory(e, index);
                            }, isChildField: true }) })), _jsx("div", __assign({ className: 'col-md-4 w-200px' }, { children: _jsx(MultiSelectFormik, { name: "".concat(name, "[").concat(index, "].productPubId"), label: 'Barang', formik: useForm, isMulti: false, options: optionProducts || [], value: selectedproduct, onchange: function (e) {
                                onchangeproduct(e, index);
                            }, isChildField: true, onInputChange: function (value) { return setFilterProducts(function (prev) { return (__assign(__assign({}, prev), { keyword: value })); }); } }) })), _jsx("div", __assign({ className: 'col-md-4 w-200px' }, { children: _jsx(InputFormik, { label: 'Qty', type: 'text', placeholder: 'Qty', name: "".concat(name, "[").concat(index, "].qty"), formik: useForm, onblur: function (e) {
                                handleBlur(e, index);
                            }, isChildField: true }) }))] })), ((_b = useForm.values.sendProduct) === null || _b === void 0 ? void 0 : _b.products.length) > 1 && (_jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-danger', onClick: function () { return removeProduct(index); } }, { children: _jsx("i", { className: 'fa fa-trash p-0' }) })))] })));
};
export default ProductForm;
