export var TalentStatusList;
(function (TalentStatusList) {
    TalentStatusList[TalentStatusList["NotYet"] = 0] = "NotYet";
    TalentStatusList[TalentStatusList["OnGoing"] = 1] = "OnGoing";
    TalentStatusList[TalentStatusList["Done"] = 2] = "Done";
    TalentStatusList[TalentStatusList["Cancelled"] = 3] = "Cancelled";
    TalentStatusList[TalentStatusList["Rejected"] = 4] = "Rejected";
})(TalentStatusList || (TalentStatusList = {}));
export var talentStatuses = Object.values(TalentStatusList);
export var TalentStatusNameList;
(function (TalentStatusNameList) {
    TalentStatusNameList["NotYet"] = "Belum Kerja Sama";
    TalentStatusNameList["OnGoing"] = "On Going";
    TalentStatusNameList["Done"] = "Sudah Selesai";
    TalentStatusNameList["Cancelled"] = "Dibatalkan";
    TalentStatusNameList["Rejected"] = "Ditolak";
})(TalentStatusNameList || (TalentStatusNameList = {}));
export var talentStatusName = Object.keys(TalentStatusNameList);
//initial values
export var TalentStatusOptions = [
    {
        value: TalentStatusList.NotYet,
        name: TalentStatusNameList.NotYet,
    },
    {
        value: TalentStatusList.OnGoing,
        name: TalentStatusNameList.OnGoing,
    },
    {
        value: TalentStatusList.Done,
        name: TalentStatusNameList.Done,
    },
    {
        value: TalentStatusList.Cancelled,
        name: TalentStatusNameList.Cancelled,
    },
    {
        value: TalentStatusList.Rejected,
        name: TalentStatusNameList.Rejected,
    },
];
