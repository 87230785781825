var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Divider, Typography } from '@mui/material';
import { FormatDateTime } from '@src/app/helper';
import { makeUrl } from '@src/utils/commonUtils';
var ProfileHistoryForm = function (_a) {
    var datas = _a.datas, handleClose = _a.handleClose;
    return (_jsxs("div", { children: [datas && datas.length > 0 && (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: 'd-flex flex-row border border-dashed border-gray-200 px-2 py-2' }, { children: [_jsx(Typography, __assign({ variant: 'body1', className: 'd-flex flex-column w-250px fw-bold' }, { children: "Nama" })), _jsx(Typography, __assign({ variant: 'body1', className: 'd-flex flex-column w-250px fw-bold mx-4' }, { children: "Link" })), _jsx(Typography, __assign({ variant: 'body1', className: 'd-flex flex-column w-300px fw-bold' }, { children: "Action" }))] })), datas.map(function (obj, i) { return (_jsx("div", { children: _jsxs("div", __assign({ className: 'd-flex flex-row border border-dashed border-gray-200 px-2 py-2' }, { children: [_jsx("div", __assign({ className: 'd-flex flex-column w-250px' }, { children: _jsx(Typography, __assign({ variant: 'h6', style: { wordBreak: 'break-word' } }, { children: obj.profileName })) })), _jsx("div", __assign({ className: 'd-flex flex-column w-250px mx-4' }, { children: _jsx(Typography, __assign({ variant: 'h6', style: { wordBreak: 'break-word' } }, { children: _jsx("a", __assign({ className: 'goto', href: makeUrl(obj.profileLink), target: '_blank', rel: 'noopener noreferrer' }, { children: obj.profileLink })) })) })), _jsxs("div", __assign({ className: 'd-flex flex-column w-300px' }, { children: [_jsx(Typography, __assign({ variant: 'h6' }, { children: obj.createdBy })), _jsx(Typography, __assign({ variant: 'h6' }, { children: FormatDateTime(obj.createdOn) }))] }))] })) }, i)); })] })), _jsx(Divider, {}), datas && datas.length > 5 && (_jsx("div", __assign({ className: 'my-5' }, { children: _jsx("div", __assign({ style: { textAlign: 'right' } }, { children: _jsx("button", __assign({ type: 'button', className: 'btn btn-secondary btn-active-light-primary', onClick: function () { return handleClose(false); } }, { children: "Tutup" })) })) })))] }));
};
export default ProfileHistoryForm;
