export var RekapList = {
    dibuat: 'Per Dibuat',
    dikirim: 'Per Dikirim',
};
export var optionRekapList = [
    {
        value: 'createdOn',
        name: RekapList.dibuat,
    },
    {
        value: 'sentOn',
        name: RekapList.dikirim,
    },
];
export var PajakList = {
    dibuat: 'Dibuat tanggal',
    dibayar: 'Dibayar tanggal',
};
export var optionPajakList = [
    {
        value: 'createdOn',
        name: PajakList.dibuat,
    },
    {
        value: 'TransferredOn ',
        name: PajakList.dibayar,
    },
];
