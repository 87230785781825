var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
var DoneTransfer = function (_a) {
    var record = _a.record, uploadTransferItem = _a.uploadTransferItem, wrongTransferItem = _a.wrongTransferItem, proofItem = _a.proofItem;
    return (_jsx(Fragment, { children: _jsxs("div", __assign({ className: 'text-center' }, { children: [_jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-warning mb-2', onClick: function () { return wrongTransferItem(record); } }, { children: "Salah Transfer" })), _jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-primary mx-2 mb-2', onClick: function () { return uploadTransferItem(record); } }, { children: "Upload Bukti Transfer" })), proofItem && (_jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-success mx-2', onClick: function () { return proofItem(record); } }, { children: "View Bukti Upload" })))] })) }));
};
export default DoneTransfer;
