var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes, Outlet } from 'react-router-dom';
import { Error500 } from './components/Error500';
import { Error404 } from './components/Error404';
import { Error403 } from './components/Error403';
var ErrorsLayout = function () {
    return _jsx(Outlet, {});
};
var ErrorsPage = function () { return (_jsx(Routes, { children: _jsxs(Route, __assign({ element: _jsx(ErrorsLayout, {}) }, { children: [_jsx(Route, { path: '404', element: _jsx(Error404, {}) }), _jsx(Route, { path: '403', element: _jsx(Error403, {}) }), _jsx(Route, { path: '500', element: _jsx(Error500, {}) }), _jsx(Route, { index: true, element: _jsx(Error404, {}) })] })) })); };
export { ErrorsPage };
