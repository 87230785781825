export var initialValue = {
    createdBy: '',
    createdOn: 0,
    deletedBy: '',
    deletedOn: '',
    profileLink: '',
    profileName: '',
    recordStatus: 0,
    socialMedia: {
        pubId: '',
        name: '',
    },
    name: '',
    pubId: '',
    updatedBy: '',
    updatedOn: '',
};
export var AddressValues = {
    recipientName: '',
    recipientPhoneNumber: '',
    address: '',
    cityPubId: '',
    city: {
        name: '',
        pubId: '',
    },
};
export var BankAccountValues = {
    bankPubId: '',
    bank: {
        name: '',
        pubId: '',
    },
    accountNumber: '',
    accountName: '',
    branchName: '',
    cityPubId: '',
    city: {
        name: '',
        pubId: '',
    },
};
export var AttachmentValues = {
    name: '',
    jobPubId: '',
    talentPubId: '',
    filePubId: '',
    files: '', //for upload file
};
