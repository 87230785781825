var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useEffect } from 'react';
var InputFormik = function (_a) {
    var label = _a.label, placeholder = _a.placeholder, type = _a.type, name = _a.name, formik = _a.formik, isRequired = _a.isRequired, onblur = _a.onblur, _b = _a.formatNumber, formatNumber = _b === void 0 ? false : _b, _c = _a.isChildField, isChildField = _c === void 0 ? false : _c, _d = _a.isNPWP, isNPWP = _d === void 0 ? false : _d, maxlength = _a.maxlength, onfocus = _a.onfocus, min = _a.min, max = _a.max, _e = _a.disabled, disabled = _e === void 0 ? false : _e, errorMessage = _a.errorMessage;
    var frontIndex = '';
    var indexNumber = '';
    var lastIndex = '';
    var dataValue = '';
    if (isChildField) {
        var fieldLength = name.split('.').length;
        indexNumber = parseInt(name.replace(/\D/g, ''));
        frontIndex = name.split('.')[0].replace("[".concat(indexNumber, "]"), '');
        lastIndex = name.split('.')[1];
        // this function to get the final field value if the name contains dot without index, like bank.accountNumber
        var getFieldValueFromKey = function (keyString) {
            // check if the key contains .
            if (keyString.includes('.')) {
                var keys = keyString.split('.');
                var value = formik.values;
                // loop the section until have final value like formik.values[bank][accountNumber]
                for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
                    var key = keys_1[_i];
                    value = value[key];
                    if (value === undefined) {
                        // Return '' if any key in the path is not found
                        return '';
                    }
                }
                return value;
            }
            else {
                // just return the final value if the name doesnt contains dot, ex: formik.values[bank]
                return formik.values[keyString];
            }
        };
        if (fieldLength > 2) {
            // this pattern to check if the child field contains name with square bracket in their midname such as sendProducts.products.qty
            var squareBracketPattern = /\[(.*?)\]/;
            // split last index to get parent's field name without index
            var lastIndexName = lastIndex.split('[')[0];
            // split field name from formik, to get the final form field name. ex: sendProduct.products[0].qty then the field name is qty
            var fieldName_1 = name.split('.');
            var lastFieldName = fieldName_1.pop();
            if (squareBracketPattern.test(lastIndex) && lastFieldName) {
                var fieldIndex = lastIndex.match(squareBracketPattern)[1];
                // add some validation to check the data availibility
                dataValue =
                    Array.isArray(formik.values[frontIndex][lastIndexName]) &&
                        typeof formik.values[frontIndex][lastIndexName][fieldIndex] !== 'undefined' &&
                        formik.values[frontIndex][lastIndexName][fieldIndex][lastFieldName];
            }
        }
        else {
            dataValue =
                Array.isArray(formik.values[frontIndex]) && formik.values[frontIndex][indexNumber]
                    ? formik.values[frontIndex][indexNumber][lastIndex]
                    : getFieldValueFromKey(name);
        }
    }
    var formatThousandNumber = function (inputValue) {
        inputValue = inputValue.replace(/\D/g, '');
        inputValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return inputValue;
    };
    var handleInputChange = function (event) {
        var parseInteger = function (str) {
            var parsedValue = parseInt(str);
            return isNaN(parsedValue) ? null : parsedValue;
        };
        var inputValue = event.target.value;
        if (parseInteger(inputValue) === null) {
            formik.setFieldValue(name, inputValue);
        }
        else {
            var formattedValue = formatThousandNumber(inputValue);
            formik.setFieldValue(name, formattedValue);
        }
    };
    var handleInputNPWPChange = function (event) {
        var digitValue = event.target.value.replace(/\D/g, '');
        formik.setFieldValue(name, formatNpwp(digitValue));
    };
    var fieldName = isChildField ? name.split('.') : name;
    // Recursive function to access nested value
    var getNestedValue = function (obj, keys) {
        if (obj && keys.length > 0) {
            var currentKey = keys[0], remainingKeys_1 = keys.slice(1);
            if (Array.isArray(obj[currentKey])) {
                return obj[currentKey].map(function (item) { return getNestedValue(item, remainingKeys_1); });
            }
            if (typeof obj === 'object' && currentKey in obj) {
                return getNestedValue(obj[currentKey], remainingKeys_1);
            }
        }
        return obj;
    };
    // this function is to convert field name to proper array field key list
    var getFieldKey = function () {
        var arrayData = name.split('.');
        var index = arrayData.findIndex(function (value) { return value.includes('['); });
        if (index !== -1) {
            var splitValue = arrayData[index].split('[');
            var indexNum = splitValue[1].replace(']', '');
            arrayData.splice(index, 1);
            arrayData.splice(index, 0, splitValue[0], indexNum);
        }
        return arrayData;
    };
    // this function to make validation to if the field name is deep nested
    var fieldKey = isChildField && fieldName.length > 2 ? getFieldKey() : fieldName;
    var touched = isChildField && fieldName.length > 2
        ? getNestedValue(formik.touched, fieldKey)
        : getNestedValue(formik.touched, fieldName);
    var error = isChildField && fieldName.length > 2
        ? getNestedValue(formik.errors, fieldKey)
        : getNestedValue(formik.errors, fieldName);
    // this function is to return the status of the field error and touch status
    var touchedValue = touched && touched.length > 0 && touched[fieldKey[2]];
    var touchedData = touchedValue && fieldKey.length > 3 ? touchedValue[fieldKey[fieldKey.length - 1]] : touched;
    var errorValue = error && error.length > 0 && error[fieldKey[2]];
    var errorData = errorValue && fieldKey.length > 3 ? errorValue[fieldKey[fieldKey.length - 1]] : error;
    // this function to implement classname, depends on the field if its error or success
    var isValidClassname = function () {
        if (!isChildField) {
            if (formik.touched[name] && formik.errors[name]) {
                return 'is-invalid';
            }
            else if (formik.touched[name] && !formik.errors[name]) {
                return 'is-valid';
            }
        }
        else {
            if (errorData &&
                typeof errorData !== 'object' &&
                typeof touchedData !== 'object' &&
                touchedData) {
                return 'is-invalid';
            }
            else if (!errorData &&
                typeof errorData !== 'object' &&
                typeof touchedData !== 'object' &&
                touchedData) {
                return 'is-valid';
            }
        }
    };
    var returnValue = function (value) {
        var fieldValue = value;
        var formatValue = function () {
            var formattedValue = formatThousandNumber("".concat(value));
            fieldValue = formattedValue;
        };
        var parseInteger = function (str) {
            var parsedValue = parseInt(str);
            return isNaN(parsedValue) ? null : parsedValue;
        };
        if (typeof indexNumber === 'string') {
            if (typeof value === 'number' && formatNumber) {
                formatValue();
            }
            else if (typeof value === 'string' && formatNumber) {
                if (parseInteger(value) === null) {
                    fieldValue = value;
                }
                else {
                    formatValue();
                }
            }
        }
        return fieldValue;
    };
    function formatNpwp(value) {
        return value.length >= 15
            ? value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3,})/, '$1.$2.$3.$4-$5.$6')
            : value;
    }
    useEffect(function () {
        if (formatNumber) {
            if (typeof dataValue === 'number') {
                var formattedValue = formatThousandNumber("".concat(dataValue));
                formik.setFieldValue(name, formattedValue);
            }
        }
    }, [formatNumber, dataValue]);
    return (_jsxs("div", __assign({ className: 'fv-row mb-7' }, { children: [_jsx("label", __assign({ className: "form-label fs-6 fw-bolder text-dark ".concat(isRequired ? 'required' : '') }, { children: label })), _jsx("input", { placeholder: placeholder, className: clsx("form-control form-control-lg mb-3 mb-lg-0 lh-base ".concat(isValidClassname())), type: type, name: name, onChange: function (e) {
                    return formatNumber
                        ? handleInputChange(e)
                        : isNPWP
                            ? handleInputNPWPChange(e)
                            : formik.setFieldValue(name, e.target.value);
                }, value: isChildField ? dataValue : returnValue(formik.values[name]), onBlur: onblur, maxLength: maxlength, onFocus: onfocus, min: min, max: max, disabled: disabled }), !isChildField && formik.touched[name] && formik.errors[name] && (_jsx("div", __assign({ className: 'fv-plugins-message-container' }, { children: _jsx("div", __assign({ className: 'fv-help-block' }, { children: _jsx("span", __assign({ role: 'alert' }, { children: formik.errors[name] })) })) }))), (errorMessage ||
                (isChildField &&
                    errorData &&
                    typeof errorData !== 'object' &&
                    typeof touchedData !== 'object' &&
                    touchedData)) && (_jsx("div", __assign({ className: 'fv-plugins-message-container' }, { children: _jsx("div", __assign({ className: 'fv-help-block' }, { children: _jsx("span", __assign({ role: 'alert' }, { children: errorMessage || errorData })) })) })))] })));
};
export default InputFormik;
