var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './Record.module.scss';
import { FormatDateTime } from '@src/app/helper';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
var RecordNoSchedule = function (_a) {
    var record = _a.record;
    return (_jsx(OverlayTrigger, __assign({ placement: "right", delay: { show: 250, hide: 400 }, overlay: function (props) { return (_jsx(Tooltip, __assign({ id: "button-tooltip" }, props, { children: record.talentTeams ? record.talentTeams[0].team.name : record.jobs ? record.jobs[0].team.name : record.job ? record.job.team.name : record.team.name }))); } }, { children: _jsxs("div", { children: [_jsxs("div", __assign({ className: 'mb-5' }, { children: [_jsx("p", __assign({ className: "".concat(styles['title'], " mb-1") }, { children: "Terakhir Upload:" })), record.lastUploadOn ? _jsx("p", __assign({ className: 'mb-1' }, { children: FormatDateTime(record.lastUploadOn) })) : '-'] })), record.confirmedBy && (_jsxs("div", __assign({ className: 'by mb-3' }, { children: [_jsx("p", __assign({ className: 'mb-1' }, { children: "Dikonfirmasi Oleh:" })), _jsx("p", __assign({ className: 'mb-1' }, { children: record.confirmedBy })), _jsx("p", __assign({ className: 'mb-1' }, { children: FormatDateTime(record.confirmedOn) }))] })))] }) })));
};
export default RecordNoSchedule;
