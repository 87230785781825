var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createContext, useEffect, useState } from 'react';
import qs from 'qs';
function createResponseContext(initialState) {
    return createContext(initialState);
}
function isNotEmpty(obj) {
    return obj !== undefined && obj !== null && obj !== '';
}
// Example: page=1&items_per_page=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state) {
    var pagination = qs.stringify(state, { filter: ['page', 'items_per_page'], skipNulls: true });
    var sort = qs.stringify(state, { filter: ['sort', 'order'], skipNulls: true });
    var search = isNotEmpty(state.search)
        ? qs.stringify(state, { filter: ['search'], skipNulls: true })
        : '';
    var filter = state.filter
        ? Object.entries(state.filter)
            .filter(function (obj) { return isNotEmpty(obj[1]); })
            .map(function (obj) {
            return "filter_".concat(obj[0], "=").concat(obj[1]);
        })
            .join('&')
        : '';
    return [pagination, sort, search, filter]
        .filter(function (f) { return f; })
        .join('&')
        .toLowerCase();
}
function parseRequestQuery(query) {
    var cache = qs.parse(query);
    return cache;
}
function calculatedGroupingIsDisabled(isLoading, data) {
    if (isLoading) {
        return true;
    }
    return !data || !data.length;
}
function calculateIsAllDataSelected(data, selected) {
    if (!data) {
        return false;
    }
    return data.length > 0 && data.length === selected.length;
}
function groupingOnSelect(id, selected, setSelected) {
    if (!id) {
        return;
    }
    if (selected.includes(id)) {
        setSelected(selected.filter(function (itemId) { return itemId !== id; }));
    }
    else {
        var updatedSelected = __spreadArray([], selected, true);
        updatedSelected.push(id);
        setSelected(updatedSelected);
    }
}
function groupingOnSelectAll(isAllSelected, setSelected, data) {
    if (isAllSelected) {
        setSelected([]);
        return;
    }
    if (!data || !data.length) {
        return;
    }
    setSelected(data.filter(function (item) { return item.id; }).map(function (item) { return item.id; }));
}
// Hook
function useDebounce(value, delay) {
    // State and setters for debounced value
    var _a = useState(value), debouncedValue = _a[0], setDebouncedValue = _a[1];
    useEffect(function () {
        // Update debounced value after delay
        var handler = setTimeout(function () {
            setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return function () {
            clearTimeout(handler);
        };
    }, [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
}
function isFullURL(value) {
    var regex = /^(https?:\/\/)?[\w.-]+\.[a-zA-Z]{2,}(\/\S*)?$/;
    return regex.test(value);
}
export { createResponseContext, stringifyRequestQuery, parseRequestQuery, calculatedGroupingIsDisabled, calculateIsAllDataSelected, groupingOnSelect, groupingOnSelectAll, useDebounce, isNotEmpty, isFullURL, };
