var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { AsideMenuMain } from './AsideMenuMain';
import { DrawerComponent, ToggleComponent } from '../../../assets/ts/components';
var AsideMenu = function (_a) {
    var asideMenuCSSClasses = _a.asideMenuCSSClasses;
    var pathname = useLocation().pathname;
    useEffect(function () {
        setTimeout(function () {
            DrawerComponent.reinitialization();
            ToggleComponent.reinitialization();
        }, 50);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    return (_jsx("div", __assign({ id: 'kt_aside_menu', className: 'menu menu-column menu-title-gray-600 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold fs-6', "data-kt-menu": 'true' }, { children: _jsx(AsideMenuMain, {}) })));
};
export { AsideMenu };
