export var StatusList;
(function (StatusList) {
    StatusList["true"] = "true";
    StatusList["false"] = "false";
})(StatusList || (StatusList = {}));
export var blacklistStatuses = Object.values(StatusList);
//initial values
export var BlacklistOptions = [
    {
        value: StatusList.true,
        name: 'Blacklist',
    },
    {
        value: StatusList.false,
        name: 'Tidak blacklist',
    },
];
export var AffiliateOptions = [
    {
        value: StatusList.true,
        name: 'Ya',
    },
    {
        value: StatusList.false,
        name: 'Tidak',
    },
];
