import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
var DefaultDateFormat = 'DD/MM/YYYY';
export function FormatDate(value) {
    return dayjs(value).format(DefaultDateFormat);
}
export function FormatDateEpoch(value) {
    return dayjs(value * 1000).format(DefaultDateFormat);
}
export function FormatDateEpochUTC(value) {
    return dayjs.utc(value * 1000).format(DefaultDateFormat);
}
var DefaultDateTimeFormat = 'DD MMM YYYY HH:mm';
export function FormatDateTime(value) {
    return dayjs(dayjs.unix(value)).format(DefaultDateTimeFormat);
}
var YearMonthDateFormatHypen = 'YYYY-MM-DD';
export function FormatYearMonthDateHypen(value) {
    return dayjs(value).format(YearMonthDateFormatHypen);
}
var YearMonthDateFormat = 'YYYY/MM/DD';
export function FormatYearMonthDate(value) {
    return dayjs(value).format(YearMonthDateFormat);
}
var YearMonthFormat = 'YYYY/MM';
export function FormatYearMonth(value) {
    return dayjs(value).format(YearMonthFormat);
}
