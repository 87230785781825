var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { JobTypeEnum, RoleList, userInformation } from '@src/app/helper';
import { BargainType } from '@src/app/helper/BargainType.enum';
import { Fragment } from 'react';
var NegoJob = function (_a) {
    var _b, _c;
    var record = _a.record, editItem = _a.editItem, approveItem = _a.approveItem, cancelItem = _a.cancelItem, jobType = _a.jobType;
    var role = JSON.parse(userInformation).roles[0];
    return (_jsx(Fragment, { children: ((jobType == JobTypeEnum.Nego || jobType == JobTypeEnum.NegoOnly) || jobType == '' && record.job && !((_b = record.job) === null || _b === void 0 ? void 0 : _b.isHold) && !((_c = record.job) === null || _c === void 0 ? void 0 : _c.isCanceled)) && (_jsxs("div", __assign({ className: 'text-center' }, { children: [_jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-warning mx-2', onClick: function () { return editItem(record); } }, { children: "Ubah" })), role === RoleList.CampaignExecutive && record.job && (_jsx(_Fragment, { children: record.job.isApproved || record.job.bargainType === BargainType.NEGOSPV ? (_jsx(_Fragment, { children: _jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-primary mx-2 my-2', onClick: function () { return approveItem(record); } }, { children: "Proses" })) })) : undefined })), role === RoleList.SpvCampaignExecutive && (_jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-primary mx-2 my-2', onClick: function () { return approveItem(record); } }, { children: "Setuju" }))), _jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-danger', onClick: function () { return cancelItem(record); } }, { children: "Batal" }))] }))) }));
};
export default NegoJob;
