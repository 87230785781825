import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Navigate, Routes } from 'react-router-dom';
import { useAuth } from '../../pages/auth/core/Auth';
export function Logout() {
    var logout = useAuth().logout;
    useEffect(function () {
        logout();
        document.location.reload();
    }, [logout]);
    return (_jsx(Routes, { children: _jsx(Navigate, { to: '/auth/login' }) }));
}
