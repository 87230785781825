// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".y13oLOLZ47IKyax1WzOZ{display:flex;align-items:center;column-gap:20px;justify-content:flex-start}", "",{"version":3,"sources":["webpack://./src/app/pages/SchedulePage/LateUpload/LateUpload.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,eAAA,CACA,0BAAA","sourcesContent":[".card-search {\n  display: flex;\n  align-items: center;\n  column-gap: 20px;\n  justify-content: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card-search": "y13oLOLZ47IKyax1WzOZ"
};
export default ___CSS_LOADER_EXPORT___;
