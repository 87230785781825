var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import TextAreaFormik from '@src/components/Atoms/Formik/TextArea/TextAreaFormik';
var RejectForm = function (_a) {
    var useForm = _a.useForm, name = _a.name;
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: 'mb-3' }, { children: _jsx("div", { children: _jsx(TextAreaFormik, { label: name.toUpperCase(), placeholder: name, name: name.toLowerCase(), formik: useForm }) }) })) }));
};
export default RejectForm;
