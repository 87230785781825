var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useDebounce } from 'use-debounce';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import axios from 'axios';
import { FormatParam } from '@src/app/helper';
var API_BASE_URL = process.env.NEXUS_API_URL;
var API_URL = {
    GET_TEAM_LIST: "".concat(API_BASE_URL, "/teams"),
    DELETE_TEAM: "".concat(API_BASE_URL, "/teams"),
    GET_TEAM_DATA: "".concat(API_BASE_URL, "/teams"),
    CREATE_TEAM: "".concat(API_BASE_URL, "/teams"),
    UPDATE_TEAM: "".concat(API_BASE_URL, "/teams"),
};
export function fetchTeams(param) {
    if (param === void 0) { param = {}; }
    return __awaiter(this, void 0, void 0, function () {
        var queryString, url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryString = FormatParam(param);
                    if (queryString == undefined) {
                        queryString = '';
                    }
                    url = "".concat(API_URL.GET_TEAM_LIST);
                    return [4 /*yield*/, axios.get("".concat(url, "?").concat(queryString))];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response.data];
            }
        });
    });
}
function fetchTeamsById(_a) {
    var _b = _a === void 0 ? {} : _a, id = _b.id;
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (id == undefined) {
                        id = '';
                    }
                    url = "".concat(API_URL.GET_TEAM_LIST, "/").concat(id);
                    return [4 /*yield*/, axios.get(url)];
                case 1:
                    response = (_c.sent()).data;
                    return [2 /*return*/, response.data];
            }
        });
    });
}
// Create new team
function createTeam(team) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = team.id, values = __rest(team, ["id"]);
                    return [4 /*yield*/, axios.post(API_URL.CREATE_TEAM, values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
// Update team
function updateTeam(team) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = team.id, values = __rest(team, ["id"]);
                    return [4 /*yield*/, axios.put("".concat(API_URL.UPDATE_TEAM, "/").concat(team.id), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
// Delete team
function deleteTeamById(id) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.delete("".concat(API_URL.DELETE_TEAM, "/").concat(id))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
// Bulk delete talent categories
function deleteTeamsByIds(ids) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.delete(API_URL.DELETE_TEAM, {
                        data: {
                            pubIds: ids,
                        },
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
// Get by Id
export function useTeamById(id) {
    return useQuery(['team', id], function () { return fetchTeamsById({ id: id }); });
}
// Custom hook for CRUD operations
export function useTeams(param, queryOptions) {
    var _this = this;
    var queryClient = useQueryClient();
    var debouncedFilter = useDebounce(param, 500)[0];
    var _a = useQuery(['team', debouncedFilter], function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, fetchTeams(param)];
    }); }); }, queryOptions), team = _a.data, isLoading = _a.isLoading, error = _a.error, refetch = _a.refetch;
    var createTeamMutation = useMutation(createTeam, {
        onSuccess: function (newTeam) {
            queryClient.setQueryData('team', function (oldTeams) {
                if (oldTeams === void 0) { oldTeams = []; }
                return __spreadArray(__spreadArray([], oldTeams, true), [newTeam], false);
            });
            toast.success('Tim berhasil dibuat');
        },
        onError: function (error) {
            toast.error("Tim gagal dibuat: ".concat(error.message));
        },
    });
    var updateTeamMutation = useMutation(updateTeam, {
        onSuccess: function (updatedTeam) {
            queryClient.setQueryData('team', function (oldTeams) {
                if (oldTeams === void 0) { oldTeams = []; }
                return oldTeams.map(function (team) { return (team.id === updatedTeam.id ? updatedTeam : team); });
            });
            toast.success('Tim berhasil diupdate');
        },
        onError: function (error) {
            toast.error("Tim gagal diupdate: ".concat(error.message));
        },
    });
    var deleteTeamMutation = useMutation(deleteTeamById, {
        onSuccess: function () {
            queryClient.invalidateQueries('team');
            toast.success('Tim berhasil dihapus');
        },
        onError: function (error) {
            toast.error("Tim gagal dihapus: ".concat(error.message));
        },
    });
    var deleteTeamsMutation = useMutation(deleteTeamsByIds, {
        onSuccess: function () {
            queryClient.invalidateQueries('team');
            toast.success('Tim berhasil dihapus');
        },
        onError: function (error) {
            toast.error("Tim gagal dihapus: ".concat(error.message));
        },
    });
    return {
        team: team,
        isLoading: isLoading,
        error: error,
        refetch: refetch,
        createTeam: createTeamMutation.mutateAsync,
        updateTeam: updateTeamMutation.mutateAsync,
        deleteTeam: deleteTeamMutation.mutateAsync,
        deleteTeams: deleteTeamsMutation.mutateAsync,
    };
}
