/**
 * Make sure the URL is a valid URL by adding the required protocol.
 *
 * @param url The URL to check.
 * @returns The valid URL with the required protocol (http/https).
 */
export var makeUrl = function (url) {
    if (url.includes('https://') || url.includes('http://')) {
        return url;
    }
    else {
        return "https://".concat(url);
    }
};
export var capitalize = function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
};
