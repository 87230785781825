var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment, useEffect, useMemo } from 'react';
import { PageTitle } from '@src/_metronic/layout/core';
import { KTCard } from '@src/_metronic/helpers';
import DataTable from 'react-data-table-component';
import styles from './GroupTransfer.module.scss';
import '@src/app/styles/datatable.css';
import Select from '@src/components/Atoms/Select/Select';
import Input from '@src/components/Atoms/Input/Input';
import RecordTalent from '@src/components/molecules/Record/Talent';
import RecordAdmin from '@src/components/molecules/Record/Admin';
import RecordDescription from '@src/components/molecules/Record/Description';
import GroupTransferActionDropdown from '@src/components/Atoms/ActionDropdown/GroupTransferActionDropdown';
import { MultiDimensionalFilter, paginationOptions, userInformation } from '@src/app/helper';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import Process from '@src/components/molecules/TransferGroup/Process';
import { useTransferPerGroup } from '../../TransferPage/Transfer/Transfer.hooks';
import RecordCollaboration from '@src/components/molecules/Record/Collaboration';
import { toast } from 'react-toastify';
import Loading from '@src/components/Atoms/Loading/Loading';
import RecordJobTransfer from '@src/components/molecules/Record/JobTransfer';
//breadcrumb
var usersBreadcrumbs = [
    {
        title: 'Transfer',
        path: '/transfer/all-transfer#NotYetTransfer',
        isSeparator: false,
        isActive: false,
    },
];
var GroupTransferIndex = function () {
    var _a = useState({
        page: 1,
        pageSize: 10,
        keyword: '',
        filterBy: '[]',
        Type: 'TransferAsGroup',
    }), filter = _a[0], setFilter = _a[1];
    var _b = useState(''), selectedSocialMediaId = _b[0], setSelectedSocialMediaId = _b[1];
    // const {collaborations, isLoading, updateCollaborationStatus} = useCollaboration(filter)
    var _c = useTransferPerGroup(filter), transfersGroup = _c.transfersGroup, isLoadingTransfer = _c.isLoading, refetchTransfer = _c.refetch;
    var socialMedia = JSON.parse(userInformation).socialMedias;
    var teams = JSON.parse(userInformation).teams;
    var optionSocialMedia = socialMedia &&
        socialMedia.length > 0 &&
        socialMedia.map(function (social) { return ({
            name: social.name,
            value: social.pubId,
        }); });
    var optionTeam = teams &&
        teams.map(function (team) { return ({
            name: team.name,
            value: team.pubId,
        }); });
    var handleMultiDimensionalFilterChange = function (event) {
        var filterValue = filter.filterBy !== '' ? JSON.parse(filter.filterBy) : '';
        if (filter.filterBy === '') {
            var filterCriteria = [[event.target.name, event.target.value]];
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterCriteria) }));
        }
        else {
            var filterNew = MultiDimensionalFilter(event, filterValue);
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterNew) }));
            setSelectedSocialMediaId(event.target.value);
        }
    };
    var handleFilterChange = function (event) {
        var _a;
        setFilter(__assign(__assign({}, filter), (_a = {}, _a[event.target.name] = event.target.value, _a)));
    };
    var handlePageChange = function (page) {
        setFilter(__assign(__assign({}, filter), { page: page }));
    };
    var handlePageSizeChange = function (size) {
        setFilter(__assign(__assign({}, filter), { page: 1, pageSize: size }));
    };
    //select
    var _d = useState([]), selectedRows = _d[0], setSelectedRows = _d[1];
    var _e = useState([]), selectedRecords = _e[0], setSelectedRecords = _e[1];
    var _f = useState(''), searchValue = _f[0], setSearchValue = _f[1];
    var _g = useState(false), isSelected = _g[0], setIsSelected = _g[1];
    var _h = useState(false), isOpenProcess = _h[0], setIsOpenProcess = _h[1];
    var handleRemoveRow = function (id) {
        var index = selectedRows.findIndex(function (data) { return data.job.talent.pubId === id; });
        var newData = __spreadArray([], selectedRows, true);
        newData.splice(index, 1);
        setSelectedRows(newData);
        setSelectedRecords(newData);
    };
    var handleRowSelected = function (state) {
        setSelectedRows(state.selectedRows);
    };
    var handleSearchGroup = function () {
        if (searchValue === '') {
            setSelectedRecords(selectedRows);
        }
        else {
            var filteredData = selectedRecords.filter(function (row) {
                return row.job.talent.name.toLowerCase().includes(searchValue.toLowerCase());
            });
            setSelectedRecords(filteredData);
        }
    };
    var filteredSelectedRecords = useMemo(function () {
        return selectedRecords.filter(function (row) {
            return row.job.talent.name.toLowerCase().includes(searchValue.toLowerCase());
        });
    }, [searchValue, selectedRecords]);
    // TODO: show cp email and cp other and country name on datatable row, as its missing from api return
    var columns = [
        {
            name: 'No.Transfer',
            cell: function (record) {
                return _jsx(RecordJobTransfer, { transfers: record.job.jobTransfers });
            },
        },
        {
            name: 'Talent',
            cell: function (record) { return (_jsx(RecordTalent, { name: record.job.talent.name, pubId: record.job.talent.pubId, cpName: record.job.talent.cpName, cpPhoneNumber: record.job.talent.cpPhoneNumber, cpEmail: record.job.talent.cpEmail, profiles: record.job.talent.profiles, onClick: function () { } })); },
            sortable: true,
        },
        {
            name: 'Kerja sama',
            cell: function (record) { return _jsx(RecordCollaboration, { record: record.job || '', id: record.job.pubId }); },
            sortable: true,
        },
        {
            name: 'Keterangan',
            // todo: please create proper record component to render this
            cell: function (record) { return (_jsx(RecordDescription, { total: record.job.totalFee, bankName: record.job.bankAccount.bank.name, cityName: record.job.bankAccount.city.name, accountNumber: record.job.bankAccount.accountNumber, accountName: record.job.bankAccount.accountName, invoice: record.job.invoiceUrl })); },
            sortable: true,
        },
        {
            name: 'Oleh',
            cell: function (record) { return _jsx(RecordAdmin, { record: record.job }); },
            sortable: true,
        },
    ];
    var filteredColumns = [
        {
            name: 'Talent',
            cell: function (record) { return (_jsx(RecordTalent, { name: record.job.talent.name, pubId: record.job.talent.pubId, cpName: record.job.talent.cpName, cpPhoneNumber: record.job.talent.cpPhoneNumber, cpEmail: record.job.talent.cpEmail, profiles: record.job.talent.profiles, onClick: function () { } })); },
            sortable: true,
        },
        {
            name: 'Kerja sama',
            cell: function (record) { return _jsx(RecordCollaboration, { record: record.job || '', id: record.job.pubId }); },
            sortable: true,
        },
        {
            name: 'Keterangan',
            // todo: please create proper record component to render this
            cell: function (record) { return (_jsx(RecordDescription, { total: record.job.totalFee, bankName: record.job.bankAccount.bank.name, cityName: record.job.bankAccount.city.name, accountNumber: record.job.bankAccount.accountNumber, accountName: record.job.bankAccount.accountName, invoice: record.job.invoiceUrl })); },
            sortable: true,
        },
        {
            name: 'Action',
            cell: function (record) { return (_jsx(GroupTransferActionDropdown, { handleRemove: handleRemoveRow, record: record.job })); },
        },
    ];
    useEffect(function () {
        var filterValue = JSON.parse(filter.filterBy);
        if (socialMedia && socialMedia.length > 0) {
            var filterCriteria = ['division.socialMedia.pubId', socialMedia[0].pubId];
            filterValue.push(filterCriteria);
        }
        if (teams && teams.length > 0) {
            var filterCriteria = ['team.pubId', optionTeam && optionTeam[0].value];
            filterValue.push(filterCriteria);
        }
        setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterValue) }));
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(PageTitle, __assign({ breadcrumbs: usersBreadcrumbs }, { children: "Transfer Group" })), isSelected && (_jsx(KTCard, __assign({ className: 'mb-5' }, { children: _jsxs("div", __assign({ className: 'card-header border-0 pt-6' }, { children: [_jsxs("div", __assign({ className: styles['card-filter'] }, { children: [_jsx(Input, { placeholder: 'Search', type: 'text', name: 'groupKeyword', value: searchValue, onChange: function (e) {
                                        setSearchValue(e.target.value);
                                    } }), _jsx("button", __assign({ type: 'button', onClick: function () {
                                        if (selectedRows.length > 0) {
                                            setIsOpenProcess(true);
                                        }
                                        else {
                                            toast.error('Silahkan pilih data terlebih dahulu');
                                        }
                                    }, className: 'btn btn-primary btn-active-light-primary mr-2 btn-sm' }, { children: "Proses Transfer Secara Grup" }))] })), _jsx("div", __assign({ className: 'card-body py-4' }, { children: _jsx(DataTable, { title: ' ', columns: filteredColumns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: filteredSelectedRecords, noHeader: true, selectableRows: false, progressComponent: _jsx(Loading, {}), pagination: true, paginationDefaultPage: 1, paginationPerPage: 10, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange }, selectedRecords && selectedRecords.length) }))] })) }))), _jsx(KTCard, { children: _jsxs("div", __assign({ className: 'card-header border-0 pt-6' }, { children: [_jsxs("div", __assign({ className: styles['card-search'] }, { children: [_jsxs("div", __assign({ className: styles['card-search__filter'] }, { children: [_jsx(Input, { placeholder: 'Search', type: 'text', name: 'keyword', value: filter.keyword, onChange: function (e) {
                                                handleFilterChange(e);
                                            } }), optionSocialMedia && optionSocialMedia.length > 0 && (_jsx(Select, { name: 'division.socialMedia.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, optionList: optionSocialMedia, defaultValue: optionSocialMedia[0].value })), _jsx("div", __assign({ className: 'card-title' }, { children: optionTeam && optionTeam.length > 0 && (_jsx(Select, { name: 'team.pubId', onChange: function (e) {
                                                    handleMultiDimensionalFilterChange(e);
                                                }, placeholder: 'Filter by Team', optionList: optionTeam, defaultValue: optionTeam[0].value })) }))] })), _jsx("div", { children: !isLoadingTransfer && transfersGroup && transfersGroup.data.length > 0 ? (_jsx("button", __assign({ type: 'button', onClick: function () {
                                            setIsSelected(true);
                                            setSelectedRecords(selectedRows);
                                        }, className: 'btn btn-primary btn-active-light-primary mr-2 btn-sm', disabled: selectedRows.length === 0 }, { children: "Pilih" }))) : undefined })] })), _jsx("div", __assign({ className: 'card-body py-4' }, { children: _jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: transfersGroup ? transfersGroup.data : [], 
                                // contextActions={contextActions}
                                onSelectedRowsChange: handleRowSelected, noHeader: true, selectableRows: true, progressPending: isLoadingTransfer, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: filter.filterBy === ''
                                    ? transfersGroup && transfersGroup.recordsTotal
                                    : transfersGroup && transfersGroup.recordsFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange }, transfersGroup && transfersGroup.data.length) }))] })) }), _jsx(Process, { allrecords: setSelectedRecords, record: selectedRecords, isOpen: isOpenProcess, handleClose: function () { return setIsOpenProcess(false); }, refetch: refetchTransfer })] }));
};
export default GroupTransferIndex;
