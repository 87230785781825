var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, Fragment, useEffect, useMemo } from 'react';
import { PageTitle } from '@src/_metronic/layout/core';
import { KTCard } from '@src/_metronic/helpers';
import DataTable from 'react-data-table-component';
import '@src/app/styles/datatable.css';
import { useTransfers } from './ApprovalTransfer.hooks';
import Select from '@src/components/Atoms/Select/Select';
import Input from '@src/components/Atoms/Input/Input';
import { useSocialMedia } from '../../../SocialMediaPage/SocialMedia/SocialMedia.hooks';
import RecordTalent from '@src/components/molecules/Record/Talent';
import RecordAdmin from '@src/components/molecules/Record/Admin';
import { useTeams } from '../../../TeamPage/TeamHooks/Team.hooks';
import { useDivisions } from '../../../DivisionPage/DivisionHooks/Division.hooks';
import { DefaultAllList, FormatYearMonthDate, MultiDimensionalFilter, paginationOptions, } from '@src/app/helper';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import DrawerForm from '@src/components/molecules/Drawer/DrawerForm';
import RejectForm from '@src/components/Atoms/Drawer/JobDetailFormDrawer/RejectForm';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTransfer } from '@src/app/pages/JobPage/JobHooks/JobDetail.hooks';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import Date from '@src/components/Atoms/Date/Date';
import dayjs from 'dayjs';
import Loading from '@src/components/Atoms/Loading/Loading';
import { Link } from 'react-router-dom';
import RecordDescription from '@src/components/molecules/Record/Description';
import { ApprovalTransferDrawerForm } from './ApprovalTransferDrawerForm';
import RecordJobTransfer from '@src/components/molecules/Record/JobTransfer';
//breadcrumb
var usersBreadcrumbs = [
    {
        title: 'Approval Transfer',
        path: '',
        isSeparator: true,
        isActive: false,
    },
];
var RejectValues = {
    reason: '',
    pubId: '',
};
var RejectFormSchema = Yup.object().shape({
    reason: Yup.string().required('Field belum diisi'),
});
export var ApprovalFormSchema = Yup.object().shape({
    paymentDue: Yup.string().required('Field belum diisi'),
});
export var DEFAULT_PLUS_DAY_APPROVAL = 13;
var ApprovalTransferIndex = function () {
    var intl = useIntl();
    var id = useParams().id;
    var _a = useState({
        page: 1,
        pageSize: 10,
        keyword: '',
        filterBy: '[]',
        initialFilterBy: '[["Approved", false],["Rejected", false]]',
    }), filter = _a[0], setFilter = _a[1];
    var _b = useState({
        page: 1,
        pageSize: 100,
        keyword: '',
        filterBy: '[]',
        initialFilterBy: '[]',
    }), filterPageSize = _b[0], setFilterPageSize = _b[1];
    var _c = useState(dayjs().format('YYYY-MM-DD').toString()), date = _c[0], setDate = _c[1];
    var onChangeDate = function (e) {
        var event = { target: { name: '', value: null } };
        event.target.name = 'date';
        event.target.value = e == null ? '' : FormatYearMonthDate(e);
        setDate(event.target.value);
        handleMultiDimensionalFilterChange(event);
    };
    var _d = useState(false), isOpenAdd = _d[0], setIsOpenAdd = _d[1];
    var _e = useState('Alasan untuk Reject Transfer'), tabName = _e[0], setTabName = _e[1];
    var _f = useTransfer(id), approveTransfer = _f.approveTransfer, rejectTransfer = _f.rejectTransfer;
    var _g = useState(''), talentId = _g[0], setTalentId = _g[1];
    var _h = useState(''), addressId = _h[0], setTransferId = _h[1];
    var _j = useTransfers(filter), transfers = _j.transfers, isLoading = _j.isLoading;
    var _k = useSocialMedia(), socialMedia = _k.socialMedia, isLoadingSocialMedia = _k.isLoading, errorSocialMedia = _k.error;
    var _l = useTeams(filterPageSize), team = _l.team, isLoadingTeams = _l.isLoading, errorTeam = _l.error;
    var _m = useDivisions(), divisions = _m.divisions, isLoadingDivisions = _m.isLoading, errorDivisions = _m.error;
    var optionSocialMedia = socialMedia &&
        socialMedia.length > 0 &&
        socialMedia.map(function (social) { return ({
            name: social.name,
            value: social.pubId,
        }); });
    optionSocialMedia && optionSocialMedia.unshift(DefaultAllList);
    var optionTeam = team &&
        team.data &&
        team.data.length > 0 &&
        team.data.map(function (team) { return ({
            name: team.name,
            value: team.pubId,
        }); });
    optionTeam && optionTeam.unshift(DefaultAllList);
    var optionDivisions = divisions &&
        divisions.length > 0 &&
        divisions.map(function (division) { return ({
            name: "".concat(division.name, " - ").concat(division.socialMedia.name),
            value: division.pubId,
        }); });
    var handleMultiDimensionalFilterChange = function (event) {
        var filterValue = filter.filterBy !== '' ? JSON.parse(filter.filterBy) : '';
        if (filter.filterBy === '') {
            var filterCriteria = [[event.target.name, event.target.value]];
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterCriteria) }));
        }
        else {
            var filterNew = MultiDimensionalFilter(event, filterValue);
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterNew) }));
        }
    };
    var handleFilterChange = function (event) {
        var _a;
        setFilter(__assign(__assign({}, filter), (_a = {}, _a[event.target.name] = event.target.value, _a)));
    };
    var handlePageChange = function (page) {
        setFilter(__assign(__assign({}, filter), { page: page }));
    };
    var handlePageSizeChange = function (size) {
        setFilter(__assign(__assign({}, filter), { page: 1, pageSize: size }));
    };
    var navigate = useNavigate();
    //gotodetail
    var gotoDetail = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("/approval/job/detail/".concat(record.pubId));
            return [2 /*return*/];
        });
    }); };
    var columns = [
        {
            name: 'No.Transfer',
            cell: function (record) {
                return _jsx(RecordJobTransfer, { transfers: record.jobs.map(function (job) { return job.jobTransfers; }).flat() });
            },
        },
        {
            name: 'No. Kerjasama',
            cell: function (record) {
                return record.jobs.map(function (job) { return (_jsx(Link, __assign({ to: "/job/detail/".concat(job.jobNumber) }, { children: job.jobNumber }))); });
            },
        },
        {
            name: 'Nama',
            cell: function (record) {
                return (_jsx(RecordTalent, { name: record.talent.name, pubId: record.talent.pubId, cpName: record.talent.cpName, cpPhoneNumber: record.talent.cpPhoneNumber, onClick: function () { return gotoDetail(record); } }));
            },
            sortable: true,
        },
        {
            name: 'No Rekening',
            cell: function (record) {
                return (_jsxs("div", { children: [_jsxs("p", { children: [record.talentBankAccount.bank.name, record.talentBankAccount.city && " - ".concat(record.talentBankAccount.city.name)] }), _jsx("p", { children: record.talentBankAccount.accountNumber }), _jsx("p", { children: record.talentBankAccount.accountName })] }));
            },
            sortable: true,
        },
        {
            name: 'Total Transfer',
            selector: function (row) { return "Rp ".concat(row.totalAmount.toLocaleString()); },
            sortable: true,
        },
        {
            name: 'Note',
            cell: function (record) {
                return (_jsx(_Fragment, { children: record.jobs[0].jobTransfers.map(function (obj, key) {
                        return (_jsx(RecordDescription, { total: obj.totalAmount, bankName: obj.bank.name, cityName: obj.talentBankAccount.city && obj.talentBankAccount.city.name, accountNumber: obj.accountNumber, accountName: obj.accountName, pajakType: obj.pajakType, pajakCategory: obj.pajakCategory, pajakMethod: obj.pajakMethod, paymentMethod: obj.paymentMethod, pajakFee: obj.pajakFee, pajakBruto: obj.pajakBruto, pajakPPN: obj.pajakPPN, bankTransfer: obj.bankTransferBy ? obj.bankTransferBy.name : '-', invoice: record.invoiceUrl, spk: record.spkUrl, transfer: record.transferUrl, note: record.note }));
                    }) }));
            },
            sortable: true,
        },
        {
            name: 'Oleh',
            cell: function (record) { return _jsx(RecordAdmin, { record: record }); },
            sortable: true,
        },
        {
            name: 'Action',
            cell: function (record) {
                return (_jsxs("div", { children: [_jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-primary', onClick: function () { return approveItem(record); } }, { children: "Approve" })), _jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-danger mx-2', onClick: function () { return rejectItem(record); } }, { children: "Reject" }))] }));
            },
        },
    ];
    //approve
    var _o = useState(), selectedApproval = _o[0], setSelectedApproval = _o[1];
    var approveItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setSelectedApproval(record);
            return [2 /*return*/];
        });
    }); };
    //reject
    var rejectItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setTalentId(record.pubId); //record.talent.pubId
            setTransferId(record.pubId);
            setIsOpenAdd(true);
            return [2 /*return*/];
        });
    }); };
    var rejectDetail = { data: RejectValues }.data;
    var rejectaddressValues = {
        note: (rejectDetail === null || rejectDetail === void 0 ? void 0 : rejectDetail.reason) || '',
        pubId: (rejectDetail === null || rejectDetail === void 0 ? void 0 : rejectDetail.pubId) || '',
    };
    var RejectFormik = useFormik({
        initialValues: rejectDetail ? rejectaddressValues : RejectValues,
        enableReinitialize: true,
        validationSchema: RejectFormSchema,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var e_1, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        values.pubId = addressId;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, rejectTransfer(__assign({ id: talentId }, values))];
                    case 2:
                        _a.sent();
                        setIsOpenAdd(false);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        error = e_1.response.data;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); },
    });
    // if createdOn is less than today, default is today
    // else createdOn
    var minDateApproval = useMemo(function () {
        if (!selectedApproval)
            return dayjs();
        var today = dayjs();
        if (dayjs(selectedApproval.createdOn * 1000).isBefore(today, 'date')) {
            return today;
        }
        return dayjs(selectedApproval.createdOn * 1000);
    }, [selectedApproval]);
    // If today is more than createdOn + 13 than default is today
    // else createdOn + 13
    var defaultDateApproval = useMemo(function () {
        if (!selectedApproval)
            return '';
        var today = dayjs();
        if (today.isAfter(dayjs(selectedApproval.createdOn * 1000).add(DEFAULT_PLUS_DAY_APPROVAL, 'day'))) {
            return today.format('YYYY-MM-DD');
        }
        else {
            return dayjs(selectedApproval.createdOn * 1000)
                .add(DEFAULT_PLUS_DAY_APPROVAL, 'day')
                .format('YYYY-MM-DD');
        }
    }, [selectedApproval]);
    var ApprovalFormik = useFormik({
        initialValues: {
            paymentDue: defaultDateApproval,
        },
        enableReinitialize: true,
        validationSchema: ApprovalFormSchema,
        onSubmit: function (_a) {
            var paymentDue = _a.paymentDue;
            return __awaiter(void 0, void 0, void 0, function () {
                var e_2, error;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!selectedApproval)
                                return [2 /*return*/];
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 3, 4, 5]);
                            return [4 /*yield*/, approveTransfer(__assign(__assign({ id: selectedApproval.talent.pubId }, selectedApproval), { paymentDue: paymentDue }))];
                        case 2:
                            _b.sent();
                            return [3 /*break*/, 5];
                        case 3:
                            e_2 = _b.sent();
                            error = e_2.response.data;
                            toast.error(error.message);
                            return [3 /*break*/, 5];
                        case 4:
                            setSelectedApproval(undefined);
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        },
    });
    useEffect(function () {
        var filterValue = JSON.parse(filter.filterBy);
        if (socialMedia) {
            var filterCriteria = ['jobs.division.socialMedia.pubId', socialMedia[0].pubId];
            filterValue.push(filterCriteria);
        }
        if (date) {
            var filterCriteria = ['date', date];
            filterValue.push(filterCriteria);
        }
        setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterValue) }));
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(PageTitle, __assign({ breadcrumbs: usersBreadcrumbs }, { children: "Approval Transfer" })), _jsxs(KTCard, { children: [_jsx("div", __assign({ className: 'd-flex flex-column' }, { children: _jsx("div", __assign({ className: 'card-header border-0 pt-6 d-flex flex-row' }, { children: _jsxs("div", __assign({ className: 'card-title flex' }, { children: [_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Input, { placeholder: 'Search', type: 'text', name: 'keyword', value: filter.keyword, onChange: function (e) {
                                                handleFilterChange(e);
                                            } }) })), _jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Date, { date: date, handleonchange: onChangeDate }) })), _jsx("div", __assign({ className: 'card-title' }, { children: !isLoadingSocialMedia && optionSocialMedia && (_jsx(Select, { name: 'jobs.division.socialMedia.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, placeholder: 'Filter by Social Media', optionList: optionSocialMedia, defaultValue: optionSocialMedia[0].value, isPlaceholder: false })) })), _jsx("div", __assign({ className: 'card-title' }, { children: !isLoadingDivisions && optionDivisions && (_jsx(Select, { name: 'jobs.division.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, placeholder: 'Filter by Division', optionList: optionDivisions })) })), _jsx("div", __assign({ className: 'card-title' }, { children: !isLoadingTeams && optionTeam && (_jsx(Select, { name: 'jobs.team.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, placeholder: 'Filter by Team', optionList: optionTeam, isPlaceholder: false })) }))] })) })) })), _jsx("div", __assign({ className: 'card-body py-4' }, { children: _jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: transfers ? transfers.data : [], noHeader: true, progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: transfers && transfers.recordsFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange }, transfers && transfers.data.length) }))] }), selectedApproval && (_jsx(ApprovalTransferDrawerForm, { open: true, formik: ApprovalFormik, minDate: minDateApproval, onClose: function () { return setSelectedApproval(undefined); } })), _jsx(DrawerForm, { tabName: tabName, useForm: RejectFormik, isOpen: isOpenAdd, handleClose: setIsOpenAdd, onSubmit: RejectFormik.handleSubmit, component: RejectForm, name: 'Reason', idForEdit: '' })] }));
};
export default ApprovalTransferIndex;
