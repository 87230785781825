export var FileCategories = {
    Invoices: 'Invoices',
    Photos: 'Photos',
    IDs: 'IDs',
    NPWPs: 'NPWPs',
    SPKs: 'SPKs',
    ProofOfTransfers: 'ProofOfTransfers',
    ProofOfRefunds: 'ProofOfRefunds',
    ProofOfUploads: 'ProofOfUploads',
};
export var FileCategory = Object.values(FileCategories);
export var optionFileCategoriesList = [
    {
        label: 'Invoice',
        value: FileCategories.Invoices,
    },
    {
        label: 'Foto',
        value: FileCategories.Photos,
    },
    {
        label: 'KTP',
        value: FileCategories.IDs,
    },
    {
        label: 'NPWP',
        value: FileCategories.NPWPs,
    },
    {
        label: 'SPK',
        value: FileCategories.SPKs,
    },
    {
        label: 'Bukti Transfer',
        value: FileCategories.ProofOfTransfers,
    },
    {
        label: 'Bukti Refund',
        value: FileCategories.ProofOfRefunds,
    },
    {
        label: 'Bukti Upload',
        value: FileCategories.ProofOfUploads,
    },
];
