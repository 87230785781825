export var talentDetailTabOptions = {
    profile: 'Profile',
    kerjasama: 'Kerja Sama',
    transfer: 'Transfer',
    refund: 'Refund',
    buktiupload: 'Bukti Upload',
    alamat: 'Alamat',
    norekening: 'No Rekening',
    barangdikirim: 'Barang Dikirim',
    alasanreject: 'Alasan Reject',
    attachment: 'Attachment',
    teams: 'Teams',
};
export var talentDetailTabPropertyName = Object.keys(talentDetailTabOptions);
export var jobDetailTabOptions = {
    kerjasama: 'Kerja Sama',
    jadwal: 'Jadwal',
    transfer: 'Transfer',
    barangdikirim: 'Barang Dikirim',
    buktiupload: 'Upload Bukti',
    attachment: 'Attachment',
};
export var jobDetailTabPropertyName = Object.keys(jobDetailTabOptions);
export var jobDetailApprovalOptions = {
    alamat: 'Alamat',
    norekening: 'No Rekening',
};
export var jobDetailApprovalList = Object.keys(jobDetailApprovalOptions);
