// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EkdVaHiyQ1kLAhMH2tlr{background-color:#f9f9f9;padding:30px;margin-bottom:30px;border-radius:5px;display:flex;align-items:center;column-gap:30px}.Euc1OsiXiXf2PG6kQgRr{display:flex;justify-content:space-between;align-items:center;margin-bottom:10px}.oEk8opSAPLibkCekQaIf{flex-grow:1}.fXUQXfJm1WvKGE4lOboA{margin-top:-16px}", "",{"version":3,"sources":["webpack://./src/components/molecules/Talent/Form/TalentForm.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CAGF,sBACE,WAAA,CAGF,sBACE,gBAAA","sourcesContent":[".profile-container {\n  background-color: #f9f9f9;\n  padding: 30px;\n  margin-bottom: 30px;\n  border-radius: 5px;\n  display: flex;\n  align-items: center;\n  column-gap: 30px;\n}\n\n.social-media-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 10px;\n}\n\n.profile-layout {\n  flex-grow: 1;\n}\n\n.profile-status-message {\n  margin-top: -16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile-container": "EkdVaHiyQ1kLAhMH2tlr",
	"social-media-container": "Euc1OsiXiXf2PG6kQgRr",
	"profile-layout": "oEk8opSAPLibkCekQaIf",
	"profile-status-message": "fXUQXfJm1WvKGE4lOboA"
};
export default ___CSS_LOADER_EXPORT___;
