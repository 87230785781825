var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment, useEffect } from 'react';
import { PageTitle } from '@src/_metronic/layout/core';
import { KTCard, isFullURL } from '@src/_metronic/helpers';
import DataTable from 'react-data-table-component';
import styles from './DoneUpload.module.scss';
import '@src/app/styles/datatable.css';
import Select from '@src/components/Atoms/Select/Select';
import { useSocialMedia } from '../../SocialMediaPage/SocialMedia/SocialMedia.hooks';
import { DefaultAllList, FormatYearMonthDate, MultiDimensionalFilter, RoleList, paginationOptions, userInformation, } from '@src/app/helper';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import { useTeams } from '../../TeamPage/TeamHooks/Team.hooks';
import { useJobTypes } from '../../JobTypePage/JobType/JobType.hooks';
import RecordContactPerson from '@src/components/molecules/Record/ContactPerson';
import RecordName from '@src/components/molecules/Record/Name';
import { ScheduleFilterType } from '@src/components/molecules/Schedule/NoSchedule/NoSchedule.constant';
import { useSchedule } from '../NoSchedule/NoSchedule.hooks';
import RecordJoblist from '@src/components/molecules/Record/JobList';
import RecordUploadSchedule from '@src/components/molecules/Record/UploadSchedule';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import ScheduleActionButton from '@src/components/Atoms/Buttons/ScheduleActionButton';
import swal from 'sweetalert';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useProcessSchedule } from '@src/components/molecules/Schedule/NoSchedule/NoSchedule.request';
import DrawerDataTable from '@src/components/molecules/Drawer/DrawerDataTable';
import EditScheduleForm from '@src/components/Atoms/Drawer/ScheduleDrawer/EditScheduleForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useDivisions } from '../../DivisionPage/DivisionHooks/Division.hooks';
import dayjs from 'dayjs';
import Loading from '@src/components/Atoms/Loading/Loading';
import Input from '@src/components/Atoms/Input/Input';
//breadcrumb
var usersBreadcrumbs = [
    {
        title: 'Done Upload',
        path: '',
        isSeparator: true,
        isActive: false,
    },
];
var DoneUploadIndex = function () {
    var navigate = useNavigate();
    var id = useParams().id;
    var role = JSON.parse(userInformation).roles[0];
    var socialMedias = JSON.parse(userInformation).socialMedias;
    var socmedIds = socialMedias === null || socialMedias === void 0 ? void 0 : socialMedias.map(function (socmed) { return socmed.pubId; });
    var intl = useIntl();
    var _a = useState(), talentRecord = _a[0], setTalentRecord = _a[1];
    var _b = useState(false), isOpenEditSettings = _b[0], setIsOpenEditSettings = _b[1];
    var _c = useState({
        page: 1,
        pageSize: 10,
        keyword: '',
        filterBy: '[]',
        Type: ScheduleFilterType.DONEUPLOAD,
        orderBy: '[["upOn","desc"]]',
    }), filter = _c[0], setFilter = _c[1];
    var _d = useSchedule(filter), schedules = _d.schedules, isLoading = _d.isLoading, refetchSchedule = _d.refetch;
    var _e = useState({
        page: 1,
        pageSize: 100,
        keyword: '',
        filterBy: '[]',
        initialFilterBy: "[[\"recordStatus\", \"Active\"]]",
    }), filterJobType = _e[0], setFilterJobType = _e[1];
    var _f = useJobTypes(filterJobType), jobTypes = _f.jobTypes, isLoadingJobType = _f.isLoading, refetchJobType = _f.refetch;
    var undoSchedule = useProcessSchedule(id).undoSchedule;
    var _g = useState(dayjs().format('YYYY-MM-DD').toString()), date = _g[0], setDate = _g[1];
    var onChangeDate = function (e) {
        var event = { target: { name: '', value: null } };
        event.target.name = 'date';
        event.target.value = e == null ? '' : FormatYearMonthDate(e);
        setDate(event.target.value);
        handleMultiDimensionalFilterChange(event);
    };
    var _h = useSocialMedia(), socialMedia = _h.socialMedia, isLoadingSocialMedia = _h.isLoading;
    var _j = useTeams(), team = _j.team, isLoadingTeams = _j.isLoading;
    var _k = useDivisions(), divisions = _k.divisions, isLoadingDivision = _k.isLoading;
    if (role === RoleList.CampaignExecutive) {
        socialMedia = JSON.parse(userInformation).socialMedias;
        divisions = JSON.parse(userInformation).divisions;
        team = JSON.parse(userInformation).teams;
    }
    var getOptionTeam = function (team) {
        if (team && team.data) {
            if (team.data.length > 0) {
                return team.data.map(function (team) { return ({
                    name: team.name,
                    value: team.pubId,
                }); });
            }
            else {
                return [];
            }
        }
        else {
            if (team) {
                return team.map(function (team) { return ({
                    name: team.name,
                    value: team.pubId,
                }); });
            }
            else {
                return [];
            }
        }
    };
    var optionSocialMedia = socialMedia && socialMedia.length > 0
        ? socialMedia.map(function (social) { return ({
            name: social.name,
            value: social.pubId,
        }); })
        : undefined;
    role !== RoleList.CampaignExecutive &&
        optionSocialMedia &&
        optionSocialMedia.unshift(DefaultAllList);
    var optionDivision = divisions && divisions.length > 0
        ? role === RoleList.CampaignExecutive
            ? divisions &&
                divisions.map(function (division, index) { return ({
                    name: "".concat(division.name, " - ").concat(socialMedia && socialMedia[index].name),
                    value: division.pubId,
                }); })
            : divisions &&
                divisions.map(function (division) { return ({
                    name: "".concat(division.name, " - ").concat(division.socialMedia.name),
                    value: division.pubId,
                }); })
        : undefined;
    var optionTeam = getOptionTeam(team);
    role !== RoleList.CampaignExecutive && optionTeam && optionTeam.unshift(DefaultAllList);
    var optionJobType = jobTypes && jobTypes.data && jobTypes.data.length > 0
        ? jobTypes.data
            .filter(function (obj) { return socmedIds.includes(obj.socialMedia.pubId); })
            .map(function (job) { return ({
            name: job.name,
            value: job.pubId,
        }); })
        : undefined;
    var handleMultiDimensionalFilterChange = function (event) {
        var filterValue = filter.filterBy !== '' ? JSON.parse(filter.filterBy) : '';
        if (filter.filterBy === '') {
            var filterCriteria = [[event.target.name, event.target.value]];
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterCriteria) }));
        }
        else {
            var filterNew = MultiDimensionalFilter(event, filterValue);
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterNew) }));
        }
    };
    var handlePageChange = function (page) {
        setFilter(__assign(__assign({}, filter), { page: page }));
    };
    var handlePageSizeChange = function (size) {
        setFilter(__assign(__assign({}, filter), { page: 1, pageSize: size }));
    };
    var handleFilterChange = function (event) {
        var _a;
        setFilter(__assign(__assign({}, filter), (_a = {}, _a[event.target.name] = event.target.value, _a)));
    };
    var handleLinkItem = function (url) {
        if (isFullURL(url)) {
            window.open(url, '_blank');
        }
        else {
            window.open("https://".concat(url), '_blank');
        }
    };
    var handleEditItem = function (record, talentId, tenantId) {
        var recordData = __assign(__assign({}, record), { talentId: talentId, tenantId: tenantId });
        setIsOpenEditSettings(true);
        setTalentRecord(recordData);
    };
    var handleUndoItem = function (id) {
        swal({
            title: intl.formatMessage({ id: 'GENERAL.VALIDATION' }),
            text: '',
            icon: 'warning',
            buttons: [
                intl.formatMessage({ id: 'GENERAL.VALIDATION.NO' }),
                intl.formatMessage({ id: 'GENERAL.VALIDATION.YES' }),
            ],
            dangerMode: true,
        }).then(function (willDelete) { return __awaiter(void 0, void 0, void 0, function () {
            var e_1, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!willDelete) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, undoSchedule(id)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        error = e_1.response.data;
                        toast.error(error.message);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    };
    var gotoTalent = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("/talent/detail/".concat(record));
            return [2 /*return*/];
        });
    }); };
    // TODO: show cp email and cp other and country name on datatable row, as its missing from api return
    var columns = [
        {
            name: 'Contact Person',
            cell: function (record) { return (_jsx(RecordContactPerson, { cpName: record.talent.cpName, cpPhoneNumber: record.talent.cpPhoneNumber })); },
            sortable: true,
        },
        {
            name: 'Nama',
            cell: function (record) { return (_jsx(RecordName, { pubId: record.talent.pubId, talentName: record.talent.name, profiles: record.talent.profiles })); },
            sortable: true,
        },
        {
            name: 'Kerja Sama',
            cell: function (record) { return (_jsx(RecordJoblist, { scheduleJobNumber: record.jobNumber, schedules: record.schedules })); },
            sortable: true,
        },
        {
            name: 'Jadwal Upload',
            cell: function (record) { return _jsx(RecordUploadSchedule, { schedules: record.schedules }); },
            sortable: true,
        },
        {
            name: 'Tanggal Upload',
            cell: function (record) { return (_jsx(RecordUploadSchedule, { record: record, schedules: record.schedules, showConfirmBy: true })); },
            sortable: true,
        },
        {
            name: 'Action',
            cell: function (record) { return (_jsx(ScheduleActionButton, { schedules: record.schedules, talent: record.talent, linkItem: handleLinkItem, editItem: handleEditItem, undoItem: handleUndoItem })); },
        },
    ];
    var CAColumns = [
        {
            name: 'Contact Person',
            cell: function (record) { return (_jsx(RecordContactPerson, { cpName: record.talent.cpName, cpPhoneNumber: record.talent.cpPhoneNumber })); },
            sortable: true,
        },
        {
            name: 'Nama',
            cell: function (record) { return (_jsx(RecordName, { pubId: record.talent.pubId, talentName: record.talent.name, profiles: record.talent.profiles })); },
            sortable: true,
        },
        {
            name: 'Kerja Sama',
            cell: function (record) { return _jsx(RecordJoblist, { schedules: record.schedules }); },
            sortable: true,
        },
        {
            name: 'Jadwal Upload',
            cell: function (record) { return _jsx(RecordUploadSchedule, { schedules: record.schedules }); },
            sortable: true,
        },
        {
            name: 'Tanggal Upload',
            cell: function (record) { return _jsx(RecordUploadSchedule, { schedules: record.schedules, showConfirmBy: true }); },
            sortable: true,
        },
        {
            name: 'Action',
            cell: function (record) { return (_jsx(ScheduleActionButton, { schedules: record.schedules, talent: record.talent, linkItem: handleLinkItem, editItem: handleEditItem, undoItem: handleUndoItem })); },
        },
    ];
    var _l = useState(0), jobTypeValue = _l[0], setJobTypeValue = _l[1];
    var onChangeSocialMedia = function (e) {
        filterJobType['filterBy'] = "[[\"socialMedia.pubId\", \"".concat(e.target.value, "\"]]");
        setJobTypeValue(jobTypeValue + 1);
    };
    useEffect(function () {
        refetchJobType();
    }, [jobTypeValue]);
    useEffect(function () {
        var filterValue = JSON.parse(filter.filterBy);
        var filterJobTypeValue = JSON.parse(filterJobType.filterBy);
        if (socialMedia && socialMedia.length > 0) {
            var filterCriteria = ['division.socialMedia.pubId', socialMedia && socialMedia[0].pubId];
            filterValue.push(filterCriteria);
            var filterJobTypeCriteria = ['socialMedia.pubId', socialMedia && socialMedia[0].pubId];
            filterJobTypeValue.push(filterJobTypeCriteria);
        }
        if (optionTeam) {
            var filterCriteria = ['team.pubId', optionTeam && optionTeam[0].value];
            filterValue.push(filterCriteria);
        }
        if (date) {
            var filterCriteria = ['date', date];
            filterValue.push(filterCriteria);
        }
        setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterValue) }));
        setFilterJobType(__assign(__assign({}, filterJobType), { filterBy: JSON.stringify(filterJobTypeValue) }));
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(PageTitle, __assign({ breadcrumbs: usersBreadcrumbs }, { children: "Done Upload" })), _jsxs(KTCard, { children: [_jsx("div", __assign({ className: 'card-header border-0 pt-6' }, { children: _jsxs("div", __assign({ className: styles['card-search'] }, { children: [_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Input, { placeholder: 'Search', type: 'text', name: 'keyword', value: filter.keyword, onChange: function (e) {
                                            handleFilterChange(e);
                                        } }) })), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, { value: date, inputFormat: 'DD/MM/YYYY', dayOfWeekFormatter: function (day) { return day; }, onChange: function (e) {
                                            onChangeDate(e);
                                        }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { className: 'product-datepicker form-control form-control-solid', style: { width: 200 } }))); } }) })), !isLoadingSocialMedia && optionSocialMedia && optionSocialMedia.length > 0 && (_jsx(Select, { name: 'division.socialMedia.pubId', onChange: function (e) {
                                        handleMultiDimensionalFilterChange(e);
                                        onChangeSocialMedia(e);
                                    }, optionList: optionSocialMedia, defaultValue: optionSocialMedia[0].value, isPlaceholder: false })), !isLoadingDivision && optionDivision && optionDivision.length > 0 && (_jsx(Select, { name: 'division.pubId', onChange: function (e) {
                                        handleMultiDimensionalFilterChange(e);
                                    }, optionList: optionDivision, defaultValue: optionDivision[0].value })), !isLoadingTeams && optionTeam && optionTeam.length > 0 && (_jsx(Select, { name: 'team.pubId', onChange: function (e) {
                                        handleMultiDimensionalFilterChange(e);
                                    }, optionList: optionTeam, defaultValue: optionTeam[0].value })), !isLoadingJobType && optionJobType && optionJobType.length > 0 && (_jsx(Select, { name: 'jobDetail.jobType.pubId', onChange: function (e) {
                                        handleMultiDimensionalFilterChange(e);
                                    }, optionList: optionJobType }))] })) })), _jsx("div", __assign({ className: 'card-body py-4' }, { children: _jsx(DataTable, { title: ' ', columns: role == RoleList.CampaignAssistant ? CAColumns : columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: schedules ? schedules.data : [], noHeader: true, 
                            // contextActions={contextActions}
                            progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: filter.filterBy === ''
                                ? schedules && schedules.recordsTotal
                                : schedules && schedules.recordsFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange }, schedules && schedules.data.length) }))] }), isOpenEditSettings && (_jsx(DrawerDataTable, { isOpen: isOpenEditSettings, handleClose: setIsOpenEditSettings, record: talentRecord, component: EditScheduleForm, size: 'LG', title: 'Edit Jadwal', refetch: refetchSchedule }))] }));
};
export default DoneUploadIndex;
