var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import TalentIndex from '../TalentPage/Talent/Talent';
import BlastIndex from '../BlastPage/Blast/Blast';
import SendProductIndex from '../SendProductPage/SendProduct/SendProduct';
import { RoleList, userInformation } from '@src/app/helper';
import SendProductNonTalentIndex from '../SendProductPage/SendProductNonTalent/SendProductNonTalent';
var usersBreadcrumbs = [
    {
        title: 'Dashboard',
        path: '',
        isSeparator: true,
        isActive: false,
    },
];
var DashboardWrapper = function () {
    var role = JSON.parse(userInformation).roles[0];
    useEffect(function () {
        var _a;
        // We have to show toolbar only for dashboard page
        (_a = document.getElementById('kt_layout_toolbar')) === null || _a === void 0 ? void 0 : _a.classList.remove('d-none');
        return function () {
            var _a;
            (_a = document.getElementById('kt_layout_toolbar')) === null || _a === void 0 ? void 0 : _a.classList.add('d-none');
        };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, __assign({ breadcrumbs: usersBreadcrumbs }, { children: "Dashboard" })), _jsxs("div", __assign({ className: 'row g-5 g-xl-8' }, { children: [role === RoleList.Community ? (_jsxs(_Fragment, { children: [_jsx(TalentIndex, { disableBreadcrumbs: true, withActionDetail: true }), _jsx(BlastIndex, { disableBreadcrumbs: true })] })) : undefined, role === RoleList.CampaignExecutive ? (_jsx(_Fragment, { children: _jsx(TalentIndex, { disableBreadcrumbs: true, withActionDetail: true }) })) : undefined, role === RoleList.SpvCampaignExecutive ? (_jsx(_Fragment, { children: _jsx(TalentIndex, { disableBreadcrumbs: true, withActionDetail: true }) })) : undefined, role === RoleList.Audit ? (_jsx(_Fragment, { children: _jsx(TalentIndex, { disableBreadcrumbs: true, withActionDetail: true }) })) : undefined, role === RoleList.Finance ? (_jsx(_Fragment, { children: _jsx(TalentIndex, { disableBreadcrumbs: true, withActionDetail: true }) })) : undefined, role === RoleList.CampaignAssistant ? (_jsx(_Fragment, { children: _jsx(TalentIndex, { disableBreadcrumbs: true, withActionDetail: true }) })) : undefined, role === RoleList.Reqsi || role === RoleList.Appsi ? (_jsx(_Fragment, { children: _jsx(SendProductNonTalentIndex, { disableBreadcrumbs: true }) })) : undefined, role === RoleList.Admin ? (_jsxs(_Fragment, { children: [_jsx(SendProductIndex, { disableBreadcrumbs: true }), _jsx(TalentIndex, { disableBreadcrumbs: true, withActionDetail: true })] })) : undefined] }))] }));
};
export { DashboardWrapper };
