export var ScheduleFilterType;
(function (ScheduleFilterType) {
    ScheduleFilterType["NOSCHEDULE"] = "NoSchedule";
    ScheduleFilterType["WITHSCHEDULE"] = "WithSchedule";
    ScheduleFilterType["LATEUPLOAD"] = "LateUpload";
    ScheduleFilterType["DONEUPLOAD"] = "DoneUpload";
    ScheduleFilterType["DONEUPLOADNOLINK"] = "DoneUploadNoLink";
})(ScheduleFilterType || (ScheduleFilterType = {}));
export var ScheduleType;
(function (ScheduleType) {
    ScheduleType["ALLATONCE"] = "AllAtOnce";
    ScheduleType["ONEBYONE"] = "OneByOne";
    ScheduleType["CARRYOVER"] = "CarryOver";
    ScheduleType["PARTIAL"] = "Partial";
})(ScheduleType || (ScheduleType = {}));
export var dummyScheduleModelOptions = [
    {
        name: 'Satu Per Satu',
        value: ScheduleType.ONEBYONE,
    },
    {
        name: 'Keseluruhan',
        value: ScheduleType.ALLATONCE,
    },
    {
        name: 'Parsial',
        value: ScheduleType.PARTIAL,
    },
    //add carryover here if needed
];
export var ScheduleOptions;
(function (ScheduleOptions) {
    ScheduleOptions["NOSCHEDULE"] = "NoSchedule";
    ScheduleOptions["RESCHEDULE"] = "ReSchedule";
})(ScheduleOptions || (ScheduleOptions = {}));
export var dummyScheduleNumber = [
    {
        name: '1',
        value: '1',
    },
    {
        name: '2',
        value: '2',
    },
    {
        name: '3',
        value: '3',
    },
];
export var scheduleTalentCategory = [
    {
        name: 'Belum Dijadwal',
        value: 'NoSchedule',
    },
    {
        name: 'Reschedule',
        value: 'Reschedule',
    },
];
export var transferInitialValues = {
    paymentMethod: '',
    accountNumber: '',
    taxStatus: '',
    transferTotal: '',
    note: '',
    transferProof: '',
    status: '',
    transferSource: '',
};
export var ScheduleInitialValues = {
    schedulingType: '',
    startDate: '',
    totalPost: '',
    details: [
        {
            date: '',
            briefingTypePubId: '',
            briefingDetailTypePubId: '',
            campaignProductPubId: '',
            briefCategory: '',
            briefCategoryPubId: '',
            campaignSeriesProductPubId: '',
            campaignDetailProductPubId: '',
            monthYear: '',
            linkBrief: '',
        },
    ],
};
