var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import InputFormik from '@src/components/Atoms/Formik/Input/InputFormik';
import { handleFocus } from '@src/app/helper/NumberHelpers';
var SForm = function (_a) {
    var useForm = _a.useForm;
    var id = useParams().id;
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: 'mx-5 mx-xl-15 my-7' }, { children: _jsx(InputFormik, { label: 'Nominal', type: 'text', placeholder: 'Nominal', name: 'amount', formik: useForm, formatNumber: true, isRequired: true, onfocus: handleFocus }) })) }));
};
export default SForm;
