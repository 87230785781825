var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import FileFormik from '../../Formik/Input/FileFormik';
import TextAreaFormik from '../../Formik/TextArea/TextAreaFormik';
import InputFormik from '../../Formik/Input/InputFormik';
var RefundTransferForm = function (_a) {
    var useForm = _a.useForm;
    var _b = useState({
        proofRefundFilePubId: '',
    }), files = _b[0], setFiles = _b[1];
    var onChangeFiles = function (e) {
        var _a;
        setFiles(__assign(__assign({}, files), (_a = {}, _a[e.target.name] = e.target.files, _a)));
        useForm.setFieldValue(e.target.name, e.target.files);
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: 'mb-3' }, { children: _jsx("div", { children: _jsx(InputFormik, { label: 'Total Refund', type: 'text', placeholder: 'Total Refund', name: 'totalAmount', formik: useForm }) }) })), _jsx("div", __assign({ className: 'mb-3' }, { children: _jsx("div", { children: _jsx(TextAreaFormik, { label: 'Alasan', placeholder: 'Alasan', name: 'reason', formik: useForm }) }) })), _jsx("div", __assign({ className: 'mb-3' }, { children: _jsx("div", { children: _jsx(FileFormik, { label: 'Refund Transfer', placeholder: 'Input refund transfer here', name: 'proofRefundFilePubId', isRequired: true, formik: useForm, onChange: onChangeFiles }) }) }))] }));
};
export default RefundTransferForm;
