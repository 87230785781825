// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b46G2JdL1O9vNLxKv6u0{margin-top:.5rem;text-decoration:underline;cursor:pointer}.iSVe93rO643yCXMXEZ0p{margin-top:2rem;margin-bottom:1.2rem}.iSVe93rO643yCXMXEZ0p ._AhE61R5tMGUm1QWFBme{font-weight:600}.aw3WXWs59vEQhgTkA_t9{margin-bottom:1rem}.uIL78PUO_p8z0C5oEuxI{margin-top:.5rem;text-decoration:underline;cursor:pointer;color:var(--bs-link-color)}.Mbt15Bm7Js43gxDrkGzX{margin-top:1.7rem;margin-bottom:1rem}", "",{"version":3,"sources":["webpack://./src/components/molecules/Record/Record.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,yBAAA,CACA,cAAA,CAGF,sBACE,eAAA,CACA,oBAAA,CAEA,4CACE,eAAA,CAIJ,sBACE,kBAAA,CAGF,sBACE,gBAAA,CACA,yBAAA,CACA,cAAA,CACA,0BAAA,CAGF,sBACE,iBAAA,CACA,kBAAA","sourcesContent":[".record-talent__name {\n  margin-top: 0.5rem;\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.record-cp__section {\n  margin-top: 2rem;\n  margin-bottom: 1.2rem;\n\n  .title {\n    font-weight: 600;\n  }\n}\n\n.record-socmed__section {\n  margin-bottom: 1rem;\n}\n\n.record-job__id {\n  margin-top: 0.5rem;\n  text-decoration: underline;\n  cursor: pointer;\n  color: var(--bs-link-color);\n}\n\n.record-job__total-fee {\n  margin-top: 1.7rem;\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"record-talent__name": "b46G2JdL1O9vNLxKv6u0",
	"record-cp__section": "iSVe93rO643yCXMXEZ0p",
	"title": "_AhE61R5tMGUm1QWFBme",
	"record-socmed__section": "aw3WXWs59vEQhgTkA_t9",
	"record-job__id": "uIL78PUO_p8z0C5oEuxI",
	"record-job__total-fee": "Mbt15Bm7Js43gxDrkGzX"
};
export default ___CSS_LOADER_EXPORT___;
