var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState, useEffect, } from 'react';
var MetronicSplashScreenContext = createContext(undefined);
var MetronicSplashScreenProvider = function (_a) {
    var children = _a.children;
    var _b = useState(0), count = _b[0], setCount = _b[1];
    var visible = count > 0;
    useEffect(function () {
        var splashScreen = document.getElementById('splash-screen');
        // Show SplashScreen
        if (splashScreen && visible) {
            splashScreen.classList.remove('hidden');
            return function () {
                splashScreen.classList.add('hidden');
            };
        }
        // Hide SplashScreen
        var timeout;
        if (splashScreen && !visible) {
            timeout = window.setTimeout(function () {
                splashScreen.classList.add('hidden');
            }, 3000);
        }
        return function () {
            clearTimeout(timeout);
        };
    }, [visible]);
    return (_jsx(MetronicSplashScreenContext.Provider, __assign({ value: setCount }, { children: children })));
};
var LayoutSplashScreen = function (_a) {
    var _b = _a.visible, visible = _b === void 0 ? true : _b;
    // Everything are ready - remove splashscreen
    var setCount = useContext(MetronicSplashScreenContext);
    useEffect(function () {
        if (!visible) {
            return;
        }
        if (setCount) {
            setCount(function (prev) {
                return prev + 1;
            });
        }
        return function () {
            if (setCount) {
                setCount(function (prev) {
                    return prev - 1;
                });
            }
        };
    }, [setCount, visible]);
    return null;
};
export { MetronicSplashScreenProvider, LayoutSplashScreen };
