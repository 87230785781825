var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DataTable from 'react-data-table-component';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import { Fragment, useState } from 'react';
import Loading from '@src/components/Atoms/Loading/Loading';
import RecordConfirmation from '@src/components/molecules/Record/Confirmation';
import RecordJoblist from '@src/components/molecules/Record/JobList';
import { isFullURL } from '@src/_metronic/helpers';
import TransferProofActionButton from '@src/components/Atoms/Buttons/TransferProofActionButton';
import Insight from '@src/components/molecules/Schedule/Insight';
import { paginationOptions } from '@src/app/helper';
import DrawerDataTable from '@src/components/molecules/Drawer/DrawerDataTable';
import EditScheduleForm from '@src/components/Atoms/Drawer/ScheduleDrawer/EditScheduleForm';
var BuktiUpload = function (_a) {
    var datas = _a.datas, isLoading = _a.isLoading, filter = _a.filter, handlePageSizeChange = _a.handlePageSizeChange, handlePageChange = _a.handlePageChange, handleFilterChange = _a.handleFilterChange, refetchSchedule = _a.refetchSchedule;
    var _b = useState(false), showInsight = _b[0], setShowInsight = _b[1];
    var _c = useState(0), indexData = _c[0], setIndexData = _c[1];
    var _d = useState(false), isOpenEditSettings = _d[0], setIsOpenEditSettings = _d[1];
    var _e = useState(), talentRecord = _e[0], setTalentRecord = _e[1];
    var columns = [
        {
            name: 'Kerja Sama',
            cell: function (record) {
                return (_jsx(RecordJoblist, { schedules: record.schedules, jobNumber: record.jobNumber, isFromTalent: true }));
            },
            sortable: true,
        },
        {
            name: 'Oleh',
            cell: function (record) {
                return _jsx(RecordConfirmation, { record: record.schedules, tooltipTeam: record.team.name });
            },
            sortable: true,
        },
        {
            name: 'Action',
            cell: function (record, rowIndex) {
                return (_jsx(TransferProofActionButton, { schedules: __spreadArray([], record.schedules, true), talent: record.talent, linkItem: handleLinkItem, insightItem: function () { return handleInsightItem(rowIndex); }, imgItem: handleImgItem, editItem: handleEditItem }));
            },
        },
    ];
    var handleEditItem = function (record, talentId, tenantId) {
        var recordData = __assign(__assign({}, record), { talentId: talentId, tenantId: tenantId });
        setIsOpenEditSettings(true);
        setTalentRecord(recordData);
    };
    var handleImgItem = function (file) {
        window.open("".concat(file), '_blank');
    };
    var handleInsightItem = function (rowIndex) {
        setIndexData(rowIndex);
        setShowInsight(true);
    };
    var handleLinkItem = function (url) {
        var checkUrl = isFullURL(url);
        if (!checkUrl) {
            window.open("https://".concat(url), '_blank');
        }
        else {
            window.open(url, '_blank');
        }
    };
    return (_jsxs(Fragment, { children: [!isLoading ? (datas && datas.data && datas.data.length > 0 ? (_jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: datas ? datas.data : [], noHeader: true, progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: datas && datas.recordsFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange })) : (_jsx(NotFound, {}))) : (_jsx(Loading, {})), _jsx(Insight, { datas: datas && datas.data[indexData], isOpen: showInsight, handleClose: function () {
                    setShowInsight(false);
                } }), isOpenEditSettings && (_jsx(DrawerDataTable, { isOpen: isOpenEditSettings, handleClose: setIsOpenEditSettings, record: talentRecord, component: EditScheduleForm, size: 'LG', title: 'Edit Jadwal', refetch: refetchSchedule }))] }));
};
export default BuktiUpload;
