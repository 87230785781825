var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { AsideDefault } from './components/aside/AsideDefault';
import { Footer } from './components/Footer';
import { HeaderWrapper } from './components/header/HeaderWrapper';
import { ScrollTop } from './components/ScrollTop';
import { Content } from './components/Content';
import { PageDataProvider } from './core';
import { useLocation } from 'react-router-dom';
import { MenuComponent } from '../assets/ts/components';
var MasterLayout = function () {
    var location = useLocation();
    useEffect(function () {
        setTimeout(function () {
            MenuComponent.reinitialization();
        }, 500);
    }, []);
    useEffect(function () {
        setTimeout(function () {
            MenuComponent.reinitialization();
        }, 500);
    }, [location.key]);
    return (_jsxs(PageDataProvider, { children: [_jsx("div", __assign({ className: 'd-flex flex-column flex-root' }, { children: _jsxs("div", __assign({ className: 'page d-flex flex-row flex-column-fluid' }, { children: [_jsx(AsideDefault, {}), _jsxs("div", __assign({ className: 'wrapper d-flex flex-column flex-row-fluid', id: 'kt_wrapper' }, { children: [_jsx(HeaderWrapper, {}), _jsx("div", __assign({ id: 'kt_content', className: 'content d-flex flex-column flex-column-fluid' }, { children: _jsx(Content, { children: _jsx(Outlet, {}) }) })), _jsx(Footer, {})] }))] })) })), _jsx(ScrollTop, {})] }));
};
export { MasterLayout };
