var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DefaultLayoutConfig } from './DefaultLayoutConfig';
var LAYOUT_CONFIG_KEY = process.env.REACT_APP_BASE_LAYOUT_CONFIG_KEY || 'LayoutConfig';
export function getLayout() {
    var ls = localStorage.getItem(LAYOUT_CONFIG_KEY);
    if (ls) {
        try {
            return JSON.parse(ls);
        }
        catch (er) {
            console.error(er);
        }
    }
    return DefaultLayoutConfig;
}
function setLayout(config) {
    try {
        localStorage.setItem(LAYOUT_CONFIG_KEY, JSON.stringify(config));
    }
    catch (er) {
        console.error(er);
    }
}
export function getEmptyCssClasses() {
    return {
        header: [],
        headerContainer: [],
        headerMobile: [],
        headerMenu: [],
        aside: [],
        asideMenu: [],
        asideToggle: [],
        toolbar: [],
        toolbarContainer: [],
        content: [],
        contentContainer: [],
        footerContainer: [],
        sidebar: [],
        pageTitle: [],
    };
}
export function getEmptyHTMLAttributes() {
    return {
        asideMenu: new Map(),
        headerMobile: new Map(),
        headerMenu: new Map(),
        headerContainer: new Map(),
        pageTitle: new Map(),
    };
}
export function getEmptyCSSVariables() {
    return {
        body: new Map(),
    };
}
var LayoutSetup = /** @class */ (function () {
    function LayoutSetup() {
    }
    LayoutSetup.initCSSClasses = function () {
        LayoutSetup.classes = getEmptyCssClasses();
    };
    LayoutSetup.initHTMLAttributes = function () {
        LayoutSetup.attributes = Object.assign({}, getEmptyHTMLAttributes());
    };
    LayoutSetup.initCSSVariables = function () {
        LayoutSetup.cssVariables = getEmptyCSSVariables();
    };
    LayoutSetup.initLayout = function (config) { };
    LayoutSetup.initHeader = function (config) {
        LayoutSetup.classes.headerContainer.push(config.width === 'fluid' ? 'container-fluid' : 'container-xxl');
        if (config.fixed.desktop) {
            document.body.classList.add('header-fixed');
        }
        if (config.fixed.tabletAndMobile) {
            document.body.classList.add('header-tablet-and-mobile-fixed');
        }
    };
    LayoutSetup.initToolbar = function (config) { };
    LayoutSetup.initPageTitle = function (config) { };
    LayoutSetup.initContent = function (config) {
        LayoutSetup.classes.contentContainer.push(config.width === 'fluid' ? 'container-fluid' : 'container-xxl');
    };
    LayoutSetup.initAside = function (config) {
        // Fixed aside
        if (config.fixed) {
            document.body.classList.add('aside-fixed');
        }
        // Default minimized
        if (config.minimized) {
            document.body.setAttribute('data-kt-aside-minimize', 'on');
            LayoutSetup.classes.asideToggle.push('active');
        }
        // Aside secondary
        if (config.secondaryDisplay) {
            document.body.classList.add('aside-secondary-enabled');
        }
        else {
            document.body.classList.add('aside-secondary-enabled');
        }
    };
    LayoutSetup.initAsideMenu = function (config) { };
    LayoutSetup.initFooter = function (config) {
        LayoutSetup.classes.footerContainer.push("container-".concat(config.width === 'fluid' ? 'fluid' : 'xxl'));
    };
    LayoutSetup.initConfig = function (config) {
        var _a, _b;
        if ((_a = config.main) === null || _a === void 0 ? void 0 : _a.darkSkinEnabled) {
            document.body.classList.add('dark-skin');
        }
        // Init layout
        LayoutSetup.initLayout(config);
        if (((_b = config.main) === null || _b === void 0 ? void 0 : _b.type) !== 'default') {
            return;
        }
        LayoutSetup.initHeader(config.header);
        LayoutSetup.initPageTitle(config.pageTitle);
        LayoutSetup.initToolbar(config.toolbar);
        LayoutSetup.initContent(config.content);
        LayoutSetup.initAside(config.aside);
        LayoutSetup.initFooter(config.footer);
        LayoutSetup.initAsideMenu(config.aside);
    };
    LayoutSetup.updatePartialConfig = function (fieldsToUpdate) {
        var config = LayoutSetup.config;
        var updatedConfig = __assign(__assign({}, config), fieldsToUpdate);
        LayoutSetup.initCSSClasses();
        LayoutSetup.initCSSVariables();
        LayoutSetup.initHTMLAttributes();
        LayoutSetup.isLoaded = false;
        LayoutSetup.config = updatedConfig;
        LayoutSetup.initConfig(Object.assign({}, updatedConfig));
        LayoutSetup.isLoaded = true; // remove loading there
        return updatedConfig;
    };
    LayoutSetup.setConfig = function (config) {
        setLayout(config);
    };
    LayoutSetup.isLoaded = false;
    LayoutSetup.config = getLayout();
    LayoutSetup.classes = getEmptyCssClasses();
    LayoutSetup.attributes = getEmptyHTMLAttributes();
    LayoutSetup.cssVariables = getEmptyCSSVariables();
    LayoutSetup.bootstrap = (function () {
        LayoutSetup.updatePartialConfig(LayoutSetup.config);
    })();
    return LayoutSetup;
}());
export { LayoutSetup };
