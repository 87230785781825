// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".q_VSxjGmiIUknJP7y4Ru{padding:1rem;display:flex;align-items:center;justify-content:flex-start;column-gap:1rem}.q_VSxjGmiIUknJP7y4Ru span{cursor:pointer}.Px0qluQyu_aHKNR7II1Y{padding:1.5rem}", "",{"version":3,"sources":["webpack://./src/components/Atoms/Drawer/Drawer.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,eAAA,CAEA,2BACE,cAAA,CAIJ,sBACE,cAAA","sourcesContent":[".back-container {\n  padding: 1rem;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  column-gap: 1rem;\n\n  span {\n    cursor: pointer;\n  }\n}\n\n.drawer-content {\n  padding: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"back-container": "q_VSxjGmiIUknJP7y4Ru",
	"drawer-content": "Px0qluQyu_aHKNR7II1Y"
};
export default ___CSS_LOADER_EXPORT___;
