var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useDebounce } from 'use-debounce';
import { FormatParam } from '@src/app/helper';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import axios from 'axios';
var API_BASE_URL = process.env.NEXUS_API_URL;
var FILE_API_BASE_URL = process.env.NEXUS_FILE_API_URL;
var API_URL = {
    BASE_JOB_URL: "".concat(API_BASE_URL, "/jobs"),
};
function fetchJob(param) {
    if (param === void 0) { param = {}; }
    return __awaiter(this, void 0, void 0, function () {
        var queryString, url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryString = FormatParam(param);
                    url = "".concat(API_URL.BASE_JOB_URL, "/?").concat(queryString);
                    return [4 /*yield*/, axios.get(url)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response.data];
            }
        });
    });
}
function fetchTalentJob(id, param) {
    if (param === void 0) { param = {}; }
    return __awaiter(this, void 0, void 0, function () {
        var queryString, strId, url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryString = FormatParam(param);
                    strId = '';
                    url = "".concat(API_URL.BASE_JOB_URL, "/by-talent/").concat(id, "?").concat(queryString, " ");
                    return [4 /*yield*/, axios.get(url)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response.data];
            }
        });
    });
}
function fetchRejectTalents(param) {
    return __awaiter(this, void 0, void 0, function () {
        var queryString, url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryString = FormatParam(param);
                    url = "".concat(API_URL.BASE_JOB_URL, "/reject-talent?").concat(queryString);
                    return [4 /*yield*/, axios.get(url)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    });
}
function fetchJobById(_a) {
    var _b = _a === void 0 ? {} : _a, id = _b.id;
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    url = "".concat(API_URL.BASE_JOB_URL, "/").concat(id);
                    return [4 /*yield*/, axios.get(url)];
                case 1:
                    response = (_c.sent()).data;
                    return [2 /*return*/, response.data];
            }
        });
    });
}
function fetchJobHistory(_a) {
    var id = _a.id;
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    url = "".concat(API_URL.BASE_JOB_URL, "/").concat(id, "/histories");
                    return [4 /*yield*/, axios.get(url)];
                case 1:
                    response = (_b.sent()).data;
                    return [2 /*return*/, response.data];
            }
        });
    });
}
function processJob(job) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = job.id, values = __rest(job, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(API_URL.BASE_JOB_URL, "/").concat(id, "/process"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
function finalRecapJob(job) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = job.id, values = __rest(job, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(API_URL.BASE_JOB_URL, "/").concat(id, "/final-recap"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
function updateJob(job) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = job.id, values = __rest(job, ["id"]);
                    return [4 /*yield*/, axios.put("".concat(API_URL.BASE_JOB_URL, "/").concat(id), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
function cancelJob(_a) {
    var id = _a.id, reason = _a.reason;
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, axios.post("".concat(API_URL.BASE_JOB_URL, "/").concat(id, "/cancel"), {
                        note: reason,
                    })];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
function approveJob(_a) {
    var id = _a.id, reason = _a.reason, paymentDue = _a.paymentDue;
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, axios.post("".concat(API_URL.BASE_JOB_URL, "/").concat(id, "/approve"), {
                        note: reason,
                        paymentDue: paymentDue,
                    })];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
function undoApprovalJob(jobPubId) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post("".concat(API_URL.BASE_JOB_URL, "/").concat(jobPubId, "/undo-approve"), { note: '' })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
function templateInvoiceJob(job) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = job.id, values = __rest(job, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(API_URL.BASE_JOB_URL, "/").concat(id, "/generate-invoice"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
function templateSPKJob(job) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = job.id, values = __rest(job, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(API_URL.BASE_JOB_URL, "/").concat(id, "/generate-spk"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
function templateSPJob(job) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = job.id, values = __rest(job, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(API_URL.BASE_JOB_URL, "/").concat(id, "/generate-surat-pernyataan"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
function templateSKJob(job) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = job.id, values = __rest(job, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(API_URL.BASE_JOB_URL, "/").concat(id, "/generate-surat-kuasa"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
export function useJobById(id, enabled) {
    return useQuery(['jobDetail', id], function () { return fetchJobById({ id: id }); }, {
        enabled: enabled,
    });
}
export function useJobHistory(id, queryOptions) {
    return useQuery(['jobHistory', id], function () { return fetchJobHistory({ id: id }); }, queryOptions);
}
// Custom hook for CRUD operations
export function useRejectTalentsQuery(params, queryOptions) {
    var _a = useQuery(__assign({ queryKey: ['jobs', 'reject-talent', params], queryFn: function () { return fetchRejectTalents(params); } }, queryOptions)), data = _a.data, isLoading = _a.isLoading, others = __rest(_a, ["data", "isLoading"]);
    return __assign({ data: data === null || data === void 0 ? void 0 : data.data, isLoading: isLoading }, others);
}
export function useJob(param, enabled) {
    var _this = this;
    var queryClient = useQueryClient();
    var debouncedFilter = useDebounce(param, 500)[0];
    var _a = useQuery(['jobs', debouncedFilter], function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, fetchJob(param)];
    }); }); }, {
        enabled: enabled,
    }), jobs = _a.data, isLoading = _a.isLoading, error = _a.error, refetch = _a.refetch;
    var processJobMutation = useMutation(processJob, {
        onSuccess: function (newJob) {
            queryClient.setQueryData('jobs', function (oldJob) {
                if (oldJob === void 0) { oldJob = []; }
                return __spreadArray(__spreadArray([], oldJob, true), [newJob], false);
            });
            toast.success('Kerja sama berhasil diproses');
            queryClient.refetchQueries({ queryKey: 'jobDetail' });
        },
        onError: function (error) {
            toast.error("Kerja sama gagal diproses: ".concat(error.message));
        },
    });
    var finalRecapJobMutation = useMutation(finalRecapJob, {
        onSuccess: function (newJob) {
            queryClient.setQueryData('jobs', function (oldJob) {
                if (oldJob === void 0) { oldJob = []; }
                return __spreadArray(__spreadArray([], oldJob, true), [newJob], false);
            });
            toast.success('Kerja sama berhasil direkap');
            queryClient.refetchQueries({ queryKey: 'jobDetail' });
        },
        onError: function (error) {
            toast.error("Kerja sama gagal direkap: ".concat(error.message));
        },
    });
    var updateJobMutation = useMutation(updateJob, {
        onSuccess: function (newJob) {
            queryClient.setQueryData('jobs', function (oldJob) {
                if (oldJob === void 0) { oldJob = []; }
                return __spreadArray(__spreadArray([], oldJob, true), [newJob], false);
            });
            toast.success('Kerja sama berhasil diupdate');
            queryClient.refetchQueries({ queryKey: 'jobDetail' });
            queryClient.refetchQueries({ queryKey: 'jobs' });
        },
        onError: function (error) {
            toast.error("Kerja sama gagal diupdate: ".concat(error.message));
        },
    });
    var cancelJobMutation = useMutation(cancelJob, {
        onSuccess: function (newJob) {
            queryClient.setQueryData('jobs', function (oldJob) {
                if (oldJob === void 0) { oldJob = []; }
                return __spreadArray(__spreadArray([], oldJob, true), [newJob], false);
            });
            toast.success('Kerja sama berhasil dibatalkan');
            queryClient.refetchQueries({ queryKey: 'jobDetail' });
        },
        onError: function (error) {
            toast.error("Kerja sama gagal dibatalkan: ".concat(error.message));
        },
    });
    var approveJobMutation = useMutation(approveJob, {
        onSuccess: function (newJob) {
            queryClient.setQueryData('jobs', function (oldJob) {
                if (oldJob === void 0) { oldJob = []; }
                return __spreadArray(__spreadArray([], oldJob, true), [newJob], false);
            });
            toast.success('Kerja sama berhasil diapprove');
            queryClient.refetchQueries({ queryKey: 'jobDetail' });
            queryClient.refetchQueries({ queryKey: 'talentJobs' });
            queryClient.refetchQueries({ queryKey: 'jobs' });
        },
        onError: function (error) {
            toast.error("Kerja sama gagal diapprove: ".concat(error.message));
        },
    });
    var undoApprovalMutation = useMutation(undoApprovalJob, {
        onSuccess: function () {
            toast.success('Kerja sama berhasil di undo');
            queryClient.refetchQueries({ queryKey: 'jobDetail' });
            queryClient.refetchQueries({ queryKey: 'talentJobs' });
        },
        onError: function (error) {
            toast.error("Kerja sama gagal undo: ".concat(error.message));
        },
    });
    var templateInvoiceJobMutation = useMutation(templateInvoiceJob, {
        onSuccess: function (newJob) {
            queryClient.setQueryData('jobs', function (oldJob) {
                if (oldJob === void 0) { oldJob = []; }
                return __spreadArray(__spreadArray([], oldJob, true), [newJob], false);
            });
            toast.success('Template Invoice berhasil digenerate');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Template Invoice gagal digenerate: ".concat(error.message));
        },
    });
    var templateSPKJobMutation = useMutation(templateSPKJob, {
        onSuccess: function (newJob) {
            queryClient.setQueryData('jobs', function (oldJob) {
                if (oldJob === void 0) { oldJob = []; }
                return __spreadArray(__spreadArray([], oldJob, true), [newJob], false);
            });
            toast.success('Template SPK berhasil digenerate');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Template SPK gagal digenerate: ".concat(error.message));
        },
    });
    var templateSPJobMutation = useMutation(templateSPJob, {
        onSuccess: function (newJob) {
            queryClient.setQueryData('jobs', function (oldJob) {
                if (oldJob === void 0) { oldJob = []; }
                return __spreadArray(__spreadArray([], oldJob, true), [newJob], false);
            });
            toast.success('Template Surat Pernyataan berhasil digenerate');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Template Surat Pernyataan gagal digenerate: ".concat(error.message));
        },
    });
    var templateSKJobMutation = useMutation(templateSKJob, {
        onSuccess: function (newJob) {
            queryClient.setQueryData('jobs', function (oldJob) {
                if (oldJob === void 0) { oldJob = []; }
                return __spreadArray(__spreadArray([], oldJob, true), [newJob], false);
            });
            toast.success('Template Surat Keterangan berhasil digenerate');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Template Surat Keterangan gagal digenerate: ".concat(error.message));
        },
    });
    return {
        jobs: jobs,
        isLoading: isLoading,
        error: error,
        refetch: refetch,
        processJob: processJobMutation.mutateAsync,
        finalRecapJob: finalRecapJobMutation.mutateAsync,
        updateJob: updateJobMutation.mutateAsync,
        cancelJob: cancelJobMutation.mutateAsync,
        approveJob: approveJobMutation.mutateAsync,
        undoApprovalJob: undoApprovalMutation.mutateAsync,
        templateInvoiceJob: templateInvoiceJobMutation.mutateAsync,
        templateSPKJob: templateSPKJobMutation.mutateAsync,
        templateSPJob: templateSPJobMutation.mutateAsync,
        templateSKJob: templateSKJobMutation.mutateAsync,
    };
}
export function useTalentJob(id, param, enabled) {
    var _this = this;
    var _a = useQuery(['talentJobs', param, id], function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, fetchTalentJob(id, param)];
    }); }); }, {
        enabled: enabled && !!id,
    }), talentJobs = _a.data, isLoading = _a.isLoading, refetchTalentJob = _a.refetch;
    return {
        talentJobs: talentJobs,
        isLoading: isLoading,
        refetchTalentJob: refetchTalentJob,
    };
}
//jobs activity start from here
function fetchActivityById(id) {
    return __awaiter(this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(id !== undefined)) return [3 /*break*/, 2];
                    return [4 /*yield*/, axios.get("".concat(API_URL.BASE_JOB_URL, "/").concat(id, "/comments"))];
                case 1:
                    res = (_a.sent()).data;
                    return [2 /*return*/, res.data];
                case 2: return [2 /*return*/, []];
            }
        });
    });
}
function createActivity(job) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = job.id, values = __rest(job, ["id"]);
                    return [4 /*yield*/, axios.post("".concat(API_URL.BASE_JOB_URL, "/").concat(id, "/comments"), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
function editActivity(job) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = job.id, values = __rest(job, ["id"]);
                    return [4 /*yield*/, axios.put("".concat(API_URL.BASE_JOB_URL, "/").concat(id, "/comments/").concat(job.pubId), values)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
function deleteActivity(job) {
    return __awaiter(this, void 0, void 0, function () {
        var id, values, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = job.id, values = __rest(job, ["id"]);
                    return [4 /*yield*/, axios.delete("".concat(API_URL.BASE_JOB_URL, "/").concat(id, "/comments/").concat(job.pubId))];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    });
}
export function useActivityById(id, queryOptions) {
    return useQuery(['activity', id], function () { return fetchActivityById(id); }, queryOptions);
}
export function useJobsActivity(id) {
    var queryClient = useQueryClient();
    var createActivityMutation = useMutation(createActivity, {
        onSuccess: function (newActivity) {
            queryClient.setQueryData('activity', function (oldActivities) {
                if (oldActivities === void 0) { oldActivities = []; }
                return __spreadArray(__spreadArray([], oldActivities, true), [
                    newActivity,
                ], false);
            });
            toast.success('Aktifitas berhasil dibuat');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Aktifitas gagal dibuat: ".concat(error.message));
        },
    });
    var editActivityMutation = useMutation(editActivity, {
        onSuccess: function (newActivity) {
            queryClient.setQueryData('activity', function (oldActivities) {
                if (oldActivities === void 0) { oldActivities = []; }
                return __spreadArray(__spreadArray([], oldActivities, true), [
                    newActivity,
                ], false);
            });
            toast.success('Aktifitas berhasil diedit');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Aktifitas gagal diedit: ".concat(error.message));
        },
    });
    var deleteActivityMutation = useMutation(deleteActivity, {
        onSuccess: function (newActivity) {
            queryClient.setQueryData('activity', function (oldActivities) {
                if (oldActivities === void 0) { oldActivities = []; }
                return __spreadArray(__spreadArray([], oldActivities, true), [
                    newActivity,
                ], false);
            });
            toast.success('Aktifitas berhasil dihapus');
            queryClient.refetchQueries();
        },
        onError: function (error) {
            toast.error("Aktifitas gagal dihapus: ".concat(error.message));
        },
    });
    return {
        createActivity: createActivityMutation.mutateAsync,
        editActivity: editActivityMutation.mutateAsync,
        deleteActivity: deleteActivityMutation.mutateAsync,
    };
}
