import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Tab } from 'bootstrap';
import { useEffect, useRef } from 'react';
import { MenuComponent, DrawerComponent, ScrollComponent, ScrollTopComponent, StickyComponent, ToggleComponent, SwapperComponent, } from '../assets/ts/components';
import { useLayout } from './core';
export function MasterInit() {
    var config = useLayout().config;
    var isFirstRun = useRef(true);
    var pluginsInitialization = function () {
        isFirstRun.current = false;
        setTimeout(function () {
            ToggleComponent.bootstrap();
            ScrollTopComponent.bootstrap();
            DrawerComponent.bootstrap();
            StickyComponent.bootstrap();
            MenuComponent.bootstrap();
            ScrollComponent.bootstrap();
            SwapperComponent.bootstrap();
            document.querySelectorAll('[data-bs-toggle="tab"]').forEach(function (tab) {
                Tab.getOrCreateInstance(tab);
            });
        }, 500);
    };
    useEffect(function () {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            pluginsInitialization();
        }
    }, [config]);
    return _jsx(_Fragment, {});
}
