var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment, useEffect, useMemo } from 'react';
import { PageTitle } from '@src/_metronic/layout/core';
import { KTCard } from '@src/_metronic/helpers';
import DataTable from 'react-data-table-component';
import '@src/app/styles/datatable.css';
import { useJobs } from './ApprovalJob.hooks';
import { useJob } from '@src/app/pages/JobPage/JobHooks/JobDetail.hooks';
import Select from '@src/components/Atoms/Select/Select';
import Input from '@src/components/Atoms/Input/Input';
import { useSocialMedia } from '../../../SocialMediaPage/SocialMedia/SocialMedia.hooks';
import { useDivisions } from '../../../DivisionPage/DivisionHooks/Division.hooks';
import { useTeams } from '../../../TeamPage/TeamHooks/Team.hooks';
import swal from 'sweetalert';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import RecordTalent from '@src/components/molecules/Record/Talent';
import RecordApproval from '@src/components/molecules/Record/Collaboration';
import RecordAdmin from '@src/components/molecules/Record/Admin';
import { FilterStatus, MultiDimensionalFilter, optionApprovals, paginationOptions, FormatYearMonthDate, DefaultAllList, } from '@src/app/helper';
import { useNavigate, useParams } from 'react-router-dom';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DrawerForm from '@src/components/molecules/Drawer/DrawerForm';
import { holdJobs, unholdJobs } from '@src/app/pages/JobPage/Job/Job.requests';
import HoldTransferForm from '@src/components/Atoms/Drawer/TransferFormDrawer/HoldTransferForm';
import RejectForm from '@src/components/Atoms/Drawer/JobDetailFormDrawer/RejectForm';
import Date from '@src/components/Atoms/Date/Date';
import dayjs from 'dayjs';
import Loading from '@src/components/Atoms/Loading/Loading';
import RecordDescription from '@src/components/molecules/Record/Description';
import ApprovalActionKerjasama from '@src/app/modules/job/ApprovalActionKerjasama';
//breadcrumb
var usersBreadcrumbs = [
    {
        title: 'Approval Kerja Sama',
        path: '',
        isSeparator: true,
        isActive: false,
    },
];
var RejectValues = {
    note: '',
    pubId: '',
};
var RejectFormSchema = Yup.object().shape({
    note: Yup.string().required('Field belum diisi'),
});
var HoldValues = {
    reason: '',
};
var HoldFormSchema = Yup.object().shape({
    reason: Yup.string().required('Field belum diisi'),
});
var ApprovalIndex = function () {
    var intl = useIntl();
    var id = useParams().id;
    var _a = useState({
        page: 1,
        pageSize: 10,
        keyword: '',
        filterBy: '[]',
        Type: optionApprovals[0].value,
    }), filter = _a[0], setFilter = _a[1];
    var _b = useState({
        page: 1,
        pageSize: 100,
        keyword: '',
        filterBy: '[]',
        initialFilterBy: '[]',
    }), filterPageSize = _b[0], setFilterPageSize = _b[1];
    var _c = useState(dayjs().format('YYYY-MM-DD').toString()), date = _c[0], setDate = _c[1];
    var onChangeDate = function (e) {
        var event = { target: { name: '', value: null } };
        event.target.name = 'date';
        event.target.value = e == null ? '' : FormatYearMonthDate(e);
        setDate(event.target.value);
        handleMultiDimensionalFilterChange(event);
    };
    var _d = useState(false), isOpenReject = _d[0], setIsOpenReject = _d[1];
    var _e = useState(false), isOpenHold = _e[0], setIsOpenHold = _e[1];
    var _f = useState(''), talentId = _f[0], setTalentId = _f[1];
    var _g = useState(''), jobId = _g[0], setJobId = _g[1];
    var _h = useJobs(filter), jobs = _h.jobs, isLoading = _h.isLoading, refetchJobs = _h.refetch;
    var _j = useSocialMedia(), socialMedia = _j.socialMedia, isLoadingSocialMedia = _j.isLoading;
    var _k = useTeams(filterPageSize), team = _k.team, isLoadingTeams = _k.isLoading;
    var _l = useDivisions(), divisions = _l.divisions, isLoadingDivisions = _l.isLoading;
    var rejectJob = useJob(id).rejectJob;
    var _m = useState(''), tabName = _m[0], setTabName = _m[1];
    var _o = useState(true), recordHold = _o[0], setRecordHold = _o[1];
    var _p = useState([]), selectedRows = _p[0], setSelectedRows = _p[1];
    var toggleCleared = useState(false)[0];
    var optionSocialMedia = socialMedia &&
        socialMedia.length > 0 &&
        socialMedia.map(function (social) { return ({
            name: social.name,
            value: social.pubId,
        }); });
    optionSocialMedia && optionSocialMedia.unshift(DefaultAllList);
    var optionTeam = team &&
        team.data &&
        team.data.length > 0 &&
        team.data.map(function (team) { return ({
            name: team.name,
            value: team.pubId,
        }); });
    optionTeam && optionTeam.unshift(DefaultAllList);
    var optionDivisions = divisions &&
        divisions.length > 0 &&
        divisions.map(function (division) { return ({
            name: "".concat(division.name, " - ").concat(division.socialMedia.name),
            value: division.pubId,
        }); });
    var handleMultiDimensionalFilterChange = function (event) {
        var filterValue = filter.filterBy !== '' ? JSON.parse(filter.filterBy) : '';
        if (filter.filterBy === '') {
            var filterCriteria = [[event.target.name, event.target.value]];
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterCriteria) }));
        }
        else {
            var filterNew = MultiDimensionalFilter(event, filterValue);
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterNew) }));
            // setSelectedSocialMediaId(event.target.value)
        }
    };
    var handleFilterChange = function (event) {
        var _a;
        setFilter(__assign(__assign({}, filter), (_a = {}, _a[event.target.name] = event.target.value, _a)));
    };
    var handlePageChange = function (page) {
        setFilter(__assign(__assign({}, filter), { page: page }));
    };
    var handlePageSizeChange = function (size) {
        setFilter(__assign(__assign({}, filter), { page: 1, pageSize: size }));
    };
    var navigate = useNavigate();
    //gotodetail
    var gotoDetail = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("/talent/detail/".concat(record.pubId));
            return [2 /*return*/];
        });
    }); };
    var handleUnhold = function () {
        swal({
            title: 'Confirm Action',
            text: 'Do you want to unhold of this records?',
            icon: 'warning',
            buttons: [
                intl.formatMessage({ id: 'GENERAL.VALIDATION.NO' }),
                intl.formatMessage({ id: 'GENERAL.VALIDATION.YES' }),
            ],
            dangerMode: true,
        }).then(function (willUpdate) { return __awaiter(void 0, void 0, void 0, function () {
            var jobId_1, data, e_1, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!willUpdate) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        jobId_1 = [];
                        if (selectedRows.length > 0) {
                            selectedRows.map(function (data) { return jobId_1.push(data.pubId); });
                        }
                        else {
                            toast.error('Silahkan pilih data terlebih dahulu');
                        }
                        return [4 /*yield*/, unholdJobs(jobId_1, '')];
                    case 2:
                        data = (_a.sent()).data;
                        if (data.message) {
                            toast.success(data.message);
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _a.sent();
                        error = e_1.response.data;
                        toast.error(error.message);
                        return [3 /*break*/, 5];
                    case 4:
                        refetchJobs();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); });
    };
    var contextActions = useMemo(function () {
        var handleHold = function () {
            setIsOpenHold(true);
            setTabName(filter.Type === FilterStatus.OnHold ? 'Unhold Kerja Sama' : 'Hold Kerja Sama');
        };
        return (_jsxs(_Fragment, { children: [selectedRows.length > 0 && filter.Type === FilterStatus.OnReview && (_jsx("button", __assign({ onClick: handleHold, className: 'btn btn-sm btn-warning' }, { children: "Hold" }), 'delete')), selectedRows.length > 0 && filter.Type === FilterStatus.OnHold && (_jsx("button", __assign({ onClick: handleUnhold, className: 'btn btn-sm btn-warning' }, { children: "Unhold" }), 'delete'))] }));
    }, [selectedRows, toggleCleared]);
    var handleRowSelected = function (state) {
        setSelectedRows(state.selectedRows);
    };
    // TODO: show cp email and cp other and country name on datatable row, as its missing from api return
    var columns = [
        {
            name: 'Talent',
            cell: function (record) { return (_jsx(RecordTalent, { name: record.talent.name, pubId: record.talent.pubId, cpName: record.talent.cpName, cpPhoneNumber: record.talent.cpPhoneNumber, cpEmail: record.talent.cpEmail, profiles: record.talent.profiles, onClick: function () { return gotoDetail(record.talent); } })); },
            sortable: true,
        },
        {
            name: 'Kerja sama',
            cell: function (record) {
                return _jsx(RecordApproval, { id: record.pubId, record: record.job || '' });
            },
            sortable: true,
        },
        {
            name: 'Bank',
            cell: function (record) {
                return (_jsxs("div", { children: [_jsxs("p", { children: [record.job.bankAccount.bank.name, record.job.bankAccount.branchName && " - ".concat(record.job.bankAccount.branchName)] }), _jsx("p", { children: record.job.bankAccount.accountNumber }), _jsx("p", { children: record.job.bankAccount.accountName })] }));
            },
            sortable: true,
        },
        {
            name: 'Fee',
            cell: function (record) {
                return (_jsxs("div", { children: [_jsxs("p", { children: ["Rp ", record.job.totalFee.toLocaleString()] }), _jsx("div", { children: record.job.jobTransfers.map(function (obj, key) {
                                return (_jsxs(_Fragment, { children: [_jsx(RecordDescription, { fee: obj.totalAmount, bankName: obj.bank.name, cityName: obj.talentBankAccount.city && obj.talentBankAccount.city.name, accountNumber: obj.accountNumber, accountName: obj.accountName, pajakType: obj.pajakType, pajakCategory: obj.pajakCategory, pajakMethod: obj.pajakMethod, paymentMethod: obj.paymentMethod, pajakFee: obj.pajakFee, pajakBruto: obj.pajakBruto, pajakPPN: obj.pajakPPN, bankTransfer: obj.bankTransferBy ? obj.bankTransferBy.name : '-', invoice: obj.invoiceUrl, spk: obj.spkUrl, transfer: obj.transferUrl, note: obj.note }), record.job.jobTransfers.length > 1 && _jsx("hr", {})] }));
                            }) })] }));
            },
            sortable: true,
        },
        {
            name: 'Alasan Hold',
            cell: function (record) {
                return _jsx("p", { children: record.job.holdReason });
            },
            omit: recordHold,
        },
        {
            name: 'Oleh',
            cell: function (record) { return _jsx(RecordAdmin, { record: record.job }); },
            sortable: true,
        },
        {
            name: 'Action',
            cell: function (record) {
                return (_jsxs("div", { children: [(!record.job.address && !record.job.bankAccount) ||
                            (!record.job.address && record.job.bankAccount && record.job.bankAccount.approved) ||
                            (record.job.address && record.job.address.approved && !record.job.bankAccount) ||
                            (record.job.address &&
                                record.job.address.approved &&
                                record.job.bankAccount &&
                                record.job.bankAccount.approved) ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: 'mb-1' }, { children: _jsx(ApprovalActionKerjasama, { job: record.job, jobPubId: record.pubId }) })), _jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-danger mx-2', onClick: function () { return rejectItem(record); } }, { children: "Reject" }))] })) : (''), _jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-success', onClick: function () { return invoiceItem(record); } }, { children: "Invoice" }))] }));
            },
        },
    ];
    //reject
    var rejectItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setIsOpenReject(true);
            setTalentId(record.talent.pubId);
            setJobId(record.pubId);
            setTabName('Reject Kerja Sama');
            return [2 /*return*/];
        });
    }); };
    var rejectDetail = { data: RejectValues }.data;
    var rejectJobValues = {
        note: (rejectDetail === null || rejectDetail === void 0 ? void 0 : rejectDetail.note) || '',
        pubId: (rejectDetail === null || rejectDetail === void 0 ? void 0 : rejectDetail.pubId) || '',
    };
    var HoldDetail = { data: HoldValues }.data;
    var holdValues = {
        reason: (HoldDetail === null || HoldDetail === void 0 ? void 0 : HoldDetail.reason) || '',
    };
    var HoldFormik = useFormik({
        initialValues: HoldDetail ? holdValues : HoldValues,
        enableReinitialize: true,
        validationSchema: HoldFormSchema,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var jobId_2, data, e_2, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        jobId_2 = [];
                        if (selectedRows.length > 0) {
                            selectedRows.map(function (data) { return jobId_2.push(data.pubId); });
                        }
                        else {
                            toast.error('Silahkan pilih data terlebih dahulu');
                        }
                        return [4 /*yield*/, holdJobs(jobId_2, values.reason)];
                    case 1:
                        data = (_a.sent()).data;
                        if (data.message) {
                            toast.success(data.message);
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        e_2 = _a.sent();
                        error = e_2.response.data;
                        toast.error(error);
                        return [3 /*break*/, 4];
                    case 3:
                        setIsOpenHold(false);
                        refetchJobs();
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); },
    });
    var RejectFormik = useFormik({
        initialValues: rejectDetail ? rejectJobValues : RejectValues,
        enableReinitialize: true,
        validationSchema: RejectFormSchema,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var response, e_3, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        values.pubId = jobId;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, rejectJob({ id: jobId, note: values.note })];
                    case 2:
                        response = _a.sent();
                        if (response) {
                            toast.success('Data berhasil diupdate');
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        e_3 = _a.sent();
                        error = e_3.response.message;
                        toast.error(error);
                        return [3 /*break*/, 5];
                    case 4:
                        setIsOpenReject(false);
                        refetchJobs();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); },
    });
    var invoiceItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            window.open(record.job.invoiceUrl, '_blank', 'noreferrer');
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        var filterValue = JSON.parse(filter.filterBy);
        if (socialMedia) {
            var filterCriteria = ['division.socialMedia.pubId', socialMedia[0].pubId];
            filterValue.push(filterCriteria);
        }
        if (date) {
            var filterCriteria = ['date', date];
            filterValue.push(filterCriteria);
        }
        setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterValue) }));
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(PageTitle, __assign({ breadcrumbs: usersBreadcrumbs }, { children: "Approval Kerja Sama" })), _jsxs(KTCard, { children: [_jsx("div", __assign({ className: 'd-flex flex-column' }, { children: _jsx("div", __assign({ className: 'card-header border-0 pt-6 d-flex flex-row' }, { children: _jsxs("div", __assign({ className: 'card-title flex' }, { children: [_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Input, { placeholder: 'Search', type: 'text', name: 'keyword', value: filter.keyword, onChange: function (e) {
                                                handleFilterChange(e);
                                            } }) })), _jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Date, { date: date, handleonchange: onChangeDate }) })), _jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Select, { name: 'Type', onChange: function (e) {
                                                handleFilterChange(e);
                                                setSelectedRows([]);
                                                setRecordHold(e.target.value == FilterStatus.OnHold ? false : true);
                                            }, optionList: optionApprovals, defaultValue: optionApprovals[0].value }) })), _jsx("div", __assign({ className: 'card-title' }, { children: !isLoadingSocialMedia && optionSocialMedia && (_jsx(Select, { name: 'division.socialMedia.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, placeholder: 'Filter by Social Media', optionList: optionSocialMedia, defaultValue: optionSocialMedia[0].value, isPlaceholder: false })) })), _jsx("div", __assign({ className: 'card-title' }, { children: !isLoadingDivisions && optionDivisions && (_jsx(Select, { name: 'division.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, placeholder: 'Filter by Division', optionList: optionDivisions })) })), _jsx("div", __assign({ className: 'card-title' }, { children: !isLoadingTeams && optionTeam && (_jsx(Select, { name: 'team.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, placeholder: 'Filter by Team', optionList: optionTeam, isPlaceholder: false })) }))] })) })) })), _jsx("div", __assign({ className: 'card-body py-4' }, { children: _jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: jobs ? jobs.data : [], progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: jobs && jobs.recordsFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange, contextActions: contextActions, onSelectedRowsChange: handleRowSelected, clearSelectedRows: toggleCleared, noHeader: selectedRows.length > 0 ? false : true, selectableRows: true }, jobs && jobs.data.length) }))] }), _jsx(DrawerForm, { tabName: tabName, useForm: HoldFormik, isOpen: isOpenHold, handleClose: setIsOpenHold, onSubmit: HoldFormik.handleSubmit, component: HoldTransferForm, idForEdit: '' }), _jsx(DrawerForm, { tabName: tabName, useForm: RejectFormik, isOpen: isOpenReject, handleClose: setIsOpenReject, onSubmit: RejectFormik.handleSubmit, component: RejectForm, idForEdit: '', name: 'note' })] }));
};
export default ApprovalIndex;
