var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
export var loginSchema = Yup.object().shape({
    username: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Username is required'),
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
});
export var loginInitialValues = {
    username: '',
    password: '',
};
export function LoginForm(_a) {
    var formik = _a.formik, title = _a.title, loading = _a.loading, withBackRoute = _a.withBackRoute;
    var navigate = useNavigate();
    return (_jsxs("form", __assign({ className: 'form w-100', onSubmit: formik.handleSubmit, noValidate: true, id: 'kt_login_signin_form' }, { children: [_jsx("div", __assign({ className: 'text-center mb-10' }, { children: _jsx("h1", __assign({ className: 'text-dark mb-3' }, { children: title })) })), _jsxs("div", __assign({ className: 'fv-row mb-10' }, { children: [_jsx("label", __assign({ className: 'form-label fs-6 fw-bolder text-dark' }, { children: "Username" })), _jsx("input", __assign({ placeholder: 'Username' }, formik.getFieldProps('username'), { className: clsx('form-control form-control-lg form-control-solid', { 'is-invalid': formik.touched.username && formik.errors.username }, {
                            'is-valid': formik.touched.username && !formik.errors.username,
                        }), type: 'username', name: 'username', autoComplete: 'off' })), formik.touched.username && formik.errors.username && (_jsx("div", __assign({ className: 'fv-plugins-message-container' }, { children: _jsx("div", __assign({ className: 'fv-help-block' }, { children: _jsx("span", __assign({ role: 'alert' }, { children: formik.errors.username })) })) })))] })), _jsxs("div", __assign({ className: 'fv-row mb-10' }, { children: [_jsx("div", __assign({ className: 'd-flex justify-content-between mt-n5' }, { children: _jsx("div", __assign({ className: 'd-flex flex-stack mb-2' }, { children: _jsx("label", __assign({ className: 'form-label fw-bolder text-dark fs-6 mb-0' }, { children: "Password" })) })) })), _jsx("input", __assign({ type: 'password', placeholder: 'Password', autoComplete: 'off' }, formik.getFieldProps('password'), { className: clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.password && formik.errors.password,
                        }, {
                            'is-valid': formik.touched.password && !formik.errors.password,
                        }) })), formik.touched.password && formik.errors.password && (_jsx("div", __assign({ className: 'fv-plugins-message-container' }, { children: _jsx("div", __assign({ className: 'fv-help-block' }, { children: _jsx("span", __assign({ role: 'alert' }, { children: formik.errors.password })) })) })))] })), formik.status && (_jsx("div", __assign({ className: 'fv-row mb-10' }, { children: _jsx("div", __assign({ className: 'fv-plugins-message-container' }, { children: _jsx("div", __assign({ className: 'fv-help-block' }, { children: _jsx("span", __assign({ role: 'alert' }, { children: formik.status })) })) })) }))), _jsxs("div", __assign({ className: 'text-center d-flex gap-2' }, { children: [_jsxs("button", __assign({ type: 'submit', id: 'kt_sign_in_submit', className: 'btn btn-lg btn-primary w-100', disabled: formik.isSubmitting || !formik.isValid }, { children: [!loading && _jsx("span", __assign({ className: 'indicator-label' }, { children: "Continue" })), loading && (_jsxs("span", __assign({ className: 'indicator-progress', style: { display: 'block' } }, { children: ["Please wait...", _jsx("span", { className: 'spinner-border spinner-border-sm align-middle ms-2' })] })))] })), withBackRoute && (_jsx("button", __assign({ onClick: function () {
                            navigate(withBackRoute);
                        }, className: 'btn btn-light btn-active-light-primary w-100' }, { children: "Kembali" })))] }))] })));
}
