var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styles from './Record.module.scss';
import { FormatDateTime } from '@src/app/helper';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
// diambil yang paling atas 1 record aja
var RecordConfirmation = function (_a) {
    var record = _a.record, tooltipTeam = _a.tooltipTeam;
    return (_jsx("div", { children: record && record.length > 0 ? (_jsx(OverlayTrigger, __assign({ placement: "top", delay: { show: 250, hide: 400 }, overlay: function (props) { return (_jsx(Tooltip, __assign({ id: "button-tooltip" }, props, { children: tooltipTeam }))); } }, { children: _jsxs("div", __assign({ className: 'mb-3' }, { children: [_jsxs("div", { children: [_jsx("p", { children: "Dibuat Oleh:" }), _jsx("p", { children: record[0].schedule.createdBy || '-' }), _jsx("p", { children: FormatDateTime(record[0].schedule.createdOn) })] }), _jsx("hr", {}), _jsx("div", { children: record[0].schedule.updatedOn ? (_jsxs(_Fragment, { children: [_jsx("p", __assign({ className: styles['title'] }, { children: "Diubah Oleh:" })), _jsx("p", { children: record[0].schedule.updatedBy }), _jsx("p", __assign({ className: 'mb-1' }, { children: FormatDateTime(record[0].schedule.updatedOn) }))] })) : ('-') })] })) }))) : ('-') }));
};
export default RecordConfirmation;
