var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { KTCard } from '@src/_metronic/helpers';
import { PageTitle } from '@src/_metronic/layout/core';
import { DefaultAllList, FormatYearMonthDate, MultiDimensionalFilter, paginationOptions, } from '@src/app/helper';
import { useSendProduct } from '@src/app/pages/JobPage/JobHooks/JobDetail.hooks';
import { SendProductContextProvider } from '@src/app/pages/SendProductPage/SendProduct/SendProduct';
import { initialSendProductValues } from '@src/app/pages/SendProductPage/SendProduct/SendProduct.types';
import { useSendProductById, useSendProducts, } from '@src/app/pages/SendProductPage/SendProductHooks/SendProduct.hooks';
import '@src/app/styles/datatable.css';
import Date from '@src/components/Atoms/Date/Date';
import RejectForm from '@src/components/Atoms/Drawer/JobDetailFormDrawer/RejectForm';
import SendProductForm from '@src/components/Atoms/Drawer/SendProductFormDrawer/SendProductForm';
import Input from '@src/components/Atoms/Input/Input';
import Loading from '@src/components/Atoms/Loading/Loading';
import NotFound from '@src/components/Atoms/NotFound/NotFound';
import Select from '@src/components/Atoms/Select/Select';
import DrawerForm from '@src/components/molecules/Drawer/DrawerForm';
import RecordAdmin from '@src/components/molecules/Record/Admin';
import RecordTalent from '@src/components/molecules/Record/Talent';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import * as Yup from 'yup';
import { useDivisions } from '../../../DivisionPage/DivisionHooks/Division.hooks';
import { useSocialMedia } from '../../../SocialMediaPage/SocialMedia/SocialMedia.hooks';
import { useTeams } from '../../../TeamPage/TeamHooks/Team.hooks';
import { useSendProductApproval } from './ApprovalSendProduct.hooks';
//breadcrumb
var usersBreadcrumbs = [
    {
        title: 'Approval Rekap Barang',
        path: '',
        isSeparator: true,
        isActive: false,
    },
];
var RejectValues = {
    reason: '',
};
var RejectFormSchema = Yup.object().shape({});
var SendProductFormSchema = Yup.object().shape({});
var ApprovalSendProductIndex = function () {
    var _a, _b, _c, _d, _e, _f;
    var intl = useIntl();
    var _g = useState(), selectedRecord = _g[0], setSelectedRecord = _g[1];
    var _h = useState({
        page: 1,
        pageSize: 10,
        keyword: '',
        filterBy: '[]',
        initialFilterBy: '[["isApproved", false],["isRejected", false]]',
    }), filter = _h[0], setFilter = _h[1];
    var _j = useState({
        page: 1,
        pageSize: 100,
        keyword: '',
        filterBy: '[]',
        initialFilterBy: '[]',
    }), filterPageSize = _j[0], setFilterPageSize = _j[1];
    var _k = useState(dayjs().format('YYYY-MM-DD').toString()), date = _k[0], setDate = _k[1];
    var onChangeDate = function (e) {
        var event = { target: { name: '', value: null } };
        event.target.name = 'date';
        event.target.value = e == null ? '' : FormatYearMonthDate(e);
        setDate(event.target.value);
        handleMultiDimensionalFilterChange(event);
    };
    //getdata
    var _l = useState([
        {
            productPubId: '',
            productCategoryPubId: '',
            qty: 1,
        },
    ]), newVar = _l[0], setNewVar = _l[1];
    var _m = useState(false), isOpenAdd = _m[0], setIsOpenAdd = _m[1];
    var _o = useState('Alasan Reject Kirim Barang'), tabName = _o[0], setTabName = _o[1];
    var _p = useState(undefined), editId = _p[0], setEditId = _p[1];
    var _q = useState(undefined), talentId = _q[0], setTalentId = _q[1];
    var _r = useState(''), productId = _r[0], setProductId = _r[1];
    var _s = useState(false), isOpenSendProduct = _s[0], setIsOpenSendProduct = _s[1];
    var _t = useState('Kirim Barang'), sendProductTabName = _t[0], setsendProductTabName = _t[1];
    var _u = useSendProductApproval(filter), sendProducts = _u.sendProducts, isLoading = _u.isLoading;
    var _v = useSendProducts(), createSendProduct = _v.createSendProduct, updateSendProduct = _v.updateSendProduct;
    var _w = useSendProduct(editId), approveSendProduct = _w.approveSendProduct, rejectSendProduct = _w.rejectSendProduct;
    var sendProduct = useSendProductById(editId).data;
    var sendProductValues = {
        talentPubId: (_a = sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.talent.pubId) !== null && _a !== void 0 ? _a : '',
        talentAddressPubId: (_b = sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.address.pubId) !== null && _b !== void 0 ? _b : '',
        jobPubId: (_c = sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.pubId) !== null && _c !== void 0 ? _c : '',
        courierPubId: (_d = sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.courier.pubId) !== null && _d !== void 0 ? _d : '',
        details: [],
        sendProduct: (_e = {
            products: sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.details.map(function (obj, key) { return ({
                productPubId: obj.product.pubId,
                productCategoryPubId: obj.product.productCategory.pubId,
                qty: obj.qty,
            }); }),
        }) !== null && _e !== void 0 ? _e : [],
        products: sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.details.map(function (obj, key) { return ({
            productPubId: obj.product.pubId,
            productCategoryPubId: obj.product.productCategory.pubId,
            qty: obj.qty,
        }); }),
        note: (_f = sendProduct === null || sendProduct === void 0 ? void 0 : sendProduct.note) !== null && _f !== void 0 ? _f : '',
    };
    var _x = useSocialMedia(), socialMedia = _x.socialMedia, isLoadingSocialMedia = _x.isLoading, errorSocialMedia = _x.error;
    var _y = useTeams(filterPageSize), team = _y.team, isLoadingTeams = _y.isLoading, errorTeam = _y.error;
    var _z = useDivisions(), divisions = _z.divisions, isLoadingDivisions = _z.isLoading, errorDivisions = _z.error;
    var optionSocialMedia = socialMedia &&
        socialMedia.length > 0 &&
        socialMedia.map(function (social) { return ({
            name: social.name,
            value: social.pubId,
        }); });
    optionSocialMedia && optionSocialMedia.unshift(DefaultAllList);
    var optionTeam = team &&
        team.data &&
        team.data.length > 0 &&
        team.data.map(function (team) { return ({
            name: team.name,
            value: team.pubId,
        }); });
    optionTeam && optionTeam.unshift(DefaultAllList);
    var optionDivisions = divisions &&
        divisions.length > 0 &&
        divisions.map(function (division) { return ({
            name: "".concat(division.name, " - ").concat(division.socialMedia.name),
            value: division.pubId,
        }); });
    var handleMultiDimensionalFilterChange = function (event) {
        var filterValue = filter.filterBy !== '' ? JSON.parse(filter.filterBy) : '';
        if (filter.filterBy === '') {
            var filterCriteria = [[event.target.name, event.target.value]];
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterCriteria) }));
        }
        else {
            var filterNew = MultiDimensionalFilter(event, filterValue);
            setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterNew) }));
        }
    };
    var handleFilterChange = function (event) {
        var _a;
        setFilter(__assign(__assign({}, filter), (_a = {}, _a[event.target.name] = event.target.value, _a)));
    };
    var handlePageChange = function (page) {
        setFilter(__assign(__assign({}, filter), { page: page }));
    };
    var handlePageSizeChange = function (size) {
        setFilter(__assign(__assign({}, filter), { page: 1, pageSize: size }));
    };
    var navigate = useNavigate();
    //gotodetail
    var gotoDetail = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("/talent/detail/".concat(record.pubId));
            return [2 /*return*/];
        });
    }); };
    // TODO: show cp email and cp other and country name on datatable row, as its missing from api return
    var columns = [
        {
            name: 'No Pesanan',
            selector: function (row) { return row.orderNumber; },
            sortable: true,
            width: '170px',
        },
        {
            name: 'Nama',
            cell: function (record) { return (_jsx(RecordTalent, { name: record.talent.name, pubId: record.talent.pubId, cpName: record.talent.cpName, cpPhoneNumber: record.talent.cpPhoneNumber, cpEmail: record.talent.cpEmail ? record.talent.cpEmail : '', onClick: gotoDetail })); },
            sortable: true,
        },
        {
            name: 'Alamat',
            cell: function (record) {
                return (_jsx("div", __assign({ className: '' }, { children: _jsxs("p", { children: [record.address.address, ", ", record.address.city.name, ",", ' ', record.address.city.province.name] }) })));
            },
            sortable: true,
        },
        {
            name: 'Kurir',
            selector: function (row) { return row.courier.name; },
            sortable: true,
        },
        {
            name: 'Barang',
            cell: function (record) {
                return (_jsxs(_Fragment, { children: [record.details.map(function (obj, key) {
                            return (_jsx("div", { children: _jsxs("p", __assign({ className: 'createdby mb-2' }, { children: ["[", obj.product.productCategory.name, "] ", obj.product.name, ", ", obj.qty, " pc(s)"] })) }, key));
                        }), record.message && _jsx("p", __assign({ className: 'fs-7 bg-light p-2 fw-normal' }, { children: record.message }))] }));
            },
            sortable: true,
        },
        {
            name: 'Oleh',
            cell: function (record) { return _jsx(RecordAdmin, { record: record }); },
            sortable: true,
        },
        {
            name: 'Action',
            cell: function (record) {
                return (_jsxs("div", { children: [_jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-success', onClick: function () { return editItem(record); } }, { children: "Edit" })), _jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-primary mx-2', onClick: function () { return approveItem(record); } }, { children: "Approve" })), _jsx("button", __assign({ type: 'button', className: 'btn btn-sm btn-danger', onClick: function () { return rejectItem(record); } }, { children: "Reject" }))] }));
            },
            width: '260px',
        },
    ];
    var SendProductFormik = useFormik({
        initialValues: sendProduct ? sendProductValues : initialSendProductValues,
        enableReinitialize: true,
        validationSchema: SendProductFormSchema,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var courierPubId, jobPubId, note, sendProduct, talentAddressPubId, talentPubId, products, formData, e_1, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        courierPubId = values.courierPubId, jobPubId = values.jobPubId, note = values.note, sendProduct = values.sendProduct, talentAddressPubId = values.talentAddressPubId, talentPubId = values.talentPubId;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, 7, 8]);
                        products = sendProduct.products;
                        formData = {
                            courierPubId: courierPubId,
                            jobPubId: jobPubId,
                            note: note,
                            products: products,
                            talentAddressPubId: talentAddressPubId,
                            talentPubId: talentPubId,
                        };
                        if (!editId) return [3 /*break*/, 3];
                        return [4 /*yield*/, updateSendProduct(__assign({ id: editId }, formData))];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, createSendProduct(formData)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_1 = _a.sent();
                        error = e_1.response.data;
                        toast.error(error);
                        return [3 /*break*/, 8];
                    case 7:
                        setIsOpenSendProduct(false);
                        setEditId(undefined);
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        }); },
    });
    //edit
    var editItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setSelectedRecord(record);
            setIsOpenSendProduct(true);
            setEditId(record.pubId);
            setTalentId(record.talent.pubId);
            setIsOpenAdd(false);
            return [2 /*return*/];
        });
    }); };
    //approve
    var approveItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            swal({
                title: intl.formatMessage({ id: 'GENERAL.VALIDATION' }),
                text: '',
                icon: 'warning',
                buttons: [
                    intl.formatMessage({ id: 'GENERAL.VALIDATION.NO' }),
                    intl.formatMessage({ id: 'GENERAL.VALIDATION.YES' }),
                ],
                dangerMode: true,
            }).then(function (willApprove) { return __awaiter(void 0, void 0, void 0, function () {
                var e_2, error;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!willApprove) return [3 /*break*/, 4];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, approveSendProduct(__assign({}, record))];
                        case 2:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 3:
                            e_2 = _a.sent();
                            error = e_2.response.data;
                            toast.error(error.message);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            }); });
            return [2 /*return*/];
        });
    }); };
    //reject
    var rejectItem = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setProductId(record.pubId);
            setIsOpenAdd(true);
            setIsOpenSendProduct(false);
            return [2 /*return*/];
        });
    }); };
    var rejectDetail = { data: RejectValues }.data;
    var rejectaddressValues = {
        reason: (rejectDetail === null || rejectDetail === void 0 ? void 0 : rejectDetail.reason) || '',
    };
    var RejectFormik = useFormik({
        initialValues: rejectDetail ? rejectaddressValues : RejectValues,
        enableReinitialize: true,
        validationSchema: RejectFormSchema,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var e_3, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, rejectSendProduct(__assign({ id: productId }, values))];
                    case 1:
                        _a.sent();
                        setIsOpenAdd(false);
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        error = e_3.response.data;
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); },
    });
    useEffect(function () {
        var filterValue = JSON.parse(filter.filterBy);
        if (date) {
            var filterCriteria = ['date', date];
            filterValue.push(filterCriteria);
        }
        setFilter(__assign(__assign({}, filter), { filterBy: JSON.stringify(filterValue) }));
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(PageTitle, __assign({ breadcrumbs: usersBreadcrumbs }, { children: "Approval Rekap Barang" })), _jsxs(KTCard, { children: [_jsx("div", __assign({ className: 'd-flex flex-column' }, { children: _jsx("div", __assign({ className: 'card-header border-0 pt-6 d-flex flex-row' }, { children: _jsxs("div", __assign({ className: 'card-title flex' }, { children: [_jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Input, { placeholder: 'Search', type: 'text', name: 'keyword', value: filter.keyword, onChange: function (e) {
                                                handleFilterChange(e);
                                            } }) })), _jsx("div", __assign({ className: 'card-title' }, { children: _jsx(Date, { date: date, handleonchange: onChangeDate }) })), _jsx("div", __assign({ className: 'card-title' }, { children: !isLoadingSocialMedia && optionSocialMedia && (_jsx(Select, { name: 'socialMedia.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, placeholder: 'Filter by Social Media', optionList: optionSocialMedia, isPlaceholder: false })) })), _jsx("div", __assign({ className: 'card-title' }, { children: !isLoadingDivisions && optionDivisions && (_jsx(Select, { name: 'division.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, placeholder: 'Filter by Division', optionList: optionDivisions })) })), _jsx("div", __assign({ className: 'card-title' }, { children: !isLoadingTeams && optionTeam && (_jsx(Select, { name: 'team.pubId', onChange: function (e) {
                                                handleMultiDimensionalFilterChange(e);
                                            }, placeholder: 'Filter by Team', optionList: optionTeam, isPlaceholder: false })) }))] })) })) })), _jsx("div", __assign({ className: 'card-body py-4' }, { children: _jsx(DataTable, { title: ' ', columns: columns, persistTableHead: true, noDataComponent: _jsx(NotFound, {}), data: sendProducts ? sendProducts.data : [], noHeader: true, progressPending: isLoading, progressComponent: _jsx(Loading, {}), pagination: true, paginationServer: true, paginationDefaultPage: filter.page, paginationTotalRows: sendProducts && sendProducts.recordsFiltered, paginationPerPage: filter.pageSize, paginationRowsPerPageOptions: [1, 10, 20, 30, 40, 50, 100], paginationComponentOptions: paginationOptions, onChangeRowsPerPage: handlePageSizeChange, onChangePage: handlePageChange }, sendProducts && sendProducts.data.length) }))] }), _jsx(DrawerForm, { tabName: tabName, useForm: RejectFormik, isOpen: isOpenAdd, handleClose: setIsOpenAdd, onSubmit: RejectFormik.handleSubmit, component: RejectForm, idForEdit: '', name: 'Reason' }), _jsx(SendProductContextProvider, __assign({ currentData: selectedRecord }, { children: _jsx(DrawerForm, { tabName: sendProductTabName, useForm: SendProductFormik, isOpen: isOpenSendProduct, handleClose: setIsOpenSendProduct, onSubmit: SendProductFormik.handleSubmit, component: SendProductForm, idForEdit: talentId, size: 'LG', editableValue: newVar, setEditableValue: setNewVar }) }))] }));
};
export default ApprovalSendProductIndex;
