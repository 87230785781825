var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
var KTCard = function (props) {
    var className = props.className, shadow = props.shadow, flush = props.flush, resetSidePaddings = props.resetSidePaddings, border = props.border, dashed = props.dashed, stretch = props.stretch, rounded = props.rounded, utilityP = props.utilityP, utilityPY = props.utilityPY, utilityPX = props.utilityPX, children = props.children;
    return (_jsx("div", __assign({ className: clsx('card', className && className, {
            'shadow-sm': shadow,
            'card-flush': flush,
            'card-px-0': resetSidePaddings,
            'card-bordered': border,
            'card-dashed': dashed,
        }, stretch && "card-".concat(stretch), utilityP && "p-".concat(utilityP), utilityPX && "px-".concat(utilityPX), utilityPY && "py-".concat(utilityPY), rounded && "card-".concat(rounded)) }, { children: children })));
};
export { KTCard };
