var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { checkIsActive } from '../../../helpers';
import { useLayout } from '../../core';
import { ConditionalWrapper } from '../../../../utils';
var AsideMenuItemWithSubMain = function (_a) {
    var children = _a.children, to = _a.to, title = _a.title, icon = _a.icon, fontIcon = _a.fontIcon, hasBullet = _a.hasBullet, bsTitle = _a.bsTitle;
    var pathname = useLocation().pathname;
    var isActive = checkIsActive(pathname, to);
    var config = useLayout().config;
    var aside = config.aside;
    return (_jsxs("div", __assign({ className: clsx('menu-item py-3', { 'here show': isActive }), "data-kt-menu-trigger": 'click', "data-kt-menu-placement": 'right-start' }, { children: [_jsx(ConditionalWrapper, __assign({ condition: bsTitle != null, wrapper: function (children) { return (_jsx(OverlayTrigger, __assign({ placement: 'right', delay: { show: 50, hide: 200 }, overlay: function (props) { return (_jsx(Tooltip, __assign({ id: 'button-tooltip' }, props, { children: bsTitle }))); } }, { children: children }))); } }, { children: _jsx("span", __assign({ className: 'menu-link menu-center' }, { children: fontIcon && aside.menuIcon === 'font' && (_jsx("span", __assign({ className: 'menu-icon me-0' }, { children: _jsx("i", { className: clsx('bi', fontIcon, 'fs-2') }) }))) })) })), _jsx("div", __assign({ className: clsx('menu-sub menu-sub-dropdown w-225px w-lg-275px px-1 py-4', {
                    'menu-active-bg': isActive,
                }) }, { children: children }))] })));
};
export { AsideMenuItemWithSubMain };
