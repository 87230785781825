var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ProtectedRoute } from '@src/app/helper';
import { Route, Routes, Outlet } from 'react-router-dom';
import TransferIndex from './Transfer/Transfer';
import TransferPerTermIndex from './Transfer/TransferPerTerm';
import GroupTransferIndex from '../GroupTransferPage/GroupTransfer/GroupTransfer';
var TransferPage = function () {
    return (_jsx(Routes, { children: _jsxs(Route, __assign({ element: _jsx(Outlet, {}) }, { children: [_jsx(Route, { path: '/all-transfer', element: _jsx(ProtectedRoute, { permission: 'transfer.view', children: _jsx(TransferIndex, {}) }) }), _jsx(Route, { path: '/per-term', element: _jsx(ProtectedRoute, { permission: 'transfer.view', children: _jsx(TransferPerTermIndex, {}) }) }), _jsx(Route, { path: '/group-transfer', element: _jsx(ProtectedRoute, { permission: 'transfer.view', children: _jsx(GroupTransferIndex, {}) }) })] })) }));
};
export default TransferPage;
