var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Drawer, Typography } from '@mui/material';
import styles from './Drawer.module.scss';
var DrawerSize;
(function (DrawerSize) {
    DrawerSize[DrawerSize["SM"] = 400] = "SM";
    DrawerSize[DrawerSize["MD"] = 568] = "MD";
    DrawerSize[DrawerSize["LG"] = 768] = "LG";
})(DrawerSize || (DrawerSize = {}));
var DrawerComponent = function (_a) {
    var children = _a.children, open = _a.open, handleClose = _a.handleClose, _b = _a.title, title = _b === void 0 ? 'Settings' : _b, _c = _a.size, size = _c === void 0 ? 'SM' : _c;
    return (_jsxs(Drawer, __assign({ sx: {
            width: DrawerSize[size],
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: DrawerSize[size],
                boxSizing: 'border-box',
            },
        }, anchor: 'right', open: open }, { children: [_jsxs("div", __assign({ className: styles['back-container'] }, { children: [_jsx("div", __assign({ className: 'd-flex flex-row-fluid flex-left' }, { children: _jsx(Typography, __assign({ variant: 'h5' }, { children: title })) })), _jsx("div", __assign({ className: 'd-flex flex-column-auto flex-right' }, { children: _jsx("button", __assign({ type: 'button', className: 'close btn btn-flush btn-lg', "aria-label": 'Close', onClick: handleClose }, { children: _jsx("span", __assign({ "aria-hidden": 'true' }, { children: "\u00D7" })) })) }))] })), _jsx(Divider, {}), _jsx("div", __assign({ className: styles['drawer-content'] }, { children: children }))] })));
};
export default DrawerComponent;
